import BaseApi from "../Core/REST/BaseApi";
import { Department } from "./Department";
class DepartmentService extends BaseApi {
  async getAll(): Promise<Array<Department> | undefined>
  {
    let url = `${this.baseUrl}/Departments/getAll`;
    return await this.get<Array<Department> | undefined>(url);
  }
  async putDepartment(department : Department)
  {
    let url = `${this.baseUrl}/Departments/Create-Department`;
    return await this.put(url,department);
  }
}

const departmentService = new DepartmentService();
export default departmentService;
