import BaseApi from "../Core/REST/BaseApi";
import { Permission, PermissionValue } from "./Permission";
class PermissionService extends BaseApi {
  async GetAll(): Promise<Array<Permission> | undefined> {
    let url = `${this.baseUrl}/Permission/getAll`;
    return await this.get(url);
  }
  async Post(permissionValue :PermissionValue){
    let url = `${this.baseUrl}/Permission/Post-Department-Value`;
    return await this.post(url,permissionValue);
  }
  async Delete(permissionKeyId : number,departmentId : number)
  {
    let url = `${this.baseUrl}/Permission/${permissionKeyId}/${departmentId}`;
    return await this.delete(url);
  }
  async UpdateDescription(permission:Permission)
  {
    let url = `${this.baseUrl}/Permission/Update-Description`;
    return await this.patch(url,permission);
  }
}
const permissionService = new PermissionService();
export default permissionService;
