const logo = `${window.location.origin}/flofab_logo.svg`;

type LogoProps = {
  className?: string;
};

export default function LogoComponent(props: LogoProps) {
  const { className } = props;

  let cssClass = className ? className : "header-logo";

  return <img className={cssClass} src={logo} />;
}
