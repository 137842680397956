import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./quote-relationship-page.scss";
import { QuotesRelationship } from "../../../services/Quotes/Models/QuotesRelationship";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import quoteService from "../../../services/Quotes/QuoteService";
import { QuotesSerialNumber } from "../../../services/Quotes/Models/QuotesSerialNumber";
import { FilterContainerComponent } from "../../../components/Forms/Search filters/FilterContainerComponent";
import { Button, Col } from "react-bootstrap";
import { PageHeader } from "../../../components/Pages/page-components";
import BreadcrumbComponent from "../../../components/Core/BreadcrumbComponent";
type IParamTypes = {
  userId: string;
  quoteId: string;
};

export const QuoteRelationship = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { userId, quoteId } = useParams<IParamTypes>();
  const [refId, setRefId] = useState<string>("");
  const [quoteOriginal, setQuoteOriginal] = useState<QuotesRelationship>();
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [quoteSerialNumbers, setQuoteSerialNumbers] = useState<
    Array<QuotesSerialNumber>
  >([]);

  useEffect(() => {
    loadQuoteOriginal();
    loadQuoteSerialNumbers();
   
  }, [userId, quoteId]);

  const addQuote = () => {
 
    if (refId){
      if(quoteOriginal)  setErrorMessage("You cannot add multiple original quoteId to a quote, romove the following at first, then add your quoteId.");
      else {
        addQuoteOriginal(refId);
        setErrorMessage("");
      }
    
    };
  };
  const addSerial = () => {
    if (serialNumber) addQuoteSerialNumbers(serialNumber);
  };
  const deleteQuote = (relationId: number) => {
    if (relationId) deleteQuoteOriginal(relationId);
    setErrorMessage("");
  };
  const deleteSerial = (serialId: number) => {
    if (serialId) deleteQuoteSerialNumber(serialId);
  };
  const quoteKeyDown = (ev: any) => {
    
    if (ev.key === "Enter")  {
      if(quoteOriginal)  setErrorMessage("You cannot add multiple original quoteId to a quote, romove the following at first, then add your quoteId.");
      else {
        addQuoteOriginal(refId);
        setErrorMessage("");
      }
    }
  };
  const serialKeyDown = (ev: any) => {
    if (ev.key === "Enter") addQuoteSerialNumbers(serialNumber);
  };
  const breadcrumbItems = [{ text: quoteId, active: true }];
  return (
    <div id="quote-realtion">
    <a href="https://portal.flofab.com/ASP%20Frame-ViewQuote.asp"> Back</a>
      <div className="card">
        <div className="card-header">
          <h3>{t("quote-relation-page.card-header")}</h3>
        </div>
        <div className="card-body">
          <FilterContainerComponent className="row">
            <Col lg={6} md={4} sm={12}>
              <fieldset className="add-section">
                <legend>{t("quote-relation-page.ref-section")}</legend>
                <div>
                  <input
                    className="input-search"
                    type="number"
                    placeholder="Enter QuoteId"
                    value={refId}
                    onChange={(ev) => setRefId(ev.target.value)}
                    onKeyDown={(ev) => quoteKeyDown(ev)}
                  />
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={addQuote}
                  >
                    <FontAwesomeIcon size="xs" icon={faPlus} />
                  </button>
                </div>
                {errorMessage && 
                <p
                 style={{ color: "red" }}>
                      {errorMessage}
                    </p>
                  
                }
                <table>
                  <tbody>
                    {quoteOriginal &&
                        <tr>
                          <td>{quoteOriginal.parentQuoteId}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => deleteQuote(quoteOriginal.id)}
                              onKeyDown={(ev) => quoteKeyDown(ev)}
                            >
                              <FontAwesomeIcon size="xs" icon={faMinus} />
                            </button>
                          </td>
                        </tr>
                     }
                  </tbody>
                </table>
              </fieldset>
            </Col>

            <Col lg={6} md={4} sm={12}>
              <fieldset className="add-section">
                <legend>{t("quote-relation-page.serial-section")}</legend>
                <input
                  className="input-search"
                  type="text"
                  placeholder="Enter Serial Number"
                  value={serialNumber}
                  onChange={(ev) => setSerialNumber(ev.target.value)}
                  onKeyDown={(ev) => serialKeyDown(ev)}
                />
                <button
                  className="btn btn-sm btn-outline-primary"
                  onClick={addSerial}
                >
                  <FontAwesomeIcon size="xs" icon={faPlus} />
                </button>
                <table>
                  <tbody>
                    {quoteSerialNumbers &&
                      quoteSerialNumbers.map((serial, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{serial.serialNumber}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => deleteSerial(serial.id)}
                            >
                              <FontAwesomeIcon size="xs" icon={faMinus} />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </fieldset>
            </Col>
          </FilterContainerComponent>
        </div>
      </div>
    </div>
  );

  function loadQuoteOriginal() {
    if (userId && quoteId) {
      quoteService
        .getOriginalQuote(userId, quoteId)
        .then((res) => {
           setQuoteOriginal(res);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  // function loadQuoteRelations() {
  //   if (userId && quoteId) {
  //     quoteService
  //       .getQuoteRelations(userId, quoteId)
  //       .then((res) => {
  //         if (!res)
  //           console.error(t("quote-realtion-page.error-fetch-relation-quotes"));
  //         else setQuoteRelations(res);
  //       })
  //       .catch((error) => {
  //         toast.error(error);
  //       });
  //   }
  // }
  function loadQuoteSerialNumbers() {
    if (userId && quoteId) {
      quoteService
        .getQuoteSerialNumbers(userId, quoteId)
        .then((res) => {
          if (!res)
            console.error(t("quote-realtion-page.error-fetch-relation-quotes"));
          else setQuoteSerialNumbers(res);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  function addQuoteOriginal(refId: string) {
    if (userId && quoteId) {
      quoteService
        .addQuoteOriginal(userId, quoteId, parseInt(refId))
        .then((res) => {
          if (!res)
            setErrorMessage("Quote Not Fouond!");
          else {
            loadQuoteOriginal();
            setRefId("");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  function deleteQuoteOriginal(relationId: number) {
    if (userId && quoteId) {
      quoteService
        .deleteQuoteOriginal(userId, quoteId, relationId)
        .then(() => {
          console.log("here");
          loadQuoteOriginal();
        });
    }
  }
  function addQuoteSerialNumbers(serialNumber: string) {
    if (userId && quoteId) {
      const quotesSerialNumber = new QuotesSerialNumber({
        id: 0,
        quoteId: parseInt(quoteId), 
        serialNumber: serialNumber
      });
      console.log(typeof(serialNumber));
      quoteService
        .addQuoteSerialNumber(userId, quotesSerialNumber)
        .then((res) => {
          if (!res)
          console.error("Error adding quote serial number:", res);
            // console.error(t("quote-realtion-page.error-add-relation-quotes"));
          else {
            loadQuoteSerialNumbers();
            setSerialNumber("");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  function deleteQuoteSerialNumber(serialId: number) {
    if (userId && quoteId) {
      quoteService
        .deleteQuoteSerialNumber(userId, quoteId, serialId)
        .then(() => {
          loadQuoteSerialNumbers();
        });
    }
  }
};
