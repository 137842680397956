import { useState, useEffect } from "react";
import { Button, Col, FormControl, FormLabel } from "react-bootstrap";
import singleItemSrvice from "../../services/SingleItem/SingleItemService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./add-single-item-page.scss";
import { SingleItemQuickNavigationLinks } from "../../services/SingleItem/Models/SingleItemQuickNavigationLinks";
import { SingleItemQuickNavigationLinkTypes } from "../../services/SingleItem/Models/SingleItemQuickNavigationLinkTypes";
import { Item } from "../../services/Core/Items/Item";
import {
  useCollapse,
} from "../../components/CollapseComponent";
import { useTranslation } from "react-i18next";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
import { SearchButton } from "../../components/general-button-components";
import ConfiramtionPopup from "../../components/Core/Popup-confirmation-Component";

type IParamTypes = {
  userId: string;
  quoteId: string;
};

export default function AddSingleItemPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId, quoteId } = useParams<IParamTypes>();
  const [menuItems, setMenuItems] = useState<
    Array<SingleItemQuickNavigationLinkTypes>
  >([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState<
    Array<SingleItemQuickNavigationLinkTypes>
  >([]);
  const [quantity, setQuantity] = useState(1);

  const [items, setItems] = useState<Array<Item>>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [searchType, setSearchType] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] =
    useState<SingleItemQuickNavigationLinks | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const [itOCode, setItOCode] = useState(selectedItem?.it_ocode || "");
  const [description, setDescription] = useState(selectedItem?.itemDescription || "");

  useEffect(() => {
    loadSingleItemQuickNavigationMenuItems();
  }, []);

  useEffect(() => {
    if (selectedSubMenu) {
      loadAllItemsByOCode(selectedSubMenu.searchCode);
    }
  }, [selectedSubMenu]);

  useEffect(() => {
    if (selectedSubMenu) {
      loadAllItemsByOCode(selectedSubMenu.searchCode); 
    }
      setItOCode(selectedItem?.it_ocode || "");
      setDescription(selectedItem?.itemDescription || "");
  }, [selectedItem]);

  function onMenuItemClick(menuItem: any): void {
    if (selectedMenuItem === menuItem) {
      setSelectedMenuItem(null); 
    } else {
      setSelectedMenuItem(menuItem);
    }
  }
  function onSubMenuClick(subMenuItem: SingleItemQuickNavigationLinks) {
    setSelectedSubMenu(subMenuItem);
  }
  function onItemClick(item: any): void {
    setSelectedItem(item);
  }
  const handleSearchType = (event: any) => {
    const value = event.target.value;
    setSearchType(value);
    const filteredTypes = menuItems.filter((type) =>
      type.typeName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredMenuItems(filteredTypes);
  };
  const handleSearchByOCode = () => {
    if (itOCode !== "") {
      loadAllItemsByOCode(encodeURIComponent(itOCode));
    }
  };
  const handleSearchByDesc = () => {
    if (description !== "") {
      loadAllItemsByDescription(encodeURIComponent(description));
    }
  };

  function handleAddToQuote() {
    if (selectedItem == null) {
      toast.warn(t(`add-single-item-page.warn-select-item`));
    } else {
      if (quantity === null) setQuantity(1);
      if (quantity > 0 && selectedItem.itemDetails) {
        selectedItem.qty = quantity;

        singleItemSrvice
          .addItemtoQuote(
            parseInt(userId || ""),
            parseInt(quoteId || ""),
            selectedItem
          )
          .then((res) => {
            toast.success(t(`add-single-item-page.success-added`));

            const savedChoice = localStorage.getItem('userChoice');

            if (savedChoice) {
              if (savedChoice === 'goToQuote') {
                handleBackToQuote(); 
              }
              else if (savedChoice === 'stay') { 
              }
            } else {
              setShowPopup(true);
            }

            setItems([]);

            setSelectedItem({
              ...selectedItem,
            });
            setSelectedItem(null);
          })
          .catch((error) => {
            toast.error(t(`add-single-item-page.error-add-to-quote`));
          });
      } else {
        toast.error(t(`add-single-item-page.error-enter-qty`));
      }
    }
  }

  function handleBackToQuote() {
    setShowPopup(false);
    const activeTabIndex = 1;
    sessionStorage.setItem("activeTabIndex", activeTabIndex.toString());
    navigate(-2);
  }

  return (
    <div id="add-single-item">
      <BreadcrumbComponent
        items={[
          { text: "Quotes", link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          {
            text: "Add Item",
            active: true,
          },
        ]}
      />
      <div className="page-title wrapper">
        <h1 className="h3">{t("add-single-item-page.title")}</h1>
      </div>
      <div className="sidebar">
        <input
          type="text"
          placeholder={t(`add-single-item-page.search`) || "Enter type"}
          value={searchType}
          onChange={handleSearchType}
        />
        <ul>
          {filteredMenuItems.map((type: any, index: number) => (
            <li key={index} onClick={() => onMenuItemClick(type)}>
              {type.typeName}
              {selectedMenuItem === type && (
                <ul>
                  {type.links.map(
                    (item: SingleItemQuickNavigationLinks, index: number) => (
                      <li
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from calling onMenuItemClick
                          onSubMenuClick(item);
                        }}
                        className={selectedSubMenu === item ? "active" : ""}
                      >
                        {item.name}
                      </li>
                    )
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <div className="item-list">
          <ul>
            {items.map((item: any, index: number) => (
              <li key={index} onClick={() => onItemClick(item)}>
                {item.itemDescriptionToShow}
              </li>
            ))}
          </ul>
        </div>
        <div className="item-detail">
          <div className="inline-detail">
            <FormLabel htmlFor="itOCode">
              {t(`add-single-item-page.it-ocode`)}:
            </FormLabel>
            <FormControl
              id="itOCode"
              size="sm"
              value={itOCode}
              onChange={(ev) => setItOCode(ev.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  handleSearchByOCode();
                }
              }}
            />
            <SearchButton
              title="Edit"
              btnSize="sm"
              onClick={handleSearchByOCode}
            />
          </div>
          <div className="inline-detail">
            <FormLabel htmlFor="descr">
              {t(`add-single-item-page.description`)}:
            </FormLabel>
            <FormControl
              id="descr"
              size="sm"
              value={description}
              onChange={(ev) => setDescription(ev.target.value)}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  handleSearchByDesc();
                }
              }}
            />
            <SearchButton
              title="Edit"
              btnSize="sm"
              onClick={handleSearchByDesc}
            />
          </div>
          <div className="inline-detail">
            <div className="inline-triple">
            <FormLabel htmlFor="stockQty">
              {t(`add-single-item-page.qty-in-stock`)}:
            </FormLabel>
            <FormControl
              id="stockQty"
              size="sm"
              value={
                selectedItem?.itemDetails &&
                selectedItem?.itemDetails.qtyOnHand !== null
                  ? selectedItem?.itemDetails.qtyOnHand
                  : 0
              }
              readOnly
            />
            <FormLabel htmlFor="reserved">
              {t(`add-single-item-page.reserved`)}:
            </FormLabel>
            <FormControl
              id="reserved"
              size="sm"
              value={
                selectedItem?.itemDetails &&
                selectedItem?.itemDetails.qtyReserved !== null
                  ? selectedItem?.itemDetails.qtyReserved
                  : 0
              }
              readOnly
            />
            <FormLabel htmlFor="reserved">
              {t(`add-single-item-page.qty-on-order`)}:
            </FormLabel>
            <FormControl
              id="onOrder"
              size="sm"
              value={
                selectedItem?.itemDetails &&
                selectedItem?.itemDetails.qtyOnOrder !== null
                  ? selectedItem?.itemDetails.qtyOnOrder
                  : 0
              }
              readOnly
            />
            </div>
          </div>
          <div className="inline-detail">
            <FormLabel htmlFor="qty">
              {t(`add-single-item-page.qty-add-quote`)}:
            </FormLabel>
            <FormControl
              type="number"
              id="qty"
              size="sm"
              defaultValue={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
            />
          </div>
          <div className="inline-detail">
            <FormLabel htmlFor="cost">Cost:</FormLabel>
            <FormControl
              id="cost"
              size="sm"
              value={selectedItem?.it_cost?.toFixed(2) || ""}
              readOnly
            />
          </div>
          <button className="btn btn-primary" onClick={handleAddToQuote}>
            {t(`add-single-item-page.add-to-quote`)}
          </button>
          <button className="btn btn-primary" onClick={handleBackToQuote}>
            {t(`add-single-item-page.close`)}
          </button>
        </div>
      </div>
      <ConfiramtionPopup
        show={showPopup}
        title={t("add-single-item-page.success-added")}
        message={t("add-single-item-page.navigate-message")}
        onGoToQuote={handleBackToQuote}
        onStayHere={() => setShowPopup(false)}
        onClose={() => setShowPopup(false)}
        rememberMe={true}
      />
    </div>
  );

  function loadSingleItemQuickNavigationMenuItems() {
    singleItemSrvice
      .getAllQuickNavigationLinkTypes()
      .then((res) => {
        if (res) {
          setMenuItems(res);
          setFilteredMenuItems(res);
        }
      })
      .catch((error) => {
        toast.error(t(`add-single-item-page.error-fetch-nav`));
      });
  }

  function loadAllItemsByOCode(ocode: string) {
    singleItemSrvice
      .getAllItemsByOCode(ocode)
      .then((res) => {
        if (res) {
          setItems(res);
        }
      })
      .catch((error) => {
        toast.error(t(`add-single-item-page.error-fetch-ocode`));
      });
  }
  function loadAllItemsByDescription(descr: string) {
    singleItemSrvice
      .getAllItemsByDescription(descr)
      .then((res) => {
        if (res) {
          setItems(res);
        }
      })
      .catch((error) => {
        toast.error(t(`add-single-item-page.error-fetch-description`));
      });
  }
}
