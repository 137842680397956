import { useNavigate, useParams } from "react-router-dom";
import "./quote-page.scss";
import BreadcrumbComponent from "../../../components/Core/BreadcrumbComponent";
import { useEffect, useMemo, useState } from "react";
import { PageSectionComponent } from "../../../components/Core/Page Section Component/PageSectionComponent";
import {
  Button,
  FormControl,
  FormLabel,
  FormSelect,
  ListGroupItem,
  Modal,
  Row,
} from "react-bootstrap";
import {
  QuoteKitComponent,
  QuoteSingleItemComponent,
  QuoteSummaryComponent,
  SystemBoosterComponent,
} from "./quote-page-component";
import Quote, { ClientEmail, QuoteCust } from "../../../services/Quotes/Quote";
import quoteService from "../../../services/Quotes/QuoteService";
import { useTranslation } from "react-i18next";
import stringHelper from "../../../services/Core/Helpers/string-helper";
import Activity, {
  ActivityObjectType,
  ActivityType,
} from "../../../services/Activities/Activity";
import {
  ActivityHistoryComponent,
  ActivityManagerComponent,
} from "../../../components/activities/ActivitiesManagerComponent";
import { QuoteHistory } from "../../../services/Quotes/Models/QuoteHistory";
import {
  Page,
  PageDetails,
  PageHeader,
  PageInfo,
  PageTitle,
} from "../../../components/Pages/page-components";
import orderService from "../../../services/Orders/OrderService";
import { toast } from "react-toastify";
import {
  ProgressBar,
  ProgressBarComponent,
} from "../../../components/Charts/ProgressBarComponent";
import activityService from "../../../services/Activities/ActivityService";
import activityExtensions from "../../../services/Activities/ActivityExtensions";
import Popup, { PopupGetInfo } from "../../../components/Core/Popup";
import transportService from "../../../services/Transports/TransportServices";
import ShipVia from "../../../services/Transports/Models/ShipVia";
import { DOTS } from "../../../hooks/usePagination";
import {
  AddButton,
  CopyButton,
  CopyToButton,
  DeleteButton,
  DeleteProductButton,
  DrawingToPdfButton,
  EditButton,
  PrintPreviewButton,
  RefreshButton,
  RemoveButton,
  SaveButton,
  SelectAllButton,
} from "../../../components/general-button-components";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import {
  QuoteDownloadStatus,
  QuoteDownloadStatusTypes,
} from "../../../services/Quotes/Models/QuoteDownloadStatus";
import { SelectComponent } from "../../../components/Forms/select-component";
import { QuoteRelationship } from "./quote-relationship-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CollapseComponent } from "../../../components/CollapseComponent";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ConfiramtionPopup from "../../../components/Core/Popup-confirmation-Component";
import QuoteKit from "../../../services/Quotes/Models/QuoteKit";
import { QuoteSingleItem } from "../../../services/SingleItem/Models/QuoteSingleItem";
import { QuoteHeatExchanger } from "../../../services/HeatExchangers/Models/QuoteHeatExchanger";
import { BoosterSystem } from "../../../services/Systems/BoosterSystem/BoosterSystem";
import dateHelper from "../../../services/Core/Helpers/date-helper";
import numberHelper from "../../../services/Core/Helpers/number-helper";
import QuoteSumamryTable from "../../../services/Quotes/Models/QuoteSummaryTable";

type IParamTypes = {
  userId: string;
  quoteId: string;
};

export default function QuotePage() {
  const { t } = useTranslation();
  const { userId, quoteId } = useParams<IParamTypes>();
  const [tab, setTab] = useState(0);
  const [editing, setEditing] = useState(true);

  const [quote, setQuote] = useState<Quote>();
  const [quoteSummary, setQuoteSummary] = useState<QuoteSumamryTable>();

  const [originalQuote, setOriginalQuote] = useState<Quote>();
  const [histories, setHistories] = useState<QuoteHistory[]>();
  const [progressHistory, setProgressHistory] = useState<ProgressBar[]>();
  const [notes, setNotes] = useState<Array<Activity>>([]);

  const [isAddClient, setIsAddClient] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>("");
  const [clientId, setClientId] = useState<number>();
  const [contact, setContact] = useState<ClientEmail>();
  const [clients, setClients] = useState<Array<QuoteCust>>();

  const [shipViaArr, setShipViaArr] = useState<Array<ShipVia>>();
  const [showTAC, setShowTAC] = useState<boolean>(false);

  const [editReminder, setEditReminder] = useState<boolean>(false);

  const [showPrintPreview, setShowPrintPreview] = useState<boolean>(false);

  const [showProdCategory, setShowProdCategory] = useState(false);
  const [showRelatedQuote, setshowRelatedQuote] = useState(false);

  const [showCopyTo, setShowCopyTo] = useState(false);
  const [quoteIdCopyTo, setQuoteIdCopyTo] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationQ, setShowConfirmationQ] = useState(false);
  const [showToolBar, setShowToolBar] = useState(false);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedKits, setSelectedKits] = useState<QuoteKit[]>([]);
  const [selectedItems, setSelectedItems] = useState<QuoteSingleItem[]>([]);
  const [selectedHEC, setSelectedHEC] = useState<QuoteHeatExchanger[]>([]);
  const [selectedSytems, setSelectedSystems] = useState<BoosterSystem[]>([]);

  const [checkboxOptions, setCheckboxOptions] = useState<
    QuoteDownloadStatusTypes[]
  >([]);

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    loadDlStatusTypes();
    if (userId) {
      if (quoteId) {
        loadQuote();
        loadQuoteSummary();
      } else setEditing(true);
    }
  }, [
    userId,
    quoteId,
    selectedHEC,
    selectedItems,
    selectedKits,
    selectedSytems,
  ]);

  useEffect(() => {
    if (quote && quoteSummary) {
      setQuote(prevQuote => {
        if (!prevQuote) return undefined; 
  
        return {
          ...prevQuote, 
          totalNetPrice: quoteSummary.totalQuoteNetPrice 
        };
      });
    }
  }, [quoteSummary]);


  useEffect(() => {

    switch (tab) {
      case 2:
        !shipViaArr && loadShipViaList();
        break;
      case 3:
        loadNotes();
        break;
      case 4:
        !histories && loadQuoteHistory();
        break;
      case 5:
        !progressHistory && loadProgressHistory();
        break;
      default:
        break;
    }
  }, [tab]);



  useEffect(() => {
    const activeTab = sessionStorage.getItem("activeTabIndex");
    if (activeTab === "1") {
      setTab(1);
    } else {
      setTab(0)
    };
    sessionStorage.setItem("activeTabIndex", "0");
  }, []);

  // useEffect for handling page unload to clear the activeTabIndex
  // useEffect(() => {
  //   const handleUnload = () => {
  //     sessionStorage.removeItem("activeTabIndex");
  //   };

  //   window.addEventListener("beforeunload", handleUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleUnload);
  //   };
  // }, []);

  useEffect(() => {
    if (!editing && userId && quoteId && quote) {
      //console.log("Updated Quote:", quote);
      quoteService.UpdateAllOfQuote(parseInt(userId), quote).then((res) => {
        if (res) {
          loadQuote();
        }
      });
    }
  }, [editing]);

  const title =
    quoteId !== undefined
      ? t("quote-page.title", { 0: quoteId })
      : t("quote-page.new-quote");

  const contacts = useMemo(() => {
    let client = clients?.find((x) => x.id === clientId);
    return client?.contacts ?? [];
  }, [clientId]);

  const active = editing ? "active" : "";

  const handleAddProductBtnClick = () => {
    // save data before go to add products
    onEditSaveBtnClick();
    setShowProdCategory(true);
  };
  const handleControlClick = () => {
    setshowRelatedQuote(true);
  };
  const onCloseClick = () => {
    setShowProdCategory(false);
  };
  const onCopyBtnClick = () => {
    const newQuote = new Quote(
      { id: 0 },
      selectedHEC,
      selectedSytems,
      selectedKits,
      selectedItems,
      [],
      [],
      [],
      []
    );
    quoteService
      .createCopyOfQuote(userId, newQuote)
      .then((res) => {
        window.open(`/Quotes/${userId}/${res}`);
      })
      .catch((error) => {
        toast.error(t("quote-page.error-copy-quote"), error);
      });
  };

  const onCopytoYourQuoteBtnClick = () => {
    const newQuote = new Quote(
      { id: quoteIdCopyTo },
      selectedHEC,
      selectedSytems,
      selectedKits,
      selectedItems,
      [],
      [],
      [],
      []
    );
    quoteService
      .createCopyOfQuote(userId, newQuote)
      .then((res) => {
        window.open(`/Quotes/${userId}/${res}`);
      })
      .catch((error) => {
        toast.error(t("quote-page.error-copy-quote"), error);
      });
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    if (isSelectAll) {
      if (quote?.quoteKits) {
        setSelectedKits([...quote.quoteKits]);
      }
      if (quote?.quoteSingleItems) {
        setSelectedItems([...quote.quoteSingleItems]);
      }
    } else {
      setSelectedHEC([]);
      setSelectedItems([]);
      setSelectedKits([]);
      setSelectedSystems([]);
    }
  };

  const handleDeleteSelected = () => {
    // Logic to delete selected products
    const newQuote = new Quote(
      { id: quoteId },
      selectedHEC,
      selectedSytems,
      selectedKits,
      selectedItems,
      [],
      [],
      [],
      []
    );

    quoteService
      .deleteSelectedQuoteItems(userId, newQuote)
      .then((res) => {
        setSelectedHEC([]);
        setSelectedItems([]);
        setSelectedKits([]);
        setSelectedSystems([]);
        setShowConfirmation(false);
        setShowToolBar(false);
      })
      .catch((error) => {
        toast.error(t("quote-page.error-delete-items"), error);
      });
  };

  function handleKitSelect(selectedKit: QuoteKit) {
    setShowToolBar(true);
    if (selectedKits.find((kit) => kit.id === selectedKit.id)) {
      setSelectedKits(
        selectedKits.filter((kit) => !(kit.id === selectedKit.id))
      );
      if (selectedKits.length === 1 && selectedItems.length === 0)
        setShowToolBar(false);
    } else {
      setSelectedKits([...selectedKits, selectedKit]);
      setShowToolBar(true);
    }
  }

  // function handleKitClick(selectedKit: QuoteKit) {
  //   console.log(selectedKit);
  //   if(selectedKit)
  //     navigate(`/Pumps/Edit-Quote-Pump/${userId}/${quoteId}/${selectedKit.id}`)
  // }

  function handleItemSelect(selectedItem: QuoteSingleItem) {
    if (selectedItems.find((item) => item.id === selectedItem.id)) {
      setSelectedItems(
        selectedItems.filter((item) => !(item.id === selectedItem.id))
      );
      if (selectedKits.length === 0 && selectedItems.length === 1)
        setShowToolBar(false);
    } else {
      setSelectedItems([...selectedItems, selectedItem]);
      setShowToolBar(true);
    }
  }

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    setQuote((prevQuote: Quote | undefined) => {
      if (!prevQuote) {
        return undefined;
      }

      if (isChecked) {
        if (!prevQuote.quoteDownloadStatuses) {
          prevQuote.quoteDownloadStatuses = [];
        }
        prevQuote.quoteDownloadStatuses.push(
          new QuoteDownloadStatus({
            id: 0,
            quoteId: prevQuote.id,
            dlStatusId: index,
          })
        );
      } else {
        if (prevQuote.quoteDownloadStatuses) {
          prevQuote.quoteDownloadStatuses =
            prevQuote.quoteDownloadStatuses.filter(
              (status) =>
                !(
                  status.quoteId === prevQuote.id && status.dlStatusId === index
                )
            );
        }
      }
      return prevQuote;
    });
  };

  function handleDeletePump(deletedKitId: number) {
    if (quote) {
      if (quote.quoteKits.find((kit) => kit.id === deletedKitId)) {
        const updatedKits = quote.quoteKits.filter(kit => kit.id !== deletedKitId);
        setQuote(prevQuote => prevQuote ? {
          ...prevQuote,
          quoteKits: updatedKits
        } : undefined);
        loadQuoteSummary();
      }
    }
  };

  function handleDeleteSingleItem(deletedItemId: number) {
    if (quote) {
      if (quote.quoteSingleItems.find((item) => item.id === deletedItemId)) {
        const updatedItems = quote.quoteSingleItems.filter(item => item.id !== deletedItemId);
        setQuote(prevQuote => prevQuote ? {
          ...prevQuote,
          quoteSingleItems: updatedItems
        } : undefined);
        loadQuoteSummary();
      }
    }
  };

  return (
    <Page id="quote-details">
      <PageHeader className="d-block">
        <BreadcrumbComponent
          items={[
            { text: "Quotes", link: `/Quotes/${userId}` },
            {
              text: title,
              active: true,
            },
          ]}
        />
      </PageHeader>

      <PageTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h1 className="h2 me-3">{title}</h1>
            <RefreshButton
              btnSize="sm"
              onClick={loadQuote}
              title="Refresh page"
              className="me-3"
            />
            {!editing ? (
              <EditButton
                title="Edit"
                btnSize="sm"
                onClick={onEditSaveBtnClick}
                className="me-3"
              />
            ) : (
              <SaveButton
                title="Save"
                btnSize="sm"
                onClick={onEditSaveBtnClick}
                className="me-3"
              />
            )}
            <PrintPreviewButton
              btnSize="sm"
              onClick={loadPrint}
              title={t("quote-page.print-preview") || "PrintPreview"}
              className="me-3"
            />
            <DrawingToPdfButton
              btnSize="sm"
              title={t("quote-page.drawing-to-pdf") || "Drawing to Pdf"}
              onClick={drawingToPdf}
              className="me-3"
            />
            <DeleteButton
              btnSize="sm"
              title={t("quote-page.delete-quote") || "Delete Quote"}
              onClick={() => setShowConfirmationQ(true)}
              className="me-3"
            />
            
          </div>

          <ConfiramtionPopup
            show={showConfirmationQ}
            title={t("quote-page.delete-quote") || "Delete Quote"}
            message={t("quote-page.warn-delete-quote-message")}
            onDelete={onConfirmClick}
            onClose={() => setShowConfirmationQ(false)}
          />

          <div className="d-flex align-items-center">
            <h5 className="mb-0">
              Total Price: {quote ? numberHelper.toNumberFormat(quote.totalNetPrice) : 0} $
            </h5>
          </div>
        </div>
      </PageTitle>

      <PageSectionComponent
        active={tab}
        onClick={setTab}
        items={[
          { text: t("quote-page.info"), key: 0 },
          { text: t("quote-page.products"), key: 1 },
          { text: t("quote-page.transport"), key: 2 },
          { text: t("quote-page.notes"), key: 3 },
          { text: t("quote-page.history"), key: 4 },
          { text: t("quote-page.quote-progress"), key: 5 },
        ]}
      />

      <PageDetails>
        <TabContainerComponent show={tab === 0} >
          <div className="quote-info-container">
            <div className="mb-4">
              <FormLabel htmlFor="client">{t("common.client")}s</FormLabel>
              <button
                className="btn add-client-btn btn-outline-primary"
                onClick={() => setIsAddClient(true)}
              >
                {t("quote-page.add-client")}
              </button>
              <table className="table client-list">
                <tbody>
                  {quote?.quotesClients.map((client, i) => {
                    let isChecked = client.id === quote.clientListId;
                    let btnTitle =
                      t("common.remove-obj", { 0: client.name }) ?? "";
                    return (
                      <tr key={i}>
                        <td>
                          <input
                            type="radio"
                            name="quote-client"
                            checked={isChecked}
                            onChange={() => onQuoteClientClick(client.id)}
                          />
                        </td>
                        <td>{client.name}</td>
                        <td>
                          {client.email}
                          {client.tel && <div>{client.tel}</div>}
                        </td>
                        <td>
                          {!isChecked && (
                            <RemoveButton
                              title={btnTitle}
                              btnSize="sm"
                              iconSize="xs"
                              onClick={() => removeClient(client.id)}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="row align-items-center">
              <div className="mb-2 col-lg-6 col-sm-12">
                <FormLabel htmlFor="salesman">
                  {t("quote-page.salesman")}
                </FormLabel>
                <FormControl id="salesman" size="sm" disabled />
              </div>

              <div className="mb-2 col-lg-6 col-sm-12">
                <FormLabel htmlFor="owned-by">
                  {t("quote-page.owned-by")}
                </FormLabel>
                <FormControl
                  id="owned-by"
                  defaultValue={quote?.ownedBy}
                  size="sm"
                  disabled
                />
              </div>

              <div className="mb-2 col-lg-6 col-sm-12">
                <FormLabel htmlFor="jobName">
                  {t("quote-page.job-name")}
                </FormLabel>
                <FormControl
                  id="jobName"
                  defaultValue={quote?.jobName}
                  size="sm"
                  className={active}
                  onChange={(ev) => {
                    setValue("jobName", ev.target.value);
                  }}
                  disabled={!editing}
                />
              </div>

              <div className="mb-2 col-lg-6 col-sm-12">
                <FormLabel htmlFor="date">{t("common.date")}</FormLabel>
                <FormControl
                  id="date"
                  defaultValue={
                    quote?.date &&
                    stringHelper.toDateString(new Date(quote.date))
                  }
                  size="sm"
                  type="date"
                  disabled
                />
              </div>

              <div className="col-12 mb-3">
                <FormLabel htmlFor="quoteStatus">
                  {t("quote-page.quote-status")}
                </FormLabel>
                <br />
                <SelectComponent
                  //id="quoteStatus"
                  defaultValue={quote?.status}
                  className={active}
                  onChange={(statusId) => setValue("status", statusId)}
                  disabled={!editing}
                  options={[
                    { text: t("quotes.status-1"), value: 1 },
                    { text: t("quotes.status-2"), value: 2 },
                    { text: t("quotes.status-0"), value: 0 },
                    { text: t("quotes.status-4"), value: 4 },
                    { text: t("quotes.status-5"), value: 5 },
                    { text: t("quotes.status-3"), value: 3 },
                    { text: t("quotes.status-6"), value: 6 },
                  ]}
                />
              </div>

              <div className="col-12 mb-3">
                <FormLabel htmlFor="dlStatus">
                  {t("quote-page.dl-status")}
                </FormLabel>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    border: "1px solid lightgray",
                    padding: "3px",
                  }}
                >
                  {checkboxOptions?.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        width: "30%",
                        marginRight: "3px",
                        padding: "3px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        defaultChecked={quote?.quoteDownloadStatuses?.some(
                          (status) => status.dlStatusId === option.id
                        )}
                        className={active}
                        onChange={(ev) =>
                          handleCheckboxChange(index + 1, ev.target.checked)
                        }
                        disabled={!editing}
                      />
                      <label
                        htmlFor={`checkbox${index}`}
                        style={{ paddingLeft: "3px" }}
                      >
                        {option.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12 mb-3">
                <FormLabel htmlFor="remark">{t("quote-page.remark")}</FormLabel>
                <FormControl
                  id="remark"
                  defaultValue={quote?.remark}
                  size="sm"
                  className={active}
                  onChange={(ev) => setValue("remark", ev.target.value)}
                  disabled={!editing}
                />
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <FormLabel htmlFor="purchase-order">
                  {t("quote-page.po")}
                </FormLabel>
                <FormControl
                  id="purchase-order"
                  defaultValue={quote?.po}
                  size="sm"
                  className={active}
                  disabled={!editing}
                  onChange={(ev) => setValue("po", ev.target.value)}
                />
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <FormLabel htmlFor="purchase-order">
                  {t("quote-page.po-date")}
                </FormLabel>
                <input
                  id="po-date"
                  className={`form-control form-control-sm ${active}`}
                  type="date"
                  defaultValue={
                    quote?.poEmailDate instanceof Date
                      ? quote?.poEmailDate.toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(ev) => {
                    setValue("poEmailDate", ev.target.value);
                  }}
                  disabled={!editing}
                />
              </div>

              <div className="col-12 mb-3">
                <FormLabel htmlFor="originalQuote">
                  {t("quote-page.original-quote")}
                </FormLabel>
                <FormControl
                  id="original-quote"
                  defaultValue={
                    showRelatedQuote
                      ? ""
                      : quote?.originalQuote
                        ? quote?.originalQuote.parentQuoteId
                        : ""
                  }
                  size="sm"
                  className={active}
                  onClick={handleControlClick}
                  disabled={!editing}
                />
                {showRelatedQuote && (
                  <Popup
                    title={"Add Relation"}
                    onClose={() => setshowRelatedQuote(false)}
                    show={showRelatedQuote}
                  >
                    <QuoteRelationship />
                  </Popup>
                )}
              </div>

              <div className="col-12 mb-3">
                {quote?.originalQuote !== null && quote?.originalQuote ? (
                  <>
                    <div className="col-12 mb-3">
                      <div id="serial-collapse">
                        <h5
                          className="d-flex justify-content-between"
                          onClick={() => setCollapsed(!collapsed)}
                        >
                          <FormLabel>
                            {t("quote-page.quote-serial-numbers")}
                          </FormLabel>
                          <FontAwesomeIcon size="sm" icon={faChevronDown} />
                        </h5>
                        <CollapseComponent collapsed={collapsed}>
                          <div className="row">
                            {quote?.quoteSerialNumbers &&
                              quote?.quoteSerialNumbers.map((serial, index) => (
                                <div
                                  key={index}
                                  className="col-md-6 col-sm-12 mb-3"
                                >
                                  <FormControl
                                    id={`serials-${index}`}
                                    defaultValue={serial.serialNumber}
                                    size="sm"
                                    disabled={!editing}
                                    onClick={handleControlClick}
                                    className={active}
                                  />
                                </div>
                              ))}
                          </div>
                        </CollapseComponent>
                      </div>
                    </div>
                  </>
                ) : (
                  quote?.quoteRelations &&
                  quote?.quoteRelations.length > 0 && (
                    <>
                      <div id="related-quote-collapse">
                        <h5
                          className="d-flex justify-content-between"
                          onClick={() => setCollapsed(!collapsed)}
                        >
                          <FormLabel>
                            {t("quote-page.related-quotes")}
                          </FormLabel>
                          <FontAwesomeIcon size="sm" icon={faChevronDown} />
                        </h5>
                        <CollapseComponent collapsed={collapsed}>
                          <div className="row">
                            {quote?.quoteRelations.map((relation, index) => (
                              <div
                                key={index}
                                className="col-md-6 col-sm-12 mb-3"
                              >
                                <a
                                  href="#"
                                  onClick={() => {
                                    window.open(
                                      `/Quotes/${userId}/${relation.childQuoteId}`
                                    );
                                  }}
                                >
                                  <FormControl
                                    id={`relatedQuotes-${index}`}
                                    defaultValue={relation.childQuoteId}
                                    size="sm"
                                  />
                                </a>
                              </div>
                            ))}
                          </div>
                        </CollapseComponent>
                      </div>
                    </>
                  )
                )}
              </div>

              {/* reminder */}
              {/* <div className="col-12 mb-3">
                <ProductInfoContainer>
                  <ProductInfo_Name>Reminder</ProductInfo_Name>
                  <ProductInfo_Value className="me-2">
                    {quote?.reminderEnable ? (
                      <>
                        Enabled
                        <MeasurementUnit
                          unit={stringHelper.toDateString(quote?.reminder)}
                        />
                      </>
                    ) : (
                      "Disabled"
                    )}
                  </ProductInfo_Value>
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => setEditReminder(true)}
                  >
                    Edit reminder
                  </button>
                </ProductInfoContainer>
              </div> */}
              {/* reminder */}
            </div>

            <div className="text-center">
              <a className="action-link" onClick={() => setShowTAC(true)}>
                TERMS & CONDITIONS
              </a>
            </div>
          </div>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 1}>
          <div className="quote-products-header">
            {/* <h2>Products</h2> */}
            {/* <button className="btn-add-prod">Add
            <img src="plus_icon.png" alt="Add icon" className="plus-icon"></img>
            </button> */}
            <AddButton
              title={t("quote-page.add-product") || "Add Product"}
              btnSize="sm"
              onClick={handleAddProductBtnClick}
            />
            {showToolBar && (
              <div className="row menu-product">
                <div className="col-auto align-self-center">
                  <SelectAllButton
                    title={ t("common.select-all") || "Select All"}
                    btnSize="sm"
                    onClick={handleSelectAll}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <CopyButton
                     title={ t("common.copy") || "Copy"}
                    btnSize="sm"
                    onClick={onCopyBtnClick}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <CopyToButton
                    title= { t("common.copy-to-your-quote") ||"Copy to your quote"}
                    btnSize="sm"
                    onClick={(e) => setShowCopyTo(true)}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <DeleteProductButton
                    title={ t("common.delete-selected-Items") ||"Delete Selected Item(s)"}
                    btnSize="sm"
                    onClick={(e) => setShowConfirmation(true)}
                  />
                </div>
              </div>
            )}
          </div>
          <PopupGetInfo
            title={"Copy to your quote"}
            onClose={() => {
              setShowCopyTo(false);
              setQuoteIdCopyTo("");
            }}
            onOk={onCopytoYourQuoteBtnClick}
            show={showCopyTo}
          >
            <input
              id="copyTo"
              type="number"
              placeholder="Enter a quote number"
              onChange={(e) => setQuoteIdCopyTo(e.target.value)}
            />
          </PopupGetInfo>
          <ConfiramtionPopup
            show={showConfirmation}
            title={t("quote-page.delete-quote") || "Delete Quote"}
            message={
              t("quote-page.warn-delete-quote-selected-items")
            }
            onDelete={handleDeleteSelected}
            onClose={() => setShowConfirmation(false)}
          ></ConfiramtionPopup>

          <ProductSelectionComponent
            show={showProdCategory}
            onClick={onCloseClick}
            userId={userId}
            quoteId={quoteId}
          />

          <div className="quote-products-container">
            {/*{quote?.quoteHeatExchangers &&*/}
            {/*  quote.quoteHeatExchangers.map((item, i) => {*/}
            {/*    return (*/}
            {/*      <div key={i} className="mb-2">*/}
            {/*        <HeatExchangerComponent*/}
            {/*          userId={userId}*/}
            {/*          heatExchanger={item}*/}
            {/*          pageNumber={i + 1}*/}
            {/*          editing={editing}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    );*/}
            {/*  })}*/}

            {quote?.boosterSystems.map((system) => {
              return (
                <div className="mb-2">
                  <SystemBoosterComponent
                    userId={userId}
                    pageNumber={(quote?.quoteHeatExchangers.length ?? 0) + 1}
                    editing={editing}
                    system={system}
                  />
                </div>
              );
            })}

            {quote?.quoteKits.map((quoteKit, i) => {
              return (
                <div
                  key={i + quote.quoteKits.length}
                  className="mb-2"
                // onDoubleClick={() => handleKitClick(quoteKit)}
                >
                  <QuoteKitComponent
                    userId={userId}
                    quoteId={quoteId}
                    pageNumber={(quote?.quoteKits.length ?? 0) + 1}
                    quoteKit={quoteKit}
                    editing={editing}
                    onSelect={(kit) => handleKitSelect(kit)}
                    isSelected={selectedKits.some(
                      (kit) => kit.id === quoteKit.id
                    )}
                    onUpdate={(updatedKit) => handleUpdate("kit", updatedKit)}
                    onDelete={handleDeletePump}
                  />
                </div>
              );
            })}

            {quote?.quoteSingleItems.map((quoteSingleItem, i) => {
              return (
                <div key={i + quote.quoteSingleItems.length} className="mb-2">
                  <QuoteSingleItemComponent
                    userId={userId}
                    quoteId={quoteId}
                    pageNumber={(quote?.quoteSingleItems.length ?? 0) + 1}
                    quoteSingleItem={quoteSingleItem}
                    editing={editing}
                    onSelect={(item) => handleItemSelect(item)}
                    isSelected={selectedItems.some(
                      (item) => item.id === quoteSingleItem.id
                    )}
                    onUpdate={(updatedQuoteSingleItem) =>
                      handleUpdate("singleItem", updatedQuoteSingleItem)
                    }
                    onDelete={handleDeleteSingleItem}
                  />
                </div>
              );
            })}
            {quoteSummary &&
              <QuoteSummaryComponent summaryData={quoteSummary} />
            }

          </div>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 2}>
          <div className="quote-info-container">
            <h4 className="h5">Ship To</h4>
            <div className="row">
              <div className="mb-2 col-12">
                <FormLabel htmlFor="ship-to-info">Info</FormLabel>
                <textarea
                  className={`form-control form-control-sm ${active}`}
                  id="ship-to-info"
                  value={quote?.shipTo}
                  onChange={(ev) => setValue("shipTo", ev.target.value)}
                  disabled={!editing}
                  rows={3}
                />
              </div>

              <div className="mb-3 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Date Requested</FormLabel>
                <FormControl
                  id="date-req"
                  size="sm"
                  type="date"
                  className={active}
                  defaultValue={
                    quote?.datereq &&
                    stringHelper.toDateString(
                      dateHelper.getDatefromString(quote.datereq)
                    )
                  }
                  onChange={(ev) => setValue("datereq", ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-3 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Client PO#</FormLabel>
                <FormControl
                  id="client-po"
                  size="sm"
                  className={active}
                  value={quote?.shiptopo}
                  onChange={(ev) => setValue("shiptopo", ev.target.value)}
                  disabled={!editing}
                />
              </div>

              <hr className="mt-2 mb-3" />

              {shipViaArr && (
                <div className="mb-2 col-md-6 col-sm-12">
                  <FormLabel htmlFor="ship-via">Ship via</FormLabel>
                  {!editing ? (
                    <span className="form-control form-control-sm">
                      {quote?.shipVia}
                    </span>
                  ) : (
                    <FormSelect
                      id="ship-via"
                      size="sm"
                      style={{
                        width: "100%",
                        backgroundColor: editing ? "#f0f0f0" : "inherit",
                      }}
                      defaultValue={quote?.shipVia ?? shipViaArr[0].name}
                      onChange={(ev) => setValue("shipVia", ev.target.value)}
                      disabled={!editing}
                    >
                      {shipViaArr?.map((shipvia, i) => {
                        return (
                          <option key={i} value={shipvia.name}>
                            {shipvia.name}
                          </option>
                        );
                      })}
                      <option key={"Others"} value={"Others"}>
                        Others
                      </option>
                    </FormSelect>
                  )}
                </div>
              )}
              {quote?.shipVia === DOTS && (
                <div className="mb-2 col-md-6 col-sm-12">
                  <FormLabel htmlFor="ship-via-other">Other</FormLabel>
                  <FormControl
                    id="ship-via-other"
                    size="sm"
                    className={active}
                    onChange={(ev) => setValue("shipVia", ev.target.value)}
                    disabled={!editing}
                  />
                </div>
              )}
              <div className="mb-2 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Account</FormLabel>
                <FormControl
                  id="ship-acc"
                  size="sm"
                  className={active}
                  value={quote?.shipAcc}
                  onChange={(ev) => setValue("shipAcc", ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-2 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Broker</FormLabel>
                <FormControl
                  id="broker"
                  size="sm"
                  className={active}
                  value={quote?.broker}
                  onChange={(ev) => setValue("broker", ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-2 col-md-6 col-sm-12">
                <FormLabel htmlFor="salesman">Broker Tel</FormLabel>
                <FormControl
                  id="broker-ph"
                  size="sm"
                  className={active}
                  value={quote?.brokerph}
                  onChange={(ev) => setValue("brokerph", ev.target.value)}
                  disabled={!editing}
                />
              </div>
              <div className="mb-2 col-12">
                <FormLabel htmlFor="ship-note">Ship Note</FormLabel>
                <textarea
                  className={`form-control form-control-sm ${active}`}
                  id="ship-note"
                  value={quote?.shipnote}
                  onChange={(ev) => setValue("shipnote", ev.target.value)}
                  disabled={!editing}
                  rows={1}
                />
              </div>
            </div>
          </div>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 3}>
          {quote && (
            <ActivityManagerComponent
              userId={userId}
              objectId={quote?.id}
              objectType={ActivityObjectType.Quotes}
              activities={notes}
              loadActivities={loadNotes}
            />
          )}
        </TabContainerComponent>

        <TabContainerComponent show={tab === 4}>
          <Page>
            {histories && histories.length > 0 ? (
              <PageDetails>
                {histories.map((history, i) => {
                  return (
                    <ActivityHistoryComponent
                      key={i}
                      username={history.username}
                      date={history.date}
                      description={history.description}
                    />
                  );
                })}
              </PageDetails>
            ) : (
              <PageTitle>
                <h5>No data found</h5>
              </PageTitle>
            )}
          </Page>
        </TabContainerComponent>

        <TabContainerComponent show={tab === 5}>
          <Page>
            {progressHistory && progressHistory.length !== 0 ? (
              <PageInfo>
                {progressHistory.map((progressBar, i) => {
                  return (
                    <Page className="page-border mb-2" key={i}>
                      <PageTitle>
                        <h6>{progressBar.title}</h6>
                      </PageTitle>
                      <PageDetails>
                        <ProgressBarComponent progressBar={progressBar} />
                      </PageDetails>
                    </Page>
                  );
                })}
              </PageInfo>
            ) : (
              <PageTitle>
                <h5>No data found</h5>
              </PageTitle>
            )}
          </Page>
        </TabContainerComponent>
      </PageDetails>

      <Popup title={"Add Client"} onClose={onAddClientClose} show={isAddClient}>
        <div className="container">
          <Row>
            <div className="col-auto">
              <FormLabel htmlFor="addClientSearch">Client Name:</FormLabel>
            </div>
            <div className="col-auto">
              <FormControl
                id="addClientSearch"
                className="border"
                size="sm"
                value={clientName}
                onChange={(ev) => setClientName(ev.target.value)}
              />
            </div>
            <div className="col-auto">
              <Button size="sm" onClick={loadClientContact}>
                {t("common.search")}
              </Button>
            </div>
          </Row>
          {clients && (
            <>
              <hr />
              <Row className="m-3">
                <div className="col-lg-6 col-md-12 item-list">
                  <h5 className="h6">{t("common.client")}s :</h5>
                  <div className="list-group">
                    {clients?.map((client, i) => {
                      return (
                        <ListGroupItem
                          key={i}
                          variant="action"
                          onClick={() => setClientId(client.id)}
                          active={client.id === clientId}
                        >
                          {client.name} {client.city}
                        </ListGroupItem>
                      );
                    })}
                  </div>
                </div>
                {clientId && (
                  <div className="col-lg-6 col-md-12 item-list">
                    <h5 className="h6">Contacts :</h5>
                    <div className="list-group">
                      {contacts?.map((clientEmail, i) => {
                        return (
                          <ListGroupItem
                            key={i}
                            variant="action"
                            onClick={() => setContact(clientEmail)}
                            active={
                              clientEmail.contactId === contact?.contactId
                            }
                          >
                            {clientEmail.name}{" "}
                            {clientEmail.email !== "none" && clientEmail.email}
                          </ListGroupItem>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Row>
              <hr />
              {contact && (
                <div className="text-center mt-1">
                  <Button size="sm" onClick={addClient}>
                    Add client
                  </Button>
                  <span className="ms-2">{contact.name}</span>
                </div>
              )}
            </>
          )}
        </div>
      </Popup>

      <Popup
        title={"TERMS & CONDITIONS"}
        onClose={() => setShowTAC(false)}
        show={showTAC}
      >
        <div className="terms-conditions-container">
          <p>{quote?.note}</p>
        </div>
      </Popup>

      <Popup
        show={editReminder}
        title={"Reminder"}
        onClose={() => setEditReminder(false)}
      >
        <div className="row align-items-center">
          <div className="mb-2 col-6">
            <div className="form-check">
              <FormCheckLabel htmlFor="reminder-radio-btn">
                {quote?.reminderEnable ? "Disable" : "Enable"} reminder
              </FormCheckLabel>
              <FormCheckInput
                id="reminder-radio-btn"
                checked={quote?.reminderEnable}
                onChange={(ev) => setValue("reminderEnable", ev.target.checked)}
              />
            </div>
          </div>
          {quote?.reminderEnable && (
            <div className="mb-2 col-6">
              <FormLabel htmlFor="reminder-date">Reminder</FormLabel>
              <input
                id="reminder-date"
                className={`form-control form-control-sm ${active}`}
                type="date"
                defaultValue={stringHelper.toDateString(quote?.reminder)}
                min={stringHelper.toDateString(new Date(Date.now()))}
                onChange={(ev) => setValue("reminder", ev.target.nodeValue)}
              />
            </div>
          )}
          <div className="col-auto ms-2"></div>
        </div>
        <div className="text-center">
          <button className="btn btn-primary" onClick={updateReminder}>
            Save
          </button>
        </div>
      </Popup>
    </Page>
  );

  function onEditSaveBtnClick() {
    if (editing) {
      // Handle save
      if (userId && quote) {
        // quoteService.updateQuoteInfo(parseInt(userId), quote).then((res) => {
        //     if (res) {
        //       loadQuote();
        //     }
        //   });
        //console.log(quote);
        quoteService.UpdateAllOfQuote(parseInt(userId), quote).then((res) => {
          if (res) {
            loadQuote();
          }
        });
      }
      //setEditing(false);
    } else {
      setEditing(true);
      setOriginalQuote({ ...quote } as Quote);
    }
  }

  function updateReminder() {
    let hasChanges =
      quote?.reminderEnable !== originalQuote?.reminderEnable ||
      quote?.reminder !== originalQuote?.reminder;

    if (userId && quoteId && quote && hasChanges) {
      // console.log(quote.reminder, quote.reminderEnable);
      quoteService
        .updateQuoteReminder(
          userId,
          quoteId,
          quote.reminderEnable,
          stringHelper.toDateString(quote.reminder)
        )
        .then((res) => {
          if (res) {
            loadQuote();
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function loadQuote() {
    if (userId && quoteId) {
      quoteService
        .getQuote(userId, quoteId)
        .then((data) => {
          if (data) {
            setQuote(data);
          }
        })
        .catch(() => { });
    }
  }
  function loadQuoteSummary() {
    if (userId && quoteId) {
      quoteService
        .getQuoteSummary(userId, quoteId)
        .then((data) => {
          if (data) {
            setQuoteSummary(data);
          }
        })
        .catch(() => { });
    }
  }
  function loadDlStatusTypes() {
    if (userId) {
      quoteService
        .getAllQuoteDlStatusTypes(userId)
        .then((data) => {
          if (data) {
            setCheckboxOptions(data);
          }
        })
        .catch(() => { });
    }
  }

  function loadNotes() {
    if (userId && quoteId) {
      let objectType = ActivityObjectType.Quotes;
      let activityType = ActivityType.Comment;
      activityService
        .getActivities(userId, quoteId, objectType, activityType)
        .then((data) => {
          if (data) setNotes(activityExtensions.buildActivities(data));
        })
        .catch(() => {
          return new Array<Activity>();
        });
    }
  }

  function loadQuoteHistory() {
    if (userId && quoteId) {
      quoteService
        .getQuoteHistory(userId, quoteId)
        .then((data) => {
          //console.log("history", data);
          if (data) {
            setHistories(data.map((history) => new QuoteHistory(history)));
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }

  function loadProgressHistory() {
    if (userId && quoteId) {
      orderService
        .getOrderHistoryProgress(userId, quoteId)
        .then((res) => {
          //console.log(res);
          if (res === undefined) {
            console.error(t("quote-page.error-progress-history-undefined"));
          } else {
            setProgressHistory(res);
          }
        })
        .catch((error) => {
          toast.error(t("quote-page.error-progress-history"), error);
        });
    }
  }

  function loadClientContact() {
    if (userId && clientName) {
      quoteService
        .getQuoteClients(userId, clientName)
        .then((data) => {
          if (data) {
            setClients(
              data.map((cust) => {
                let contacts = cust.contacts.map((c) => new ClientEmail(c));
                return new QuoteCust(cust, contacts);
              })
            );
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function loadShipViaList() {
    transportService
      .getAllShipVia()
      .then((data) => {
        if (data) {
          setShipViaArr(data);
        }
      })
      .catch((error) => console.error(error));
  }

  function onAddClientClose() {
    setIsAddClient(false);
    setClientId(undefined);
    setClientName("");
    setClients(undefined);
    setContact(undefined);
  }
  function onCopytoClose() {
    setShowCopyTo(false);
  }

  function addClient() {
    if (userId && quoteId && contact?.contactId && contact.clientId) {
      quoteService
        .addQuoteClient(userId, quoteId, contact.clientId, contact.contactId)
        .then((res) => {
          if (!res) {
            console.error(t("quote-page.error-add-client"));
          } else {
            loadQuote();
            onAddClientClose();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function onQuoteClientClick(id: number) {
    if (userId && quoteId && quote?.client !== id) {
      quoteService
        .updateQuotesClient(userId, quoteId, id)
        .then((res) => {
          if (res) {
            loadQuote();
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function removeClient(id: number) {
    if (userId && quoteId) {
      quoteService
        .deleteQuoteClient(userId, quoteId, id)
        .then((res) => {
          if (res) {
            loadQuote();
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function setValue(key: string, value: any) {
    setQuote((prevQuote) => ({ ...prevQuote, [key]: value } as Quote));
  }

  function loadPrint() {
    setShowPrintPreview(true);
    window.open(`/Quotes/${userId}/${quoteId}/print`);
  }
  
  function drawingToPdf() {
    if (quoteId) {
      quoteService
        .drawingToPdf(quoteId)
        .then((blob) => {
          if (blob) { 
            if (blob.size > 0) {
              // Create a File object
              const pdfFile = new File([blob], `Drawings-Quote-${quoteId}.pdf`, {
                type: 'application/pdf',
              });
  
              // Create a URL and open the PDF in a new tab
              const fileUrl = window.URL.createObjectURL(pdfFile);
              window.open(fileUrl, '_blank');
  
              // Download
              const link = document.createElement('a');
              link.href = fileUrl;
              link.setAttribute('download', pdfFile.name);
              document.body.appendChild(link);
              link.click();
              link.remove();
            } else {
              console.error(t("quote-page.error-blob-empty"));
            }
          } else {
            console.error(t("quote-page.error-blob-fetch-failed"));
          }
        })
        .catch((error) => console.error('Error fetching PDF:', error));
    }
  }

  function onConfirmClick() {
    if (userId && quoteId) {
      quoteService
        .deleteQuote(userId, quoteId)
        .then((res) => {
          setShowConfirmationQ(false);
          localStorage.setItem('activeTabIndex', "1");
          window.location.href = `/Quotes/${userId}`;
        })
        .catch((error) => console.error(error));
    }
  }

  function handleUpdate(type: string, updatedItem: any) {
    setQuote((prevQuote) => {
      if (prevQuote) {
        const updatedQuote = { ...prevQuote };

        switch (type) {
          case "kit":
            updatedQuote.quoteKits = updatedQuote.quoteKits.map((kit) =>
              kit.id === updatedItem.id ? updatedItem : kit
            );
            break;
          case "hxchanger":
            updatedQuote.quoteHeatExchangers =
              updatedQuote.quoteHeatExchangers.map((hx) =>
                hx.id === updatedItem.id ? updatedItem : hx
              );
            break;
          case "singleItem":
            updatedQuote.quoteSingleItems = updatedQuote.quoteSingleItems.map(
              (item) => (item.id === updatedItem.id ? updatedItem : item)
            );
            break;
          // Add cases for other types (tank, system, etc.)
          default:
            break;
        }

        return updatedQuote;
      } else {
        return new Quote(
          {
            id: 0,
            jobName: "",
            client: 0,
            clientListId: 0,
            user: 0,
            date: new Date(),
            po: "",
            hasRel: false,
            isRMA: false,
            toOrder: false,
            reminderEnable: false,
            note: "",
            originalQuote: undefined,
          },
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          []
        );
      }
    });
  }
}

type TabContainerProp = {
  show?: boolean;
  children?: React.ReactNode;
};
function TabContainerComponent(props: TabContainerProp) {
  const { show, children } = props;
  return <>{show && children}</>;
}

interface ProductSelectionProps {
  show: boolean;
  onClick: () => void;
  userId?: string;
  quoteId?: string;
}
function ProductSelectionComponent({
  show,
  onClick,
  userId,
  quoteId,
}: ProductSelectionProps) {

  const navigate = useNavigate();
  const products = [
    {
      name: "Pumps",
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/pump.svg`,
    },
    {
      name: "Item",
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/air.svg`,
    },
    {
      name: "Storage Buffer Tank",
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/storage-buffer-tank.svg`,
    },
    {
      name: "System",
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/system.svg`,
    },
    {
      name: "Misc Kits",
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/misc.svg`,
    },
    {
      name: "Heat Exch. PHE",
      image: `${process.env.PUBLIC_URL}/images/ProductsCatIcon/heat-exchanger.svg`,
    },
  ];

  return (
    <Modal show={show} onHide={onClick} id="product-selection" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add a product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="product-grid">
          {products.map((product, index) => (
            <div
              key={index}
              className="product-item"
              onClick={() => onAddCatClick(product)}
            >
              <div className="image-container">
                <img src={product.image} alt={product.name} />
              </div>
              <div className="button-container">
                <Button
                  key={product.name}
                  onClick={() => onAddCatClick(product)}
                >
                  {product.name}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function onAddCatClick(product: any) {
    if (product.name === "Item" && userId && quoteId) {
      navigate(`/SingleItem/${userId}/${quoteId}`);
    }
    if (product.name === "Pumps" && userId && quoteId) {
      navigate(`/Pumps/${userId}/Pump-Search/${quoteId}`);
    }
  }
}
