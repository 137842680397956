import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import stringHelper from "../../services/Core/Helpers/string-helper";
import { WebQuote } from "../../services/Orders/Models/Order";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import orderService from "../../services/Orders/OrderService";
import LoadingComponent from "../../components/Core/Loading";
import {
  ReactTableHeaderOptions,
  TableExportDataButton,
  getTableHeaderSortProps,
} from "../../components/react-table/react-table-component";
import Pagination from "../../components/react-table/Pagination";
import Popup from "../../components/Core/Popup";
import { useParams } from "react-router-dom";
import Activity, {
  ActivityObjectType,
  ActivityRequest,
  ActivityType,
} from "../../services/Activities/Activity";
import activityService from "../../services/Activities/ActivityService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import useColumnVisibility from "../../hooks/React table/useColumnVisibility";
import { ColumnVisibilityComponent } from "../../components/react-table/column-visibility-component";
import useColumnFilters from "../../hooks/React table/useColumnFilters";
import ResetFilterComponent from "../../components/react-table/react-table-header-options";
import { SelectComponent } from "../../components/Forms/select-component";

type IParamTypes = {
  employeeId: string;
};

export default function WebQuotesTable() {
  const { t, i18n } = useTranslation();
  const { employeeId } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<WebQuote>>([]);
  const [orderId, setOrderId] = useState<number>();
  const [activity, setActivity] = useState<Activity | undefined>();
  const [comment, setComment] = useState<string>("");

  const [dateFilter, setDateFilter] = useState<Date>(new Date(Date.now()));

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    columnFilters,
    editFilterFn,
    setColumnFilters,
    resetFiltersFn,
    getFilterValueFn,
  } = useColumnFilters(undefined, () => setDateFilter(new Date(Date.now())));

  useEffect(() => {
    if (employeeId !== undefined) {
      loadData();
    }
  }, [employeeId, dateFilter]);

  const columns: any = useMemo(() => {
    return [
      {
        header: "common.quote",
        accessorKey: "quoteId",
      },
      {
        header: "orders-page.date",
        accessorKey: "date",
        cell: (props: any) => stringHelper.toDateString(props.getValue()),
      },
      {
        header: "orders-page.status",
        accessorKey: "status",
        cell: (props: any): any =>
          t(`orders-page.web-quote-status-${props.getValue()}`),
      },
      {
        header: "common.country",
        accessorKey: "country",
        cell: (props: any) => t(`client.country-${props.getValue()}`) ?? "",
        filterFn: "includesString",
      },
      {
        header: "orders-page.owned-by",
        accessorKey: "ownedBy",
      },
      {
        header: "common.client",
        accessorKey: "clientContact",
      },
      {
        header: "orders-page.notes",
        accessorKey: "activities",
        cell: (props: any) => {
          let activities = props.getValue() as Array<Activity>;
          if (activities && activities.length > 0) {
            return (
              <p className="card-text text-muted">
                {activities[0].description}
              </p>
            );
          }
        },
      },
    ];
  }, [i18n]);

  const table = useReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { orderId: false, "mrt-row-expand": true },
    },
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  const order: WebQuote | undefined = useMemo(() => {
    return data.find((elem) => elem.quoteId === orderId) ?? undefined;
  }, [data, orderId]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div id="filters">
        <div className="row mb-2">
          <div className="col-3">
            <fieldset className="border rounded-3 p-1">
              <legend className="legend float-none w-auto px-3">
                {t(`orders-page.country`)}:
              </legend>

              <SelectComponent
                className="form-select-sm"
                defaultValue={getFilterValueFn("country")}
                onChange={(value) => {
                  editFilterFn("country", value);
                }}
                options={[
                  { text: "All", value: "" },
                  { text: t(`client.country-Us`), value: "Us" },
                  { text: t(`client.country-Canada`), value: "Canada" },
                ]}
              />
            </fieldset>
          </div>

          <div className="col-2">
            <fieldset className="border rounded-3 p-1">
              <legend className="legend float-none w-auto px-3">
                {t(`orders-page.date`)}:
              </legend>
              <input
                type="month"
                className="form-control form-control-sm"
                value={stringHelper.toMonthYearString(dateFilter)}
                onChange={(ev) =>
                  ev.target.valueAsDate && setDateFilter(ev.target.valueAsDate)
                }
              />
            </fieldset>
          </div>
        </div>
      </div>

      <div id="search-bar" className="m-3">
        <div className="input-group">
          <input
            type="search"
            className="form-control form-control-sm rounded"
            placeholder={t("common.search") || ""}
            onChange={(ev) => setGlobalFilter(ev.target.value)}
          />
        </div>
      </div>

      <div id="orders-table">
        <table className="table table-hover">
          <thead>
            <tr className="table-options">
              <th
                colSpan={
                  table.getAllFlatColumns().filter((x) => x.getIsVisible())
                    .length
                }
              >
                <ColumnVisibilityComponent
                  columns={iColumnVisibility}
                  className="me-1"
                />

                <ResetFilterComponent
                  resetFilterFn={resetFiltersFn}
                  className="me-1"
                />

                <TableExportDataButton
                  data={getExportData()}
                  filename="web-quotes"
                />
              </th>
            </tr>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {t(header.column.columnDef.header as string)}
                        <ReactTableHeaderOptions header={header} />
                        <br />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  onClick={() => setOrderId(row.original.quoteId)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination reactTable={table} siblingCount={2} />
      </div>

      <Popup
        onOk={closeComments}
        onClose={closeComments}
        show={order !== undefined}
        title={"Comments"}
      >
        <div className="card">
          {order?.activities.map((commentTemp, i) => {
            let canEdit = canEditComment(commentTemp);
            let isEditable = activity?.id === commentTemp.id;
            return (
              <div key={i} className="card" style={{ padding: "0", margin: 0 }}>
                <div className="card-body">
                  {activity && isEditable && canEdit ? (
                    <textarea
                      className="form-control"
                      value={activity.description}
                      onChange={(ev) =>
                        setActivity({
                          ...activity,
                          description: ev.target.value,
                        })
                      }
                    />
                  ) : (
                    <p className="card-text">{commentTemp.description}</p>
                  )}
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-2">
                      <p className="card-text text-muted">
                        <small>By '{commentTemp.employeeName}'</small>
                      </p>
                    </div>
                    <div className="col-6">
                      <small className="text-muted">
                        Created{" "}
                        {stringHelper.toDateString(commentTemp.createdDate)}
                      </small>
                    </div>
                    <div className="col-4 text-end">
                      {canEdit && (
                        <>
                          {!isEditable ? (
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary mx-1"
                              onClick={() => setActivity(commentTemp)}
                            >
                              <FontAwesomeIcon icon={["fas", "pencil"]} />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary mx-1"
                              onClick={patchActivity}
                            >
                              save
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => deleteComment(commentTemp.id)}
                          >
                            <FontAwesomeIcon icon={["fas", "trash-can"]} />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {order?.activities && order?.activities.length > 0 && <hr />}
          <div className="card-footer">
            <label htmlFor="new-activity-textarea">Add a comment</label>
            <textarea
              id="new-activity-textarea"
              className="form-control"
              value={comment}
              onChange={(ev) => setComment(ev.target.value)}
            ></textarea>
            <div className="text-center">
              <button
                className="btn btn-outline-primary mt-1"
                onClick={createComment}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );

  function getExportData(): Array<any> {
    return table.getFilteredRowModel().rows.map((item) => {
      let temp = item.original as WebQuote;
      return {
        Quote: temp.quoteId,
        Date: stringHelper.toDateString(temp.date),
        Status: temp.status,
        Country: t(`client.country-${temp.country}`),
      };
    });
  }

  function loadData() {
    if (employeeId) {
      let date: string = stringHelper.toMonthYearString(dateFilter);
      orderService
        .getWebQuotes(employeeId, date)
        .then((data) => {
          if (data) {
            // console.log(data);
            setData(data.map((elem) => new WebQuote(elem)));
          }
        })
        .catch(() => {
          toast.error("An error occured while fetching the orders");
        })
        .finally(() => setLoading(false));
    }
  }

  function createComment() {
    if (employeeId && order?.quoteId && comment) {
      let data = new ActivityRequest(
        ActivityType.Comment,
        order?.quoteId.toString(),
        ActivityObjectType.Quotes,
        comment
      );

      // console.log(data);
      activityService
        .postActivity(employeeId, data)
        .then((res) => {
          if (res) {
            setComment("");
            loadData();
          }
        })
        .catch(() => {
          toast.error("An error occured while creating your comment");
        });
    }
  }

  function patchActivity() {
    let canEdit = canEditComment(activity);
    if (employeeId && canEdit && activity?.description) {
      activityService
        .patchActivity(employeeId, activity.id, activity.description)
        .then((data) => {
          if (data) {
            loadData();
            setActivity(undefined);
          }
        })
        .catch(() => {
          toast.error("An error occured while updating your comment");
        });
    }
  }

  function deleteComment(activityId: string) {
    if (employeeId) {
      activityService
        .deleteActivity(employeeId, activityId)
        .then((data) => {
          if (data) {
            loadData();
          }
        })
        .catch(() => {
          toast.error("An error occured while deleting your comment");
        });
    }
  }

  function closeComments() {
    setOrderId(undefined);
    setActivity(undefined);
    setComment("");
  }

  function canEditComment(temp: Activity | undefined): boolean {
    return (
      temp !== undefined &&
      employeeId === temp?.employeeId.toString() &&
      temp.createdDate.getMilliseconds() - Date.now() <= 604800000
    );
  }
}
