import { useState, FormEvent } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Col, Row } from "react-bootstrap";
import { Page } from "../../../components/Pages/page-components";
import { useTranslation } from "react-i18next";
import stringHelper from "../../../services/Core/Helpers/string-helper";
import { faEye, faEyeSlash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loginService from "../../../services/Login/LoginService";
import LoginGmail from "../../../services/Login/LoginGmail";
import { Client } from "../../../services/Users/Client";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { GetProvinceOptions } from "../../Users/user-form-components";
import { useRef } from "react";
import userService from "../../../services/Users/UserService";
import { ChangeEvent } from "react";
import { Dispatch, SetStateAction } from "react";
type Props = {
  defaultUser: Client;
  setDefaultUser: Dispatch<SetStateAction<Client | undefined>>;
};
function Profile({ defaultUser, setDefaultUser }: Props) {
  return (
    <Page className="loginPage">
      <Row className="mt-2">
        <Col sm={12}>
          <div className="card mt-2">
            <div className="card-body">
              <ProfilePhotoForm
                defaultUser={defaultUser}
                setDefaultUser={setDefaultUser}
              />
            </div>
          </div>
        </Col>
        {true && (
          <>
            <Col sm={12}>
              <div className="card mt-2">
                <div className="card-body">
                  <UserDetailsForm
                    defaultUser={defaultUser}
                    setDefaultUser={setDefaultUser}
                  />
                </div>
              </div>
            </Col>

            <Col sm={6}>
              <div className="card mt-2 h-100">
                <div className="card-body">
                  <EditPasswordForm />
                </div>
              </div>
            </Col>
          </>
        )}
        <Col sm={6}>
          <div className="card mt-2 h-100">
            <div className="card-body">
              <LinkClientIdGmailComponent />
            </div>
          </div>
        </Col>
      </Row>
    </Page>
  );
}
function LinkClientIdGmailComponent() {
  const { t } = useTranslation();
  return (
    <>
      <div className="row text-center mb-3">
        <div className="col-auto align-self-center">
          <h5 className="mb-0">{t("profile.link-with-google")}</h5>
        </div>
      </div>
      <label className="mb-3">{t("profile.allow-login-google")}</label>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          loginService
            .LinkClientIdGmail(credentialResponse as LoginGmail)
            .then(() => {
              toast.success(t(`profile.gmail-link-success`));
            })
            .catch((err: AxiosError) => {
              toast.error(t(`profile.gmail-link-error-${err.response?.data}`));
            });
        }}
        onError={() => {
          toast.error("login-page.googleLogin-error");
        }}
        shape="pill"
        logo_alignment="center"
        text="continue_with"
        size="large"
        theme="filled_blue"
      />
    </>
  );
}
function UserDetailsForm({ defaultUser, setDefaultUser }: Props) {
  const [user, setUser] = useState(defaultUser);
  const [validated, setValidated] = useState(false);
  const [editing, setEditing] = useState(false);
  const [sendEmailCountry, setSendEmailCountry] = useState(false);
  const { t } = useTranslation();
  function setValue(key: string, value: any) {
    setUser({ ...user, [key]: value } as Client);
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (editing) {
      if (form.checkValidity() === true) {
        userService
          .updateSelf(user)
          .then(() => {
            toast.success("profile.success-changing-user");
            setEditing(false);
            setValidated(false);
            setDefaultUser({ ...user });
            if (sendEmailCountry) {
              userService.ClientChangeCountryRequest();
            }
          })
          .catch((err: AxiosError) => {
            toast.error(t(`profile.error-changing-user-${err.response?.data}`));
          });
      } else setValidated(true);
    } else {
      setEditing(true);
    }
  };
  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="user-form"
      >
        <div className="row text-center mb-3">
          <div className="col-auto align-self-center">
            <h5>{t("profile.User-Details")}</h5>
          </div>
          <div className="col-auto align-self-center">
            <Button size="sm" type="submit">
              {editing ? t("common.save") : t("common.edit")}
            </Button>
            {editing && (
              <Button
                className="btn-outline-primary red ms-3"
                size="sm"
                onClick={() => {
                  setEditing(false);
                  setUser(defaultUser);
                }}
              >
                <FontAwesomeIcon icon={faX} />
              </Button>
            )}
          </div>
        </div>
        <Row className="mb-2">
          <Col xs={6} md={5}>
            <Form.Group>
              <Form.Label>{t("user.nom")}</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  disabled={!editing}
                  value={user.nom}
                  type="text"
                  name="nom"
                  placeholder={"" + t("user.nom")}
                  aria-describedby="inputGroupPrepend"
                  onChange={(ev) => setValue("nom", ev.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a {t("user.nom")}.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={6} md={5}>
            <Form.Group>
              <Form.Label>{t("user.email")}</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  disabled={!editing}
                  value={user.email}
                  type="text"
                  name="email"
                  placeholder={"example@mail.com"}
                  aria-describedby="inputGroupPrepend"
                  pattern={stringHelper.RegExpToString(
                    stringHelper.RegExpEmailAdress
                  )}
                  onChange={(ev) => setValue("email", ev.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("register-page.bad-email-format")}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm={6} md={5}>
            <Form.Group>
              <Form.Label>{t("user.country")}</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  disabled={!editing}
                  value={user.country}
                  as="select"
                  name="country"
                  aria-describedby="inputGroupPrepend"
                  required
                  onChange={(ev) => setValue("country", ev.target.value)}
                >
                  <option value="Us">Us</option>
                  <option value="Canada">Canada</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={6} md={5}>
            <Form.Group>
              <Form.Label>{t("user.province")}</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  disabled={!editing}
                  value={user.province}
                  as="select"
                  name="province"
                  aria-describedby="inputGroupPrepend"
                  isInvalid={user.province === undefined}
                  onChange={(ev) => setValue("province", ev.target.value)}
                  required
                >
                  <GetProvinceOptions
                    country={user.country ?? "us"}
                  ></GetProvinceOptions>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {t("register-page.no-province")}.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {(user.country !== defaultUser.country ||
          user.province !== defaultUser.province) && (
          <Row className="mb-2">
            <Col sm={12}>
              <Form.Group>
                <InputGroup hasValidation>
                  <Form.Check
                    onChange={(ev) =>
                      setSendEmailCountry(ev.target.value === "on")
                    }
                    type="checkbox"
                    label={t("profile.send-email-change-country")}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}
function EditPasswordForm() {
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [editingPassword, setEditingPassword] = useState(false);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (editingPassword) {
      const form = event.currentTarget;
      if (form.checkValidity() === true) {
        userService
          .updateSelfPassword(password)
          .then(() => {
            setEditingPassword(false);
            setValidated(false);
            toast.success("profile.success-changing-password");
          })
          .catch((err: AxiosError) => {
            toast.error(
              t(`profile.error-changing-password-${err.response?.data}`)
            );
          });
      } else setValidated(true);
    } else {
      setPassword("");
      setEditingPassword(true);
    }
  };
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="user-form"
    >
      <div className="row text-center mb-3">
        <div className="col-auto">
          <h5>{t("profile.Change-Password")}</h5>
        </div>
        <div className="col-auto">
          <Button size="sm" type="submit">
            {editingPassword ? t("common.save") : t("common.edit")}
          </Button>
          {editingPassword && (
            <Button
              className="btn-outline-primary red ms-3"
              size="sm"
              onClick={() => {
                setEditingPassword(false);
              }}
            >
              <FontAwesomeIcon icon={faX} />
            </Button>
          )}
        </div>
        <div className="col-auto"></div>
      </div>
      <label className="form-label mb-3">{t("user.password")}</label>
      <InputGroup hasValidation>
        <Form.Control
          type={showPassword && editingPassword ? "text" : "password"}
          value={editingPassword ? password : "******"}
          className={editingPassword ? "active" : ""}
          onChange={(ev) => setPassword(ev.target.value)}
          pattern={stringHelper.RegExpToString(stringHelper.RegExpPassword)}
          aria-describedby="inputGroupPrepend"
          required
          disabled={!editingPassword}
        />
        {editingPassword && (
          <InputGroup.Text
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </InputGroup.Text>
        )}
        <Form.Control.Feedback type="invalid">
          {t("register-page.bad-password-format")}
        </Form.Control.Feedback>
      </InputGroup>
    </Form>
  );
}
function ProfilePhotoForm({ defaultUser, setDefaultUser }: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const uploadProfileImage = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files[0]) {
      let imageFile = ev.target.files[0];
      //
      const reader = new FileReader();
      reader.onload = (x) => {
        var img = new Image();

        img.onload = function () {
          const limitSize = 2097152;
          const limitWidthHeight = 400;
          let errorMessage = "";
          if (imageFile.size > limitSize) {
            errorMessage = t("profile.image-size-too-big");
          }
          if (img.width < limitWidthHeight) {
            errorMessage = t("profile.image-width-too-small");
          }
          if (img.height < limitWidthHeight) {
            errorMessage = t("profile.image-height-too-small");
          }
          setErrorMessage(errorMessage);
          if (!errorMessage)
            userService
              .uploadProfileImage(imageFile)
              .then((res) => {
                if (typeof res === "string")
                  setDefaultUser({ ...defaultUser, logoPath: res });
              })
              .catch(() => {
                toast.error(t("profile.post-image-error"));
              });
        };

        if (typeof x.target?.result === "string") img.src = x.target.result;
      };
      reader.readAsDataURL(imageFile);
    }
  };
  const deleteProfileImage = () => {
    userService
      .deleteProfileImage()
      .then(() => {
        setDefaultUser({ ...defaultUser, logoPath: undefined });
      })
      .catch(() => {
        toast.error(t("profile.delete-image-error"));
      });
  };
  return (
    <Row>
      <h5 className="mb-3">{t("profile.Profile-Photo")}</h5>
      <Col sm={6} className="d-flex">
        <img
          className="avatar-bg"
          title="profil Image"
          onError={(e) => {
            e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/default.jpg`
          }}
          alt="process.env.REACT_APP_IMAGE_BASE_URL}/profiles/default.jpg"
          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/${
            defaultUser.logoPath ? defaultUser.logoPath : "default.jpg"
          }`
        }
        ></img>
        <input
          onChange={uploadProfileImage}
          ref={inputRef}
          accept="image/*"
          type="file"
          className="d-none"
          id="getAvatar"
        />
        <span className="mx-3 d-flex flex-column justify-content-evenly">
          <Button
            variant="outline-primary"
            onClick={() => inputRef.current?.click()}
          >
            {t("profile.Upload-Image")}
          </Button>
          {defaultUser.logoPath && (
            <a onClick={deleteProfileImage} id="remove-photo-button">
              {t("profile.remove-image")}
            </a>
          )}

        </span>
      </Col>
      <Col sm={6} id="photo-requirment">
        <div>
          <h5>{t("profile.image-requirments")}</h5>
          <ol className="mb-0">
            <li>{t("profile.photo-min-dimension")}</li>
            <li>{t("profile.photo-max-size")}</li>
            <li>{t("profile.photo-content-requirments")}</li>
          </ol>
        </div>
      </Col>
      {errorMessage && (
            <div className="alert alert-danger mb-0">{errorMessage}</div>
          )}
    </Row>
  );
}
export default Profile;
