import BaseApi from "../Core/REST/BaseApi";
import PumpCouplingAlignmentSheet from "./Models/PumpCouplingAlignmentSheet";
import pumpExtensions from "./PumpExtensions";
import PumpTestSheet, {
  PumpTestSheetInfo,
  PumpTestSheetTag,
  PumpTestSheetValue,
} from "./Models/PumpTestSheet";
import PumpSearchConfig from "./Models/PumpSearchConfig";
import { PumpType } from "./Models/PumpType";
import PumpSearchCriteria from "./Models/PumpSearchCriteria";
import PumpSearchPump from "./Models/PumpSearchPump";
import PumpSearchOption from "./Models/PumpSerachOption";
import QuoteKit from "../Quotes/Models/QuoteKit";
import { Pump } from "./Models/Pump";

class PumpService extends BaseApi {

  async getPumpTypes(userId: string): Promise<PumpType[][] | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/Pump-Types`;
    return await this.get<PumpType[][] | undefined>(url);
  }

  async getPumpAvailableConstructions(
    userId: string,
    pumpTypeId: number
  ): Promise<string[] | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/Available-Construction/${pumpTypeId}`;
    return await this.get<string[] | undefined>(url);
  }

  async getPumpAvailableFlangeTypes(
    userId: string,
    pumpTypeId: number
  ): Promise<string[] | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/Available-Flange-Types/${pumpTypeId}`;
    return await this.get<string[] | undefined>(url);
  }

  async getPumpAvailableVoltPhHz(
    userId: string
  ): Promise<string[][] | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/Available-Volt-PH-Hz`;
    return await this.get<string[][]>(url);
  }

  async getConfigPumpSearch(
    userId: string,
    pumpTypeId: number
  ): Promise<PumpSearchConfig | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/Config-Pump-search/${pumpTypeId}`;
    return await this.get<PumpSearchConfig | undefined>(url);
  }

  async getPumpAlignmentSheets(
    externalFileId: string,
    employeeId: string
  ): Promise<PumpCouplingAlignmentSheet[] | undefined> {
    let url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/employee/${employeeId}`;
    return await this.get<PumpCouplingAlignmentSheet[] | undefined>(url);
  }

  async createAlignmentSheet(
    externalFileId: string,
    employeeId: string,
    serialNumber: string
  ): Promise<string | undefined> {
    let url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/employee/${employeeId}/${serialNumber}`;
    return await this.post(url, {});
  }

  async saveAlignmentSheet(
    employeeId: string,
    data: any
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/pumps/externalFile/employee/${employeeId}/alignment-sheet/${data.id}`;
    return await this.put(url, data);
  }
  async updatePumpAlignmentSheetFinished(
    employeeId: string,
    id: number
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/pumps/externalFile/employee/${employeeId}/alignment-sheet/${id}`;
    return await this.patch(url, {});
  }
  async getPumpTestSheet(
    externalFileId: string,
    employeeId: string
  ): Promise<PumpTestSheet | undefined> {
    let url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/${employeeId}/pump-test-sheets`;
    return await this.get<PumpTestSheet>(url)
      .then((data) => {
        // console.log(data);
        if (data) {
          let sheets: Array<PumpTestSheetInfo> = [];
          if (data.sheets.length === 0) {
            sheets = [
              pumpExtensions.generateNewPumpTestSheet(
                employeeId,
                externalFileId
              ),
            ];
          } else {
            sheets = data.sheets.map((sheet) => {
              const id = sheet.id;
              let pumpSheetTags: Array<PumpTestSheetTag> = [];
              let pumpSheetValues: Array<PumpTestSheetValue> = [];

              if (sheet.pumpTestSheetTag.length === 0) {
                pumpSheetTags.push(new PumpTestSheetTag({ sheetId: id }));
              } else {
                pumpSheetTags = sheet.pumpTestSheetTag.map(
                  (tag) => new PumpTestSheetTag(tag)
                );
              }

              if (sheet.pumpTestSheetValues.length === 0) {
                pumpSheetValues =
                  pumpExtensions.generatePumpTestSheetValues(id);
              } else {
                pumpSheetValues = sheet.pumpTestSheetValues.map((value) => {
                  return new PumpTestSheetValue(value);
                });

                if (sheet.pumpTestSheetValues.length < 5) {
                  pumpSheetValues = pumpSheetValues.concat(
                    pumpExtensions.generatePumpTestSheetValues(
                      id,
                      sheet.pumpTestSheetValues.length
                    )
                  );
                }
              }

              return new PumpTestSheetInfo(
                sheet,
                pumpSheetTags,
                pumpSheetValues
              );
            });
          }
          return new PumpTestSheet(data, sheets);
        }
        return undefined;
      })
      .catch((error) => undefined);
  }

  async createPumpTestSheet(
    externalFileId: string,
    employeeId: string,
    data: any
  ): Promise<number | undefined> {
    let url = `${this.baseUrl}/pumps/externalFile/${externalFileId}/${employeeId}/pump-test-sheets`;
    return await this.post(url, data);
  }

  async updatePumpTestSheet(
    employeeId: string,
    data: any
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/pumps/${employeeId}/pump-test-sheets/${data.id}`;
    return await this.put(url, data);
  }

  async updatePumpTestSheetFinished(
    employeeId: string,
    id: number
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/pumps/${employeeId}/pump-test-sheets/${id}`;
    return await this.patch(url, {});
  }
  async getPumps(searchCriteria : PumpSearchCriteria, userId: string): Promise<Array<PumpSearchPump> | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/load-pumps`;
    return await this.post(url, searchCriteria);
  }
  async getPumpOptions(seriesId : number, quoteKitId : string | number): Promise<Array<PumpSearchOption> | undefined> {
    let url = `${this.baseUrl}/pumps/${seriesId}/${quoteKitId}/load-options`;
    return await this.get(url);
  }
  async addPumpAndOption(selectedPump : PumpSearchPump, userId: string,systemId: string): Promise<number | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/add-pump-to-quote/${systemId}`;
    return await this.post(url, selectedPump);
  }
  async addPumpOptions(selectedPumpOption : Array<PumpSearchOption>, userId: string,systemId: string, quoteId: string, quoteKitId: string): Promise<number | undefined> {
    let url = `${this.baseUrl}/pumps/${userId}/add-pump-options-to-quote/${quoteId}/${quoteKitId}/${systemId}`;
    return await this.post(url, selectedPumpOption);
  }
  async generatePumpCurve(selectedPump : PumpSearchPump): Promise<string | undefined> {
    let url = `${this.baseUrl}/pumps/generate-pump-curve`;
    return await this.post(url, selectedPump);
  }
  async generateQuotePumpCurve(quoteKit : QuoteKit): Promise<string | undefined> {
    let url = `${this.baseUrl}/pumps/generate-quote-pump-curve`;
    return await this.post(url, quoteKit);
  }
  async getAllPumps(
  ): Promise<Pump[] | undefined> {
    let url = `${this.baseUrl}/pumps/get-all-pumps`;
    return await this.get<Pump[] | undefined>(url);
  }
}



const pumpService = new PumpService();
export default pumpService;
