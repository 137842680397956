import { useEffect, useState } from "react";

export type IChanges = {
  changes: Array<string>;
  hasChanges: () => boolean;
  addFn: (key: string) => void;
  removeAllFn: (key: string) => void;
  resetFn: () => void;
};

export function useChanges(
  depencies: Array<any>,
  defaultValue?: Array<string>
) {
  const [changes, setChanges] = useState<Array<string>>([]);

  useEffect(() => {
    setChanges(defaultValue ?? []);
  }, depencies);

  const hasChanges = () => changes.length > 0;

  const addFn = (key: string) => {
    if (!changes.includes(key)) {
      setChanges([...changes, key]);
    }
  };

  const removeAllFn = (key: string) => {
    if (changes.includes(key)) {
      setChanges([...changes].filter((c) => c !== key));
    }
  };

  const resetFn = () => setChanges(defaultValue ?? []);

  return {
    changes: changes,
    hasChanges,
    addFn,
    removeAllFn,
    resetFn,
  } as IChanges;
}
