import Popup from "../../components/Core/Popup";
import departmentService from "../../services/Departments/departmentService";
import { Department } from "../../services/Departments/Department";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import stringHelper from "../../services/Core/Helpers/string-helper";
import country from "./country.json";
import { FormControl } from "react-bootstrap";
import defaultDiscountService from "../../services/DefaultDiscount/defaultDiscountService";
import { DefaultDiscount } from "../../services/DefaultDiscount/DefaultDiscount";
type newDepartmentFormProps = {
  isOpen: boolean;
  closeAction: () => void;
  createDepartmentAction: (newDepartment: Department, any: any) => void;
};
export function CreateNewDepartmentFormPopup(props: newDepartmentFormProps) {
  const [departmentArray, setDepartmentArray] = useState<Array<Department>>();
  useEffect(() => {
    departmentService
      .getAll()
      .then((data) => setDepartmentArray(data))
      .catch(() => {
        toast.error(t("user-form-page.load-department-error"));
      });
  }, []);
  const { t } = useTranslation();
  const [newDepartment, setNewDepartment] = useState<Department>();
  return (
    <Popup
      onClose={props.closeAction}
      onOk={() => createNewDepartment(props.createDepartmentAction)}
      title={t("user-form-page.create-department")}
      show={props.isOpen}
    >
      <>
        <label className="form-label">
          {t("user-form-page.create-department")}
        </label>
        <input
          type="text"
          className="form-control active"
          defaultValue={newDepartment?.name}
          onChange={(ev) =>
            setNewDepartment({
              ...newDepartment,
              name: ev.target.value,
            } as Department)
          }
        />
      </>
    </Popup>
  );
  function validateDepartment(departementName: string) {
    if (departementName === "") {
      toast.error(t("user-form-page.empty-new-department"));
      return false;
    }
    if (departmentArray?.find((e) => e.name === departementName)) {
      let errorMsg =
        t("user-form-page.department-duplicate-error") + ": " + departementName;
      toast.error(errorMsg);
      return false;
    }
    return true;
  }
  function createNewDepartment(
    createDepartmentAction: (newDepartment: Department, any: any) => void
  ) {
    let departmentName = stringHelper.capitalizeFirstLetter(
      newDepartment?.name ? newDepartment.name : ""
    );
    if (newDepartment && validateDepartment(departmentName)) {
      newDepartment.name = departmentName;
      departmentService
        .putDepartment(newDepartment)
        .then((res) => {
          createDepartmentAction(newDepartment, res);
        })
        .catch(() => {
          toast.error(t("user-form-page.departement-post-error"));
        });
    }
  }
}
type DefaultDiscountFormProps = {
  employeeId: string;
  isOpen: boolean;
  closeAction: () => void;
};
export function DefaultDiscountFormPopup(props: DefaultDiscountFormProps) {
  const { t } = useTranslation();
  const [discountUSA, setDiscountUSA] = useState<DefaultDiscount>();
  const [discountCAN, setDiscountCAN] = useState<DefaultDiscount>();
  useEffect(() => {
    defaultDiscountService
      .getAll(props.employeeId)
      .then((data) => {
        setDiscountUSA(data?.find((x) => x.country === "USA"));
        setDiscountCAN(data?.find((x) => x.country === "CAN"));
      })
      .catch(() => {
        toast.error(t("user-form-page.load-defaultDiscount-error"));
      });
  }, []);
  return (
    <Popup
      onClose={props.closeAction}
      onOk={() => {
        if (discountCAN !== undefined) {
          defaultDiscountService.putDefaultDiscount(props.employeeId, discountCAN)
          .catch(() => {
            toast.error(t("user-form-page.put-defaultDiscount-error"));
          });
        }
        if (discountUSA !== undefined) {
          defaultDiscountService.putDefaultDiscount(props.employeeId, discountUSA);
        }
        props.closeAction();
      }}
      title="Change default discount"
      show={props.isOpen}
    >
      <label className="form-label">
        <h2>CAN</h2>
      </label>
      <div className="row">
        {Object.entries(discountCAN ? discountCAN : {}).map(([name, val]) => (
          <div
            key={name + discountCAN?.country}
            className="col-md-6 col-sm-12 mb-2"
          >
            {name !== "country" && name !== "id" && (
              <>
                <label className="form-label" htmlFor={name}>
                  {t(`user.discount.${name}`)}
                </label>
                <FormControl
                  id={name + "CAN"}
                  value={isNaN(val) ? "" : val}
                  type="number"
                  min={-100}
                  max={100}
                  size="sm"
                  className="active"
                  onBlur={(ev) => {
                    if (ev.target.value === "")
                      setDiscountCAN({
                        ...discountCAN,
                        [name]: 0,
                      } as DefaultDiscount);
                  }}
                  onChange={(ev) =>
                    setDiscountCAN({
                      ...discountCAN,
                      [name]: parseInt(ev.target.value),
                    } as DefaultDiscount)
                  }
                />
              </>
            )}
          </div>
        ))}
      </div>
      <label className="form-label">
        <h2>USA</h2>
      </label>
      <div className="row">
        {Object.entries(discountUSA ? discountUSA : {}).map(([name, val]) => (
          <div
            key={name + discountCAN?.country}
            className="col-md-6 col-sm-12 mb-2"
          >
            {name !== "country" && name !== "id" && (
              <>
                <label className="form-label" htmlFor={name}>
                  {t(`user.discount.${name}`)}
                </label>
                <FormControl
                  id={name + "USA"}
                  value={isNaN(val) ? "" : val}
                  type="number"
                  min={-100}
                  max={100}
                  size="sm"
                  className="active"
                  onBlur={(ev) => {
                    if (ev.target.value === "")
                      setDiscountUSA({
                        ...discountUSA,
                        [name]: 0,
                      } as DefaultDiscount);
                  }}
                  onChange={(ev) =>
                    setDiscountUSA({
                      ...discountUSA,
                      [name]: parseInt(ev.target.value),
                    } as DefaultDiscount)
                  }
                />
              </>
            )}
          </div>
        ))}
      </div>
    </Popup>
  );
  type Props = {
    discount: [string, number];
    country: string;
    onChangeAction: (newDiscount: number) => void;
  };
}

export function getProvince(provinceName:string)
{
  return country.find((x) => x.name === provinceName)?.provinces;
}
type getProvinceProps = {
  country: string;
};
export function GetProvinceOptions(props: getProvinceProps) {
  const { t } = useTranslation();
  let provinceArray = getProvince(props.country)
  return (
    <>
      <option key={-1} value={""}>
        {t("register-page.choose-province")}
      </option>
      {provinceArray?.map((province, index) => (
        <option key={country + "" + index} value={province.name}>
          {province.name}
        </option>
      ))}
    </>
  );
}