export class EmailConfig {
    id: string;
    subject: string;
    recipients: Array<EmailRecipient>
    constructor(
        data: any
    ){
        if (data.id === undefined) throw new ReferenceError("id undefined");
        this.id = data.id;
        if (data.subject === undefined) throw new ReferenceError("subject undefined");
        this.subject= data.subject;
        if(data.recipient === undefined) throw new ReferenceError("recipient undefined");
        this.recipients = data.recipients
    }
}
export class EmailRecipient
{
    email: string;
    type: EmailRecipientType;
    constructor(
        data: any
    )
    {
        if (data.email === undefined) throw new ReferenceError("email undefined");
        this.email = data.email;
        if (data.type === undefined) throw new ReferenceError("type undefined");
        this.type = data.type;
    }
}
enum EmailRecipientType { To = 0, CC = 1, BCC = 2 }
