export class QuoteItem {
  id: number;
  quoteKitId: number;
  itemName: string;
  qty: number;
  cost: number;
  it_code: number;
  it_ocode: string;

  itemDetails: ItemDetails;

  constructor(data: any, itemDetails: ItemDetails) {
    this.id = data.id;
    this.quoteKitId = data.quoteKitId;
    this.itemName = data.itemName;
    this.qty = data.qty;
    this.cost = data.cost;
    this.it_code = data.it_code;
    this.it_ocode = data.it_ocode;

    this.itemDetails = itemDetails;
  }
}

export class ItemDetails {
  qtyReserved: number;
  qtyOnOrder: number;
  qtyOnHand: number;

  constructor(data: any) {
    this.qtyReserved = data.qtyReserved ?? 0;
    this.qtyOnOrder = data.qtyOnOrder ?? 0;
    this.qtyOnHand = data.qtyOnHand ?? 0;
  }

  public getQtyAvailable() {
    return this.qtyOnHand - this.qtyReserved;
  }
}
