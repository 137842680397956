import { ProgressBar } from "../../components/Charts/ProgressBarComponent";
import BaseApi from "../Core/REST/BaseApi";
import Quote from "../Quotes/Quote";
import { CustOrdersAmount } from "./Models/CustOrdersAmount";
import { OpenOrder, OrderHistoryProgress, WebQuote } from "./Models/Order";
import QuoteTableModel from "./Models/QuoteTableModel";

class OrderService extends BaseApi {
  async getAllQuotes(
    employeeId: string,
    startDate?: string,
    endDate?: string,
    poStartDate?: string,
    poEndDate?: string,
    status?: number,
    withNetPrice: boolean = false
  ): Promise<Array<QuoteTableModel> | undefined> {
    let url = `${this.baseUrl}/orders/${employeeId}`;
    let params: Array<string> = [];
    if (startDate && endDate) {
      params.push(`startDate=${startDate}&endDate=${endDate}`);
    }
    if (poStartDate && poEndDate) {
      params.push(`poStartDate=${poStartDate}&poEndDate=${poEndDate}`);
    }
    if (status != null) params.push(`status=${status}`);
    if (withNetPrice) params.push(`withTotal=${withNetPrice}`);

    params.forEach((param, i) => {
      if (i === 0) {
        url += "?" + param;
      } else {
        url += "&" + param;
      }
    });

    return await this.get<Array<QuoteTableModel>>(url);
  }

  async getOpenOrders(
    employeeId: string
  ): Promise<Array<OpenOrder> | undefined> {
    let url = `${this.baseUrl}/orders/${employeeId}/Open-Orders`;
    return await this.get<Array<OpenOrder>>(url);
  }

  async getWebQuotes(
    employeeId: string,
    date: string
  ): Promise<Array<WebQuote> | undefined> {
    let url = `${this.baseUrl}/orders/${employeeId}/Web-Quotes/${date}`;
    return await this.get<Array<WebQuote>>(url);
  }

  async getQuoteNetPrice(employeeId: string, quoteId: number) {
    let url = `${this.baseUrl}/orders/${employeeId}/net-price/${quoteId}`;
    return await this.get<number>(url);
  }

  async getAllUsers(
    employeeId: string,
    startDate?: string,
    endDate?: string,
    poStartDate?: string,
    poEndDate?: string,
    status?: number
  ) {
    let url = `${this.baseUrl}/orders/${employeeId}/users`;
    let params: Array<string> = [];
    if (startDate && endDate) {
      params.push(`startDate=${startDate}&endDate=${endDate}`);
    }
    if (poStartDate && poEndDate) {
      params.push(`poStartDate=${poStartDate}&poEndDate=${poEndDate}`);
    }
    if (status != null) params.push(`status=${status}`);

    params.forEach((param, i) => {
      if (i === 0) {
        url += "?" + param;
      } else {
        url += "&" + param;
      }
    });

    return await this.get<Array<string>>(url);
  }
  async getOrderHistoryProgressHdr(employeeId: string, quoteId: string): Promise<OrderHistoryProgress | undefined> {
    let url = `${this.baseUrl}/orders/${employeeId}/order-history-hdr/${quoteId}`;
    return await this.get<OrderHistoryProgress | undefined>(url);
}
  
async getOrderHistoryProgress(employeeId: string, quoteId: string): Promise<Array<ProgressBar> | undefined> {
        let url = `${this.baseUrl}/orders/${employeeId}/order-history-progressbar/${quoteId}`;
        return await this.get<Array<ProgressBar> | undefined>(url);
    }
    async getCustOrdersAmount(employeeId: string, startDate?: string, endDate?: string)
    : Promise<Array<CustOrdersAmount> | undefined> {
      let url = `${this.baseUrl}/orders/${employeeId}/cust-orders-amount?startDate=${startDate}&endDate=${endDate}`;
      return await this.get<Array<CustOrdersAmount>>(url);
      
    }

}

const orderService = new OrderService();
export default orderService;
