import { useParams, useNavigate } from "react-router-dom";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
import userService from "../../services/Users/UserService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Page,
  PageDetails,
  PageHeader,
  PageTitle,
} from "../../components/Pages/page-components";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { Client } from "../../services/Users/Client";
import CheckAllowedComponent from "../../components/CheckAllowedComponent";
type IParamTypes = {
  employeeId: string;
  userId: string;
  userName: string;
};
type Props = {
  employee: Client;
};

export default function DeleteUser({employee}:Props) {
  
  const { t } = useTranslation();
  const {userId, userName } = useParams<IParamTypes>();
  const [isDeleted, setIsDeleted] = useState(false);
  const title = t("delete-user-page.delete");
  const navigate = useNavigate();
  let employeeId: string = employee.id.toString();

  return (
    <CheckAllowedComponent  user={employee} objectName="alter-user" redirect={true}>
    <Page id="quote-details">
      <PageHeader className="d-block">
        <BreadcrumbComponent
          items={[
            {
              text: t("manage-users-page.manage-users"),
              link: `/UserForm/${userId}`,
            },
            {
              text: title,
              active: true,
            },
          ]}
        />
      </PageHeader>
      <PageTitle>
        <div className="row text-center">
          <div className="col-auto align-self-center">
            <h1 className="h2">
              {t("delete-user-page.delete") + " " + userName}
            </h1>
          </div>
          <div className="col-auto align-self-center">
            {!isDeleted && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  navigate(`/UserForm/${userId}`);
                }}
              >
                {t("delete-user-page.return")}
              </Button>
            )}
          </div>
          <div className="col-auto align-self-center"></div>
        </div>
      </PageTitle>

      <PageDetails>
        {!isDeleted ? (
          <>
            <div className="mt-3">
              {t("delete-user-page.delete-message") + " " + userName + "?"}
            </div>
            <Button
              className="mt-5 red"
              onClick={() => {
                userService
                  .deleteUser(
                    employeeId ? employeeId : "",
                    userId ? userId : "-1"
                  )
                  .then(() => {
                    setIsDeleted(true);
                  })
                  .catch(() => {
                    toast.error(t("delete-user-page.error-delete"));
                  });
              }}
            >
              {t("delete-user-page.delete")}
            </Button>
          </>
        ) : (
          <>
            <div className="mt-3">
              {userName + " " + t("delete-user-page.confirm-delete")}
            </div>
            <Button
              className="mt-5"
              onClick={() => {
                navigate(`/ManageUsers/${employeeId}`);
              }}
            >
              {t("delete-user-page.return")}
            </Button>
          </>
        )}
      </PageDetails>
    </Page>
    </CheckAllowedComponent>
  );
}
