import { useEffect, useState } from "react";
import userService from "../../services/Users/UserService";

export default function useGetMe(userId?: string) {
  const [state, setState] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = () => {
    setLoading(true);
    if (userId) {
      userService
        .getMe(userId)
        .then((data) => {
          if (data) setState(data);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(loadData, [userId]);

  return { userName: state, meLoading: loading, loadMe: loadData };
}
