import { useEffect, useState } from "react";
import "./FilterContainerComponent.scss";
import stringHelper from "../../../services/Core/Helpers/string-helper";
import { FormControl, FormLabel } from "react-bootstrap";

type FilterContainerProps = {
  id?: string;
  className?: string;
  children: React.ReactNode;
};

export function FilterContainerComponent(props: FilterContainerProps) {
  const divProps = {
    id: props.id,
    className: `filter-container ${props.className ?? ""}`,
    children: props.children,
  };

  return <div {...divProps} />;
}

export function FilterFieldset(props: FilterContainerProps) {
  const elemProps = {
    id: props.id,
    className: `filter-fieldset ${props.className ?? ""}`,
    children: props.children,
  };

  return <fieldset {...elemProps} />;
}

export function DateRangeContainer(props: FilterContainerProps) {
  const divProps = {
    id: props.id,
    className: `date-range-container ${props.className ?? ""}`,
    children: props.children,
  };

  return <div {...divProps} />;
}

type IDateRange = {
  startDate?: string;
  endDate?: string;

  setStartDateFn: (val?: string) => void;
  setEndDateFn: (val?: string) => void;
};
export function useDateRangePicker(
  minDateValue?: Date | string,
  maxDateValue?: Date | string
): IDateRange {
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();

  const getDateString = (val: Date | string): string => {
    return typeof val === "string" ? val : stringHelper.toDateString(val);
  };

  useEffect(() => {
    if (minDateValue && maxDateValue) {
      setMinDate(getDateString(minDateValue));
      setMaxDate(getDateString(maxDateValue));
    }
  }, [minDateValue, maxDateValue]);

  return {
    startDate: minDate,
    endDate: maxDate,
    setStartDateFn: setMinDate,
    setEndDateFn: setMaxDate,
  };
}

type DatePickerProps = {
  id?: string;
  className?: string;
  dateRange: IDateRange;
};
export function DatePickerComponent(props: DatePickerProps) {
  const { id, className, dateRange } = props;
  return (
    <DateRangeContainer id={id} className={className}>
      <FormLabel className="me-2">From</FormLabel>
      <FormControl
        size="sm"
        type="date"
        value={dateRange.startDate || ""}
        onChange={(ev) => dateRange.setStartDateFn(ev.target.value)}
      />
      <FormLabel className="me-2">To</FormLabel>
      <FormControl
        size="sm"
        type="date"
        value={dateRange.endDate || ""}
        onChange={(ev) => dateRange.setEndDateFn(ev.target.value)}
      />
    </DateRangeContainer>
  );
}
