import React, { useMemo, useEffect, useState, useRef, forwardRef } from "react";
import "./quote-print-preview.scss";
import Quote from "../../../services/Quotes/Quote";
import QuoteKit from "../../../services/Quotes/Models/QuoteKit";
import { useTranslation } from "react-i18next";
import stringHelper from "../../../services/Core/Helpers/string-helper";
import { useParams } from "react-router-dom";
import quoteService from "../../../services/Quotes/QuoteService";
import { QuoteSingleItem } from "../../../services/SingleItem/Models/QuoteSingleItem";
import { Option } from "../../../services/Core/Items/Item";
import { toast } from "react-toastify";

import { Details, Summary } from "../../../services/Print/Models/PrintModel";
import { Button } from "react-bootstrap";
import numberHelper from "../../../services/Core/Helpers/number-helper";

type PrintProps = {
  userId: string;
  quoteId: string;
};

export const PrintComponent = () => {
  const { t } = useTranslation();
  const { userId, quoteId } = useParams<PrintProps>();
  const [quote, setQuote] = useState<Quote>();
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [details, setDetails] = useState<Details[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const [editing, setEditing] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    if (userId && quoteId) {
      quoteService
        .getPrintPreviewData(userId, quoteId)
        .then((data) => {
          if (data) {
            setQuote(data.quote);
            setSummaries(data.summaries);
            setDetails(data.details);
            setTotalPrice(data.totalPrice);
            setTotalWeight(data.totalWeight);
          }
        })
        .catch(() => {
          toast.error("'Load Print Data' : Error while fetching data");
        });
    }
  }
  return (
    <div id="print-page">
      <div className="print-tools">

        <Button className="btn-tools" onClick={onRefreshBtnClick}>{t("quote-print-page.refresh")}</Button>
        <Button className="btn-tools" onClick={onSummaryBtnClick}>{t("quote-print-page.summary")}</Button>
        {!editing ? (
          <Button className="btn-tools" onClick={onEditSaveBtnClick}>{t("quote-print-page.edit-on")}</Button>
        ) : (
          <Button className="btn-tools" onClick={onEditSaveBtnClick}>{t("quote-print-page.edit-off")}</Button>
        )}
        {!isHidden ? (
          <Button className="btn-tools" onClick={onShowDescBtnClick}>{t("quote-print-page.short-descr-on")}</Button>
        ) : (
          <Button className="btn-tools" onClick={onShowDescBtnClick}>{t("quote-print-page.short-descr-off")}</Button>
        )}
        <Button className="btn-tools" onClick={onPrintBtnClick}>{t("quote-print-page.print")}</Button>   
      </div>

      <div className="print-container">
        {/* ************  quote Kits ************ */}
        {quote && quote.quoteKits.length > 0 &&  quote.quoteKits.map((kit, index) => 
        <>
          <div className="print-container-items">
            <PrintHeaderComponent quote={quote} pageNumber={1}/>
            <PumpComponent kit={kit} isEditable={editing} isHidden={isHidden} />  
          </div>
          <div className="page-break"></div>
          </>
        )}

        {/* ************  quote single items ************ */}
        {quote && quote.quoteSingleItems.length > 0 && (
          <div className="print-container-items">
            <PrintHeaderComponent quote={quote} pageNumber={1}/>
            <SingleItemComponent items={quote.quoteSingleItems} isEditable={editing} isHidden={isHidden} />
          </div>
        )}

        <div className="page-break"></div>

        {/* ************  Summary ************ */}
        {quote && (
          <div className="print-container-items">
            <PrintHeaderComponent quote={quote} pageNumber={1}/>
            {details && (
              <DetailsComponent ref={ref} details={details} totalPrice={totalPrice} totalWeight={totalWeight} />
            )}
            {summaries && (
              <SummaryComponent summaries={summaries} totalPrice={totalPrice} totalWeight={totalWeight} />
            )}
          </div>
        )}

        <div className="page-break"></div>

        {/* ************  Terms and Conditions ************ */}
        <div className="print-container-items">
          <TermsAndConditionsComponent userId={userId} />
        </div>
      </div>
    </div>

  );

  function onEditSaveBtnClick() {
    if (editing) setEditing(false);
    else setEditing(true);
  }
  function onShowDescBtnClick() {
    if (isHidden) setIsHidden(false);
    else setIsHidden(true);
  }
  function onPrintBtnClick() {
    window.print();
  }
  function onSummaryBtnClick() {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }
   function onRefreshBtnClick(){
     window.location.reload(); 
   }
};

interface PrintHeaderProps {
  quote : Quote;
  pageNumber: number;
}

export function PrintHeaderComponent(props:PrintHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className="header-container">
      <table className="header-table">
        <thead>
          <tr>
            <th colSpan={5}>
              <span className="header-info">
              {t("quote-print-page.po-info-message")}  
                <a href=" mailto:angeliegauvreau@flofab.com">
                  angeliegauvreau@flofab.com
                </a>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img
                src="/images/logos/logoFF.png"
                alt="Logo"
                className="logo-main"
              />
            </td>
            <td>
              <table className="no-border">
                <tbody>
                  <tr>
                    <td className="bold">
                      <span>{t("quote-print-page.tel-number")}:  </span>
                    </td>
                    <td className="single-line">
                      <span>450-621-2995</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <span>{t("quote-print-page.fax-number")}: </span>
                    </td>
                    <td className="single-line">
                    <span>450-621-4995</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <img
                src="/images/logos/UL_Logo.png"
                alt="Logo"
                className="logo-sm"
              />
              {/* <span> (Package) </span> */}
            </td>
            <td>
              <img
                src="/images/logos/NSF_Logo.jpg"
                alt="Logo"
                className="logo-sm"
              />
              {/* <span> (Package) </span> */}
            </td>
            <td>
              <div className="quote-info-container">
                <table className="quote-info-table">
                  <tbody>
                    <tr>
                      <td className="bold">
                        <span>{t("quote-print-page.quote")}</span>
                      </td>
                      <td className="bold">
                        <span>:</span>
                      </td>
                      <td className="single-line">
                        <span>{props.quote.id}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <span>{t("quote-print-page.creation")}</span>
                      </td>
                      <td className="bold">
                        <span>:</span>
                      </td>
                      <td className="single-line">
                        <span>{stringHelper.toDateString(new Date(props.quote.date))}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <span>{t("quote-print-page.revision")}</span>
                      </td>
                      <td className="bold">
                        <span>:</span>
                      </td>
                      <td className="single-line">
                        <span>{stringHelper.toDateString(new Date())}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <table className="sales-job-table">
                <tbody>
                  <tr>
                    <td className="bold underline">
                      <span>{t("quote-print-page.sales-rep")} </span>
                    </td>
                    <td className="bold">
                      <span>: </span>
                    </td>
                    <td>
                      <span>{props.quote.ownedBy ?? ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bold underline">
                      <span>{t("quote-print-page.job-name")}</span>
                    </td>
                    <td className="bold">
                      <span>: </span>
                    </td>
                    <td>
                      <span>{props.quote.jobName ?? ""}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              {/* <div className="page-container">
                <span>Page</span>
                <span className="page-number">{pageNumber}</span>
              </div> */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface PumpProps {
  kit: QuoteKit;
  isEditable: boolean;
  isHidden: boolean;
}
export const PumpComponent: React.FC<PumpProps> = ({ kit, isEditable, isHidden }) => {
  const { t } = useTranslation();
  const [editedKit, setEditedKit] = useState<QuoteKit>(kit);

  const handleKitPriceEdit = (kit: QuoteKit, price: number) => {
    kit.netPrice = price;
    setEditedKit(kit);
  };
  const handleOptionPriceEdit = (kit: QuoteKit, optionIndex: number, price: number) => {
    kit.options[optionIndex].netPrice = price;
    setEditedKit(kit);
  };


  // const calculateTotalUnitPrice = (kit: QuoteKit): number => {
  //   let totalPrice = kit.netPrice;
  //   totalPrice += kit.options.reduce((acc, option) => acc + option.netPrice, 0);
  //   return totalPrice;
  // };
  // const calculateTotalPrice = (kit: QuoteKit): number => {
  //   let totalPrice = kit.netPrice * kit.qty;
  //   totalPrice += kit.options.reduce((acc, option) => acc + (option.netPrice) * kit.qty, 0);
  //   return totalPrice;
  // };
  // const totalUnitPrice = kits.reduce((acc, kit) => acc + calculateTotalUnitPrice(kit), 0);
  // const totalPrice = kits.reduce((acc, kit) => acc + calculateTotalPrice(kit), 0);
  const totalUnitPrice = kit.netPrice +  kit.options.reduce((acc, option) => acc + option.netPrice, 0);
  const totalPrice =  kit.netPrice * kit.qty + kit.options.reduce((acc, option) => acc + (option.netPrice) * kit.qty, 0);

  return (
    <div className="body-product">
        <table className="table-product">
          <thead>
            <tr>
              <th>{t("quote-print-page.qty")}</th>
              <th>{t("quote-print-page.items")}</th>
              <th>{t("quote-print-page.unit-price")}</th>
              <th>{t("quote-print-page.total-price")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="product-details-row">
              <td>{kit.qty}</td>
              <td className="details-table">
                <table>
                  <tbody>
                    <tr>
                      <td className="bold">{t("quote-print-page.pump")}</td>
                    </tr>
                    <tr>
                      <td className="column1 bold">{t("quote-print-page.tag")}</td>
                      <td className="column2 bold">{kit.tag}</td>
                    </tr>
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-page.quotekit-manufacturer")}</td>
                        <td className="column2">{kit.manufacturer}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-page.quotekit-manufacturer")}</td>
                        <td className="column2">{kit.manufacturer}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="column1">{t("quote-page.quotekit-flow-rate")}</td>
                      <td className="column2">{stringHelper.getFlowString(kit.flow)}</td>
                    </tr>
                    <tr>
                      <td className="column1">{t("quote-page.quotekit-head")}</td>
                      <td className="column2">{stringHelper.getHeadString(kit.head)}</td>
                    </tr>

                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-page.quotekit-type")}</td>
                        <td className="column2">{kit.pumpType}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-page.quotekit-type")}</td>
                        <td className="column2">{kit.pumpType}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="column1">{t("quote-page.quotekit-model")}</td>
                      <td className="column2">{kit.model}</td>
                    </tr>
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-page.quotekit-impeler-diameter")}</td>
                        <td className="column2">{stringHelper.getDiamString(kit.diam)}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-page.quotekit-impeler-diameter")}</td>
                        <td className="column1">{stringHelper.getDiamString(kit.diam)}</td>
                      </tr>
                    )}

                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-page.quotekit-construction")}</td>
                        <td className="column2">{kit.sConstruction}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-page.quotekit-construction")}</td>
                        <td className="column2">{kit.sConstruction}</td>
                      </tr>
                    )}
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-page.quotekit-flange-type")}</td>
                        <td className="column2">{kit.flangeType}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-page.quotekit-flange-type")}</td>
                        <td className="column2">{kit.flangeType}</td>
                      </tr>
                    )}
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-page.quotekit-glycol")}</td>
                        <td className="column2">{kit.glycol}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-page.quotekit-glycol")}</td>
                        <td className="column2">{kit.glycol}</td>
                      </tr>
                    )}
                    <tr>
                      <td className="column1">{t("quote-print-page.unit-weight")}</td>
                      <td className="column2">{stringHelper.getWeightString(kit.weight)}</td>
                    </tr>
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-print-page.note")}</td>
                        <td className="column2">{kit.note}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-print-page.note")}</td>
                        <td className="column2">{kit.note}</td>
                      </tr>
                    )}
                    {/* motor */}
                    {!kit.barePump && (
                      <>
                        <tr>
                          <td className="bold">{t("quote-print-page.motor")}</td>
                        </tr>
                        {!isHidden ? (
                          <tr>
                            <td className="column1">{t("quote-page.quotekit-manufacturer")}</td>
                            <td className="column2">{kit.motorManufacturer}</td>
                          </tr>
                        ) : (
                          <tr className="hide-on-print">
                            <td className="column1 red-info">{t("quote-page.quotekit-manufacturer")}</td>
                            <td className="column2">{kit.motorManufacturer}</td>
                          </tr>
                        )}
                        <tr>
                          <td className="column1">{t("quote-page.quotekit-power")}</td>
                          <td className="column2">{stringHelper.getMPowerString(kit.hp) ?? "---"}</td>
                        </tr>
                        <tr>
                          <td className="column1">{t("quote-page.quotekit-phases")}</td>
                          <td className="column2">{kit.phase}</td>
                        </tr>
                        <tr>
                          <td className="column1">{t("quote-page.quotekit-rpm")}</td>
                          <td className="column2">{kit.rpm}</td>
                        </tr>
                        {!isHidden ? (
                          <tr>
                            <td className="column1">{t("quote-print-page.motor-type")}</td>
                            <td className="column2">
                              {stringHelper.getMotorType(kit.odp, kit.submersible)}
                            </td>
                          </tr>
                        ) : (
                          <tr className="hide-on-print">
                            <td className="column1 red-info">{t("quote-print-page.motor-type")}</td>
                            <td className="column2">
                              {stringHelper.getMotorType(kit.odp, kit.submersible)}
                            </td>
                          </tr>
                        )}
                        {!isHidden ? (
                          <tr>
                            <td className="column1">{t("quote-print-page.motor-efficiency")}</td>
                            <td className="column2">
                              {stringHelper.getMotorEfficiency(
                                kit.premiumEfficiency,
                                kit.premiumEfficiencyMotorNotNorthAmerican
                              )}
                            </td>
                          </tr>
                        ) : (
                          <tr className="hide-on-print">
                            <td className="column1 red-info">{t("quote-print-page.motor-efficiency")}</td>
                            <td className="column2">
                              {stringHelper.getMotorEfficiency(
                                kit.premiumEfficiency,
                                kit.premiumEfficiencyMotorNotNorthAmerican
                              )}
                            </td>
                          </tr>
                        )}
                        {!isHidden ? (
                          <tr>
                            <td className="column1">{t("quote-page.quotekit-suction")}</td>
                            <td className="column2">{kit.suction}</td>
                          </tr>
                        ) : (
                          <tr className="hide-on-print">
                            <td className="column1 red-info">{t("quote-page.quotekit-suction")}</td>
                            <td className="column2">{kit.suction}</td>
                          </tr>
                        )}
                        {!isHidden ? (
                          <tr>
                            <td className="column1">{t("quote-page.quotekit-discharge")}</td>
                            <td className="column2">{kit.discharge}</td>
                          </tr>
                        ) : (
                          <tr className="hide-on-print">
                            <td className="column1 red-info">{t("quote-page.quotekit-discharge")}</td>
                            <td className="column2">{kit.discharge}</td>
                          </tr>
                        )}
                        {!isHidden ? (
                          <tr>
                            <td className="column1">{t("quote-print-page.motor-note")}</td>
                            <td className="column2">{kit.motorNote}</td>
                          </tr>
                        ) : (
                          <tr className="hide-on-print">
                            <td className="column1 red-info">{t("quote-print-page.motor-note")}</td>
                            <td className="column2">{kit.motorNote}</td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </td>
              <td style={{ width: "10%" }}>
                <div style={{ width: "100%" }}>
                  {isEditable ? (
                    <input
                      type="number"
                      defaultValue={numberHelper.toFixed(kit.netPrice, 2)}
                      style={{ width: "100%" }}
                      onChange={(e) =>  
                        handleKitPriceEdit(kit, parseFloat(e.target.value))
                      }
                    />
                  ) : (
                    `$${stringHelper.toFixedNumberFormat(kit.netPrice, 2)}`
                  )}
                </div>
              </td>
              <td>${stringHelper.toFixedNumberFormat(kit.netPrice * kit.qty, 2)}</td>
              {/* <td>
                {isEditable ? (
                  <input
                    type="number"
                    defaultValue={kit.netPrice * kit.qty}
                    onChange={(e) =>
                      handleKitPriceEdit(index, parseFloat(e.target.value))
                    }
                  />
                ) : (
                  `$${stringHelper.toFixedNumberFormat(
                    kit.netPrice * kit.qty,
                    2
                  )}`
                )}
              </td> */}
            </tr>
            {kit.options && (
              <PumpOptionComponent
                options={kit.options}
                qty={kit.qty}
                isEditable={isEditable}
                isHidden={isHidden}
              />
            )}
            <tr>
              <td> </td>
              <td> </td>
              <td>${stringHelper.toFixedNumberFormat(totalUnitPrice, 2)}</td>
              <td>${stringHelper.toFixedNumberFormat(totalPrice, 2)}</td>
            </tr>
          </tbody>
        </table>
    </div>
  );
};

interface PumpOptionProp {
  options: Array<Option>;
  qty: number;
  isEditable: boolean;
  isHidden: boolean;
}
export const PumpOptionComponent: React.FC<PumpOptionProp> = ({
  options,
  qty,
  isEditable,
  isHidden,

}) => {
  const { t } = useTranslation();

  // const handlePriceEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   onPriceEdit(parseFloat(e.target.value));
  // };

  // Function to handle option price editing for the kit
  const handleOptionPriceEdit = (optionIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {

  };
  const handleOptionUnitPriceEdit = (optionIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {

  };
  return (
    <>
      {options &&
        options.map((option, index) => (
          <tr key={index} className="product-details-row">
            <td>{qty}</td>
            <td className="details-table">
              <table>
                <tbody>
                  <tr>
                    <td className="bold column1">
                      {option.objectType === 0 && (
                        <span>{t("quote-print-page.default-option-name")}{index + 1}</span>
                      )
                      }
                    </td>
                  </tr>

                  {option.objectType > 0 && (
                    <>
                      <tr>
                        <td className="column1">{t("quote-print-page.code")}</td>
                        <td className="column2">{option.items[0].it_ocode}</td>
                      </tr>
                    </>
                  )}
                  <>
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-print-page.name")}</td>
                        <td className="column2">{option.name}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-print-page.name")}</td>
                        <td className="column2">{option.name}</td>
                      </tr>
                    )}
                  </>
                  {/* if is vfd */}
                  {option.objectType === 3 && option.items.length > 0 && (
                    <>
                      {!isHidden ? (
                        <tr>
                          <td className="column1">{t("quote-print-page.description")}</td>
                          <td className="column2">
                            {option.items[0].itemDescription}
                          </td>
                        </tr>
                      ) : (
                        <tr className="hide-on-print">
                          <td className="column1 red-info">{t("quote-print-page.description")}</td>
                          <td className="column2">
                            {option.items[0].itemDescription}
                          </td>
                        </tr>
                      )}
                    </>
                  )}

                </tbody>
              </table>
            </td>
            <td style={{ width: "10%" }}>
              <div style={{ width: "100%" }}>
                {isEditable ? (
                  <input
                    type="number"
                    style={{ width: "100%" }}
                    defaultValue={numberHelper.toFixed(option.netPrice, 2)}
                    onChange={(e) =>
                      handleOptionUnitPriceEdit(index, e)
                    }
                  />
                ) : (
                  `$${stringHelper.toFixedNumberFormat(option.netPrice, 2)}`
                )}
              </div>
            </td>
            <td>${stringHelper.toFixedNumberFormat(option.netPrice * qty, 2)}</td>
            {/* <td>
              {isEditable ? (
                <input
                  type="number"
                  defaultValue={option.netPrice}
                  onChange={(e) =>
                    handleOptionPriceEdit(index, e)
                  }
                />
              ) : (
                `$${stringHelper.toFixedNumberFormat(option.netPrice * qty, 2)}`
              )}
            </td> */}
            {/* <td>${stringHelper.toFixedNumberFormat(option.netPrice, 2)}</td>
                 */}
          </tr>
        ))}
    </>
  );
};

interface SingleItemProps {
  items: Array<QuoteSingleItem>;
  isEditable: boolean;
  isHidden: boolean;
}
export const SingleItemComponent: React.FC<SingleItemProps> = ({
  items,
  isEditable,
  isHidden,
}) => {
  const { t } = useTranslation();
  return (
    <div className="body-product">
      <table className="table-product">
        <thead>
          <tr>
          <th>{t("quote-print-page.qty")}</th>
              <th>{t("quote-print-page.items")}</th>
              <th>{t("quote-print-page.unit-price")}</th>
              <th>{t("quote-print-page.total-price")}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="product-details-row">
              <td>{item.qty}</td>
              <td className="details-table">
                <table>
                  <tbody>
                    <tr>
                      <td className="bold">{t("quote-print-page.default-option-name")}{index + 1}</td>
                    </tr>
                    <tr>
                      <td className="column1">{t("quote-print-page.code")}</td>
                      <td className="column2">{item.it_ocode}</td>
                    </tr>
                    <tr>
                      <td className="column1">{t("quote-print-page.name")}</td>
                      <td className="column2">{item.itemDescription}</td>
                    </tr>
                    {!isHidden ? (
                      <tr>
                        <td className="column1">{t("quote-print-page.weight")}</td>
                        <td className="column2">{item.weight}</td>
                      </tr>
                    ) : (
                      <tr className="hide-on-print">
                        <td className="column1 red-info">{t("quote-print-page.weight")}</td>
                        <td className="column2">{item.weight}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>

              <td style={{ width: "10%" }}>
                <div style={{ width: "100%" }}>
                  {isEditable ? (
                    <input
                      type="number"
                      style={{ width: "100%" }}
                      defaultValue={numberHelper.toFixed(item.netPrice,2)}
                      onChange={(e) => {
                        const newValue = parseFloat(e.target.value);
                        const updatedKit = { ...item, netPrice: newValue };
                      }}
                    />
                  ) : (
                    `$${stringHelper.toFixedNumberFormat(item.netPrice, 2)}`
                  )}
                </div>
              </td>
              <td>
                ${stringHelper.toFixedNumberFormat(item.netPrice * item.qty)}
              </td>
            </tr>
          ))}
          <tr>
            <td> </td>
            <td> </td>
            <td>
              ${stringHelper.toFixedNumberFormat(getItemsUnitPrice(items), 2)}
            </td>
            <td>
              ${stringHelper.toFixedNumberFormat(getItemsTotalPrice(items), 2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
function getItemsUnitPrice(items: QuoteSingleItem[]) {
  
  var totalItems = 0;
  items.map((item: QuoteSingleItem) => {
    totalItems += item.netPrice;
  });
  return totalItems;
}
function getItemsTotalPrice(items: QuoteSingleItem[]) {
  var totalItems = 0;
  items.map((item: QuoteSingleItem) => {
    totalItems += item.netPrice * item.qty;
  });
  return totalItems;
}

interface DetailsProps {
  details: Array<Details>;
  totalPrice: number;
  totalWeight: number
}
export const DetailsComponent = forwardRef<HTMLDivElement, DetailsProps>(({
  details, totalPrice, totalWeight
}, ref) => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState<Details[]>(details);
  return (
    <div ref={ref} className="details-container">
      <div className="table-title">
        <h3>{t("quote-print-page.details")}</h3>
      </div>
      <table className="details-table">
        <thead>
          <tr>
              <th>{t("quote-print-page.qty")}</th>
              <th>{t("quote-print-page.items")}</th>
              <th>{t("quote-print-page.unit-price")}</th>
              <th>{t("quote-print-page.total-weight")}</th>
              <th>{t("quote-print-page.total-price")}</th>
          </tr>
        </thead>
        <tbody>
          {detail.map((det, index) => (
            <tr key={index} className="product-details-row">
              <td>{det.qty}</td>
              <td>{det.type}</td>
              <td>{det.tag}</td>
              <td>{stringHelper.getWeightString(det.totalWeight)}</td>
              <td>${stringHelper.toFixedNumberFormat(det.totalPrice, 2)}</td>
            </tr>
          ))}
          <tr className="product-details-row">
            <td></td>
            <td></td>
            <td></td>
            <td>{stringHelper.getWeightString(totalWeight)}</td>
            <td>${stringHelper.toFixedNumberFormat(totalPrice, 2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
);

interface SummaryProps {
  summaries: Array<Summary>;
  totalPrice: number;
  totalWeight: number
}

export const SummaryComponent: React.FC<SummaryProps> = ({
  summaries, totalPrice, totalWeight
}) => {
  const { t } = useTranslation();
  const [sums, setSums] = useState<Summary[]>(summaries);

  return (
    <div className="summary-container">
      <div className="table-title">
        <h3>{t("quote-print-page.summary")}</h3>
      </div>
      <table className="summary-table">
        <thead>
          <tr>
            <th>{t("quote-print-page.type")}</th>
            <th>{t("quote-print-page.total-weight")}</th>
            <th>{t("quote-print-page.total-price")}</th>
          </tr>
        </thead>
        <tbody>
          {sums.map((sum, index) => (
            <tr key={index} className="product-details-row">
              <td>{sum.type}</td>
              <td>{stringHelper.getWeightString(sum.totalWeight)}</td>
              <td>${stringHelper.toFixedNumberFormat(sum.totalPrice, 2)}</td>
            </tr>
          ))}
          <tr className="product-details-row">
            <td></td>
            <td>{stringHelper.getWeightString(totalWeight)}</td>
            <td>${stringHelper.toFixedNumberFormat(totalPrice, 2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
interface TermsAndConditionsProps {
  userId: string | undefined;
}
export function TermsAndConditionsComponent(props: TermsAndConditionsProps){
  const [terms, setTerms] = useState<string>();
  const { t } = useTranslation();
  useEffect(() => {
    if (props.userId) 
      loadTermsAndConditions(props.userId, 2);
  }, []);

  function loadTermsAndConditions(userId: string, id: number) {
    if (userId && id) {
      quoteService
        .getTermsAndConditions(userId, id)
        .then((data) => {
          if (data) {
            setTerms(data);
          }
        })
        .catch(() => { });
    }
  }
  return (
    <div className="terms-container">
      <div className="table-title">
        <h3>{t("quote-print-page.terms-and-condition-title")}</h3>
      </div>
      <span className="warning-text">
      {t("quote-print-page.terms-conditions-warning-message")}
      </span>
      <div className="terms-body">
        <p>{terms}</p>
      </div>
    </div>
  );
};


