import stringHelper from "../../../services/Core/Helpers/string-helper";
import { Page, PageDetails } from "../../../components/Pages/page-components";
import { useParams } from "react-router-dom";
import { useState, FormEvent } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import loginService from "../../../services/Login/LoginService";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type IParamTypes = {
  changePasswordToken: string;
};

export const ChangePassword = () => {
  const { changePasswordToken } = useParams<IParamTypes>();
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const errorAction = (err: AxiosError) => {
    setValidated(false);
    console.log(err);
    toast.error(t(`change-password-page.${err.response?.data}`));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true && changePasswordToken) {
      loginService
        .ChangePassword(changePasswordToken, password)
        .then(() => setIsSent(true))
        .catch(errorAction);
    }
    setValidated(true);
  };

  return (
    <>
      <Page id="login-page">
        <PageDetails>
          <div className="page-wrapper bg-blue bg-gra-02 font-poppins">
            <div className="wrapper">
              <div className="card card-4">
                <div className="card-body">
                  <h1 className="text-center">
                    {t("change-password-page.change-your-password")}
                  </h1>
                  <div className="text-center sm-mb--10 mb-5">
                    {t("change-password-page.logout-of-other-session")}
                  </div>
                  {!isSent ? (
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Form.Group>
                        <Form.Label>{t("user.password")}</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={"" + t("user.password")}
                            pattern={stringHelper.RegExpToString(
                              stringHelper.RegExpPassword
                            )}
                            aria-describedby="inputGroupPrepend"
                            required
                            onChange={(ev) => setPassword(ev.target.value)}
                          />
                          <InputGroup.Text
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon icon={faEye} />
                            )}
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">
                            {t("register-page.bad-password-format")}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <div className="passwordCriteria mb-2">
                        {t("register-page.password-criteria")}
                      </div>
                      <Button
                        className="loginFormButton mb-2"
                        id="loginFormButton"
                        type="submit"
                      >
                        {t("change-password-page.change-password")}
                      </Button>
                    </Form>
                  ) : (
                    <div className="text-center">
                      <div>{t("change-password-page.is-changed")}</div>
                      <a href={process.env.REACT_APP_HOME_PAGE_URL}>{t("change-password-page.return-to-login-page")}</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div />
        </PageDetails>
      </Page>
    </>
  );
};
