import "./PageSectionComponent.scss";

type PageSectionItem = {
  key: string | number;
  text: string;
};

export type PageSectionProps = {
  active: string | number;
  onClick: Function;
  items: PageSectionItem[];
};

export function PageSectionComponent(props: PageSectionProps) {
  const { active, onClick, items } = props;
  return (
    <div className="page-section">
      {items.map((item) => {
        return (
          <div
            key={item.key}
            className={`page-section-item ${
              active === item.key ? "active" : ""
            }`}
            onClick={() => onClick(item.key)}
          >
            {item.text}
          </div>
        );
      })}
    </div>
  );
}
