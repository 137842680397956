import "./activities-manager-component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Activity, {
  ActivityObjectType,
  ActivityRequest,
  ActivityType,
} from "../../services/Activities/Activity";
import stringHelper from "../../services/Core/Helpers/string-helper";
import { useState } from "react";
import { toast } from "react-toastify";
import activityService from "../../services/Activities/ActivityService";
import useGetMe from "../../hooks/Users/useGetMe";
import {
  Page,
  PageDetails,
  PageFooter,
  PageHeader,
  PageInfo,
} from "../Pages/page-components";
import {
  ProductInfoContainer,
  ProductInfo_Name,
  ProductInfo_Value,
} from "../Pages/product-info-component";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { DOTS } from "../../hooks/usePagination";

type ActivityManagerProps = {
  userId?: string;
  objectId: number | string ;
  objectType: ActivityObjectType;
  activities: Array<Activity>;
  loadActivities: () => void;
};

export function ActivityManagerComponent(props: ActivityManagerProps) {
  const { t } = useTranslation();

  const { userId, objectId, objectType, activities, loadActivities } = props;
  const { userName } = useGetMe(userId);
  const [activity, setActivity] = useState<Activity>();
  const [comment, setComment] = useState<string | undefined>();

  return (
    <Page className="activity">
      <PageDetails className="activity-list">
        {activities.length > 0 ? (
          <>
            {activities.map((commentTemp, i) => {
              let canEdit = canEditComment(commentTemp);
              let isEditing = activity?.id === commentTemp.id;
              return (
                <Page
                  className="activity page-border page-bg-secondary"
                  key={i}
                >
                  <PageHeader>
                    <ActivityUserComponent
                      username={commentTemp.employeeName ?? ""}
                    />
                    <ProductInfoContainer className="me-auto">
                      <ProductInfo_Name>
                        {t("activity.created")}:
                      </ProductInfo_Name>
                      <ProductInfo_Value>
                        {stringHelper.getTimeElapsedString(
                          commentTemp.createdDate,
                          t
                        )}
                      </ProductInfo_Value>
                    </ProductInfoContainer>
                    {canEdit && (
                      <>
                        {!isEditing ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary mx-1"
                              onClick={() => setActivity(commentTemp)}
                              title="Edit"
                            >
                              <FontAwesomeIcon
                                size="sm"
                                icon={["fas", "pencil"]}
                              />
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-danger"
                              onClick={() => deleteComment(commentTemp.id)}
                            >
                              <FontAwesomeIcon
                                size="sm"
                                icon={["fas", "trash-can"]}
                              />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary mx-1"
                              onClick={patchActivity}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => setActivity(undefined)}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </PageHeader>
                  <PageInfo className="bg-white m-1">
                    {activity && isEditing && canEdit ? (
                      <textarea
                        className="form-control"
                        value={activity.description}
                        onChange={(ev) =>
                          setActivity({
                            ...activity,
                            description: ev.target.value,
                          })
                        }
                      />
                    ) : (
                      <p className="card-text">{commentTemp.description}</p>
                    )}
                  </PageInfo>
                </Page>
              );
            })}
          </>
        ) : (
          <div className="text-center">No comments</div>
        )}
      </PageDetails>
      <PageFooter className="d-block">
        <Page className="activity page-border page-bg-secondary">
          <PageInfo className="bg-white m-1">
            <textarea
              className="form-control"
              value={comment ?? ""}
              placeholder={DOTS}
              onChange={(ev) => setComment(ev.target.value)}
            />
          </PageInfo>
          <PageFooter>
            <ActivityUserComponent username={userName ?? ""} />

            <Button variant="outline-primary" size="sm" onClick={createComment}>
              {t("common.create")}
            </Button>
          </PageFooter>
        </Page>
      </PageFooter>
    </Page>
  );

  function createComment() {
    // console.log("Create activity");
    if (userId && objectId && objectType && comment) {
      let data = new ActivityRequest(
        ActivityType.Comment,
        objectId.toString(),
        objectType,
        comment
      );

      // console.log(data);
      activityService
        .postActivity(userId, data)
        .then((res) => {
          if (res) {
            loadActivities();
          }
          setComment(undefined);
        })
        .catch(() => {
          toast.error("An error occured while creating your comment");
        });
    }
  }

  function patchActivity() {
    let canEdit = canEditComment(activity);
    if (userId && canEdit && activity?.description) {
      activityService
        .patchActivity(userId, activity.id, activity.description)
        .then((data) => {
          if (data) {
            loadActivities();
            setActivity(undefined);
          }
        })
        .catch(() => {
          toast.error("An error occured while updating your comment");
        });
    }
  }

  function deleteComment(activityId: string) {
    if (userId) {
      activityService
        .deleteActivity(userId, activityId)
        .then((data) => {
          if (data) {
            loadActivities();
          }
        })
        .catch(() => {
          toast.error("An error occured while deleting your comment");
        });
    }
  }

  function canEditComment(temp: Activity | undefined): boolean {
    return (
      temp !== undefined &&
      temp.isEditable
    );
    // return (
    //   temp !== undefined &&
    //   userId === temp?.employeeId.toString() &&
    //   temp.isEditable
    // );
  }
}

type ActivityHistoryProp = {
  username: string;
  date: Date;
  description: string;
};

export function ActivityHistoryComponent(props: ActivityHistoryProp) {
  const { username, date, description } = props;
  return (
    <Page className="activity page-border page-bg-secondary mb-2">
      <PageHeader>
        <ActivityUserComponent username={username} />
        <ProductInfoContainer className="me-2">
          <ProductInfo_Value>
            {stringHelper.toDateTimeString(date)}
          </ProductInfo_Value>
        </ProductInfoContainer>
      </PageHeader>
      <PageInfo className="bg-white">
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </PageInfo>
    </Page>
  );
}

type ActivityUserProps = {
  image?: string;
  username: string;
};
export function ActivityUserComponent(props: ActivityUserProps) {
  const { image, username } = props;
  return (
    <ProductInfoContainer className="me-4">
      <ProductInfo_Value className="badge rounded-pill bg-white text-dark me-1">
        {image ? (
          <img src={image} />
        ) : (
          <FontAwesomeIcon size="sm" icon={faUser} />
        )}
      </ProductInfo_Value>
      <ProductInfo_Value>{username}</ProductInfo_Value>
    </ProductInfoContainer>
  );
}
