import "./App.scss";
import AppHeader from "./components/app/AppHeader";
import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import ProjectsTablePage from "./pages/Projects/projects-table";
import ProductionDataPage from "./pages/Projects/production-data-page";
import OrdersPage from "./pages/Orders/orders-page";
import PumpTestSheetPage from "./pages/Pumps/Pump Tests/pump-test-sheet-page";
import { toast } from "react-toastify";
import IncomeStatementPage from "./pages/Production/production-goal-page";
import CommissionReportPage from "./pages/Sales/Sales report/sales-report-page";
import GoalManagerPage from "./pages/Goals/goal-manager-page";
import MenuComponent from "./components/Core/Sidebar Menu/menu-component";
import StockProdPage from "./pages/Sales/Stock Prod/stock-prod-page";
import LogoComponent from "./components/app/LogoComponent";
import QuotesPage from "./pages/Quotes/quotes";
import { MarginQuotePage } from "./pages/MarginQuote/margin-quote-table";
import QuotePage from "./pages/Quotes/quote/quote-page";
import { OrderHistoryProgressTable } from "./pages/Orders/order-history-progress";
import FlofabDevEnvMenu from "./components/Core/FlofabDevEnvMenu";
import ManageUsersPage from "./pages/Users/manage-users-page";
import UserForm from "./pages/Users/user-form-page";
import DeleteUser from "./pages/Users/delete-user-page";
import { EmailConfigTable } from "./pages/EmailConfig/email-config-table";
import { PageNotFound } from "./pages/errorPages/not-found";
import { RushProgramTable } from "./pages/Quotes/quote/rush-program-manager-page";
import { LoginPage } from "./pages/LoginPages/login-page";
import RegisterPage from "./pages/LoginPages/register-page";
import { CreationConfirmedPage } from "./pages/LoginPages/creation-confirmed-page";
import {
  checkSessionId,
  getSessionId,
  createSessionRememberMe,
  deleteSession,
} from "./services/Login/loginFunction";
import { Client } from "./services/Users/Client";
import loginService from "./services/Login/LoginService";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import LoadingComponent from "./components/Core/Loading";
import { QuoteRelationship } from "./pages/Quotes/quote/quote-relationship-page";
import LanguageSelector from "./components/app/LanguageSelector";
import { PageTitle } from "./components/Pages/page-components";
import ManagePermissionPage from "./pages/Permission/Manage-Permission-Page";
import AddSingleItemPage from "./pages/SingleItem/add-single-item-page";
import SettingsPage from "./pages/SettingPages/settings-page";

import { ForgotPasswordPage } from "./pages/LoginPages/ForgotPassword/forgot-password-page";
import { ChangePassword } from "./pages/LoginPages/ForgotPassword/change-password-page";
import { PrintComponent } from "./pages/Quotes/quote/quote-print-preview";
import HydrostaticTestReportPage from "./pages/HydrostaticTestReport/hydrostatic-test-report-page";
import QuotePriorityPage from "./pages/QuotePriority/quote-priority-page";
import DashboardProject from "./pages/NewProjects/dashboard-projects";
import CheckAllowedComponent from "./components/CheckAllowedComponent";
import PumpSearchPage from "./pages/Pumps/PumpSearch/pump-search-page";
import PumpListPage from "./pages/Pumps/PumpSearch/pump-list-page";
import PumpCurvePage from "./pages/Pumps/PumpCurve/pump-curve-page";
import MfvSelectionPage from "./pages/Pumps/Mfv/mfv-selection";
import SdSelectionPage from "./pages/Pumps/Sd/sd-selection";
import VfdSelectionPage from "./pages/Pumps/Vfd/vfd-selection";
import EditQuotePumpPage from "./pages/Pumps/edit-quote-pump-page";
import ReminderMenuComponent from "./components/Core/Sidebar Menu/Reminder/reminder-menu-component";
import AssignedReminderPage from "./pages/Reminder/assigned-reminder-page";
import { QuoteKitRushProgramTable } from "./pages/Quotes/quote/rush-program-page";
import AssemblyControlPage from "./pages/AssemblyControl/assembly-control-page";
import PumpCouplingAlignmentSheet from "./pages/Pumps/PumpAlignment/PumpCouplingAlignmentSheets";
import SupplierPartNumberPage from "./pages/MockupSupplierPartNumber/supplier-part-number-page";
import SupplierPartNumberPageCopy from "./pages/MockupSupplierPartNumber/supplier-part-number-page copy";

const env: string = process.env.REACT_APP_ENV || "";

function App() {
  const [user, setUser] = useState<Client>();
  const [isLoggedIn, setIsLoggedIn] = useState(checkSessionId());
  const { t } = useTranslation();

  // const isMenuHidden = window.location.pathname === '/Quotes/:userId/:quoteId/print';
  const isPrintPage = /\/Quotes\/\d+\/\d+\/print$/.test(
    window.location.pathname
  );

  useEffect(() => {
    if (isLoggedIn)
      loginService
        .GetLoginUser(getSessionId())
        .then((data) => {
          setUser(data);
        })
        .catch((err: AxiosError) => {
          toast.error(t("app-header.end-session"));
          deleteSession();
          setIsLoggedIn(false);
        });
    else if (env === "development") {
      loginService
        .LoginAdmin()
        .then((res) => {
          createSessionRememberMe(res as string);
          setIsLoggedIn(true);
        })
        .catch(() => {
          toast.error("Error while autologin, is the server started?");
        });
    }
  }, [isLoggedIn]);

  const offlinePages = (
    <>
      <Route
        path="Production/Goal/:employeeId"
        element={<IncomeStatementPage />}
      />
      <Route
        path="Production/Pages/:employeeId/:externalFileId"
        element={<PumpCouplingAlignmentSheet />}
      />
      <Route
        path="Production/Pages/pump-test-sheet/:employeeId/:externalFileId"
        element={<PumpTestSheetPage />}
      />
      <Route
        path="QuoteRelation/:userId/:quoteId"
        element={<QuoteRelationship />}
      />
      <Route
        path="Quotes/:userId/:quoteId/Quote-Rush-Manager"
        element={<RushProgramTable />}
      />
      <Route
        path="SingleItem/:userId/:quoteId"
        element={<AddSingleItemPage />}
      />
      <Route
        path="HydrostaticTestReport/:externalFileId/:employeeId"
        element={<HydrostaticTestReportPage />}
      ></Route>
      <Route
        path="assembly-control/:externalFileId/:employeeId"
        element={<AssemblyControlPage />}
      ></Route>
    </>
  );
  //console.log(user);

  if (isLoggedIn && user)
    if (isPrintPage) {
      return (
        <div className="App">
          <Routes>
            <>
              <Route
                path="Quotes/:userId/:quoteId/print"
                element={<PrintComponent />}
              />
            </>
          </Routes>
        </div>
      );
    } else {
      return (
        <div className="App layout-grid-container">
          <div className="logo-layout no-print">
            <a
              className="navbar-brand"
              href={process.env.REACT_APP_HOME_PAGE_URL}
            >
              <LogoComponent className="navbar-logo" />
            </a>
          </div>
          <div className="header-layout no-print">
            <AppHeader user={user} />
          </div>
          <div className="menu-layout box-shadow no-print">
            <MenuComponent user={user} />
          </div>

          <ReminderMenuComponent user={user} />
          <div className="main-layout m-3">
            <Routes>
              {env === "development" && (
                <Route path="/" element={<FlofabDevEnvMenu />} />
              )}

              <Route
                path="Admin/Goals/:employeeId"
                element={<GoalManagerPage />}
              />

              <Route
                path="Production/Goal/:employeeId"
                element={<IncomeStatementPage />}
              />
              <Route
                path="Production/Pages/:employeeId/:externalFileId"
                element={<PumpCouplingAlignmentSheet />}
              />
              <Route
                path="Production/Pages/pump-test-sheet/:employeeId/:externalFileId"
                element={<PumpTestSheetPage />}
              />

              <Route
                path="Production-Data/:employeeId"
                element={<ProductionDataPage />}
              />
              <Route
                path="Projects/:employeeId"
                element={<ProjectsTablePage />}
              />
              <Route
                path="Dashboard/Projects/:employeeId"
                element={<DashboardProject user={user} />}
              />

              <Route path="Orders/:employeeId" element={<OrdersPage />} />
              <Route path="Quotes/:userId" element={<QuotesPage />} />
              <Route path="Quotes/:userId/:quoteId" element={<QuotePage />} />

              <Route
                path="Sales/report/:employeeId"
                element={<CommissionReportPage />}
              />

              <Route
                path="General-Ledger/Budget-Pac-Tool/:employeeId"
                element={<StockProdPage />}
              />

              <Route
                path="MarginQuote/:employeeId"
                element={<MarginQuotePage />}
              />

              <Route
                path="Orders/:userId/Order-History-Progress"
                element={<OrderHistoryProgressTable />}
              />

              <Route
                path="ManageUsers/:employeeId"
                element={<ManageUsersPage user={user} />}
              />
              <Route
                path="UserForm/:userId"
                element={<UserForm employee={user} />}
              />
              <Route path="UserForm" element={<UserForm employee={user} />} />
              <Route
                path="DeleteUser/:userId/:userName"
                element={<DeleteUser employee={user} />}
              />
              <Route
                path="EmailConfig/:employeeId"
                element={<EmailConfigTable user={user} />}
              />
              <Route path="PageNotFound" element={<PageNotFound />} />
              {/* used in the old code */}
              <Route
                path="Quotes/:userId/:quoteId/Quote-Rush-Manager"
                element={<RushProgramTable />}
              />
              {/* used int the new code */}
              <Route
                path="Quotes/:userId/:quoteId/QuoteKit-Rush-Program"
                element={<QuoteKitRushProgramTable />}
              />
              <Route
                path="ManagePermission"
                element={<ManagePermissionPage user={user} />}
              />
              <Route
                path="Settings"
                element={<SettingsPage user={user} setUser={setUser} />}
              />
              <Route
                path="QuotePriority"
                element={
                  <CheckAllowedComponent
                    objectName="get-quote-priority"
                    user={user}
                    redirect={true}
                  >
                    <QuotePriorityPage user={user} />
                  </CheckAllowedComponent>
                }
              />
              <Route
                path="Project-Dashboard"
                element={<DashboardProject user={user} />}
              ></Route>
              <Route
                path="Pumps/:userId/Pump-Search/:quoteId"
                element={<PumpSearchPage />}
              />
              <Route path="Pumps/:userId/Search" element={<PumpSearchPage />} />
              <Route
                path="Pumps/Pump-list/:userId/:quoteId"
                element={<PumpListPage />}
              />
              <Route
                path="Pumps/Pump-Curve/:curvePath"
                element={<PumpCurvePage />}
              />
              <Route path="Pumps/Pump-Curve" element={<PumpCurvePage />} />
              <Route
                path="Pumps/MFV/:userId/:quoteId/:quoteKitId"
                element={<MfvSelectionPage />}
              />
              <Route
                path="Pumps/SD/:userId/:quoteId/:quoteKitId"
                element={<SdSelectionPage />}
              />
              <Route
                path="Pumps/Edit-Quote-Pump/:userId/:quoteId/:quoteKitId"
                element={<EditQuotePumpPage />}
              />
              <Route
                path="Pumps/VFD/:userId/:quoteId/:quoteKitId"
                element={<VfdSelectionPage />}
              />
              <Route
                path="Assigned-Reminder-Page"
                element={
                  <CheckAllowedComponent
                    objectName="get-reminder-assigned-by"
                    user={user}
                    redirect={true}
                  >
                    <AssignedReminderPage user={user}></AssignedReminderPage>
                  </CheckAllowedComponent>
                }
              ></Route>
              <Route
                path="supplier-part-number-page-copy"
                element={<SupplierPartNumberPage />}
              ></Route>
                           <Route
                path="supplier-part-number-page"
                element={<SupplierPartNumberPageCopy />}
              ></Route>
              {offlinePages}
            </Routes>
          </div>
          <div className="footer-layout"></div>
        </div>
      );
    }
  else if (!isLoggedIn)
    return (
      <>
        <PageTitle id="page-title-offline" className="p-2">
          <a
            className="navbar-brand"
            href={process.env.REACT_APP_HOME_PAGE_URL}
          >
            <LogoComponent className="navbar-logo no-print" />
          </a>
          <LanguageSelector />
        </PageTitle>
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/creation-confirmed"
            element={<CreationConfirmedPage />}
          ></Route>
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route
            path="/change-password/:changePasswordToken"
            element={<ChangePassword />}
          ></Route>
          {offlinePages}
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </>
    );
  else
    return (
      <>
        <LoadingComponent />
      </>
    );
}
export default App;
