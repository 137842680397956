import { useEffect, useState } from "react";
import StatisticContent from "../../services/Stats/StatisticContent";
import statsService from "../../services/Stats/stats-service";
import { toast } from "react-toastify";

export function useProdTypeTotal(employeeId: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
    if (employeeId) {
      statsService
        .getProductionOrderTypeQuantities(employeeId)
        .then((data) => {
          if (data) setData(data);
          else
            console.info(
              "Production Types Total Error:  'Data returned was empty'"
            );

          setLoading(false);
        })
        .catch((error) => {
          console.error("Production Types Total Error", error);
          toast.error("Error while fetching data for 'Production Types Total'");
        });
    }
  }, [employeeId]);

  return { data, loading };
}

export function useNumberOfProdJobs(employeeId: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
    if (employeeId) {
      statsService
        .getNbProductionJobs(employeeId)
        .then((data) => {
          if (data) setData(data);
          else
            console.info(
              "Production jobs total Error:  'Data returned was empty'"
            );

          setLoading(false);
        })
        .catch((error) => {
          console.error("Production jobs total Error", error);
          toast.error("Error while fetching data for 'Production jobs total'");
        });
    }
  }, [employeeId]);

  return { data, loading };
}

export function useTodaysProduction(employeeId: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
    if (employeeId) {
      statsService
        .getTodayProduction(employeeId)
        .then((data) => {
          if (data) setData(data);
          else
            console.info(
              "Today`s Production Error:  'Data returned was empty'"
            );

          setLoading(false);
        })
        .catch((error) => {
          console.error("Today`s Production Error", error);
          toast.error("Error while fetching data for 'Today's production'");
        });
    }
  }, [employeeId]);

  return { data, loading };
}

export function useProductionDelays(employeeId: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<StatisticContent | undefined>(undefined);
  const [error, setError] = useState<string>("");
  useEffect(() => {
    if (employeeId) {
      statsService
        .getProductionDelays(employeeId)
        .then((data) => {
          if (data) setData(data);
          else
            console.info(
              "Production Delay's Error:  'Data returned was empty'"
            );
            setError("Production Delay's Error:  'Data returned was empty");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Production Delay's Error", error);
          toast.error("Error while fetching data for 'Today's production'");
          setError("Error while fetching data for 'Today's production'");
        });
    }
  }, [employeeId]);

  return { data, loading ,error};
}
export function useEmployeeWeeklyHours() {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
      statsService
        .getEmployeeWeeklyHours()
        .then((data) => {
          if (data) setData(data);
          else
            console.info(
              "Production Delay's Error:  'Data returned was empty'"
            );

          setLoading(false);
        })
        .catch((error) => {
          console.error("Production Delay's Error", error);
          toast.error("Error while fetching data 'Employee Weekly Hours'");
        });
    
  }, []);

  return { data, loading };
}
export function useClosingRatio() {
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
      statsService
        .getClosingRatio()
        .then((data) => {
          if (data) setData(data);
          else
            console.info(
              "Closing ratio error:  'Data returned was empty'"
            );

        })
        .catch((error) => {
          console.error("Closing ratio error", error);
          toast.error("Error while fetching data 'Closing ratio'");
          setError(error);
        });
    
  }, []);

  return { data, error };
}
export function useEnginneringData() {
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
      statsService
        .getEngineeringData()
        .then((data) => {
          if (data) setData(data);
          else{
            console.info(
              "Engineering data error:  'Data returned was empty'"
            );
            setError("Sale data error:  'Data returned was empty'");
          }
        })
        .catch((error) => {
          console.error("Engineering data ratio error", error);
          toast.error("Error while fetching data 'Engineering Data'");
          setError(error);
        });
    
  }, []);

  return { data, error };
}
export function useSalesData() {
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
      statsService
        .getSalesData()
        .then((data) => {
          if (data) setData(data);
          else{
            console.info(
              "Sale data error:  'Data returned was empty'"
            );
            setError("Sale data error:  'Data returned was empty'");
          }
        })
        .catch((error) => {
          console.error("Sale data error", error);
          toast.error("Error while fetching data 'Sale Data'");
          setError("Error while fetching data 'Sale Data'");
        });
    
  }, []);

  return { data, error };
}
export function useCashflowData() {
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<StatisticContent | undefined>(undefined);

  useEffect(() => {
      statsService
        .getCashflowData()
        .then((data) => {
          if (data) setData(data);
          else{
            console.info(
              "Cashflow data error:  'Data returned was empty'"
            );
            setError("Cashflow data error:  'Data returned was empty'");
          }
        })
        .catch((error) => {
          console.error("Cashflow data error", error);
          toast.error("Error while fetching data 'Cashflow Data'");
          setError("Error while fetching data 'Cashflow Data'");
        });
    
  }, []);

  return { data, error };
}