import Activity from "./Activity";

class ActivityExtensions {
  buildActivities(data: Array<any>): Array<Activity> {
    let activities: Array<Activity> = [];
    if (data) {
      data.forEach((activity) => {
        let activityTemp = new Activity(activity);
        if (activityTemp) {
          activities.push(activityTemp);
        }
      });
    }
    return activities;
  }
}

const activityExtensions = new ActivityExtensions();
export default activityExtensions;
