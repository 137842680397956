import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsRotate,
  faCheck,
  faCheckDouble,
  faCopy,
  faFileImport,
  faFilePdf,
  faFloppyDisk,
  faPencil,
  faPlug,
  faPlus,
  faPrint,
  faSearch,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";

type ButtonSize = "sm" | "lg" |undefined;
type ButtonProps = {
  title?: string;
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  btnSize?: ButtonSize;
  iconSize?: SizeProp;

};

const generateButtonProps = (props: ButtonProps, className?: string) => {
  const { title, onClick, btnSize } = props;
  return {
    className: `btn btn-${btnSize ?? "sm"} ${props.className}  ${className}`,
    title,
    onClick,
  };
};

export function SearchButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary")}>
      <FontAwesomeIcon icon={faSearch} />
    </button>
  );
}

export function RemoveButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-outline-danger")}>
      <FontAwesomeIcon size={props.iconSize} icon={faX} />
    </button>
  );
}

export function SaveButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary")}>
      <FontAwesomeIcon icon={faFloppyDisk} />
    </button>
  );
}

export function EditButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary")}>
      <FontAwesomeIcon size={props.iconSize} icon={faPencil} />
    </button>
  );
}

export function RefreshButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-outline-primary")}>
      <FontAwesomeIcon size={props.iconSize} icon={faArrowsRotate} />
    </button>
  );
}

export function AddButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary")}>
      <FontAwesomeIcon size={props.iconSize} icon={faPlus} />
      <span> {props.title}</span>
    </button>
  );
}
export function PrintPreviewButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary")}>
      <FontAwesomeIcon icon={faPrint} />
    </button>
  );
}
export function DeleteButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary red")}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );
}
export function DrawingToPdfButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-primary")}>
      <FontAwesomeIcon icon={faFilePdf} />
    </button>
  );
}
export function DeleteProductButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-outline")}>
      <FontAwesomeIcon icon={faTrash} />
      {props.title && <span style={{ marginLeft: "8px" }}>{props.title}</span>}
    </button>
  );
}
export function CopyButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-outline")}>
      <FontAwesomeIcon size={props.iconSize} icon={faCopy} />
      {props.title && <span style={{ marginLeft: "8px" }}>{props.title}</span>}
    </button>
  );
}
export function CopyToButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-outline")}>
      <FontAwesomeIcon size={props.iconSize} icon={faFileImport} />
      {props.title && <span style={{ marginLeft: "8px" }}>{props.title}</span>}
    </button>
  );
}
export function SelectAllButton(props: ButtonProps) {
  return (
    <button {...generateButtonProps(props, "btn-outline")}>
      <FontAwesomeIcon size={props.iconSize} icon={faCheckDouble} />
      {props.title && <span style={{ marginLeft: "8px" }}>{props.title}</span>}
    </button>
  );
}

