import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export interface ICollapse {
  collapsed?: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
  click: () => void;
  getTitle: () => string;
}

export function useCollapse(defaultValue?: boolean) {
  const [collapsed, setCollapsed] = useState(defaultValue);

  const click = () => {
    setCollapsed(!collapsed);
  };

  const getTitle = () => {
    return collapsed ? "Show" : "Hide";
  };

  return { collapsed, setCollapsed, click, getTitle } as ICollapse;
}

type Props = {
  children: React.ReactNode;
  collapsed?: boolean;
};

export function CollapseComponent(props: Props) {
  return <>{!props.collapsed && <>{props.children}</>}</>;
}

type CollapsedIconProps = {
  collapsed?: boolean;
  size?: SizeProp;
};
export function CollapsedIconComponent(props: CollapsedIconProps) {
  const { size, collapsed } = props;
  const iconDefinition = collapsed ? faChevronDown : faChevronUp;

  return <FontAwesomeIcon size={size} icon={iconDefinition} />;
}

type CollapseButtonProps = {
  collapse: ICollapse;
  children?: React.ReactNode;
  iconSize?: SizeProp;
};
export function CollapseButtonComponent(props: CollapseButtonProps) {
  const { collapse, iconSize, children } = props;
  return (
    <a className="btn" onClick={collapse.click}>
      <CollapsedIconComponent size={iconSize} collapsed={collapse.collapsed} />
      {children}
    </a>
  );
}
