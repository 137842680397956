import { useTranslation } from "react-i18next";
import numberHelper from "../../../services/Core/Helpers/number-helper";
import { CommissionReportItem } from "../../../services/Sales/Models/CommissionReport";

type Props = {
  reportItem: CommissionReportItem;
  goal: number;
  currency: string;
};

export default function QuarterSectionReportComponent(props: Props) {
  const { t } = useTranslation();
  const { reportItem, goal, currency } = props;

  return (
    <div className="quarter-grid-container">
      <div className="quarter-title">
        <h6>{reportItem && reportItem.quarter.name}</h6>
      </div>
      <div className="bordered">{t("sales-report-page.goal")}</div>
      <div className="bordered">{t("sales-report-page.actual")}</div>
      <div className="bordered">
        {numberHelper.toCurrencyFormat(goal, currency)}
      </div>
      <div className={`bordered ${getTotalBackground()}`}>
        {reportItem &&
          numberHelper.toCurrencyFormat(reportItem.total, currency)}
      </div>
    </div>
  );

  function getTotalBackground() {
    if (reportItem) {
      let now = new Date(Date.now());

      if (now > reportItem.quarter.endDate) {
        return reportItem.total < props.goal ? "text-danger" : "text-success";
      }
      if (now < reportItem.quarter.startDate) return "";
      if (
        now >= reportItem.quarter.startDate &&
        now <= reportItem.quarter.endDate
      ) {
        return reportItem.total < props.goal ? "text-warning" : "text-success";
      }
    }
  }
}
