import BaseApi from "../Core/REST/BaseApi";
import LoginGmail from "./LoginGmail";
import { Client } from "../Users/Client";
class LoginService extends BaseApi {
  async LoginGmail(loginGmail : LoginGmail,isExtended:boolean)
  {
    let url = `${this.baseUrl}/Login/Login-Gmail/${isExtended}`;
    return await this.post(url,loginGmail);
  }
  async Login(loginCredential : LoginCredential,isExtended:boolean)
  {
    let url = `${this.baseUrl}/Login/login/${isExtended}`;

    return await this.post(url,loginCredential);
  }
  async CreateAccountRequestGmail(companyName:string,countryGmail:string,provinceGmail:string,loginGmail : LoginGmail)
  {
    let url = `${this.baseUrl}/Login/Create-Account-Request-Gmail/${companyName}/${countryGmail}/${provinceGmail}`;
    return await this.post(url,loginGmail);
  }
  async LinkClientIdGmail(loginGmail : LoginGmail)
  {
    let url = `${this.baseUrl}/Login/Link-Account-Gmail`;
    return await this.patch(url,loginGmail);
  }
  async CreateAccountRequest(client:Client)
  {
    let url = `${this.baseUrl}/Login/0`;
    return await this.post(url,client);
  }
  async GetLoginUser(token:string): Promise<Client | undefined>
  {
    let url = `${this.baseUrl}/Login/${token}`;
    return await this.get(url);
  }
  async Logout(token:string): Promise<Client | undefined>
  {
    let url = `${this.baseUrl}/Login/logout/${token}`;
    return await this.post(url,{});
  }
  async SendPasswordChangeRequest(email :string)
  {
    let url = `${this.baseUrl}/Login/Send-Request/${email}`;
    return await this.put(url,{});
  }
  async ChangePassword(changePasswordToken : string,password :string)
  {
    let url = `${this.baseUrl}/Login/Change-Password`;
    return await this.patch(url,{ChangePasswordToken :changePasswordToken,Password:password});
  }
  /**
 * Only work in debug
 */
  async LoginAdmin()//only work in debug
  {
    let url = `${this.baseUrl}/Login/Login-Admin`;
    //console.log(loginCredential);

    return await this.post(url,{});
  }
}
export type LoginCredential =
{
  username : string;
  password : string;
}
const loginService = new LoginService();
export default loginService;
