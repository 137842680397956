import { useTranslation } from "react-i18next"
import GaugeChart from "react-gauge-chart";
import StatisticContent from "../../services/Stats/StatisticContent";
import { Goal } from "../../services/Goals/Goal";
import numberHelper from "../../services/Core/Helpers/number-helper";
import { useMemo, useState } from "react";
type Props=
{
    incomeStatement?: StatisticContent;
    statementQuarter?: StatisticContent;
    goal?: Goal;
}

export default function ProductionGoalDiagram({goal,incomeStatement,statementQuarter} : Props){
    const {t}= useTranslation();
    const gaugeValue = useMemo(() => {
        if (goal && incomeStatement)
          return getGaugePercentage(incomeStatement, goal.value);
        return 0;
      }, [goal, incomeStatement]);
    
      const gaugeValueQuarter = useMemo(() => {
        if (goal && statementQuarter)
          return getGaugePercentage(statementQuarter, goal.value * 3);
        return 0;
      }, [goal, statementQuarter]);
    return(
    <div className="goal-progress-container row mt-3 text-center">
    <div id="monthly-goal-progress" className="col-6">
      <h5>{t("income-statement-page.monthly-gauge-title")}</h5>
      <GaugeChart
        id="monthly-goal-progress-gauge"
        textColor="black"
        needleColor="gray"
        nrOfLevels={30}
        arcWidth={0.3}
        percent={gaugeValue}
        animate={false}
      />
    </div>
    <div id="quarter-goal-progress" className="col-6">
      <h5>{t("income-statement-page.quarter-gauge-title")}</h5>
      <GaugeChart
        id="quarter-goal-progress-gauge"
        textColor="black"
        needleColor="gray"
        nrOfLevels={30}
        arcWidth={0.3}
        percent={gaugeValueQuarter}
        animate={false}
      />
    </div>
  </div>)
    function getGaugePercentage(model: StatisticContent, goal: number) {
        let total: number = 0;
        model.series.forEach((serie) => {
          total += serie.total;
        });
        //console.log("total :", total);
        let temp: any = numberHelper.getPercentage(total, goal);
        return temp.toFixed(2) / 100;
      }
}