import { useEffect, useState } from "react";
import StatisticContent from "../../services/Stats/StatisticContent";
import statsService from "../../services/Stats/stats-service";
import { toast } from "react-toastify";

export function useIncomeStatement(
  employeeId: string | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [incomeStatement, setIncomeStatement] = useState<StatisticContent>();

  useEffect(loadIncomeStatement, [employeeId, startDate, endDate]);

  function loadIncomeStatement() {
    if (employeeId && startDate && endDate) {
      statsService
        .getIncomeStatement(employeeId, startDate, endDate)
        .then((data) => {
          if (data) {
            setIncomeStatement(data);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occured while fetching the data");
        })
        .finally(() => setLoading(false));
    }
  }

  return {
    incomeStatement,
    loadingIncomeStatement: loading,
    loadIncomeStatement,
  };
}
