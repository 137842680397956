//using only what needed, add if you need more
export class QuoteExternalFile {
    id?: number;
    quoteId?: number;
    clientId?: number;
    orderId?: number;
    kitId?: number;
    standerDate?: Date;
    requestedDate?: number;
    picked?: number;
    progression?: number;
    finition?: number;
    elec?: number;
    qcCb?: boolean;
    preShip?: boolean;
    rts?: number;
  
    statusFinished?: number;
  
    getProdStatus() {
      return getProdStatus(this)
    }
  }
  //missing backorder and dropship
  export function getProdStatus(quoteExternalFile: any) {
      let status: string = "";
  
      if ((quoteExternalFile.picked === 0 || quoteExternalFile.picked === 1) && quoteExternalFile.progression === 0) {
        status = "Picking";
      } else if (
          quoteExternalFile.picked !== null &&
          quoteExternalFile.progression !== null &&
          quoteExternalFile.picked > 0 &&
          quoteExternalFile.progression < 4
      ) {
        status = "Production";
      } else if (quoteExternalFile.progression !== null && quoteExternalFile.progression > 3) {
        if (!quoteExternalFile.elec && !quoteExternalFile.finition) {
          status = "Finition";
        } else if (!quoteExternalFile.elec && quoteExternalFile.finition) {
          status = "Elec.";
        } else if (quoteExternalFile.finition && !quoteExternalFile.qcCb) {
          status = "Q.C.";
        } else if (quoteExternalFile.qcCb && !quoteExternalFile.preShip) {
          status = "Shipping Preparation";
        } else if (quoteExternalFile.preShip && quoteExternalFile.rts === 0) {
          status = "Packaging";
        } else if (quoteExternalFile.rts && quoteExternalFile.rts > 0) {
          if (quoteExternalFile.rts === 1 && quoteExternalFile.statusFinished === 0) {
            status = "Partial Ready To Ship";
          } else if (quoteExternalFile.rts === 1 && quoteExternalFile.statusFinished === 1) {
            status = "Partial RTS & Partial Shipped";
          } else if (quoteExternalFile.rts === 2 && quoteExternalFile.statusFinished === 0) {
            status = "Ready To Ship";
          } else if (quoteExternalFile.rts === 2 && quoteExternalFile.statusFinished === 1) {
            status = "Partial Shipped";
          } else if (quoteExternalFile.rts === 2 && quoteExternalFile.statusFinished === 2) {
            status = "Shipped";
          }
        }
      }
      return status;
    }