import BaseApi from "../Core/REST/BaseApi";
import { DefaultDiscount } from "./DefaultDiscount";
class DefaultDiscountService extends BaseApi {
  async getAll(employeeId: string): Promise<Array<DefaultDiscount> | undefined>
  {
    let url = `${this.baseUrl}/DefaultDiscounts/getAll/${employeeId}`;
    return await this.get<Array<DefaultDiscount> | undefined>(url);
  }
  async putDefaultDiscount(employeeId : string,defaultDiscount : DefaultDiscount)
  {
    let url = `${this.baseUrl}/DefaultDiscounts/${employeeId}`;
    return await this.put(url,defaultDiscount);
  }
}

const defaultDiscountService = new DefaultDiscountService();
export default defaultDiscountService;
