import "./quotes.scss";
import { useTranslation } from "react-i18next";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
import { useNavigate, useParams } from "react-router-dom";
import orderService from "../../services/Orders/OrderService";
import { useEffect, useMemo, useRef, useState } from "react";
import QuoteTableModel from "../../services/Orders/Models/QuoteTableModel";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useColumnFilters from "../../hooks/React table/useColumnFilters";
import stringHelper, { DOTS } from "../../services/Core/Helpers/string-helper";
import useColumnVisibility from "../../hooks/React table/useColumnVisibility";
import Pagination from "../../components/react-table/Pagination";
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from "../../components/react-table/react-table-component";
import { ColumnVisibilityComponent } from "../../components/react-table/column-visibility-component";
import numberHelper from "../../services/Core/Helpers/number-helper";
import { SearchBarComponent } from "../../components/Forms/SearchBarComponent";
import NavTabsComponent, { NavItem } from "../../components/nav-tabs-component";
import { toast } from "react-toastify";
import ResetFilterComponent from "../../components/react-table/react-table-header-options";
import CsvExport from "../../components/CSVExportDataAsync";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, FormSelect } from "react-bootstrap";
import {
  DatePickerComponent,
  FilterContainerComponent,
  FilterFieldset,
  useDateRangePicker,
} from "../../components/Forms/Search filters/FilterContainerComponent";
import {
  CollapseComponent,
  CollapsedIconComponent,
  useCollapse,
} from "../../components/CollapseComponent";
import {
  Page,
  PageDetails,
  PageHeader,
} from "../../components/Pages/page-components";
import Popup from "../../components/Core/Popup";
import MultiSelect from 'multiselect-react-dropdown';
import ConfiramtionPopup from "../../components/Core/Popup-confirmation-Component";
import quoteService from "../../services/Quotes/QuoteService";
import { Table,Row } from "@tanstack/react-table";

type IParamTypes = {
  userId: string;
};

const defaultTab = 1;
const defaultFilterState = [{ id: "status", value: defaultTab }];

export default function QuotesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams<IParamTypes>();
  const [quotes, setQuotes] = useState<QuoteTableModel[]>([]);
  const [users, setUsers] = useState<string[]>([]);

  const dateRange = useDateRangePicker();
  const poDateRange = useDateRangePicker();

  const [globalFilter, setGlobalFilter] = useState("");
  const {
    columnFilters,
    editFilterFn,
    setColumnFilters,
    resetFiltersFn,
    getFilterValueFn,
    editFilterMultiselect,
  } = useColumnFilters(defaultFilterState, clearFilters);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const [tab, setTab] = useState<number>(defaultTab);
  const [isNewQuote, setIsNewQuote] = useState(false);

  const filterCollapse = useCollapse(true);

  const pageTitle = t("quotes-page.quotes");
  const filterBtnText = t(
    `quotes-page.${filterCollapse.getTitle().toLocaleLowerCase()}-filters-btn`
  );
  const multiselectRef = useRef<any>();
  const handleRemoveFilterClick = () => {
    resetFiltersFn();
    multiselectRef.current.resetSelectedValues();
  };

  const handleCreateNewQuote =() => {
   quoteService
   .createNewQuote(userId)
   .then((quoteId) => {
     if (quoteId ) {
         navigate(`/Quotes/${userId}/${quoteId}`)
     }
   })
   .catch(() => {
     toast.error("'Load Quotes' : Error while fetching data");
   });
  }

  useEffect(() => {
    document.title = pageTitle;
    loadQuotes();
  }, [userId]);

  const columns: any = useMemo(() => {
    return [
      {
        header: t("common.quote"),
        accessorKey: "id",
      },
      {
        header: t("common.date"),
        accessorKey: "date",
        cell: (props: any) =>
          stringHelper.toDateString(new Date(props.getValue())),
      },
      {
        header: t("quotes-page.po-email-date"),
        accessorKey: "poEmailDate",
        cell: (props: any) =>{
          const value = props.getValue();
          if (value === "0001-01-01T00:00:00") {
              return "N/A"; 
          } else {
              return stringHelper.toDateString(new Date(value));
          }
        }
      },
      {
        header: t("quotes-page.job"),
        accessorKey: "jobName",
      },
      {
        header: t("common.client"),
        accessorKey: "clientContact",
      },
      {
        header: t("quotes-page.user"),
        accessorKey: "ownedBy",
        filterFn: "arrIncludesSome",
        cell: (props: any) => props.getValue(),
      },
      {
        header: t("quotes-page.status"),
        accessorKey: "status",
        filterFn: "equals",
        cell: (props: any) => {
          return t(`quotes.status-${props.getValue()}`);
        },
      },
      {
        header: t("quotes-page.total"),
        accessorKey: "netPrice",
        cell: (props: any) => {
          return (
            <span>{numberHelper.toNumberFormat(props.getValue())}$</span>
          );
        },
      },
    ];
  }, [t]);

  const table = useReactTable({
    columns,
    data: quotes,
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { status: false, "mrt-row-expand": true },
    },
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  const navTabItems = useMemo(() => {
    let temp: NavItem[] = [
      {
        key: "",
        text: t("quotes-page.nav-tab-all", { 0: quotes.length }),
        onclick: setTabFilter,
      },
    ];

    for (let i = 0; i <= 6; i++) {
      let array = quotes.filter((x) => x.status === i);
      if (array.length > 0) {
        temp.push({
          key: i,
          text: `${t(`quotes.status-${i}`)} (${array.length})`,
          onclick: setTabFilter,
        });
      }
    }
    return temp;
  }, [quotes, t]);

  const breadcrumbItems = [{ text: pageTitle, active: true }];

  return (
    <Page className="quotes-container">
      <PageHeader id="quotes-page-header" className="d-block">
        <BreadcrumbComponent items={breadcrumbItems} />
        <div className="d-flex">
          <h1 className="h3 mb-2">{pageTitle}</h1>
          <Button
            id="new-quote-btn"
            variant="outline-primary"
            className="ms-3"
            onClick={() => setIsNewQuote(true)}
          >
            New Quote
          </Button>
        </div>
      </PageHeader>

      <section className="quote-filter-section">
        <FilterContainerComponent>
          <FilterFieldset className="px-3 py-0">
            <legend>
              <a
                className="action-btn me-2 p-0"
                onClick={filterCollapse.click}
                title={filterBtnText}
              >
                <CollapsedIconComponent
                  size="xs"
                  collapsed={filterCollapse.collapsed}
                />
                <span className="ms-2">{filterBtnText}</span>
              </a>

              {!filterCollapse.collapsed && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={loadQuotes}
                >
                  Search
                </Button>
              )}
            </legend>
            <CollapseComponent collapsed={filterCollapse.collapsed}>
              <PageDetails>
                <Page className="mb-2">
                  <PageDetails>
                    <FilterContainerComponent className="row">
                      <Col lg={2} md={4} sm={12}>
                        <FilterFieldset>
                          <legend>{t(`quotes-page.user`)}s:</legend>
                          <MultiSelect
                          ref={multiselectRef}
                          options={users.map(user => ({key: user, value: user}))}
                            onSelect={(selectedList, selectedItem) => {
                              editFilterMultiselect("ownedBy", selectedList, selectedItem);
                            }}
                            onRemove={(selectedList, removedItem) => editFilterMultiselect("ownedBy", selectedList, removedItem)} 
                            displayValue="key" 
                            style={{ searchBox: {  'borderRadius': '0.2rem' , 'paddingtop' : '0.25rem', 'paddingbuttom' : '0.25rem', 'paddingleft' : '0.5rem' } }} 
                          />
                        </FilterFieldset>
                      </Col>
                      <Col lg={4} md={8} sm={12}>
                        <FilterFieldset>
                          <legend>{t(`common.date`)}:</legend>
                          <DatePickerComponent dateRange={dateRange} />
                        </FilterFieldset>
                      </Col>
                      <Col lg={4} md={8} sm={12}>
                        <FilterFieldset>
                          <legend>{t(`quotes-page.po-email-date`)}:</legend>
                          <DatePickerComponent dateRange={poDateRange} />
                        </FilterFieldset>
                      </Col>
                    </FilterContainerComponent>
                  </PageDetails>
                </Page>
              </PageDetails>
            </CollapseComponent>
          </FilterFieldset>
        </FilterContainerComponent>
      </section>

      <PageDetails className="pt-2">
        <div className="row">
          <Col sm={10}>
            <NavTabsComponent active={tab} navItems={navTabItems} />
          </Col>
          <Col sm={2}>
            <SearchBarComponent setGlobalFilter={setGlobalFilter} />
          </Col>
        </div>

        <section id="quotes-table">
          <table className="table table-hover">
            <thead>
              <tr id="goal-table-options" className="table-options">
                <th
                  colSpan={
                    table.getAllFlatColumns().filter((x) => x.getIsVisible())
                      .length
                  }
                >

                  <ColumnVisibilityComponent
                    columns={iColumnVisibility}
                    className="mx-1"
                  />
                  <ResetFilterComponent
                    resetFilterFn={handleRemoveFilterClick}
                    className="me-1"
                    show={columnFilters.length > defaultFilterState.length}
                    filtersLength={
                      columnFilters.length - defaultFilterState.length
                    }
                  />
                  <CsvExport
                    url={getCSVDownloadLink()}
                    label={
                      <>
                        <FontAwesomeIcon
                          className="primary me-1"
                          icon={faFileArrowDown}
                        />
                        {t("react-table.export-data")}
                      </>
                    }
                    transform={transformToExportData}
                  ></CsvExport>
                </th>
              </tr>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id}>
                        <span {...getTableHeaderSortProps(header.column)}>
                          {header.column.columnDef.header?.toString()}
                          <ReactTableHeaderOptions header={header} />
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    onClick={() =>
                      navigate(`/Quotes/${userId}/${row.original.id}`)
                    }
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td className="align-vertical-center" key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
            <QuotesTotalComponent table={table}></QuotesTotalComponent>
            </tfoot>
          </table>
          <Pagination
            reactTable={table}
            siblingCount={2}
            dataName={pageTitle ?? ""}
            showDataLength
          />
        </section>
      </PageDetails>

      {isNewQuote && (
      <ConfiramtionPopup
            show={isNewQuote}
            title={"New Quote"}
            message={
              "Are you sure you want to create a new quote?"
            }
            onYes={handleCreateNewQuote}
            onClose={() => setIsNewQuote(false)}
          ></ConfiramtionPopup>
         )}
    </Page>
  );

  function loadQuotes() {
    if (
      userId &&
      ((dateRange.startDate && dateRange.endDate) ||
        (!dateRange.startDate && !dateRange.endDate))  &&
        ((poDateRange.startDate && poDateRange.endDate) ||
        (!poDateRange.startDate && !poDateRange.endDate))
    ) {
      let { startString, endString, startPOString, endPOString } = getDateStringParams();

      orderService
        .getAllQuotes(userId, startString, endString, startPOString, endPOString,undefined,true)
        .then((data) => {
          if (data) {
            // console.log(`Quotes:`, data);
            setQuotes(data);
            loadUsers();
          }
        })
        .catch(() => {
          toast.error("'Load Quotes' : Error while fetching data");
        });
    }
  }

  function loadUsers(tabValue: number = tab) {
    if (userId) {
      let { startString, endString, startPOString, endPOString } = getDateStringParams();

      orderService
        .getAllUsers(userId, startString, endString, startPOString, endPOString , tabValue)
        .then((data) => {
          // console.log("Clients:", data);
          setUsers(data ?? []);
        })
        .catch(() => []);
    }
  }

  function setTabFilter(value: any) {
    setTab(value);
    loadUsers(value);
    editFilterFn("status", value);
  }

  function getCSVDownloadLink() {
    var user = getFilterValueFn("ownedBy");
    let startString = dateRange.startDate ?? "";
    let endString = dateRange.endDate ?? "";
    let poStartString = poDateRange.startDate ?? "";
    let poEndString = poDateRange.endDate ?? "";
    return `${orderService.baseUrl}/orders/${userId}?status=${tab}&startDate=${startString}&endDate=${endString}&poStartDate=${poStartString}&poEndDate=${poEndString}&user=${user}&withTotal=true`;
  }

  function transformToExportData(data: Array<QuoteTableModel>) {
    const specialDate = new Date("0001-01-01T00:00:00"); 
    return data.map((model) => {
      const poEmailDate = model.poEmailDate !== null ? (model.poEmailDate instanceof Date ? model.poEmailDate : new Date(model.poEmailDate)) : null;
      return {
        Quote: model.id,
        Date: stringHelper.toDateString(new Date(model.date)),
        "PO Email Date": poEmailDate !== null && poEmailDate.getTime() !== specialDate.getTime() ? stringHelper.toDateString(poEmailDate) : "N/A",
        "Job name": model.jobName,
        Client: model.clientContact,
        User: model.ownedBy,
        Status: t(`quotes.status-${model.status}`),
        Total: model.netPrice,
      };
    });
  }

  function clearFilters() {
    setTab(defaultTab);
    dateRange.setStartDateFn(undefined);
    dateRange.setEndDateFn(undefined);
    poDateRange.setStartDateFn(undefined);
    poDateRange.setEndDateFn(undefined);
    setGlobalFilter("");
  }

  function getDateStringParams() {
    let params: { startString?: string, endString?: string, startPOString?: string, endPOString?: string } = {};
    if (dateRange.startDate && dateRange.endDate) {
       params.startString = dateRange.startDate;
       params.endString = dateRange.endDate;
    }
    if (poDateRange.startDate && poDateRange.endDate) {
      params.startPOString = poDateRange.startDate;
      params.endPOString = poDateRange.endDate;
    }
    return params;
  }
}

type QuoteTotalProps = {
  employeeId?: string;
  quoteId: number;
};

function QuoteTotalComponent(props: QuoteTotalProps) {
  const { employeeId, quoteId } = props;

  const [state, setState] = useState(0);
  useEffect(() => {
    if (employeeId) {
      orderService
        .getQuoteNetPrice(employeeId, quoteId)
        .then((data) => {
          if (data) {
            setState(data);
          }
        })
        .catch((error) => {
          toast.error("'Load Net price' : Error while fetching data");
        });
    }
  });

  return <>{numberHelper.toNumberFormat(state)} $</>;
}
//the total of all (filtered) quotes
type QuoteTotalsProps = {
  table: Table<QuoteTableModel>
};
function QuotesTotalComponent({table}: QuoteTotalsProps) {
  const {t} = useTranslation();
  let total = 0;
  table.getFilteredRowModel().rows.forEach((row)=>{
    total += row.getValue("netPrice") as number;
  })
  return <tr><th className="text-end" colSpan={table.getHeaderGroups()[0].headers.length-1} scope="row">Total: </th>
   <th>{numberHelper.toNumberFormat(total, "en-CA")}$</th></tr>;
}
