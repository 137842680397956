import "./sd-selection.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ConfiramtionPopup from "../../../components/Core/Popup-confirmation-Component";
import { SdRow, SdSelectionTable } from "../../../services/Sd/Models/SdSelectionTable";
import sdService from "../../../services/Sd/sd-service";
import React from "react";
import BreadcrumbComponent from "../../../components/Core/BreadcrumbComponent";

type IParamTypes = {
  userId: string;
  quoteKitId: string;
  quoteId: string;
};

export default function SdSelectionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const selectedPump = location.state?.data || [];

  const { userId, quoteId, quoteKitId } = useParams<IParamTypes>();
  const [sdSelectionTable, setSdSelectionTable] =
    useState<SdSelectionTable>();
  const [selectedSd, setSelectedSd] = useState<SdRow>();
  const [isAddToQuote, setIsAddToQuote] = useState<boolean>(false);


  useEffect(() => {
    if (userId && quoteKitId) {
      //console.log("hello")
      loadSds();
    }
  }, []);

  useEffect(() => {
    if (userId && quoteKitId && isAddToQuote) {
      addToQuote();
    }
  }, [isAddToQuote]);
  
  return (
    <div id="sd-selection-page">
      <BreadcrumbComponent
         items={[
          { text: "Quotes", link: `/Quotes/${userId}` },
          { text: `Quote ${quoteId} `, link: `/Quotes/${userId}/${quoteId}` },
          { text: "Pump Search", link: `/Pumps/${userId}/Pump-Search/${quoteId}` },
          {
            text: "Suction Diffuser",
            active: true,
          },
        ]}
      />

      <div className="page-title wrapper">
          <h3>{t("sd-selection-page.title-tb1")}</h3>
        </div>
        <div>
        <div className="card-body">
        <table className="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th className="td-name">Suction Diffuser</th>
              <th>{t("sd-selection-page.list-price")}</th>
            </tr>
          </thead>
          <tbody>
            {sdSelectionTable?.sds.map(
              (row: SdRow, rowIndex: number) => (
                <React.Fragment key={rowIndex}>
                  <tr key={rowIndex} className="table-row">
                    <td className="align-vertical-center">
                      <input
                        type="checkbox"
                        checked={row.isChecked ?? false} 
                        onChange={() => handleSdSelection(row)}
                        onClick={(e) => e.stopPropagation()} // Prevent row click event propagation
                      />
                    </td>
                    <td className="td-name">{row.name}</td>
                    <td>{row.listPrice}</td>
                  </tr>
                  {row.docs.map((doc, docIndex) => (
              <tr key={`doc-${docIndex}`}>
                <td colSpan={3} className="document-row">
                  <a  href={`/Documents/${doc.path}`}  target="_blank" rel="noopener noreferrer" >
                    {doc.name}
                  </a>
                </td>
              </tr>
             ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className="button-containers">
          <button className="btn btn-primary" onClick={handleAddToQuote}>
            {t(`sd-selection-page.add-to-quote`)}
          </button>
          {selectedPump && 
          <button className="btn btn-primary" onClick={handleSkip}>
            {t(`sd-selection-page.skip`)}
          </button>
          }
        </div>
      </div>
      {showAlert && (
        <ConfiramtionPopup
          show={showAlert}
          title={"Warn!"}
          message={alertMessage}
          onOk={() => setShowAlert(false)}
          onClose={() => setShowAlert(false)}
        ></ConfiramtionPopup>
      )}
      </div>
    </div>
  );

  function loadSds() {
    if (userId && quoteKitId) {
      sdService
        .getPumpSds(userId, quoteKitId)
        .then((res) => {
          if (res) {
            setSdSelectionTable(res);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function addToQuote() {
    if (userId && quoteId && quoteKitId && selectedSd) {
      sdService
        .addSDtoQuote(userId, quoteId, quoteKitId, selectedSd)
        .then((res) => {
          if (res) {
            toast.success(t(`sd-selection-page.success-added`));
            if(selectedPump.isVariableFrequencyDrive)
               navigate(`/Pumps/VFD/${userId}/${quoteId}/${quoteKitId}`, { state: { data: selectedPump } })
              else 
              {
                const activeTabIndex = 1;
                sessionStorage.setItem('activeTabIndex', activeTabIndex.toString());
                navigate(`/Quotes/${userId}/${quoteId}`,{ replace: true })
              }

          }
        })
        .catch((error) => console.error(error));
    }
  }
  function handleAddToQuote() {
    if (selectedSd)
      setIsAddToQuote(true);
    else {
      setShowAlert(true);
      setAlertMessage(t("sd-selection-page.warn-select-sd") || "select a Suction Diffuser valve");
    }

  }
  function handleSdSelection(sd: SdRow) {
    setSdSelectionTable(prevState => {
        if (!prevState) return { sds: [] };
        return {
            ...prevState,
            sds: prevState.sds.map(row => ({
                ...row,
                isChecked: row.id === sd.id ? true : false
            }))
        };
    });
    setSelectedSd(sd);
}
  function handleSkip() {
    navigate(`/Pumps/VFD/${userId}/${quoteId}/${quoteKitId}`, { state: { data: selectedPump } })
  }
}
