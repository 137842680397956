import "./goal-manager-page.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../components/Core/Loading";
import goalsService from "../../services/Goals/GoalService";
import { Goal } from "../../services/Goals/Goal";
import stringHelper from "../../services/Core/Helpers/string-helper";
import {
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ReactTableHeaderOptions,
  getTableHeaderSortProps,
} from "../../components/react-table/react-table-component";
import Pagination from "../../components/react-table/Pagination";
import Popup from "../../components/Core/Popup";
import { toast } from "react-toastify";
import { useGoalTypes } from "../../hooks/Goals/useGoalTypes";
import { ColumnVisibilityComponent } from "../../components/react-table/column-visibility-component";
import useColumnVisibility from "../../hooks/React table/useColumnVisibility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numberHelper from "../../services/Core/Helpers/number-helper";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import useColumnFilters from "../../hooks/React table/useColumnFilters";

type IParamTypes = {
  employeeId: string;
};

export default function GoalManagerPage() {
  const { t } = useTranslation();
  const { employeeId } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);

  const { goalTypes, loadingGoalTypes } = useGoalTypes(employeeId);

  const [data, setData] = useState<Array<Goal>>([]);
  const [goal, setGoal] = useState<Goal>();
  const [autoCompleteName, setAutoCompleteName] = useState<Array<string>>([]);
  const [goalErrors, setGoalErrors] = useState<Map<string, string>>(
    new Map([])
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [globalFilter, setGlobalFilter] = useState("");
  const { columnFilters, editFilterFn, setColumnFilters } = useColumnFilters();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const [goalNames, setGoalNames] = useState<Array<string>>([]);

  useEffect(() => {
    loadGoals();
    document.addEventListener("click", () => setAutoCompleteName([]));
  }, [employeeId]);

  const columns: any = [
    {
      header: "goals-page.goal-type",
      accessorKey: "goalTypeId",
      filterFn: (row: Row<any>, columnId: string, filterValue: any) => {
        // console.log(filterValue);
        return row.getValue(columnId) == filterValue;
      },
      cell: (props: any) => {
        let id = props.getValue();
        let goalType = goalTypes.find((x) => x.id == id);
        return goalType?.name;
      },
    },
    {
      header: "goals-page.goal-name",
      accessorKey: "goalName",
    },
    {
      header: "goals-page.goal-value",
      accessorKey: "value",
      filterFn: "equalsString",
      cell: (props: any) => numberHelper.toNumberFormat(props.getValue()),
    },
    {
      header: "goals-page.start-date",
      accessorKey: "startDate",
      cell: (props: any) => stringHelper.toDateString(props.getValue()),
    },
    {
      header: "goals-page.end-date",
      accessorKey: "endDate",
      cell: (props: any) => stringHelper.toDateString(props.getValue()),
    },
    {
      header: "",
      accessorKey: "id",
      cell: (props: any) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-outline-primary mx-1"
            onClick={() => onEditClick(props.getValue())}
          >
            <FontAwesomeIcon icon={["fas", "pencil"]} />
          </button>
        );
      },
    },
  ];

  const table = useReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { "mrt-row-expand": true },
    },
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  if (loading || loadingGoalTypes) {
    return <LoadingComponent />;
  }

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <h1>Goal Manager</h1>
        </div>
        <div className="card-body">
          <section id="filters">
            <div className="row mb-2">
              {goalTypes && (
                <div className="col-lg-3 col-sm-12">
                  <fieldset className="border rounded-3 p-1">
                    <legend className="float-none w-auto px-3">
                      {t("goals-page.goal-types")} :
                    </legend>
                    <select
                      className="form-select-sm"
                      onChange={(ev) => {
                        editFilterFn("goalTypeId", ev.target.value);
                      }}
                      disabled={goalTypes.length === 0}
                    >
                      <option key={"all"} value={""}>
                        All
                      </option>

                      {goalTypes.map((type) => {
                        return (
                          <option key={type.name} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                    </select>
                  </fieldset>
                </div>
              )}

              <div className="col-lg-3 col-sm-12">
                <fieldset className="border rounded-3 p-1">
                  <legend className="float-none w-auto px-3">
                    {t("goals-page.goal-names")} :
                  </legend>
                  <select
                    className="form-select-sm"
                    onChange={(ev) => {
                      editFilterFn("goalName", ev.target.value);
                    }}
                    disabled={goalNames.length === 0}
                  >
                    <option key={"all"} value={""}>
                      All
                    </option>

                    {goalNames.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </fieldset>
              </div>

              <div className="col-12">
                <div id="search-bar" className="m-3">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-sm rounded"
                      placeholder={t("common.search") || ""}
                      onChange={(ev) => setGlobalFilter(ev.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="goal-table">
            <table className="table table-hover">
              <thead>
                <tr id="goal-table-options" className="table-options">
                  <th
                    colSpan={
                      table.getAllFlatColumns().filter((x) => x.getIsVisible())
                        .length
                    }
                  >
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={onCreateClick}
                    >
                      {t("common.create")}
                    </button>

                    <ColumnVisibilityComponent
                      columns={iColumnVisibility}
                      className="mx-1"
                    />

                    {columnFilters.length > 0 && (
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => setColumnFilters([])}
                      >
                        <FontAwesomeIcon
                          className="me-1"
                          icon={faFilterCircleXmark}
                        />
                        <span className="badge bg-primary rounded-pill">
                          {columnFilters.length}
                        </span>
                      </button>
                    )}
                  </th>
                </tr>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      let translationKey =
                        header.column.columnDef.header?.toString() || "";
                      return (
                        <th key={header.id}>
                          <span {...getTableHeaderSortProps(header.column)}>
                            {t(translationKey)}
                            <ReactTableHeaderOptions header={header} />
                            <br />
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      onClick={() => onEditClick(row.original.id)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td className="align-vertical-center" key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination reactTable={table} siblingCount={2} />
          </section>
        </div>
      </div>

      {goal && (
        <Popup
          onOk={sendGoalRequest}
          onClose={resetEditModel}
          show={isEdit}
          title={"Create Goal"}
        >
          <div className="mb-3">
            <fieldset className="border rounded-3 p-1">
              <legend className="float-none w-auto px-3">
                {t("goals-page.goal-types")} :
              </legend>
              <select
                className="form-select-sm"
                onChange={(ev) => {
                  onGoalTypeChange(parseFloat(ev.target.value));
                }}
                defaultValue={goal.goalTypeId}
                disabled={goalTypes.length === 0}
              >
                <option key={"all"} value={0}>
                  All
                </option>

                {goalTypes.map((type) => {
                  return (
                    <option key={type.name} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </fieldset>
            {goalErrors.has("goalTypeId") && (
              <div id="goal-type-error" className="form-text text-danger">
                {t(goalErrors.get("goalTypeId") || "")}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="goal-name" className="form-label">
              {t("goals-page.goal-name")}
            </label>
            <div className="autocomplete">
              <input
                id="goal-name"
                className="form-control"
                value={goal.goalName || ""}
                onChange={(ev) => onGoalNameChange(ev.target.value)}
                autoComplete="off"
              />
              {autoCompleteName && (
                <div className="autocomplete-items">
                  {autoCompleteName.map((name) => {
                    let a = name.substring(0, goal.goalName.length);
                    let b = name.substring(goal.goalName.length);
                    return (
                      <div
                        key={name}
                        onClick={() => onGoalNameChange(name, true)}
                      >
                        <strong>{a}</strong>
                        {b}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {goalErrors.has("goalName") && (
              <div id="goal-name-error" className="form-text text-danger">
                {t(goalErrors.get("goalName") || "")}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="goal-value" className="form-label">
              {t("goals-page.goal-value")}
            </label>
            <input
              type="number"
              className="form-control"
              id="goal-value"
              value={goal.value}
              onChange={(ev) => onGoalValueChange(ev.target.valueAsNumber)}
            />
            {goalErrors.has("value") && (
              <div id="goal-value-error" className="form-text text-danger">
                {t(goalErrors.get("value") || "")}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="start-date-goal" className="form-label">
              {t("goals-page.start-date")}
            </label>
            <input
              type="date"
              className="form-control"
              id="start-date-goal"
              defaultValue={stringHelper.toDateString(goal.startDate)}
              onChange={(ev) =>
                onDateChange("startDate", ev.target.valueAsDate)
              }
            />
            {goalErrors.has("startDate") && (
              <div id="start-date-error" className="form-text text-danger">
                {t(goalErrors.get("startDate") || "")}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="end-date-goal" className="form-label">
              {t("goals-page.end-date")}
            </label>
            <input
              type="date"
              className="form-control"
              id="end-date-goal"
              defaultValue={stringHelper.toDateString(goal.endDate)}
              onChange={(ev) => onDateChange("endDate", ev.target.valueAsDate)}
            />
            {goalErrors.has("endDate") && (
              <div id="end-date-error" className="form-text text-danger">
                {t(goalErrors.get("endDate") || "")}
              </div>
            )}
          </div>
        </Popup>
      )}
    </div>
  );

  function loadGoals() {
    if (employeeId) {
      goalsService
        .getAllGoal(employeeId)
        .then((data) => {
          if (data) {
            setData(data);
            loadGoalNames(data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    }
  }

  function sendGoalRequest() {
    if (goal) {
      let hasError = false;
      if (goal.startDate >= goal.endDate) {
        setGoalError("endDate", "goals-page.end-date-error");
        setGoalError("startDate", "goals-page.start-date-error");
        hasError = true;
      }

      if (goal?.goalTypeId == null || goal?.goalTypeId === 0) {
        setGoalError("goalTypeId", "goals-page.goal-type-error");
        hasError = true;
      }

      if (goal.value <= 0) {
        setGoalError("value", "goals-page.goal-value-error");
        hasError = true;
      }

      if (hasError) return;
    }

    if (!goal || goalErrors.values.length > 0) {
      return;
    }

    if (employeeId) {
      if (goal.id && goal.id != "temp") updateGoal(employeeId, { ...goal });
      else createGoal(employeeId, { ...goal });
    }
  }

  function createGoal(employeeId: string, data: any) {
    goalsService
      .postGoal(employeeId, data)
      .then((response) => {
        if (response) {
          loadGoals();
          resetEditModel();
        } else {
          let errorMsg = t("goals-page.post-error", { 0: data.goalName });
          toast.error(errorMsg);
        }
      })
      .catch((error) => {});
  }

  function updateGoal(employeeId: string, data: any) {
    goalsService
      .putGoal(employeeId, data)
      .then((response) => {
        if (response) {
          loadGoals();
          resetEditModel();
        } else {
          let errorMsg = t("goals-page.post-error", { 0: data.goalName });
          toast.error(errorMsg);
        }
      })
      .catch((error) => {});
  }

  function onCreateClick() {
    setIsEdit(true);
    let now = Date.now();
    setGoal(
      new Goal("temp", 0, "", 0, new Date(now), new Date(now + 86400000))
    );
  }

  function onEditClick(id: string) {
    let tempGoal = data.find((g) => g.id === id);
    if (tempGoal) {
      setIsEdit(true);
      setGoal({ ...tempGoal });
    }
  }

  function resetEditModel() {
    setIsEdit(false);
    setGoal(undefined);
    setGoalErrors(new Map());
  }

  function loadGoalNames(goals: Array<Goal>) {
    let names = new Array<string>();

    goals.forEach((goal) => {
      if (!names.includes(goal.goalName)) {
        names.push(goal.goalName);
      }
    });

    setGoalNames(names);
  }

  function onGoalTypeChange(id: number = 0) {
    if (id !== 0) {
      setGoal({ ...goal, goalTypeId: id } as Goal);
      setGoalError("goalTypeId");
    } else setGoalError("goalTypeId", "goals-page.goal-type-error");
  }

  function onGoalNameChange(value: string, isAutoComplete: boolean = false) {
    if (goal && !isAutoComplete) {
      setAutoCompleteName(
        goalNames.filter((name) => name.startsWith(goal.goalName))
      );
    } else {
      setAutoCompleteName([]);
    }

    setGoal({ ...goal, goalName: value } as Goal);
  }

  function onGoalValueChange(value: number) {
    if (value <= 0) {
      setGoalError("value", "goals-page.goal-value-error");
    } else {
      setGoalError("value");
    }
    setGoal({ ...goal, value: value } as Goal);
  }

  function onDateChange(key: string, value: Date | null) {
    if (goal && value) {
      if (key === "endDate" && value != null && value <= goal.startDate) {
        setGoalError(key, "goals-page.end-date-error");
      } else if (key === "startDate" && value && value >= goal.endDate) {
        // console.log("start error");
        setGoalError(key, "goals-page.start-date-error");
      } else {
        setGoalError("startDate");
        setGoalError("endDate");
      }

      setGoal({ ...goal, [key]: value } as Goal);
    }
  }

  function setGoalError(key: string, value: string | undefined = undefined) {
    if (!value) {
      goalErrors.delete(key);
    } else {
      goalErrors.set(key, value);
    }

    setGoalErrors(new Map(goalErrors));
  }
}
