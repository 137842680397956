import BaseApi from "../Core/REST/BaseApi";
import { Client } from "./Client";
import { Customer } from "../../services/Users/Customer";
class UserService extends BaseApi {
  async getMe(employeeId: string): Promise<string | undefined> {
    let url = `${this.baseUrl}/Users/${employeeId}`;
    return await this.get<string | undefined>(url);
  }
  async getAll(employeeId: string): Promise<Array<Client> | undefined>
  {
    let url = `${this.baseUrl}/Users/getAll/${employeeId}`;
    return await this.get<Array<Client> | undefined>(url);
  }
  async getUser(userId: string): Promise <Client | undefined>
  {
    let url = `${this.baseUrl}/Users/Get-User/${userId}`;
    return await this.get <Client | undefined>(url);
  }  
  async postUser(employeeId : string,client : Client)
  {
    let url = `${this.baseUrl}/Users/${employeeId}`;
    return await this.post(url,client);
  }
  async deleteUser(employeeId: string,userId: string)
  {
    let url = `${this.baseUrl}/Users/${employeeId}/${userId}`;
    return await this.delete(url);
  }
  async updatePassword(client: Client)
  {
    let url = `${this.baseUrl}/Users/Update-Password/717`;
    return await this.patch(url,client);
  }
  async updateActive(employeeId: string,client: Client)
  {
    let url = `${this.baseUrl}/Users/Update-Active/${employeeId}`;
    return await this.patch(url,client);
  }
  async updateSelfPassword(password: string)
  {
    let url = `${this.baseUrl}/Users/Update-Self-Password`;
    return await this.patch(url,{password : password});
  }
  async updateSelf(client : Client)
  {
    let url = `${this.baseUrl}/Users/Update-Self`;
    return await this.patch(url,client);
  }
  async uploadProfileImage(image: File)
  {
    let url = `${this.baseUrl}/Users/Upload-Profile-Image`;
    const formData = new FormData();
    formData.append("profileImage",image);
    return await this.postFile(url,formData);
  }
  async deleteProfileImage()
  {
    let url = `${this.baseUrl}/Users/Delete-Profile-Image`;
    return await this.delete(url);
  }
  async getAllCust(employeeId: string)
  {
    let url = `${this.baseUrl}/Users/Get-All-Cust/${employeeId}`;
    return await this.get<Array<Customer> | undefined>(url);
  }
  async getAllUnasignedToClientCust(employeeId: string,userId :string)
  {
    let url = `${this.baseUrl}/Users/Get-All-Unassigned-Cust/${employeeId}/${userId}`;
    return await this.get<Array<Customer> | undefined>(url);
  }
  async ClientChangeCountryRequest()
  {
    let url = `${this.baseUrl}/Users/Client-Change-Country-Request`;
    return await this.post(url,{});
  }
}

const userService = new UserService();
export default userService;
