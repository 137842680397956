import BaseApi from "../Core/REST/BaseApi";
import ShipVia from "./Models/ShipVia";

class TransportService extends BaseApi {
  async getAllShipVia() {
    return await this.get<Array<ShipVia> | undefined>(
      `${this.baseUrl}/Transport/ShipVia`
    ).then((data) => {
      if (data) {
        return data.map((shipvia) => new ShipVia(shipvia));
      }
      return undefined;
    });
  }
}
const transportService = new TransportService();
export default transportService;
