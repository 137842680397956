import "./AppHeader.scss";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import LogoComponent from "./LogoComponent";
import { Client } from "../../services/Users/Client";
import UserInformation from "./UserInformation";
import {
  faSignOut,
  faGlobe,
  faSearch,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../Core/Sidebar Menu/menu-component";
import { logout } from "../../services/Login/loginFunction";

type Props = {
  children?: JSX.Element;
  user: Client;
};

export default function AppHeader({ children, user }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const navigate = useNavigate();

  return (
    <header id="nav-bar-menu">
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container-fluid justify-content-end">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebar"
            aria-controls="sidebar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            {/*
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
               <li className="nav-item">
                <a
                  className="nav-link"
                  href={process.env.REACT_APP_MAIN_MENU_URL}
                >
                  {t("app-header.main-menu")}
                </a>
              </li> 
            </ul>*/}

            <ul
              id="header-icon-container"
              className="navbar-nav justify-content-end"
            >
              <li className="d-flex flex-column justify-content-center">
                <div
                  onClick={() => {
                    navigate("settings");
                  }}
                >
                  <FontAwesomeIcon
                    className="Round-fa-Button"
                    size="lg"
                    icon={faBell}
                  />
                </div>
              </li>
              <li className="d-flex flex-column justify-content-center">
                <FontAwesomeIcon
                  onClick={() => {
                    navigate("settings");
                  }}
                  className="Round-fa-Button"
                  size="lg"
                  icon={faSearch}
                />
              </li>
              <li>
                <img
                  onClick={() => {
                    navigate("settings");
                  }}
                  className="avatar"
                  title="profil Image"
                  onError={(e) => {
                    e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/default.jpg`
                  }}
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/${
                    user.logoPath ? user.logoPath : "default.jpg"
                  }`}
                ></img>
              </li>
              <li className="nav-item">
                <UserInformation user={user} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="offcanvas offcanvas-start" id="sidebar">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            <LogoComponent className="navbar-logo" />
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <MenuComponent id="offcanvas-page-links" user={user} />
          <div id="offcanvas-page-links">
            <a href="/settings" className="side-menu-item">
              <div className="icon-item text-center">
                <img
                  className="avatar"
                  title="profil Image"
                  onError={(e) => {
                    e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/default.jpg`
                  }}
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/${
                    user.logoPath ? user.logoPath : "default.jpg"
                  }`}
                ></img>
              </div>
              <div className="text-item d-flex align-items-center ms-3">
                <div>{user.nom}</div>
              </div>
            </a>
            <div className="side-menu-item">
              <div className="icon-item text-center">
                <FontAwesomeIcon size="lg" icon={faGlobe} />
              </div>
              <LanguageSelector />
            </div>
            <div
              onClick={() => {
                logout(navigate);
              }}
              className="side-menu-item"
            >
              <div className="icon-item text-center">
                <FontAwesomeIcon size="lg" icon={faSignOut} />
              </div>
              <div className="text-item d-flex align-items-center">
                Sign Out
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
