import "./settings.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NavTabsComponent from "../../components/nav-tabs-component";
import Profile from "./SettingTabs/profile";
import Account from "./SettingTabs/account";
import Billing from "./SettingTabs/billing";
import { Client } from "../../services/Users/Client";
import { Dispatch, SetStateAction } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
type Props = {
  user: Client;
  setUser: Dispatch<SetStateAction<Client | undefined>>;
};
export default function SettingsPage({ user, setUser }: Props) {
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  const tabs = [
    { key: 0, onclick: setTab, text: t(`settings-page.Profile`) },
    { key: 1, onclick: setTab, text: t(`settings-page.Account`) },
    { key: 2, onclick: setTab, text: t(`settings-page.Billing`) },
  ];

  return (
    <div className="m-2" id="settings">
      <div className="page-title wrapper d-flex justify-content-between">
        <h1>{t("settings-page.title")}</h1>
        <div className="user-form d-flex d-sm-none w-50">
        <InputGroup>
        <Form.Control
          value={tab}
          as="select"
          name="province"
          aria-describedby="inputGroupPrepend"
          isInvalid={user.province === undefined}
          onChange={(ev) => setTab(parseInt(ev.target.value))}
          required
        >
          {tabs?.map((tab) => (
            <option key={tab.key} value={tab.key}>
              {tab.text}
            </option>
          ))}
        </Form.Control>
      </InputGroup>
      </div>
      </div>
      <NavTabsComponent
        additionalClassName="d-none d-sm-flex"
        active={tab}
        navItems={tabs}
      />

      <div>
        <div style={{ maxWidth: 1000 }}>
          {/* {tab === 0 ? <OpenOrdersTable /> : <WebQuotesTable />} */}
          {tab === 0 ? (
            <Profile setDefaultUser={setUser} defaultUser={user} />
          ) : tab === 1 ? (
            <Account />
          ) : (
            <Billing />
          )}
        </div>
      </div>
    </div>
  );
}
