import "./nav-tabs-component.scss";

type Props = {
  active: string | number;
  navItems: Array<NavItem>;
  additionalClassName? : string;
};

export type NavItem = {
  key: string | number;
  text: string;
  onclick: Function;

};

export default function NavTabsComponent(props: Props) {
  return (
    <ul className={`nav nav-tabs ${props.additionalClassName}`}>
      {props.navItems.map((navItem) => {
        return (
          <li key={navItem.key} className="nav-item">
            <a
              className={`nav-link ${
                navItem.key === props.active ? "active" : ""
              }`}
              aria-current="page"
              onClick={() => navItem.onclick(navItem.key)}
            >
              {navItem.text}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
