import { useEffect, useState } from "react";
import salesService from "../../services/Sales/SalesService";
import stringHelper from "../../services/Core/Helpers/string-helper";
import StatisticContent from "../../services/Stats/StatisticContent";

export default function usePurchasePacToolStats(
  employeeId?: string,
  start?: Date,
  end?: Date,
  type?: string
) {
  const [state, setState] = useState<StatisticContent>();
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = () => {
    if (employeeId && start && end) {
      let s = stringHelper.toDateString(start);
      let e = stringHelper.toDateString(end);

      salesService
        .getPurchasePacToolStats(employeeId, s, e, type ?? "year")
        .then((data) => {
          if (data) {
            setState(data);
          }
        })
        .catch((error) => {})
        .finally(() => setLoading(false));
    }
  };

  useEffect(loadData, [employeeId, start, end]);

  return { purchasePacTool: state, purchasePacToolLoading: loading };
}
