import mathUtils from './math-outils';
export const DOTS = "...";
export const Hyphens = "---";

class StringHelper {
  toDateString(date?: Date, timeZone: string | undefined = undefined) {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: timeZone,
    }).format(date);
  }

  toDateTimeString(
    date: Date,
    timeZone: string | undefined = undefined
  ): string {
    return new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: timeZone,
    }).format(date);
  }

  toTimeString(date: Date, timeZone: string | undefined = undefined) {
    return new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
    }).format(date);
  }

  toMonthYearString(date: Date, timeZone: string | undefined = undefined) {
    return new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "numeric",
      timeZone: timeZone,
    }).format(date);
  }
  toDayMonthString(date: Date, timeZone: string | undefined = undefined) {
    return new Intl.DateTimeFormat("en-CA", {
      weekday:"short",
      month:"short",
      day:"numeric",
      timeZone: timeZone,
    }).format(date);
  }
  toHourMinuteString(date: Date, timeZone: string | undefined = undefined) {
    return new Intl.DateTimeFormat("en-CA", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: timeZone,
    }).format(date);
  }
  getTimeElapsedString(d: Date, t: Function) {
    let now = new Date(Date.now());
    let diff =
      now.getTime() - (d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    console.log(diff, now.getTime(), d.getTime());

    let seconds = diff / 1000;
    if (seconds < 60) return "now";
    let minutes = seconds / 60;
    if (minutes < 60) return this.getTimeElapsed(minutes, "minute", t);

    let hours = minutes / 60;
    if (hours < 24) return this.getTimeElapsed(hours, "hour", t);

    let days = hours / 24;
    if (days < 7) return this.getTimeElapsed(hours, "day", t);

    let weeks = days / 7;
    let months = weeks / 4.348;
    if (months < 1) return this.getTimeElapsed(weeks, "week", t);

    if (months < 12) return this.getTimeElapsed(months, "month", t);

    return this.getTimeElapsed(months / 12, "year", t);
  }

  private getTimeElapsed(val: number, format: string, t: Function) {
    let str = t(`common.${format}`);
    if (val > 1 && str && str.charAt(str.length - 1) !== "s") str += "s";
    return t("common.elapsed-time", {
      0: this.toFixedNumberFormat(val, 0),
      1: str,
    });
  }
   getTimeString(date:Date)
  {
    return (date.getHours() <=9?"0":"")+ date.getHours()+":"+(date.getMinutes() <=9?"0":"")+date.getMinutes()+":00";
  }
  toFixedNumberFormat(
    num: number,
    fractionDigits: number = 2,
    formatType: string = "en-CA"
  ): string {
    if (num == null) return "0";
    let fixedValue = num.toFixed(fractionDigits);
    let val = parseFloat(fixedValue);
    return new Intl.NumberFormat(formatType).format(val);
  }
  capitalizeFirstLetter(string :string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  RegExpEmailAdress = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  RegExpPassword = /^[A-Za-z0-9!@#$%^&*_+-=;':,.<>?]{6,15}$/;
  RegExpToString(RegExp: RegExp)
  {
    return RegExp.toString().replaceAll("/","")
  }

  getWeightString(val : number)
  {
    return  val.toString() + " lbs (" + this.toFixedNumberFormat(mathUtils.poundsToKilo(val), 2) + "Kg)";
  }
  getFlowString(val : number)
  {
    return val.toString() + " U.S. GPM (" + this.toFixedNumberFormat(mathUtils.gpmtToCubisMetre(val)) + " M³/H or " + this.toFixedNumberFormat(mathUtils.gpmtToLitreSec(val), 2) + " L/Sec.)";
  }
  getHeadString(val : number) {
    return val.toString() + " ft (" + this.toFixedNumberFormat(mathUtils.feetToMeter(val), 2) + " M)";
  }
  getDiamString(val : number) {
    return val.toString() + " \" (" + (Math.round(mathUtils.inchToMillimeter(val))).toString() + " mm)"
  }
  getMPowerString(val : number)
  {
    return val.toString() + " Hp   (" + this.toFixedNumberFormat(mathUtils.hpToKilowatts(val), 2)  + "Kw)"
  }
  getMotorType(odp : boolean , submersible : boolean) {
    if(submersible) return "Submersible";
    else if(odp) return "ODP";
    else return "TEFC"
  }
  getMotorEfficiency(premiumEfficiency : boolean, premiumEfficiencyMotorNotNorthAmerican: boolean) {
    var res ="";
    if(premiumEfficiency)  res = "Premium Efficiency";
    if(premiumEfficiencyMotorNotNorthAmerican) 
    {
      if(res.length > 0)
        res += " - Factory Choice Premium efficiency Motor Not North American";
      else
        res += "Factory Choice Premium efficiency Motor Not North American";
    }
    return res;
  }
  ///Change a number to abride version with a unit 441 000 => 441k, 5 520 000 =>5,5M 135=>135
  getNumberToUnit(val: number):string
  {
    let abridgedVersion : string = val.toString();
    if(abridgedVersion.length > 6)
    {
      abridgedVersion = (val/1000000).toString();
      abridgedVersion = abridgedVersion.slice(0,4);
      abridgedVersion = parseFloat(abridgedVersion) + "M";
    }
    else if(abridgedVersion.length > 3)
    {
      abridgedVersion = (val/1000).toString();
      abridgedVersion = abridgedVersion.slice(0,4);
      abridgedVersion = parseFloat(abridgedVersion) + "K";
    }
    return abridgedVersion;
  }
}

const stringHelper = new StringHelper();
export default stringHelper;
