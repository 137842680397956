import BaseApi from "../Core/REST/BaseApi";
import { SingleItemQuickNavigationLinkTypes } from "./Models/SingleItemQuickNavigationLinkTypes";
import { SingleItemQuickNavigationLinks } from "./Models/SingleItemQuickNavigationLinks";
import { Item } from "../Core/Items/Item";
import itemExtensions from "../Core/Items/ItemExtensions";

class SingleItemService extends BaseApi {
  async getAllQuickNavigationLinkTypes(): Promise<Array<SingleItemQuickNavigationLinkTypes> | undefined> {
    let url = `${this.baseUrl}/SingleItem/Get-Types`;
    return await this.get<Array<SingleItemQuickNavigationLinkTypes> | undefined>(url)
  }

  async getAllQuickNavigationLinks(
    linkType: number,
  ): Promise<Array<SingleItemQuickNavigationLinks> | undefined> {
    let url = `${this.baseUrl}/SingleItem/Get/${linkType}`;
    return await this.get<Array<SingleItemQuickNavigationLinks> | undefined>(url)
  }

  async getAllItemsByOCode(
    ocode: string,
  ): Promise<Array<Item> | undefined> {
    let url = `${this.baseUrl}/SingleItem/search-code/ocode?ocode=${ocode}`;
    return await this.get<Array<Item> | undefined>(url)
  }

  async getAllItemsByDescription(
    descr: string,
  ): Promise<Array<Item> | undefined> {
    let url = `${this.baseUrl}/SingleItem/search-code/descr?descr=${descr}`;
    return await this.get<Array<Item> | undefined>(url)
  }

  // **************** QuoteSingleItem 
  async addItemtoQuote(userId: number, quoteId: number , item: Item) : Promise<number | undefined> {
    let url = `${this.baseUrl}/SingleItem/${userId}/add-to-quote/${quoteId}`;
    return await this.post(url, item);
  }

  async getQuoteSingleItem(
    quoteSingleItemId: string |number
  ): Promise<Item | undefined> {
    let url = `${this.baseUrl}/SingleItem/${quoteSingleItemId}/Quote-single-item`;
    return await this.get<Item>(url).then((data) => {
      if (data) {
        return itemExtensions.generateItem(data);
      }
      return undefined;
    });
  }

  async deleteQuoteSingleItem(
    userId: string,
    quoteId: string,
    itemId: number
  ) {
    await this.delete(
      `${this.baseUrl}/SingleItem/${userId}/${quoteId}/Quote-single-item?itemId=${itemId}`
    );
  }

  async updateQuoteSingleItem(
    itemId: number,
    qty: number,
    quoteId?: string,
    userId?: string,
  ) {
    await this.patch(
      `${this.baseUrl}/SingleItem/${userId}/${quoteId}/Quote-single-item?itemId=${itemId}&qty=${qty}`
    , {});
  }
}
const singleItemService = new SingleItemService();
export default singleItemService;