import { Item, Option } from "../Core/Items/Item";
import itemExtensions from "../Core/Items/ItemExtensions";
import BaseApi from "../Core/REST/BaseApi";
import {
  QuoteHeatExchanger,
  QuoteHeatExchangerOption,
} from "../HeatExchangers/Models/QuoteHeatExchanger";
import QuoteTableModel from "../Orders/Models/QuoteTableModel";
import { BoosterSystem } from "../Systems/BoosterSystem/BoosterSystem";
import { BoosterSystemPump } from "../Systems/BoosterSystem/BoosterSystemPump";
import { QuoteHistory } from "./Models/QuoteHistory";
import Quote, {QuoteCust, QuotesClient } from "./Quote";
import QuoteKit, { QuoteKitCost } from "./Models/QuoteKit";
import { PumpRushRow } from "./Models/QuoteRushDelayPumpInfo";
import { QuotesRelationship } from "./Models/QuotesRelationship";
import { QuotesSerialNumber } from "./Models/QuotesSerialNumber";
import { RushProgramPumpDelays } from "./Models/RushProgramPumpDelays";
import { QuoteSingleItem } from "../SingleItem/Models/QuoteSingleItem";
import { QuoteDownloadStatus, QuoteDownloadStatusTypes } from "./Models/QuoteDownloadStatus";
import { PrintModel } from "../Print/Models/PrintModel";
import QuoteSumamryTable from "./Models/QuoteSummaryTable";

class QuoteService extends BaseApi {
  async getAllQuotes(
    userId: string,
    startDate?: string,
    endDate?: string,
    poStartDate?: string,
    poEndDate?: string,
    status?: number,
    withNetPrice: boolean = false
  ): Promise<Array<QuoteTableModel> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}`;
    let params: Array<string> = [];
    if (startDate && endDate) {
      params.push(`startDate=${startDate}&endDate=${endDate}`);
    }
    if (poStartDate && poEndDate) {
      params.push(`poStartDate=${poStartDate}&poEndDate=${poEndDate}`);
    }
    if (status != null) params.push(`status=${status}`);
    if (withNetPrice) params.push(`withTotal=${withNetPrice}`);

    params.forEach((param, i) => {
      if (i === 0) {
        url += "?" + param;
      } else {
        url += "&" + param;
      }
    });

    return await this.get<Array<QuoteTableModel>>(url);
  }

  async getQuote(userId: string, quoteId: string): Promise<Quote | undefined> {
    let url = `${this.baseUrl}/Quotes/quote/${quoteId}`;
    return await this.get<Quote | undefined>(url).then((data) => {
      if (data) {
        //console.log(data)
        let heatExchangers = data.quoteHeatExchangers.map((heatExchanger) => {
          return new QuoteHeatExchanger(heatExchanger);
        });

        let systems = data.boosterSystems.map((val) => new BoosterSystem(val));
        let quoteKits = data.quoteKits.map((val) => new QuoteKit(val));
        let quoteClients: Array<QuotesClient> = [];
        if (data.quotesClients) {
          quoteClients = data.quotesClients.map(
            (item) => new QuotesClient(item)
          );
        }

        let quoteSingleItems = data.quoteSingleItems.map((val) => {if (val.itemDetails) {
          return new QuoteSingleItem(val, val.itemDetails);
        } else {
          return val;
        }});

        let quoteRelations: Array<QuotesRelationship> = [];
        if (data.quoteRelations) {
          quoteRelations = data.quoteRelations.map(
            (quoteRel) => new QuotesRelationship(quoteRel)
          );
        }

        let quoteSerials: Array<QuotesSerialNumber> = [];
        if (data.quoteSerialNumbers) {
          quoteSerials = data.quoteSerialNumbers.map(
            (serial) => new QuotesSerialNumber(serial)
          );
        }

        let quoteDownloadStatuses = data.quoteDownloadStatuses.map( (dlStatus) => new QuoteDownloadStatus(dlStatus));

        return new Quote(
          data,
          heatExchangers,
          systems,
          quoteKits,
          quoteSingleItems,
          quoteClients,
          quoteRelations,
          quoteSerials,
          quoteDownloadStatuses,
        );
      }
      return undefined;
    });
  }

  async getQuoteHistory(
    userId: string,
    quoteId: string
  ): Promise<QuoteHistory[] | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/History`;
    return await this.get<QuoteHistory[] | undefined>(url);
  }

  async getQuoteClients(userId: string, clientName: string) {
    return await this.get<Array<QuoteCust> | undefined>(
      `${this.baseUrl}/Quotes/${userId}/Clients?clientName=${clientName}`
    );
  }

  async addQuoteClient(
    userId: string,
    quoteId: string,
    clientId: number,
    contactId: number
  ): Promise<boolean | undefined> {
    return await this.post(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Clients?clientId=${clientId}&contactId=${contactId}`,
      {}
    );
  }

  async updateQuotesClient(
    userId: string,
    quoteId: string,
    quotesClientId: number
  ) {
    return await this.patch(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Clients?id=${quotesClientId}`,
      {}
    );
  }

  async deleteQuoteClient(
    userId: string,
    quoteId: string,
    quotesClientId: number
  ) {
    return await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Clients?id=${quotesClientId}`
    );
  }

  async updateQuote(userId: string, quoteId: string, data: any) {
    return await this.put<any>(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}}`,
      data
    );
  }

  async updateQuoteReminder(
    userId: string,
    quoteId: string,
    reminderEnabled: boolean,
    reminder?: string
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}}/Reminder?reminderEnable=${reminderEnabled}`;
    if (reminder != null) url += `&date=${reminder}`;
    return await this.patch(url, {});
  }

  // **** ...Quote Items... **** //
  // ** .. Heat Exchangers .. **//
  async getHEModel(
    userId: string,
    quoteId: string | number,
    quoteHeatExchangerId: number
  ): Promise<undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Heat-Exchanger/${quoteHeatExchangerId}/Model`;
    return await this.get(url);
  }

  async getAllHEItems(
    userId: string,
    quoteId: string | number,
    quoteHeatExchangerId: number
  ): Promise<Array<Item> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Heat-Exchanger/${quoteHeatExchangerId}/Items`;
    return await this.get<Array<Item>>(url);
  }

  async getAllHEIOptions(
    userId: string,
    quoteId: string | number,
    quoteHeatExchangerId: number
  ): Promise<Array<QuoteHeatExchangerOption> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Heat-Exchanger/${quoteHeatExchangerId}/Options`;
    return await this.get<Array<QuoteHeatExchangerOption>>(url);
  }

  // ** .. QuoteKit .. **//
  async getQuoteKit(
    userId: string,
    quoteId: string | number,
    quoteKitId: string
  ): Promise<QuoteKit | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}`;
    return await this.get<QuoteKit>(url).then((data) => {
      if (data) {
        return new QuoteKit(data);
      }
      return undefined;
    });
  }

  async getAllQuoteKitCost(
    userId: string,
    quoteId: string | number,
    quoteKitId: number
  ): Promise<QuoteKitCost | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Cost`;
    return await this.get<QuoteKitCost>(url).then((data) => {
      if (data) {
        return new QuoteKitCost(data);
      }
      return undefined;
    });
  }

  async getQuoteKitAndOptionsNetPrice(
    userId: string,
    quoteId: string | number,
    quoteKitId: number
  ): Promise<number | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Total-Price`;
    return await this.get<number | undefined>(url)
  }


  async getAllQKitQuoteItems(
    userId: string,
    quoteId: string | number,
    quoteKitId: number
  ): Promise<Array<Item> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Quote-Items`;
    return await this.get<Array<Item>>(url).then((data) => {
      if (data) {
        return data.map((item: Item) => itemExtensions.generateItem(item));
      }
      return undefined;
    });
  }

  async getQuoteKitOptions(
    userId: string,
    quoteId: string | number,
    quoteKitId: number
  ): Promise<Array<Option> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Kit/${quoteKitId}/Options`;
    return await this.get<Array<Option>>(url).then((data) => {
      if (data) {
        return data.map((option) => {
          return itemExtensions.generateOption(option);
        });
      }
      return undefined;
    });
  }

  // ** .. Systems .. **//
  async getSystemItems(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number
  ): Promise<Array<Item> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Items`;
    return await this.get<Array<Item>>(url).then((data) => {
      if (data) {
        return data.map((item: Item) => itemExtensions.generateItem(item));
      }
      return undefined;
    });
  }

  async getSystemPumps(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number
  ): Promise<Array<BoosterSystemPump> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Pumps`;
    return await this.get<Array<BoosterSystemPump>>(url);
  }

  async getSystemPumpItems(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number,
    bs_PumpId: number
  ): Promise<Array<Item> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Pumps/${bs_PumpId}/Items`;
    return await this.get<Array<Item>>(url);
  }

  async getSystemPumpOptions(
    userId: string,
    quoteId: string | number,
    boosterSystemId: number,
    bs_PumpId: number
  ): Promise<Array<Option> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Booster-System/${boosterSystemId}/Pumps/${bs_PumpId}/Options`;
    return await this.get<Array<Option>>(url);
  }
  /** ***** **/

  async getAllQuoteRushPrograms(
    userId: string,
    quoteId: string
  ): Promise<Array<PumpRushRow> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Rush-Manager`;
    return await this.get<Array<PumpRushRow> | undefined>(url);
  }
  async getAllRushProgramList(
    userId: string
  ): Promise<Array<RushProgramPumpDelays> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/Rush-Program-List`;
    return await this.get<Array<RushProgramPumpDelays> | undefined>(url);
  }
  async insertQuoteRushPrograms(userId: string, pumps: Array<PumpRushRow>) {
    let url = `${this.baseUrl}/Quotes/${userId}/Quote-Rush-Manager`;
    try {
      return await this.post(url, pumps);
    } catch (error) {
      console.error("Error during insertion:", error);
      throw error;
    }
  }

  // ** .. QuoteRelations .. **//
  async getQuoteRelations(
    userId: string,
    quoteId: string
  ): Promise<Array<QuotesRelationship> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Relation`;
    return await this.get<Array<QuotesRelationship> | undefined>(url);
  }
  async getOriginalQuote(
    userId: string,
    quoteId: string
  ): Promise<QuotesRelationship | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Original`;
    return await this.get<QuotesRelationship | undefined>(url);
  }
  async addQuoteOriginal(
    userId: string,
    quoteId: string,
    parentQuoteId: number
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Original`;
    return await this.post(url, parentQuoteId);
  }
  async deleteQuoteOriginal(
    userId: string,
    quoteId: string,
    relationId: number
  ) {
    await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Original?id=${relationId}`
    );
  }

  // ** .. QuotesSerialNumber .. **//
  async getQuoteSerialNumbers(
    userId: string,
    quoteId: string
  ): Promise<Array<QuotesSerialNumber> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Serial-Number`;
    return await this.get<Array<QuotesSerialNumber> | undefined>(url);
  }
  async addQuoteSerialNumber(
    userId: string,
    serialNumber: QuotesSerialNumber
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/Quote-Serial-Number`;
    return await this.post(url, serialNumber);
  }
  async deleteQuoteSerialNumber(
    userId: string,
    quoteId: string,
    serialId: number
  ) {
    await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-Serial-Number?id=${serialId}`
    );
  }

  // async updateQuoteInfo(userId: number, quote: Quote): Promise<boolean | undefined>{
  //   let url = `${this.baseUrl}/Quotes/${userId}/Update-Quote-Info`;
  //   return this.patch(url, quote);
  // }
  async UpdateAllOfQuote(userId: number, quote: Quote): Promise<boolean | undefined>{
    let url = `${this.baseUrl}/Quotes/${userId}/Update-Quote`;
    return this.patch(url, quote);
  }
  async getAllQuoteDlStatusTypes(
    userId: string
  ): Promise<Array<QuoteDownloadStatusTypes> | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/Download-Status`;
    return await this.get<Array<QuoteDownloadStatusTypes> | undefined>(url);
  }
  async createNewQuote(
    userId: string | undefined
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/Create-New-Quote`;
    return await this.post(url, userId);
  }
  async createCopyOfQuote(
    userId: string | undefined,
    quote : Quote
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/Create-Copy`;
    return await this.post(url, quote);
  }

  async getTermsAndConditions(
    userId: string,
    id: number
  ): Promise<string | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${id}/Terms`;
    return await this.get<string | undefined>(url);
  }

  async getPrintPreviewData(
    userId: string,
    quoteId: string
  ): Promise<PrintModel | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Print-Preview`;
    return await this.get<PrintModel | undefined>(url);
  }
  async deleteQuote(
    userId: string,
    quoteId: string
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}`;
    return await this.delete(url);
  }
  async deleteSelectedQuoteItems(
    userId: string | undefined,
    quote: Quote
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/delete`;
    return await this.deleteAll(url, quote );
  }
  async getAllQuotesInProduction(): Promise<Array<QuoteTableModel> | undefined>
  {
    let url = `${this.baseUrl}/Quotes/get-all-quotes-in-production`;
    return await this.get<Array<QuoteTableModel>>(url);
  }
  async deleteQuoteKit(
    userId: string,
    quoteId: string,
    kitId: number
  ) {
    await this.delete(
      `${this.baseUrl}/Quotes/${userId}/${quoteId}/Quote-kit?kitId=${kitId}`
    );
  }
  async deleteQuoteKitOption(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpOptionId: number
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Option?id=${quotePumpOptionId}`;
    return await this.delete(url);
  }
  async deleteQuoteKitMfv(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpMfvId: number
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Mfv?id=${quotePumpMfvId}`;
    return await this.delete(url);
  }
  async deleteQuoteKitSd(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpSdId: number
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Sd?id=${quotePumpSdId}`;
    return await this.delete(url);
  }
  async deleteQuoteKitVfd(
    userId: string,
    quoteId: string,
    quoteKitId: number,
    quotePumpVfdId: number
  ) {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/${quoteKitId}/Quote-Kit/delete-Vfd?id=${quotePumpVfdId}`;
    return await this.delete(url);
  }
  async getQuoteSummary(
    userId: string,
    quoteId: string
  ): Promise<QuoteSumamryTable | undefined> {
    let url = `${this.baseUrl}/Quotes/${userId}/${quoteId}/Summary`;
    return await this.get<QuoteSumamryTable | undefined>(url);
  }
  async drawingToPdf(
    quoteId: string
  ): Promise<Blob | undefined> {
    let url = `${this.baseUrl}/Quotes/${quoteId}/Drawing-To-Pdf`;
    return await this.getPdf<Blob | undefined>(url);
  }
}

const quoteService = new QuoteService();
export default quoteService;
