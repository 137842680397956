import BaseApi from "../Core/REST/BaseApi";
import { Client } from "../Users/Client";
import Reminder from "./model/Reminder";
class RemindersService extends BaseApi {
  async getUserReminders(): Promise<Array<Reminder> | undefined>
  {
    let url = `${this.baseUrl}/Reminder/get-user-reminders`;
    let reminderArray = await this.get<Array<Reminder> | undefined>(url);
    if(reminderArray)
      reminderArray.forEach(reminder => {
      if(reminder.date)
        reminder.date = new Date(reminder.date + "Z");
    });
    return reminderArray;
  }
  async createReminder(reminder:Reminder)
  {
    let url = `${this.baseUrl}/Reminder/create-reminder`;
    return await this.put(url,reminder);
  }
  async updateReminder(reminder:Reminder)
  {
    let url = `${this.baseUrl}/Reminder/update-reminder`;
    return await this.patch(url,reminder);
  }
  async checkReminder(reminder:Reminder)
  {
    let url = `${this.baseUrl}/Reminder/check-reminder`;
    return await this.patch(url,reminder);
  }
  async getRemindableUser(): Promise<Array<Client>|undefined>
  {
    let url = `${this.baseUrl}/Reminder/get-remindable-users`;
    return await this.get<Array<Client> | undefined>(url);
  }
  async GetRemindersAssignedBy(): Promise<Array<Reminder>|undefined>
  {
    let url = `${this.baseUrl}/Reminder/get-reminders-assigned-by`;
    let reminderArray =await this.get<Array<Reminder> | undefined>(url);
    if(reminderArray)
      reminderArray.forEach(reminder => {
      if(reminder.date)
        reminder.date = new Date(reminder.date + "Z");
      if(reminder.finishedDate)
        reminder.finishedDate = new Date(reminder.finishedDate + "Z");
    });
    return reminderArray;
  }
}

const remindersService = new RemindersService();
export default remindersService;
