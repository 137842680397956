import { Item, Option } from "../../Core/Items/Item";
import { QuoteItem } from "./QuoteItem";
import { QuoteRushDelayPumpInfo } from "./QuoteRushDelayPumpInfo";

export default class QuoteKit {
  id: number;
  quoteId: number;
  pumpId: number;
  nol: boolean;
  model: string;
  tag: string;
  service: string;
  note: string;
  qty: number;
  netPrice: number;
  glycol: number;
  markup: number;
  flow: number;
  head: number;
  odp: boolean;
  pumpType: string;
  submersible: boolean;
  construction: number;
  sConstruction: string;
  diam: number;
  motorType: string;
  motorNote: string;
  manufacturer: string;
  motorManufacturer: string;
  rpm: number;
  phase: string;
  hp: number;
  ansi125: boolean;
  flangePin: boolean;
  flangeType: string;
  simplex: boolean;

  weight: number;
  suct: number;
  disc: number;
  cliCusPrice: number;
  withMotor: boolean;
  barePump: boolean;
  seriesId: number;
  minimumEfficiency: number;

  premiumEfficiency: boolean;
  premiumEfficiencyMotorNotNorthAmerican: boolean;

  pumpTypeImage: string;
  discharge: string;
  suction: string;

  rushProgram : QuoteRushDelayPumpInfo;
  
  options: Array<Option>;
  quoteItems: Array<Item>;

  constructor(data: any) {
    this.id = data.id;
    this.quoteId = data.quoteId;
    this.pumpId = data.pumpId;
    this.nol = data.nol;
    this.model = data.model;
    this.tag = data.tag;
    this.service = data.service;
    this.note = data.note;
    this.qty = data.qty;
    this.netPrice = data.netPrice;
    this.glycol = data.glycol;
    this.markup = data.markup;
    this.flow = data.flow;
    this.head = data.head;
    this.odp = data.odp;
    this.pumpType = data.pumpType;
    this.submersible = data.submersible;
    this.construction = data.construction;
    this.sConstruction = data.sConstruction;
    this.diam = data.diam;
    this.motorNote = data.motorNote;
    this.manufacturer = data.manufacturer;
    this.motorManufacturer = data.motorManufacturer;
    this.rpm = data.rpm;
    this.phase = data.phase;
    this.hp = data.hp;
    this.ansi125 = data.ansi125;
    this.flangePin = data.flangePin;
    this.flangeType = data.flangeType;
    this.simplex = data.simplex;
    this.weight = data.weight;
    this.suct = data.suct;
    this.disc = data.disc;
    this.cliCusPrice = data.cliCusPrice;
    this.withMotor = data.withMotor;
    this.barePump = data.barePump;
    this.motorType = data.motorType;
    this.premiumEfficiency = data.premiumEfficiency ?? false;
    this.seriesId = data.seriesId;
    this.minimumEfficiency = data.minimumEfficiency;
    this.premiumEfficiencyMotorNotNorthAmerican =
      data.premiumEfficiencyMotorNotNorthAmerican ?? false;

    this.pumpTypeImage = data.pumpTypeImage;
    this.suction = data.suction;
    this.discharge = data.discharge;

    this.rushProgram = data.rushProgram;

    this.options =  data.options;
    this.quoteItems = data.quoteItems;


    
  }

  public isPremiumEff(): boolean {
    return this.premiumEfficiency;
  }

  public isPremiumEMNNA(): boolean {
    return this.premiumEfficiencyMotorNotNorthAmerican;
  }
}

export class QuoteKitCost {
  netPrice: number = 0;
  qty: number = 0;
  sumOfCost: number = 0;
  discount: number = 0;

  constructor(data: any) {
    this.netPrice = data.netPrice;
    this.qty = data.qty;
    this.sumOfCost = data.sumOfCost;
    this.discount = data.discount;
  }

  public getProfit(): number {
    return ((this.netPrice - this.sumOfCost) / this.netPrice) * 100;
  }
}

export class QuoteKitMultiFunctionValve {
  id: number;
  name: string;
  netPrice: number;
  listPrice: number;
  cliCusPrice: number;
  tablePage?: string;
  drawingPage?: string;

  items: Array<QuoteMultiFunctionValveItem>;

  constructor(data: any, items: Array<QuoteMultiFunctionValveItem>) {
    this.id = data.id;
    this.name = data.name;
    this.netPrice = data.netPrice;
    this.listPrice = data.listPrice;
    this.cliCusPrice = data.cliCusPrice;
    this.tablePage = data.tablePage;
    this.drawingPage = data.drawingPage;
    this.items = items;
  }
}

export class QuoteMultiFunctionValveItem {
  id: number;
  quoteMultiFunctionValeId: number;
  it_code: number;
  it_ocode: string;
  itemDescription: string;
  it_cost: number;
  qty: number;

  constructor(data: any) {
    this.id = data.id;
    this.quoteMultiFunctionValeId = data.quoteMultiFunctionValeId;
    this.it_code = data.it_code;
    this.it_ocode = data.it_ocode;
    this.itemDescription = data.itemDescription;
    this.it_cost = data.it_cost ?? 0;
    this.qty = data.qty ?? 0;
  }
}
