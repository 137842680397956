type Props = {
  id: string;
  text: string | number;
  title: string;
  size?: string;
};

export default function TextFloatingBox(props: Props) {
  const { id, text, title, size } = props;
  const pClass = `form-control ${size && `form-control-${size}`} text-center`;
  return (
    <div className="form-floating text-floating-box">
      <p className={pClass} id={id}>
        {text}
      </p>
      <label className="form-floating-label" htmlFor={id}>
        {title}
      </label>
    </div>
  );
}
