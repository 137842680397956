import "./production-data-page.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import statsService from "../../services/Stats/stats-service";
import { StatisticCardList } from "../../components/Cards/statistic-cards";
import LineChartComponent from "../../components/Charts/LineChartComponent";
import StatisticContent, { Serie } from "../../services/Stats/StatisticContent";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
import stringHelper from "../../services/Core/Helpers/string-helper";
import NavTabsComponent from "../../components/nav-tabs-component";
import {
  useNumberOfProdJobs,
  useProdTypeTotal,
  useProductionDelays,
  useTodaysProduction,
} from "../../hooks/Production/production-statistics";
import LoadingComponent from "../../components/Core/Loading";
import { toast } from "react-toastify";

type IParamTypes = {
  employeeId: string;
};

export default function ProductionDataPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams<IParamTypes>();

  const [jobsStat, setJobStat] = useState<StatisticContent>();
  const [jobsStatIndex, setJobStatIndex] = useState<number>(7);

  const todayProduction = useTodaysProduction(employeeId);
  const nbOfProdJobs = useNumberOfProdJobs(employeeId);
  const prodTypeTotal = useProdTypeTotal(employeeId);
  const prodDelays = useProductionDelays(employeeId);

  useEffect(() => {
    loadJobsStatistic();
  }, [jobsStatIndex]);

  if (
    todayProduction.loading ||
    nbOfProdJobs.loading ||
    prodTypeTotal.loading
  ) {
    return <LoadingComponent />;
  }

  return (
    <>
      <BreadcrumbComponent
        items={[{ text: t("production-data-page.title"), active: true }]}
      />
      <div className="dashboard-header">
        <div className="header-item">
          <a
            className="btn btn-outline-primary"
            onClick={() => navigate(`/Projects/${employeeId}`)}
          >
            {t("production-data-page.manage-projects")}
          </a>
        </div>
      </div>
      {prodTypeTotal.data && (
        <div className="mb-2 card" style={{ backgroundColor: "#F8F8FF" }}>
          <div className="card-header">
            <h3>Productions</h3>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              {prodTypeTotal.data.series.map((serie, i) => {
                return (
                  <StatisticCardList
                    key={i}
                    title={t(`job-types.${serie.title}`)}
                    listItems={getProdTypeListItem(serie)}
                  ></StatisticCardList>
                );
              })}
            </div>

            <hr />

            <div className="d-flex justify-content-between">
              {prodDelays && prodDelays.data && (
                <StatisticCardList
                  title={t(`production-data-page.${prodDelays.data.title}`)}
                  listItems={getProdDelayListItem(prodDelays.data)}
                ></StatisticCardList>
              )}

              {todayProduction.data && (
                <StatisticCardList
                  title={t(
                    `production-data-page.${todayProduction.data.title}`
                  )}
                  listItems={getProdListItem(todayProduction.data)}
                ></StatisticCardList>
              )}

              {nbOfProdJobs.data && (
                <div className="mb-1">
                  <StatisticCardList
                    title={t(
                      `production-data-page.${nbOfProdJobs.data.title}`
                    )}
                    listItems={getProdListItem(nbOfProdJobs.data)}
                  ></StatisticCardList>
                </div>
              )}
            </div>
          </div>

          <div className="mb-1">
            {jobsStat && (
              <>
                <NavTabsComponent
                  active={jobsStatIndex}
                  navItems={[
                    { key: 7, text: "Week", onclick: () => setJobStatIndex(7) },
                    {
                      key: 30,
                      text: "Month",
                      onclick: () => setJobStatIndex(30),
                    },
                    {
                      key: 365,
                      text: "Year",
                      onclick: () => setJobStatIndex(365),
                    },
                  ]}
                />
                <LineChartComponent
                  height="25em"
                  content={jobsStat}
                ></LineChartComponent>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );

  function loadJobsStatistic() {
    if (employeeId) {
      let now = new Date(Date.now());
      let end = stringHelper.toDateString(now);
      let start = stringHelper.toDateString(
        new Date(Date.now() - jobsStatIndex * 86400000)
      );
      statsService
        .getJobsStatistic(employeeId, start, end)
        .then((data) => {
          if (data) {
            setJobStat(data);
          }
        })
        .catch((error) => {
          toast.error("Error while fetching data for 'Production Jobs'");
        });
    }
  }

  function getProdTypeListItem(serie: Serie) {
    let temp = serie.serieItems.map((item) => {
      if (item.name !== "") {
        return {
          title: t(`job-types.${item.name}`),
          description: item.value,
        };
      }
    });
    temp.unshift({ title: "Total", description: serie.total });
    return temp;
  }

  function getProdListItem(data: StatisticContent) {
    return data.series.map((serie) => {
      return {
        title: t(`stats.${serie.title}`),
        description: serie.total,
      };
    });
  }

  function getProdDelayListItem(data: StatisticContent) {
    return data.series.map((serie) => {
      return {
        title: t(`job-types.${serie.title}`),
        description: `${serie.total.toLocaleString("fr-CA")} ${t(
          "common.hours"
        )}`,
      };
    });
  }
}
