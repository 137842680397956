import { useMemo } from "react";
import { Chart } from "react-google-charts";
import StatisticContent from "../../services/Stats/StatisticContent";
import numberHelper from "../../services/Core/Helpers/number-helper";

type Props = {
  content: StatisticContent;
  height?: string;
  width?: string;
};

export default function LineChartComponent(props: Props) {
  let colors:Array<string> = [];
  const data = useMemo(() => {

    let temp: any = [["x"]];
    props.content.series[0]?.serieItems.map((serie) => {
      if (serie) {
        temp.push([serie.name]);
      }
    });
    props.content.series.map((content) => {

      if (content) {
        if(content.color)
        colors.push(content.color);
        temp[0].push(
          `${content.title} (${numberHelper.toNumberFormat(content.total)})`
        );
        content.serieItems.map((serie, index) => {
          if (serie && temp[index + 1]) {
            temp[index + 1].push(serie.value);
          }
        });
      }
    });

    return temp;
  }, [props.content]);

  let options = {
    title: props.content.title,
    hAxis: {
      title: props.content.xAxisTitle,
    },
    vAxis: {
      title: props.content.yAxisTitle,
    },
    legend: { position: "bottom" }
  };
  if(colors && colors.length)
  {
    options = {...options,colors: colors}as any
  }
  return (
    <Chart
      chartType="LineChart"
      width={props.width}
      height={props.height}
      data={data}
      options={options}

    />
  );
}
