import { useTranslation } from "react-i18next";

type Props = {
  type: string;
};

export default function KitTypeBadgeComponent(props: Props) {
  const { t } = useTranslation();
  const { type } = props;
  const getClass = () => {
    if (type === "pump") {
      return "bg-success";
    } else if (type === "package") {
      return "bg-primary";
    } else if (type === "miskit") {
      return "bg-secondary";
    }
    return "bg-dark";
  };

  const className = getClass();

  return (
    <span className={`badge ${className}`}>
      {t(`invoices.kit-types.${type}`)}
    </span>
  );
}
