import { useState, useEffect, useRef, Fragment } from "react";
import { CSVLink } from "react-csv";
import axios from "axios";

interface HeaderType {
  label: string;
  key: string;
}
interface CsvExportProps {
  url: string;
  children?: Node;
  filename?: string;
  disable?: boolean;
  headers?: HeaderType[];
  label?: React.ReactNode;
  transform?: (data: any[]) => any[];
}

type Props = CsvExportProps;

const CsvExport = ({
  url,
  disable,
  label,
  filename,
  headers,
  transform,
}: Props) => {
  const [csvData, setCsvData]: any[] = useState([]);
  const csvInstance = useRef<any | null>(null);

  const asyncExportMethod = () =>
    axios(url, {
      method: "GET",
    })
      .then((response) => {
        // console.log(response);
        let data = response.data;
        if (transform) {
          data = transform(response.data);
        }
        setCsvData(data);
      })
      .catch((error) => console.log(error));

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  return (
    <Fragment>
      <div
        className="btn btn-sm btn-outline-primary"
        onClick={() => {
          if (disable) return;
          asyncExportMethod();
        }}
      >
        {label}
      </div>
      {csvData.length > 0 ? (
        <CSVLink
          data={csvData}
          headers={headers || Object.keys(csvData[0])}
          filename={filename || "export.csv"}
          ref={csvInstance}
        />
      ) : undefined}
    </Fragment>
  );
};

export default CsvExport;
