import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuoteHeatExchanger } from "../../../services/HeatExchangers/Models/QuoteHeatExchanger";
import { useEffect, useMemo, useState } from "react";
import { useQuoteHeatExchangerModel } from "../../../services/HeatExchangers/quote_HeatExchanger_hooks";
import { BoosterSystem } from "../../../services/Systems/BoosterSystem/BoosterSystem";
import QuoteKit, {
  QuoteKitCost,
} from "../../../services/Quotes/Models/QuoteKit";
import quoteService from "../../../services/Quotes/QuoteService";
import {
  faImage,
  faMinus,
  faPlus,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  CollapseButtonComponent,
  CollapseComponent,
  ICollapse,
  useCollapse,
} from "../../../components/CollapseComponent";
import stringHelper, {
  Hyphens,
} from "../../../services/Core/Helpers/string-helper";
import numberHelper from "../../../services/Core/Helpers/number-helper";
import mathUtils from "../../../services/Core/Helpers/math-outils";
import { toast } from "react-toastify";
import {
  ClosePageButton,
  EditPageButton,
  Page,
  PageDetails,
  PageFooter,
  PageHeader,
  PageInfo,
  PageTitle,
} from "../../../components/Pages/page-components";
import {
  MeasurementUnit,
  ProductCostComponent,
  ProductInfoContainer,
  ProductInfo_Description,
  ProductInfo_Name,
  ProductInfo_Subtitle,
  ProductInfo_Title,
  ProductInfo_Value,
  ProductInfo_Value_Editable,
} from "../../../components/Pages/product-info-component";
import { useTranslation } from "react-i18next";
import {
  Item,
  Option,
  ItemObjectTypes,
} from "../../../services/Core/Items/Item";
import {
  ItemDetails,
  QuoteItem,
} from "../../../services/Quotes/Models/QuoteItem";
import { BoosterSystemPump } from "../../../services/Systems/BoosterSystem/BoosterSystemPump";
import itemExtensions from "../../../services/Core/Items/ItemExtensions";
import pumpExtensions from "../../../services/Pumps/PumpExtensions";
import { QuoteSingleItem } from "../../../services/SingleItem/Models/QuoteSingleItem";
import ConfiramtionPopup from "../../../components/Core/Popup-confirmation-Component";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
import PumpSearchPump from "../../../services/Pumps/Models/PumpSearchPump";
import "./quote-page-component.scss";
import QuoteSummaryTable from "../../../services/Quotes/Models/QuoteSummaryTable";
import QuoteSumamryTable from "../../../services/Quotes/Models/QuoteSummaryTable";
import singleItemService from "../../../services/SingleItem/SingleItemService";
import { FormLabel } from "react-bootstrap";
import { ActivityManagerComponent } from "../../../components/activities/ActivitiesManagerComponent";
import { ActivityObjectType } from "../../../services/Activities/Activity";

type HeatExchangerProps = {
  userId?: string;
  heatExchanger: QuoteHeatExchanger;
  pageNumber: number;
  editing: boolean;
};

export function HeatExchangerComponent(props: HeatExchangerProps) {
  const { t } = useTranslation();

  const { userId, pageNumber, editing } = props;
  const [heatExchanger, setHeatExchanger] = useState<QuoteHeatExchanger>();
  const [items, setItems] = useState<Array<Item>>();

  const collapse = useCollapse(false);
  const itemsCollapse = useCollapse(true);

  const { quoteHEModel } = useQuoteHeatExchangerModel(
    userId,
    heatExchanger?.quoteNum,
    heatExchanger?.id
  );

  useEffect(() => {
    if (props.heatExchanger) {
      setHeatExchanger({ ...props.heatExchanger });
    }

    if (userId && props.heatExchanger?.quoteNum) {
      quoteService
        .getAllHEItems(
          userId,
          props.heatExchanger?.quoteNum,
          props.heatExchanger.id
        )
        .then((data) => {
          if (data) {
            setItems(
              data.map((item) => {
                return new Item(item, new ItemDetails(item.itemDetails));
              })
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [props]);

  const imagePath = `${process.env.PUBLIC_URL}/images/Heat_Exchangers/Heat-Exchanger_300x300.png`;
  const pumpIds = `heat_exchanger-${pageNumber}`;

  return (
    <Page>
      <PageHeader>
        <div className="justify-content-start">
          <CollapseButtonComponent collapse={collapse} />
        </div>

        <ProductInfoContainer className="mx-1">
          <ProductInfo_Value>Heat exchanger:</ProductInfo_Value>
          <span id={`${pumpIds}-header-tag`} title={heatExchanger?.tag}>
            {heatExchanger?.tag}
          </span>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-1 ms-1 me-auto txt-break-hide">
          <ProductInfo_Value>Model:</ProductInfo_Value>
          <span id={`${pumpIds}-header-model`} title={quoteHEModel?.model}>
            {quoteHEModel?.model}
          </span>
        </ProductInfoContainer>

        <ClosePageButton title={"Delete"} />
      </PageHeader>

      <CollapseComponent collapsed={collapse.collapsed}>
        <PageInfo>
          <div className="row">
            <div className="col-sm-3 col-12 text-center">
              <img className="info-image mb-2" src={imagePath}></img>
            </div>
            <div className="col-sm-9 col-12">
              <div className="row">
                <div className="mb-2 col-md-6 col-12 txt-break-hide">
                  <span className="product-title">{quoteHEModel?.model}</span>
                </div>
                <div className="mb-2 col-md-3 col-6">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      Quantity:
                    </ProductInfo_Name>
                    <ProductInfo_Value>{heatExchanger?.qty}</ProductInfo_Value>
                  </ProductInfoContainer>
                </div>
                <div className="mb-2 col-md-3 col-6">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      Weight:
                    </ProductInfo_Name>
                    <ProductInfo_Value>
                      {quoteHEModel?.weight}
                      <span className="measurement-unit">lbs</span>
                    </ProductInfo_Value>
                  </ProductInfoContainer>
                </div>
                <div className="mb-2 col-12">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      Description:
                    </ProductInfo_Name>
                    <span id={`${pumpIds}-description`}>
                      {heatExchanger?.description}
                    </span>
                  </ProductInfoContainer>
                </div>
              </div>
            </div>
          </div>
        </PageInfo>

        <PageDetailTitleComponent
          collapse={itemsCollapse}
          title={t("quote-page.details")}
        />

        <CollapseComponent collapsed={itemsCollapse.collapsed}>
          {items && (
            <ProductObjectDetailsComponent objects={items} editing={editing} />
          )}
        </CollapseComponent>

        <PageFooter className="text-center"></PageFooter>
      </CollapseComponent>
    </Page>
  );
}

type SystemBoosterProps = {
  userId?: string;
  pageNumber: number;
  editing: boolean;
  system: BoosterSystem;
};

export function SystemBoosterComponent(props: SystemBoosterProps) {
  const { t } = useTranslation();
  const { userId, editing } = props;

  const [system, setSystem] = useState<BoosterSystem>();
  const [items, setItems] = useState<Array<Item>>();
  const [pumps, setPumps] = useState<Array<any>>();

  const collapse = useCollapse(false);
  const pumpsCollapse = useCollapse(true);
  const itemsCollapse = useCollapse(true);

  useEffect(() => {
    if (userId && props.system) {
      setSystem(props.system);
    }
  }, [userId, props.system]);

  useEffect(() => {
    if (!itemsCollapse.collapsed && !items) {
      loadItems();
    }
  }, [itemsCollapse]);

  useEffect(() => {
    if (!pumpsCollapse.collapsed && !pumps) {
      loadPumps();
    }
  }, [pumpsCollapse]);

  const imagePath = useMemo(() => {
    return `${process.env.PUBLIC_URL}/images/Pumps/Thumbnails/.png`;
  }, [system]);

  const handlePriceChange = (newPrice: number) => {
    //// To Do /////////////////////
  };

  return (
    <Page className="page-border">
      <PageHeader>
        <div className="justify-content-start">
          <CollapseButtonComponent collapse={collapse} />
        </div>

        <ProductInfoContainer className="me-3">
          <ProductInfo_Name>{t("quote-page.system")}:</ProductInfo_Name>
          <ProductInfo_Value title={system?.tag}>
            {system?.tag}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="me-auto">
          <ProductInfo_Name>{t("common.quantity")}:</ProductInfo_Name>
          <ProductInfo_Value title={system?.qty.toString()}>
            {system?.qty}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ClosePageButton title={"Delete"} />
      </PageHeader>
      <CollapseComponent collapsed={collapse.collapsed}>
        <PageInfo>
          <div className="row">
            <div className="col-sm-3 col-12 text-center">
              <img className="info-image mb-2" src={imagePath} />
            </div>
            <div className="col-sm-9 col-12">
              <ProductInfoContainer>
                <ProductInfo_Name>
                  {t("quote-page.description")}:
                </ProductInfo_Name>
                <ProductInfo_Description className="product-description">
                  <div
                    className="bs-description"
                    dangerouslySetInnerHTML={{
                      __html: system?.description ?? "",
                    }}
                  />
                </ProductInfo_Description>
              </ProductInfoContainer>
            </div>
          </div>
        </PageInfo>

        <PageTitle className="page-breakline">
          <h4 className="h4" onClick={pumpsCollapse.click}>
            <CollapseButtonComponent collapse={pumpsCollapse} />
            Pumps
          </h4>
        </PageTitle>
        <CollapseComponent collapsed={pumpsCollapse.collapsed}>
          <PageDetails className="mx-4 bg-lightgray">
            {system?.quoteId &&
              userId &&
              pumps?.map((pump, i) => {
                return (
                  <BoosterSystemPumpComponent
                    key={`bs-pump-${i}`}
                    pump={pump}
                    quoteId={system?.quoteId}
                    userId={userId}
                    editing={editing}
                  />
                );
              })}
          </PageDetails>
        </CollapseComponent>

        <PageTitle className="page-breakline">
          <h4 className="h4" onClick={itemsCollapse.click}>
            <CollapseButtonComponent collapse={itemsCollapse} />
            {t("quote-page.details")}
          </h4>
        </PageTitle>
        <CollapseComponent collapsed={itemsCollapse.collapsed}>
          {items && (
            <ProductObjectDetailsComponent objects={items} editing={editing} />
          )}
        </CollapseComponent>
      </CollapseComponent>

      <PageFooter className="text-center">
        <ProductCostPageSection
          //cost={0}
          profit={0}
          total={0}
          netPrice={0}
          editing={editing}
          onPriceChange={handlePriceChange}
        />
      </PageFooter>
    </Page>
  );

  function loadItems() {
    if (userId && system) {
      quoteService
        .getSystemItems(userId, system.quoteId, system.id)
        .then((data) => {
          if (data) {
            setItems(data);
          }
        })
        .catch();
    }
  }

  function loadPumps() {
    if (userId && system) {
      quoteService
        .getSystemPumps(userId, system.quoteId, system.id)
        .then((data) => {
          if (data) {
            let tempPumps = data.map((pump) => new BoosterSystemPump(pump));
            // console.log("BS pumps :", tempPumps);
            setPumps(tempPumps);
          }
        })
        .catch((error) => console.error(error));
    }
  }
}

type SystemBoosterPumpProps = {
  userId: string;
  quoteId: number;
  pump: BoosterSystemPump;
  editing: boolean;
};
export function BoosterSystemPumpComponent(props: SystemBoosterPumpProps) {
  const { t } = useTranslation();
  const { userId, quoteId, pump, editing } = props;
  const navigate = useNavigate();

  const collapse = useCollapse(true);
  const optionsCollapse = useCollapse(true);
  const itemsCollapse = useCollapse(true);

  const [items, setItems] = useState<Array<Item>>();
  const [options, setOptions] = useState<Array<Option>>();

  useEffect(() => {
    if (!itemsCollapse.collapsed && !items) {
      loadBS_PumpItems();
    }
  }, [itemsCollapse]);

  useEffect(() => {
    if (!optionsCollapse.collapsed && !options) {
      loadBS_PumpOptions();
    }
  }, [optionsCollapse]);

  const imagePath = useMemo(
    () => pumpExtensions.getPumpImagePath(pump.pumpTypeImage),
    [pump]
  );

  const handlePriceChange = (newPrice: number) => {
    //// To DO /////////////////////
  };

  return (
    <Page className="page-border mb-1 mt-1">
      <PageHeader>
        <div className="justify-content-start">
          <CollapseButtonComponent collapse={collapse} />
        </div>

        <ProductInfoContainer className="mx-1">
          <ProductInfo_Name>Pump:</ProductInfo_Name>
          <ProductInfo_Value title={pump.tag}>{pump.tag}</ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-2">
          <ProductInfo_Name>{t("common.quantity")}:</ProductInfo_Name>
          <ProductInfo_Value title={pump.tag}>{pump.qty}</ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-1 me-auto txt-break-hide">
          <ProductInfo_Name>Model:</ProductInfo_Name>
          <ProductInfo_Value title={pump.model}>{pump.model}</ProductInfo_Value>
        </ProductInfoContainer>

        <ClosePageButton title={"Delete"} />
      </PageHeader>

      <CollapseComponent collapsed={collapse.collapsed}>
        <PageInfo>
          <div className="row">
            <div className="col-sm-3 col-12 text-center">
              <img className="info-image mb-2" src={imagePath} />

              <button className="btn btn-sm btn-primary mb-1 w-100">
                {t("quote-page.quotekit-curve-btn")}
              </button>
              <button className="btn btn-sm btn-primary w-100">
                {t("quote-page.quotekit-drawing-btn")}
              </button>
            </div>
            <div className="col-sm-9 col-12">
              <div className="row">
                <div className="mb-2 col-md-6 col-12 txt-break-hide">
                  <ProductInfo_Subtitle className="d-block me-2">
                    {pump.pumpType}
                  </ProductInfo_Subtitle>
                  <ProductInfo_Title>{pump.model}</ProductInfo_Title>
                </div>
                <div className="mb-2 col-md-3 col-6">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      {t("quote-page.quotekit-construction")}:
                    </ProductInfo_Name>
                    <ProductInfo_Value
                      children={pump.sConstruction ?? Hyphens}
                    />
                  </ProductInfoContainer>
                </div>
              </div>
              <div className="row">
                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-flange-type")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.flangeType ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-glycol")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.glycol ?? Hyphens}
                    <MeasurementUnit unit="%" />
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-flow-rate")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.flow ?? Hyphens}
                    <MeasurementUnit unit="U.S. GPM" />
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-head")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.head ?? Hyphens}
                    <MeasurementUnit unit="ft" />
                  </ProductInfo_Value>

                  <ProductInfo_Value className="ms-1">
                    ({mathUtils.feetToMeter(pump.head).toFixed(0)}
                    <MeasurementUnit unit="m" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-impeler-diameter")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.diam ?? Hyphens}
                    <MeasurementUnit unit='"' />
                  </ProductInfo_Value>

                  <ProductInfo_Value className="ms-1">
                    ({mathUtils.inchToMillimeter(pump.diam).toFixed(0)}
                    <MeasurementUnit unit="mm" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-power")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.hp ?? Hyphens}
                    <MeasurementUnit unit="HP" />
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-phases")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>{pump.phase ?? Hyphens}</ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">RPM</ProductInfo_Name>
                  <ProductInfo_Value>{pump.rpm ?? Hyphens}</ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-motor-type")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.motorManufacturer ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-manufacturer")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.manufacturer ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-efficiency")}
                  </ProductInfo_Name>
                  {pump.premiumEfficiency && (
                    <ProductInfo_Value className="d-block">
                      Premium Efficiency
                    </ProductInfo_Value>
                  )}
                  {pump.premiumEfficiencyMotorNotNorthAmerican === true && (
                    <ProductInfo_Value>
                      Premium Efficiency motor not north american
                    </ProductInfo_Value>
                  )}
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("common.weight")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {pump.weight ?? Hyphens}
                    <MeasurementUnit unit="lbs" />
                  </ProductInfo_Value>
                </ProductInfoContainer>
              </div>
            </div>
          </div>
        </PageInfo>

        {pump.hasItems && (
          <>
            <PageTitle className="page-breakline">
              <h5 className="h5" onClick={itemsCollapse.click}>
                <CollapseButtonComponent collapse={itemsCollapse} />
                {t("quote-page.details")}
              </h5>
            </PageTitle>
            <CollapseComponent collapsed={itemsCollapse.collapsed}>
              {items && (
                <ProductObjectDetailsComponent
                  objects={items}
                  editing={editing}
                  onOptionsChange={() => {}}
                />
              )}
            </CollapseComponent>
          </>
        )}

        {pump.hasOptions && (
          <>
            <PageTitle className="page-breakline">
              <h5 className="h5" onClick={optionsCollapse.click}>
                <CollapseButtonComponent collapse={optionsCollapse} />
                {t("quote-page.options")}
              </h5>
            </PageTitle>
            <CollapseComponent collapsed={optionsCollapse.collapsed}>
              {options && (
                <ProductObjectDetailsComponent
                  qty={pump.qty}
                  objects={options}
                  editing={editing}
                  onOptionsChange={() => {}}
                />
              )}
            </CollapseComponent>
          </>
        )}
      </CollapseComponent>

      <PageFooter className="text-center">
        <ProductCostPageSection
          //cost={0}
          profit={0}
          total={0}
          netPrice={pump.netPrice}
          editing={editing}
          onPriceChange={handlePriceChange}
        />
      </PageFooter>
    </Page>
  );

  function loadBS_PumpItems() {
    quoteService
      .getSystemPumpItems(userId, quoteId, pump.bS_ID, pump.id)
      .then((data) => {
        if (data) {
          setItems(data.map((i) => itemExtensions.generateItem(i)));
        }
      })
      .catch((error) => console.log(error));
  }

  function loadBS_PumpOptions() {
    quoteService
      .getSystemPumpOptions(userId, quoteId, pump.bS_ID, pump.id)
      .then((data) => {
        if (data) {
          setOptions(data.map((i) => itemExtensions.generateOption(i)));
        }
      })
      .catch((error) => console.log(error));
  }
}
// **** QuoteKit Component ****
type QuoteKitComponentProps = {
  userId?: string;
  quoteId?: string;
  quoteKit: QuoteKit;
  pageNumber: number;
  editing: boolean;
  isSelected: boolean;
  onSelect: (kit: QuoteKit) => void;
  onUpdate: (kit: QuoteKit) => void;
  onDelete: (id: number) => void;
};

const pumpInfoClass = "mb-2 col-xl-2 col-sm-3 col-6";
export function QuoteKitComponent(props: QuoteKitComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, quoteId, quoteKit, editing } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const collapse = useCollapse(false);
  const notesCollapse = useCollapse(true);
  const itemsCollapse = useCollapse(true);
  const optionsCollapse = useCollapse(true);

  const [updatedQuoteKit, setUpdatedQuoteKit] = useState<QuoteKit>(quoteKit);
  const [quoteKitCost, setQuoteKitCost] = useState<QuoteKitCost>();
  const [quoteKitTotalNetPrice, setQuoteKitTotalNetPrice] = useState<number>(0);
  const [items, setItems] = useState<Item[]>();
  const [options, setOptions] = useState<Option[]>();

  useEffect(() => {
    if (userId && quoteKit.id && quoteKit.quoteId) {
      quoteService
        .getAllQuoteKitCost(userId, quoteKit.quoteId, quoteKit.id)
        .then((data) => {
          setQuoteKitCost(data);
        })
        .catch(() => {
          toast.error(
            `'${quoteKit.tag}' : ${t("quote-page.error-get-quotekit-cost")}`
          );
        });
      quoteService
        .getQuoteKitAndOptionsNetPrice(userId, quoteKit.quoteId, quoteKit.id)
        .then((res) => {
          if (res) setQuoteKitTotalNetPrice(res);
        });
    }
  }, [userId, quoteKit]);

  useEffect(() => {
    if (!items && itemsCollapse.collapsed === false) {
      loadQuoteItems();
    }
  }, [itemsCollapse]);

  useEffect(() => {
    if (!options && optionsCollapse.collapsed === false) {
      loadQuoteOptions();
    }
  }, [optionsCollapse]);

  const imagePath = useMemo(
    () => pumpExtensions.getPumpImagePath(quoteKit.pumpTypeImage),
    [quoteKit]
  );

  const loadQuoteItems = () => {
    if (userId && quoteKit.id && quoteKit.quoteId) {
      quoteService
        .getAllQKitQuoteItems(userId, quoteKit.quoteId, quoteKit.id)
        .then((data) => {
          // console.log(`QuoteKit : ${quoteKit.tag}`, "options", data);
          if (data) {
            setItems(data);
          }
        })
        .catch((error) => {
          toast.error(t("quote-page.error-get-items"), error);
        });
    }
  };

  const loadQuoteOptions = () => {
    if (userId && quoteKit.id && quoteKit.quoteId) {
      quoteService
        .getQuoteKitOptions(userId, quoteKit.quoteId, quoteKit.id)
        .then((data) => {
          // console.log(`QuoteKit : ${quoteKit.tag}`, "options", data);
          if (data) {
            setOptions(data);
          }
        })
        .catch((error) => {
          toast.error(t("quote-page.error-get-options"), error);
        });
    }
  };
  const handelShowCurve = () => {
    navigate(`/Pumps/Pump-Curve`, { state: { data: quoteKit } });
  };
  const handelDrawing = () => {};
  const handleQuoteKitChange = (changes: {
    note?: string;
    motorNote?: string;
    newPrice?: number;
    newOptions?: Array<Option>;
    newQuoteItems?: Array<Item>;
  }) => {
    setUpdatedQuoteKit((prevKit: QuoteKit) => {
      const updatedKit: QuoteKit = {
        ...prevKit,
        note: changes.note !== undefined ? changes.note : prevKit.note,
        motorNote: changes.motorNote !== undefined ? changes.motorNote : prevKit.motorNote,
        netPrice:
          changes.newPrice !== undefined ? changes.newPrice : prevKit.netPrice,
        options: changes.newOptions
          ? [...changes.newOptions]
          : [...prevKit.options],
        quoteItems: changes.newQuoteItems
          ? [...changes.newQuoteItems]
          : [...prevKit.quoteItems],
        isPremiumEff: prevKit.isPremiumEff,
        isPremiumEMNNA: prevKit.isPremiumEMNNA,
      };

      props.onUpdate(updatedKit);
      return updatedKit;
    });
  };
  const handleSelectChange = () => {
    props.onSelect(quoteKit);
  };

  const onConfirmClick = () => {
    if (userId && quoteId && quoteKit.id) {
      quoteService
        .deleteQuoteKit(userId, quoteId, quoteKit.id)
        .then((res) => {
          props.onDelete(quoteKit.id);
          setShowConfirmation(false);

          // sessionStorage.setItem("activeTabIndex", "1");
          // setShowConfirmation(false);
          // window.location.reload();
        })
        .catch((error) => {
          toast.error(t("quote-page.error-delete-quotekit"), error);
        });
    }
  };

  return (
    <Page className="page-border">
      <PageHeader>
        <div className="justify-content-start">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={handleSelectChange}
          />
          <CollapseButtonComponent collapse={collapse} />
        </div>

        <ProductInfoContainer className="mx-1">
          <ProductInfo_Name>{t("quote-page.pump")}:</ProductInfo_Name>
          <ProductInfo_Value title={quoteKit.tag}>
            {quoteKit.tag}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-2">
          <ProductInfo_Name>{t("common.quantity")}:</ProductInfo_Name>
          <ProductInfo_Value title={"qty"}>{quoteKit.qty}</ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-1 me-auto txt-break-hide">
          <ProductInfo_Name>{t("quote-page.model")}:</ProductInfo_Name>
          <ProductInfo_Value title={quoteKit.model}>
            {quoteKit.model}
          </ProductInfo_Value>
        </ProductInfoContainer>
        <EditPageButton
          title={t("common.edit") || "Edit"}
          onClick={() =>
            navigate(
              `/Pumps/Edit-Quote-Pump/${userId}/${quoteId}/${quoteKit.id}`
            )
          }
        />
        <ClosePageButton
          title={t("common.delete") || "Delete"}
          onClick={() => setShowConfirmation(true)}
        />
        {showConfirmation && (
          <ConfiramtionPopup
            show={showConfirmation}
            title={t("quote-page.delete-quoteKit")}
            message={t("quote-page.warn-delete-quoteKit-message")}
            onDelete={onConfirmClick}
            onClose={() => setShowConfirmation(false)}
          ></ConfiramtionPopup>
        )}
      </PageHeader>

      <CollapseComponent collapsed={collapse.collapsed}>
        <PageInfo>
          <div className="row">
            <div className="col-sm-3 col-12 text-center">
              {imagePath ? (
                <img className="info-image mb-2" src={imagePath} />
              ) : (
                <FontAwesomeIcon className="mb-2" size="6x" icon={faImage} />
              )}
              <button
                className="btn btn-sm btn-primary mb-1 w-100"
                onClick={handelShowCurve}
              >
                {t("quote-page.quotekit-curve-btn")}
              </button>
              <button
                className="btn btn-sm btn-primary w-100"
                onClick={handelDrawing}
              >
                {t("quote-page.quotekit-drawing-btn")}
              </button>
            </div>
            <div className="col-sm-9 col-12">
              <div className="row">
                <div className="mb-2 col-md-6 col-12 txt-break-hide">
                  <a
                    href={`/Quotes/${userId}/${quoteId}/QuoteKit-Rush-Program`}
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    Rush Program
                  </a>
                  <span style={{ color: "red", marginLeft: "10px" }}>
                    {quoteKit.rushProgram
                      ? quoteKit.rushProgram.nbDays + " working days"
                      : ""}{" "}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-md-6 col-12 txt-break-hide">
                  <ProductInfo_Subtitle className="d-block me-2">
                    {quoteKit.pumpType}
                  </ProductInfo_Subtitle>
                  <ProductInfo_Title>{quoteKit.model}</ProductInfo_Title>
                </div>
                <div className="mb-2 col-md-2 col-4">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      {t("quote-page.quotekit-construction")}:
                    </ProductInfo_Name>
                    <ProductInfo_Value children={quoteKit?.sConstruction} />
                  </ProductInfoContainer>
                </div>
                <div className="mb-2 col-md-2 col-6">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      {t("quote-page.quotekit-manufacturer")}
                    </ProductInfo_Name>
                    <ProductInfo_Value>
                      {quoteKit.manufacturer ?? Hyphens}
                    </ProductInfo_Value>
                  </ProductInfoContainer>
                </div>
              </div>
              <div className="row">
                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-flange-type")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.flangeType ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-glycol")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.glycol ?? Hyphens}
                    <MeasurementUnit unit="%" />
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-flow-rate")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.flow ?? Hyphens}
                    <MeasurementUnit unit="U.S. GPM" />
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-head")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.head ?? Hyphens}
                    <MeasurementUnit unit="ft" />
                  </ProductInfo_Value>

                  <ProductInfo_Value className="ms-1">
                    ({mathUtils.feetToMeter(quoteKit.head).toFixed(0)}
                    <MeasurementUnit unit="m" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-impeler-diameter")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.diam ?? Hyphens}
                    <MeasurementUnit unit='"' />
                  </ProductInfo_Value>

                  <ProductInfo_Value className="ms-1">
                    ({mathUtils.inchToMillimeter(quoteKit.diam).toFixed(0)}
                    <MeasurementUnit unit="mm" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-power")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.hp ?? Hyphens}
                    <MeasurementUnit unit="HP" />
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-phases")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.phase ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">RPM</ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.rpm ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-motor-type")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.motorType ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-motor-manufacturer")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.motorManufacturer ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-efficiency")}
                  </ProductInfo_Name>
                  {quoteKit.premiumEfficiency && (
                    <ProductInfo_Value className="d-block">
                      Premium Efficiency
                    </ProductInfo_Value>
                  )}
                  {quoteKit.isPremiumEMNNA() === true && (
                    <ProductInfo_Value>
                      {quoteKit.minimumEfficiency}
                    </ProductInfo_Value>
                  )}
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("common.weight")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.weight ?? Hyphens}
                    <MeasurementUnit unit="lbs" />
                  </ProductInfo_Value>
                  <ProductInfo_Value className="ms-1">
                    ({mathUtils.poundsToKilo(quoteKit.weight).toFixed(0)}
                    <MeasurementUnit unit="kg" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-suction")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.suct ?? quoteKit.suction ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("quote-page.quotekit-discharge")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteKit.disc ?? quoteKit.discharge ?? Hyphens}
                  </ProductInfo_Value>
                </ProductInfoContainer>
              </div>
            </div>
          </div>
        </PageInfo>
        {/* pump price */}
        <PageFooter className="text-center ">
          <ProductCostPageSection
            profit={quoteKitCost?.getProfit()}
            total={quoteKitCost?.sumOfCost ?? 0}
            netPrice={quoteKit.netPrice}
            editing={editing}
            onPriceChange={(newPrice) => handleQuoteKitChange({ newPrice })}
          />
        </PageFooter>
        <div style={{ borderTop: "1px solid lightgray", margin: "10px" }}></div>

        <PageTitle className="page-breakline">
          <h4 className="h4" onClick={notesCollapse.click}>
            <CollapseButtonComponent collapse={notesCollapse} />
            {t("quote-page.notes")}
          </h4>
        </PageTitle>
        {/* Note */}
        <CollapseComponent collapsed={notesCollapse.collapsed}>
        <div className="note-container">
         <span className="note-header">Pump Note: </span>
          <ProductNotesComponent
            note={quoteKit.note}
            editing={editing}
            onChange={(updatedNote) =>
              handleQuoteKitChange({ note: updatedNote })
            }
          />
          <span className="note-header">Motor Note: </span>
           <ProductNotesComponent
            note={quoteKit.motorNote}
            editing={editing}
            onChange={(updatedNote) =>
              handleQuoteKitChange({ motorNote: updatedNote })
            }
          />
          </div>
        </CollapseComponent>
       
        <PageTitle className="page-breakline">
          <h4 className="h4" onClick={itemsCollapse.click}>
            <CollapseButtonComponent collapse={itemsCollapse} />
            {t("quote-page.details")}
          </h4>
        </PageTitle>

        <CollapseComponent collapsed={itemsCollapse.collapsed}>
          {items && (
            <ProductObjectDetailsComponent
              objects={items}
              editing={editing}
              onItemsChange={(updatedItems) =>
                handleQuoteKitChange({ newQuoteItems: updatedItems })
              }
            />
          )}
        </CollapseComponent>

        <PageTitle className="page-breakline">
          <h4 className="h4" onClick={optionsCollapse.click}>
            <CollapseButtonComponent collapse={optionsCollapse} />
            {t("quote-page.options")}
          </h4>
        </PageTitle>
        <CollapseComponent collapsed={optionsCollapse.collapsed}>
          {options && (
            <ProductObjectDetailsComponent
              qty={quoteKit.qty}
              objects={options}
              quoteKitId={quoteKit.id}
              userId={userId}
              quoteId={quoteId}
              editing={editing}
              onOptionsChange={(updatedOptions) =>
                handleQuoteKitChange({ newOptions: updatedOptions })
              }
            />
          )}
        </CollapseComponent>
      </CollapseComponent>
      {/* sum of pump and options price */}
      <PageFooter className="text-center">
        <ProductCostPageSection
          netPrice={quoteKitTotalNetPrice}
          editing={false}
          onPriceChange={(newPrice) => handleQuoteKitChange({ newPrice })}
        />
      </PageFooter>
    </Page>
  );
}
// **** End QuoteKit Component ****

// **** QuoteSingleItem Component ****
type QuoteSingleItemComponentProps = {
  userId?: string;
  quoteId?: string;
  quoteSingleItem: QuoteSingleItem;
  pageNumber: number;
  editing: boolean;
  isSelected: boolean;
  onSelect: (item: QuoteSingleItem) => void;
  onUpdate: (item: QuoteSingleItem) => void;
  onDelete: (id: number) => void;
};

export function QuoteSingleItemComponent(props: QuoteSingleItemComponentProps) {
  const { t } = useTranslation();
  const collapse = useCollapse(false);
  const itemsCollapse = useCollapse(true);

  const { userId, quoteId, quoteSingleItem, editing } = props;
  const [updatedQuoteSingleItem, setUpdatedQuoteSingleItem] =
    useState(quoteSingleItem);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [item, setItem] = useState<Item>();

  useEffect(() => {
    if (!item && itemsCollapse.collapsed === false) {
      loadQuoteSingleItem();
    }
  }, [itemsCollapse, item]);

  const loadQuoteSingleItem = () => {
    if (userId && quoteSingleItem.id) {
      singleItemService
        .getQuoteSingleItem(quoteSingleItem.id)
        .then((data) => {
          if (data) {
            setItem(data);
          }
        })
        .catch((error) => {
          toast.error(t("quote-page.error-get-items"), error);
        });
    }
  };

  const onConfirmClick = () => {
    if (userId && quoteId && quoteSingleItem.id) {
      singleItemService
        .deleteQuoteSingleItem(userId, quoteId, quoteSingleItem.id)
        .then((res) => {
          props.onDelete(quoteSingleItem.id);
          setShowConfirmation(false);

          // sessionStorage.setItem("activeTabIndex", "1");
          // setShowConfirmation(false);
          // window.location.reload();
        })
        .catch((error) => {
          toast.error(t("quote-page.error-delete-singleItem"), error);
        });
    }
  };

  const handleSelectChange = () => {
    props.onSelect(quoteSingleItem);
  };

  const handlePriceChange = (newPrice: number) => {
    setUpdatedQuoteSingleItem((prevItem) => {
      const updatedItem = new QuoteSingleItem(
        {
          ...prevItem,
          netPrice: newPrice,
        },
        prevItem.itemDetails,
        prevItem.files
      );
      props.onUpdate(updatedItem);
      return updatedItem;
    });
  };

  return (
    <Page className="page-border">
      <PageHeader>
        <div className="justify-content-start">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={handleSelectChange}
          />
          <CollapseButtonComponent collapse={collapse} />
        </div>

        <ProductInfoContainer className="mx-1">
          <ProductInfo_Name>Item:</ProductInfo_Name>
          <ProductInfo_Value title={quoteSingleItem.itemDescription}>
            {quoteSingleItem.itemDescription.substring(0, 80)}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-2">
          <ProductInfo_Name>{t("common.quantity")}:</ProductInfo_Name>
          <ProductInfo_Value title={quoteSingleItem.itemDescription}>
            {quoteSingleItem.qty}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-1 me-auto txt-break-hide">
          <ProductInfo_Name>Code:</ProductInfo_Name>
          <ProductInfo_Value title={quoteSingleItem.it_ocode}>
            {quoteSingleItem.it_ocode}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ClosePageButton
          title={"Delete"}
          onClick={() => setShowConfirmation(true)}
        />
        {showConfirmation && (
          <ConfiramtionPopup
            show={showConfirmation}
            title={t("quote-page.delete-item") || "Delete Item"}
            message={t("quote-page.warn-delete-item-message")}
            onDelete={onConfirmClick}
            onClose={() => setShowConfirmation(false)}
          ></ConfiramtionPopup>
        )}
      </PageHeader>

      <CollapseComponent collapsed={collapse.collapsed}>
        <PageInfo>
          <div className="row">
            <div className="col-sm-3 col-12 text-center">
              {/* {imagePath ? (
                <img className="info-image mb-2" src={imagePath} />
              ) : (
                <FontAwesomeIcon className="mb-2" size="6x" icon={faImage} />
              )} */}
              <FontAwesomeIcon className="mb-2" size="6x" icon={faImage} />
            </div>
            <div className="col-sm-9 col-12">
              <div className="row">
                <div className="mb-2 col-md-6 col-12 txt-break-hide">
                  <ProductInfo_Title>
                    {" "}
                    {quoteSingleItem.itemDescription}
                  </ProductInfo_Title>
                </div>
                <div className="mb-2 col-md-3 col-6">
                  <ProductInfoContainer>
                    <ProductInfo_Name className="d-block">
                      Code:
                    </ProductInfo_Name>
                    <ProductInfo_Value children={quoteSingleItem.it_ocode} />
                  </ProductInfoContainer>
                </div>
              </div>
              <div className="row">
                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("common.weight")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteSingleItem.it_weight ?? Hyphens}
                    <MeasurementUnit unit="lbs" />
                  </ProductInfo_Value>
                  <ProductInfo_Value className="ms-1">
                    (
                    {mathUtils
                      .poundsToKilo(quoteSingleItem.it_weight)
                      .toFixed(0)}
                    <MeasurementUnit unit="kg" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>

                <ProductInfoContainer className={pumpInfoClass}>
                  <ProductInfo_Name className="d-block">
                    {t("common.total-weight")}
                  </ProductInfo_Name>
                  <ProductInfo_Value>
                    {quoteSingleItem.it_weight
                      ? quoteSingleItem.it_weight * quoteSingleItem.qty
                      : Hyphens}
                    <MeasurementUnit unit="lbs" />
                  </ProductInfo_Value>
                  <ProductInfo_Value className="ms-1">
                    (
                    {mathUtils
                      .poundsToKilo(
                        quoteSingleItem.it_weight * quoteSingleItem.qty
                      )
                      .toFixed(0)}
                    <MeasurementUnit unit="kg" />)
                  </ProductInfo_Value>
                </ProductInfoContainer>
              </div>
            </div>
          </div>
        </PageInfo>
        {quoteSingleItem.note &&
        <div className="item-note-container">
        <ProductNotesComponent
            note={quoteSingleItem.note}
            editing={false}
          />
          </div>
         }
        <PageTitle className="page-breakline">
          <h4 className="h4" onClick={itemsCollapse.click}>
            <CollapseButtonComponent collapse={itemsCollapse} />
            {t("quote-page.details")}
          </h4>
        </PageTitle>
        <CollapseComponent collapsed={itemsCollapse.collapsed}>
          {item && (
            <ProductObjectDetailsComponent
              objects={item}
              quoteId={quoteId}
              userId={userId}
              editing={editing}
              onOptionsChange={() => {}}
            />
          )}
        </CollapseComponent>
      </CollapseComponent>

      <PageFooter className="text-center">
        <ProductCostPageSection
          // cost={quoteSingleItem.it_cost}
          qty={quoteSingleItem.qty}
          singleItemTotal={quoteSingleItem.netPrice * quoteSingleItem.qty}
          profit={quoteSingleItem.getProfit()}
          total={quoteSingleItem.it_cost}
          netPrice={quoteSingleItem.netPrice}
          editing={editing}
          onPriceChange={handlePriceChange}
        />
      </PageFooter>
    </Page>
  );
}
// **** End QuoteSingleItem Component ****

type PageDetailTitleProps = {
  collapse: ICollapse;
  title?: React.ReactNode;
};
function PageDetailTitleComponent(props: PageDetailTitleProps) {
  return (
    <div className="page-title page-breakline">
      <h4 className="h4" onClick={props.collapse.click}>
        <CollapseButtonComponent collapse={props.collapse} />
        {props.title}
      </h4>
    </div>
  );
}

type ProductObjectDetailsProps = {
  objects: Array<Option> | Array<Item> | Item;
  qty?: number;
  quoteId?: string;
  userId?: string;
  quoteKitId?: number;
  editing: boolean;
  onOptionsChange?: (options: Option[]) => void;
  onItemsChange?: (items: Item[]) => void;
};
function ProductObjectDetailsComponent(props: ProductObjectDetailsProps) {
  const { t } = useTranslation();
  const { objects, qty, userId, quoteId, quoteKitId, editing } = props;
  const [objs, setObj] = useState(objects);

  useEffect(() => {
    // Summary should be updated!
  }, [objs]);

  function handleOptionPriceChange(updatedOption: Option, obj: Option) {
    if (Array.isArray(objects) && objects.length > 0 && "id" in objects[0]) {
      const updatedOptions = (objects as Option[]).map((option: Option) =>
        option.id === updatedOption.id ? updatedOption : option
      );
      if (props.onOptionsChange) props.onOptionsChange(updatedOptions);
    } else {
      console.error(t("quote-page.error-invalid-option-type"));
    }
  }

  function handleDeleteOption(deletedOptionId: number) {
    if (Array.isArray(objs) && objs.length > 0 && "id" in objs[0]) {
      // Filter out the deleted option
      const updatedOptions = (objs as Option[]).filter(
        (option: Option) => option.id !== deletedOptionId
      );
      setObj(updatedOptions);
    } else {
      console.error(t("quote-page.error-invalid-option-type"));
    }
  }

  function handleItemQtyChange(updatedItem: Item, obj: Item) {
    if (Array.isArray(objects) && objects.length > 0 && "id" in objects[0]) {
      const updatedItems = (objects as Item[]).map((item: Item) =>
        item.id === updatedItem.id ? updatedItem : item
      );
      if (props.onItemsChange) props.onItemsChange(updatedItems);
    } else {
      console.error(t("quote-page.error-invalid-option-type"));
    }
  }

  return (
    <PageDetails>
      {Array.isArray(objs) ? (
        objs?.map((obj, i) => {
          if (obj instanceof Item) {
            return (
              <ProductItemComponent
                key={i}
                type={"detail"}
                item={obj}
                quoteId={quoteId}
                userId={userId}
                onItemChange={(updatedItem: Item) =>
                  handleItemQtyChange(updatedItem, obj)
                }
              />
            );
          }
          return (
            <ProductOptionComponent
              key={i}
              qty={qty ?? 0}
              option={obj}
              userId={userId}
              quoteId={quoteId}
              quoteKitId={quoteKitId}
              editing={editing}
              onOptionPriceChange={(updatedOption: Option) =>
                handleOptionPriceChange(updatedOption, obj)
              }
              onDelete={handleDeleteOption}
            />
          );
        })
      ) : (
        <ProductItemComponent
          key={objs.id}
          type={"detail"}
          item={objs}
          quoteId={quoteId}
          userId={userId}
          onItemChange={(updatedItem: Item) =>
            handleItemQtyChange(updatedItem, objs)
          }
        />
      )}
    </PageDetails>
  );
}

type ProductOptionProps = {
  id?: string;
  option: Option;
  qty: number;
  quoteId?: string;
  quoteKitId?: number;
  userId?: string;
  editing: boolean;
  onOptionPriceChange: (option: Option) => void;
  onDelete: (id: number) => void;
};
function ProductOptionComponent(props: ProductOptionProps) {
  const { t } = useTranslation();
  const { id, option, qty, userId, quoteId, quoteKitId, editing } = props;
  const collapse = useCollapse(true);
  const [updatedOption, setUpdatedOption] = useState(option);
  const [showConfirmation, setShowConfirmation] = useState(false);

  let priceFor = option.netPrice * qty;

  const handlePriceChange = (newPrice: number) => {
    setUpdatedOption((prevOption) => {
      const updatedOption = {
        ...prevOption,
        netPrice: newPrice,
      };
      props.onOptionPriceChange(updatedOption);
      return updatedOption;
    });
  };

  const onConfirmClick = () => {
    if (userId && quoteId && quoteKitId && option.id) {
      if (option.objectType === 0) {
        quoteService
          .deleteQuoteKitOption(userId, quoteId, quoteKitId, option.id)
          .then((res) => {
            props.onDelete(option.id);
            setShowConfirmation(false);
            //  sessionStorage.setItem("activeTabIndex", "1");
            //  setShowConfirmation(false);
            //  window.location.reload();
          })
          .catch((error) => {
            toast.error(t("quote-page.error-delete-quotekit-option"), error);
          });
      } else if (option.objectType === 1) {
        quoteService
          .deleteQuoteKitMfv(userId, quoteId, quoteKitId, option.id)
          .then((res) => {
            props.onDelete(option.id);
            setShowConfirmation(false);
            // sessionStorage.setItem("activeTabIndex", "1");
            // setShowConfirmation(false);
            // window.location.reload();
          })
          .catch((error) => {
            toast.error(t("quote-page.error-delete-quotekit-mfv"), error);
          });
      }
      if (option.objectType === 2) {
        quoteService
          .deleteQuoteKitSd(userId, quoteId, quoteKitId, option.id)
          .then((res) => {
            props.onDelete(option.id);
            setShowConfirmation(false);
            // sessionStorage.setItem("activeTabIndex", "1");
            // setShowConfirmation(false);
            // window.location.reload();
          })
          .catch((error) => {
            toast.error(t("quote-page.error-delete-quotekit-sd"), error);
          });
      }
      if (option.objectType === 3) {
        quoteService
          .deleteQuoteKitVfd(userId, quoteId, quoteKitId, option.id)
          .then((res) => {
            props.onDelete(option.id);
            setShowConfirmation(false);
            // sessionStorage.setItem("activeTabIndex", "1");
            // setShowConfirmation(false);
            // window.location.reload();
          })
          .catch((error) => {
            toast.error(t("quote-page.error-delete-quotekit-vfd"), error);
          });
      }
    }
  };

  return (
    <Page
      key={id}
      id={id}
      className="mb-2 page-border page-bg-secondary option-page"
    >
      <PageHeader className="align-items-center">
        {(option.items.length > 0 || option.files.length > 0) && (
          <div className="justify-content-start">
            <CollapseButtonComponent iconSize="xs" collapse={collapse} />
          </div>
        )}

        <ProductInfoContainer className="mx-1 txt-break-hide me-auto">
          {option.optionName && (
            <ProductInfo_Name className="info-name">
              {option.optionName} :
            </ProductInfo_Name>
          )}
          <ProductInfo_Value title={option.name}>
            {option.name}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer className="mx-1">
          <ProductInfo_Value className="info-cost">
            <span className="measurement-unit me-1">$</span>
            {stringHelper.toFixedNumberFormat(priceFor)}
            <span className="measurement-unit">(For {qty})</span>
          </ProductInfo_Value>
        </ProductInfoContainer>

        <a className="btn" title="Delete">
          <FontAwesomeIcon
            size="xs"
            icon={faX}
            onClick={() => setShowConfirmation(true)}
          />
        </a>
      </PageHeader>
      <CollapseComponent collapsed={collapse.collapsed}>
        <PageInfo>
          {option.files.map((file) => {
            return (
              <a
                key={file.name}
                href={file.link}
                target="_blank"
                className={itemDocumentsBtn}
              >
                {file.name}
              </a>
            );
          })}
        </PageInfo>
        <PageDetails>
          {option.items.map((item, i) => {
            return (
              <ProductItemComponent
                key={`option-${i}`}
                type="option"
                item={item}
              />
            );
          })}
        </PageDetails>
      </CollapseComponent>

      <PageFooter>
        <ProductCostPageSection
          //cost={option.totalEach}
          profit={option.profit}
          total={option.totalEach}
          netPrice={option.netPrice}
          editing={editing}
          onPriceChange={handlePriceChange}
        />
      </PageFooter>

      {showConfirmation && (
        <ConfiramtionPopup
          show={showConfirmation}
          title={t("quote-page.delete-quoteKit-option")}
          message={t("quote-page.warn-delete-quoteKit-option-message")}
          onDelete={onConfirmClick}
          onClose={() => setShowConfirmation(false)}
        ></ConfiramtionPopup>
      )}
    </Page>
  );
}

type ProductItemProps = {
  item: Item;
  type: "option" | "detail";
  userId?: string;
  quoteId?: string;
  onItemChange?: (item: Item) => void;
};
const itemDocumentsBtn = "btn btn-sm btn-outline-primary";
function ProductItemComponent(props: ProductItemProps) {
  const { t } = useTranslation();
  const { item, type, userId, quoteId } = props;
  const [updatedItem, setUpdatedItem] = useState(item);

  const className = `product-item-container ${
    type === "option" ? "bg-white" : ""
  }`;

  const MinusBtnClick = () => {
    const qty = updatedItem.qty;
    if (qty > 0) {
      const updatedQty = qty - 1;
      const temp = { ...updatedItem, qty: updatedQty };
      if (updatedItem.objectType === ItemObjectTypes.QuoteSingleItem) {
        singleItemService
          .updateQuoteSingleItem(updatedItem.id, updatedQty, quoteId, userId)
          .then(() => {
            {
              setUpdatedItem(temp);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(t("quote-page.error-update-qty"), error);
          });
      } else if (updatedItem.objectType === ItemObjectTypes.QuoteItem) {
        setUpdatedItem(temp);
        if (props.onItemChange) props.onItemChange(temp);
      }
    }
  };
  const PlusBtnClick = () => {
    const qty = updatedItem.qty;
    const updatedQty = qty + 1;
    const temp = { ...updatedItem, qty: updatedQty };
    if (updatedItem.objectType === ItemObjectTypes.QuoteSingleItem) {
      singleItemService
        .updateQuoteSingleItem(updatedItem.id, updatedQty, quoteId, userId)
        .then(() => {
          {
            setUpdatedItem(temp);
          }
        })
        .catch((error) => {
          toast.error(t("quote-page.error-update-qty"), error);
        });
    } else if (updatedItem.objectType === ItemObjectTypes.QuoteItem) {
      setUpdatedItem(temp);
      if (props.onItemChange) props.onItemChange(temp);
    }
  };

  return (
    <div className={className}>
      <div className="item-header">
        <ProductInfoContainer key="item_code" className="txt-break-hide">
          <ProductInfo_Name>{t("quote-page.option-code")}:</ProductInfo_Name>
          <ProductInfo_Value
            className="ms-1 d-block"
            title={updatedItem.it_ocode}
          >
            {updatedItem.it_ocode}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer key="item_available" className="txt-break-hide">
          <ProductInfo_Name className="info-name">
            {t("quote-page.option-avail")}:
          </ProductInfo_Name>
          <ProductInfo_Value className="ms-2 d-block">
            {updatedItem && updatedItem.itemDetails
              ? stringHelper.toFixedNumberFormat(
                  updatedItem.itemDetails.getQtyAvailable()
                )
              : 0}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer key="item_pickList" className="txt-break-hide">
          <ProductInfo_Name className="info-name">
            {t("quote-page.option-pickList")}:
          </ProductInfo_Name>
          <ProductInfo_Value className="ms-2 d-block">
            {updatedItem && updatedItem.itemDetails
              ? stringHelper.toFixedNumberFormat(
                  updatedItem.itemDetails.qtyReserved
                )
              : 0}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer key="item_stock" className="txt-break-hide">
          <ProductInfo_Name className="info-name">
            {t("quote-page.option-stock")}:
          </ProductInfo_Name>
          <ProductInfo_Value className="ms-2 d-block">
            {updatedItem && updatedItem.itemDetails
              ? stringHelper.toFixedNumberFormat(
                  updatedItem.itemDetails.qtyOnHand
                )
              : 0}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer key="item_onOrder" className="txt-break-hide">
          <ProductInfo_Name>
            {t("quote-page.option-on-order")}:
          </ProductInfo_Name>
          <ProductInfo_Value className="ms-2 d-block">
            {stringHelper.toFixedNumberFormat(
              updatedItem &&
                updatedItem.itemDetails &&
                updatedItem.itemDetails.qtyOnOrder
                ? updatedItem.itemDetails.qtyOnOrder
                : 0
            )}
          </ProductInfo_Value>
        </ProductInfoContainer>

        <ProductInfoContainer key="item_costEach" className="txt-break-hide">
          <ProductInfo_Name>{t("quote-page.option-each")}:</ProductInfo_Name>
          <ProductInfo_Value className="ms-2 d-block">
            <MeasurementUnit unit="$" />
            {stringHelper.toFixedNumberFormat(updatedItem.it_cost)}
          </ProductInfo_Value>
        </ProductInfoContainer>

        {type === "detail" ? (
          <div className="counter text-center">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={MinusBtnClick}
            >
              <FontAwesomeIcon size="xs" icon={faMinus} />
            </button>
            <span className="mx-1">{updatedItem.qty}</span>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={PlusBtnClick}
            >
              <FontAwesomeIcon size="xs" icon={faPlus} />
            </button>
          </div>
        ) : (
          <ProductInfoContainer className="txt-break-hide">
            <ProductInfo_Name>{t("common.quantity")}:</ProductInfo_Name>
            <ProductInfo_Value className="ms-2 d-block">
              {updatedItem.qty}
            </ProductInfo_Value>
          </ProductInfoContainer>
        )}
      </div>
      <div className="item-footer">
        <ProductInfoContainer className="item-description">
          <ProductInfo_Name>{t("quote-page.description")}:</ProductInfo_Name>
          <ProductInfo_Description
            className="ms-1 txt-break-line"
            title={updatedItem.itemDescription}
          >
            {updatedItem.itemDescription}
          </ProductInfo_Description>
        </ProductInfoContainer>
        {updatedItem.files.length > 0 && (
          <ProductInfoContainer className="item-description">
            <ProductInfo_Name>{t("common.documents")}:</ProductInfo_Name>
            <div className="item-documents d-inline">
              {updatedItem.files.map((file, i) => {
                return (
                  <a
                    key={i}
                    href={file.link}
                    target="_blank"
                    className={itemDocumentsBtn}
                  >
                    {file.name}
                  </a>
                );
              })}
            </div>
          </ProductInfoContainer>
        )}
      </div>
    </div>
  );
}

type ProductNotesProps = {
  note: string;
  quoteId?: string;
  userId?: string;
  quoteKitId?: number;
  editing: boolean;
  onChange?: (note: string) => void;
};
function ProductNotesComponent(props: ProductNotesProps) {
  const { t } = useTranslation();
  const { userId, quoteId, quoteKitId, editing } = props;
  const [note, setNote] = useState(props.note);

  useEffect(() => {}, [note]);

  function handleNoteChange(ev: string) {
    setNote(ev.toString());
    if (props.onChange) props.onChange(ev);
  }

  return (
      <div>
        {/* <FormLabel htmlFor="pump-note">Note: </FormLabel> */}
        <textarea
          className="note-textarea"
          id="pump-note"
          value={note}
          onChange={(ev) => handleNoteChange(ev.target.value)}
          disabled={!editing}
          rows={1}
          placeholder="Note"
        />
      </div>
    
  );
}

type ProductCostPageProps = {
  currency?: string;
  //cost?: number;
  qty?: number;
  singleItemTotal?: number;
  profit?: number;
  total?: number;
  netPrice: number;
  editing: boolean;
  onPriceChange: (newPrice: number) => void;
};
function ProductCostPageSection(props: ProductCostPageProps) {
  const { t } = useTranslation();
  const {
    currency,
    qty,
    singleItemTotal,
    profit,
    total,
    netPrice,
    editing,
    onPriceChange,
  } = props;
  const [price, setPrice] = useState<number>(0);
  const [newprofit, setNewProfit] = useState<number>(0);

  useEffect(() => {
    setPrice(netPrice);
  }, [netPrice]);

  useEffect(() => {
    setNewProfit(profit !== undefined ? profit : 0);
  }, [profit]);

  const handleProfitChange = (profit: number) => {
    setNewProfit(profit);
    let newPrice = netPrice;
    if (profit !== 0 && profit !== 1 && !Number.isNaN(profit)) {
      if (netPrice !== undefined && total !== undefined) {
        newPrice = total / ((100 - profit) / 100);
      }
    }
    setPrice(newPrice);

    //invoke the price change
    onPriceChange(newPrice);
  };
  const handleNetPriceChange = (newNetPrice: number) => {
    setPrice(newNetPrice);
    //invoke the price change
    onPriceChange(newNetPrice);
  };

  return (
    <>
      <ProductInfoContainer>
        <ProductInfo_Name>Cur:</ProductInfo_Name>
        <ProductInfo_Value>
          <MeasurementUnit unit={"$"} />
          {currency ?? "CAN"}
        </ProductInfo_Value>
      </ProductInfoContainer>
      {/* {cost &&
        <ProductCostComponent unit="$" productName="Cost" value={cost} />
      } */}
      {/* {profit && (
        <ProductCostComponent unit="%" productName="Profit" value={profit} />
      )} */}
      {total && (
        <ProductCostComponent unit="$" productName="Total Cost" value={total} />
      )}
      {singleItemTotal && (
        <ProductCostComponent
          unit="$"
          productName={`For ${qty}`}
          value={singleItemTotal}
        />
      )}
      <ProductInfoContainer>
        <ProductInfo_Name>{t("common.profit")}:</ProductInfo_Name>
        <span>%</span>
        {editing ? (
          <ProductInfo_Value_Editable
            type="number"
            value={newprofit && stringHelper.toFixedNumberFormat(newprofit, 2)}
            onChange={(e) => handleProfitChange(parseFloat(e.target.value))}
          />
        ) : (
          <span></span>
        )}
      </ProductInfoContainer>

      <ProductInfoContainer>
        <ProductInfo_Name>{t("common.net-price-each")}:</ProductInfo_Name>
        <span>$</span>
        {editing ? (
          <ProductInfo_Value_Editable
            type="text"
            value={stringHelper.toFixedNumberFormat(price, 2)}
            onChange={(e) => handleNetPriceChange(parseFloat(e.target.value))}
          />
        ) : (
          <span>{stringHelper.toFixedNumberFormat(price, 2)}</span>
        )}
      </ProductInfoContainer>
    </>
  );
}

type QuoteSummaryComponentProps = {
  summaryData: QuoteSumamryTable;
};

export function QuoteSummaryComponent(props: QuoteSummaryComponentProps) {
  const { t } = useTranslation();
  const { summaryData } = props;
  const summary = summaryData;

  return (
    <div id="summary-card">
      <div className="summary-header">
        <h3>Summary</h3>
      </div>

      <div className="summary-content">
        <table className="summary-table">
          <thead>
            <tr className="summary-row">
              <th className="summary-item-title"></th>
              <th className="net-value-title">Net price</th>
              <th className="cost-value-title">Cost Price</th>
              <th className="profit-value-title">Profit</th>
            </tr>
          </thead>
          <tbody>
            {summary.totalPumpsNetPrice !== 0 && (
              <tr className="summary-row">
                <td className="summary-item">{t("quote-page.pumps")}</td>
                <td className="net-value">
                  $ {numberHelper.toNumberFormat(summary.totalPumpsNetPrice)}
                </td>
                <td className="cost-value">
                  $ {numberHelper.toNumberFormat(summary.totalPumpsCost)}
                </td>
                <td className="profit-value">
                  {numberHelper.toNumberFormat(summary.totalPumpsProfit)}%
                </td>
              </tr>
            )}

            {summary.totalSystemsNetPrice !== 0 && (
              <tr className="summary-row">
                <td className="summary-item">{t("quote-page.systems")}</td>
                <td className="net-value">
                  $ {numberHelper.toNumberFormat(summary.totalSystemsNetPrice)}
                </td>
                <td className="cost-value">
                  $ {numberHelper.toNumberFormat(summary.totalSystemsCost)}
                </td>
                <td className="profit-value">
                  {numberHelper.toNumberFormat(summary.totalSystemsProfit)}%
                </td>
              </tr>
            )}

            {summary.totalTanksNetPrice !== 0 && (
              <tr className="summary-row">
                <td className="summary-item">{t("quote-page.tanks")}</td>
                <td className="net-value">
                  $ {numberHelper.toNumberFormat(summary.totalTanksNetPrice)}
                </td>
                <td className="cost-value">
                  $ {numberHelper.toNumberFormat(summary.totalTanksCost)}
                </td>
                <td className="profit-value">
                  {numberHelper.toNumberFormat(summary.totalTanksProfit)}%
                </td>
              </tr>
            )}

            {summary.totalItemsNetPrice !== 0 && (
              <tr className="summary-row">
                <td className="summary-item">{t("quote-page.items")}</td>
                <td className="net-value">
                  $ {numberHelper.toNumberFormat(summary.totalItemsNetPrice)}
                </td>
                <td className="cost-value">
                  $ {numberHelper.toNumberFormat(summary.totalItemsCost)}
                </td>
                <td className="profit-value">
                  {numberHelper.toNumberFormat(summary.totalItemsProfit)}%
                </td>
              </tr>
            )}

            {summary.totalMiscsNetPrice !== 0 && (
              <tr className="summary-row">
                <td className="summary-item">{t("quote-page.misc-systems")}</td>
                <td className="net-value">
                  $ {numberHelper.toNumberFormat(summary.totalMiscsNetPrice)}
                </td>
                <td className="cost-value">
                  $ {numberHelper.toNumberFormat(summary.totalMiscsCost)}
                </td>
                <td className="profit-value">
                  {numberHelper.toNumberFormat(summary.totalMiscsProfit)}%
                </td>
              </tr>
            )}

            {summary.totalHExchangersNetPrice !== 0 && (
              <tr className="summary-row">
                <td className="summary-item">
                  {t("quote-page.heat-exchangers")}
                </td>
                <td className="net-value">
                  ${" "}
                  {numberHelper.toNumberFormat(
                    summary.totalHExchangersNetPrice
                  )}
                </td>
                <td className="cost-value">
                  $ {numberHelper.toNumberFormat(summary.totalHExchangersCost)}
                </td>
                <td className="profit-value">
                  {numberHelper.toNumberFormat(summary.totalHExchangersProfit)}%
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="summary-content">
        <div className="summary-row">
          <div className="summary-item">{t("quote-page.pumps")}</div>
          <div className="summary-values">
            <span className="net-value">
              {numberHelper.toNumberFormat(summary.totalPumpsNetPrice)}
            </span>
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-item">{t("quote-page.systems")}</div>
          <div className="summary-values">
            <span className="net-value">{summary.totalSystemsNetPrice}</span>
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-item">{t("quote-page.tanks")}</div>
          <div className="summary-values">
            <span className="net-value">{summary.totalTanksNetPrice}</span>
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-item">{t("quote-page.items")}</div>
          <div className="summary-values">
            <span className="net-value">
              {numberHelper.toNumberFormat(summary.totalItemsNetPrice)}
            </span>
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-item">{t("quote-page.misc-systems")}</div>
          <div className="summary-values">
            <span className="net-value">{summary.totalMiscsNetPrice}</span>
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-item">{t("quote-page.heat-exchangers")}</div>
          <div className="summary-values">
            <span className="net-value">
              {summary.totalHExchangersNetPrice}
            </span>
          </div>
        </div>
        {summary.totalCustomClearancePrice !== 0 && (
          <div className="summary-row">
            <div className="summary-item">
              {t("quote-page.custom-clearance")}
            </div>
            <div className="summary-values">
              <span className="net-value">
                {summary.totalCustomClearancePrice}
              </span>
            </div>
          </div>
        )}

        <div className="total-net-price">
          <span>{t("quote-page.total-netprice")}</span>
          <span>{numberHelper.toNumberFormat(summary.totalQuoteNetPrice)}</span>
        </div>
      </div>
      <span className="important-message">
        {t("quote-page.net-price-important-message")}
      </span>
      <div className="summary-footer">
        <div className="total-weight">
          <span>{t("quote-page.total-weight")}</span>
          <span className="weight-kg">
            {numberHelper.toNumberFormat(summary.totalQuoteWeightKg)} kg
          </span>
          <span className="weight-lbs">
            {numberHelper.toNumberFormat(summary.totalQuoteWeightLb)} lbs
          </span>
        </div>
      </div>
    </div>
  );
}
