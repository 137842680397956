export default function AllowableMisaligmentComponent() {
  return (
    <table className="table-bordered table">
      <thead>
        <tr>
          <th colSpan={4}>
            Maximum RPM AND ALLOWABLE MISALIGMENT (DIM. IN INCHES)
          </th>
        </tr>
        <tr>
          <th rowSpan={2}>
            Sleeve <br /> Size
          </th>
          <th rowSpan={2}>
            Maximum <br /> RPM
          </th>
          <th colSpan={2}>Types JE, JN, JES, JNS, E&N</th>
        </tr>
        <tr>
          <th>Parallel</th>
          <th>Angular</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>2</td>
          <td>9200</td>
          <td>0.010</td>
          <td>0.035</td>
        </tr>
        <tr>
          <td>4</td>
          <td>7600</td>
          <td>0.010</td>
          <td>0.043</td>
        </tr>
        <tr>
          <td>5</td>
          <td>7600</td>
          <td>0.015</td>
          <td>0.056</td>
        </tr>
        <tr>
          <td>6</td>
          <td>6000</td>
          <td>0.015</td>
          <td>0.070</td>
        </tr>
        <tr>
          <td>7</td>
          <td>5250</td>
          <td>0.020</td>
          <td>0.081</td>
        </tr>
        <tr>
          <td>8</td>
          <td>4500</td>
          <td>0.020</td>
          <td>0.094</td>
        </tr>
        <tr>
          <td>9</td>
          <td>3750</td>
          <td>0.025</td>
          <td>0.109</td>
        </tr>
        <tr>
          <td>10</td>
          <td>3600</td>
          <td>0.025</td>
          <td>0.128</td>
        </tr>
        <tr>
          <td>11</td>
          <td>3600</td>
          <td>0.032</td>
          <td>0.151</td>
        </tr>
        <tr>
          <td>12</td>
          <td>2800</td>
          <td>0.032</td>
          <td>0.175</td>
        </tr>
        <tr>
          <td>13</td>
          <td>2400</td>
          <td>0.040</td>
          <td>0.195</td>
        </tr>
        <tr>
          <td>14</td>
          <td>2200</td>
          <td>0.045</td>
          <td>0.242</td>
        </tr>
        <tr>
          <td>16</td>
          <td>1500</td>
          <td>0.062</td>
          <td>0.330</td>
        </tr>
      </tbody>
    </table>
  );
}