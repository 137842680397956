import { useEffect, useState } from "react";
import "./Popup-confiramtion.scss";

interface IProps {
  onClose?: () => void;
  onYes?: () => void;
  onOk?: () => void;
  onDelete?: () => void;
  onGoToQuote?: () => void;
  onStayHere?: () => void;
  show: boolean;
  title?: string | null;
  message?: string | null;
  rememberMe?: boolean | null;
}

export default function ConfiramtionPopup({
  onClose,
  onYes,
  onOk,
  onDelete,
  onStayHere,
  onGoToQuote,
  show,
  title,
  message,
  rememberMe,
}: IProps) {
 

  const [saveChoice, setSaveChoice] = useState(false);
  const [userChoice, setUserChoice] = useState<string>();

  const handleSaveChoiceChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    setSaveChoice(e.target.checked);
  };


  const handleGoToQuote = () => {
    if (saveChoice) {
      localStorage.setItem('userChoice', 'goToQuote');
    }
    if (onGoToQuote) {
      onGoToQuote();
    }
  };

  const handleStayHere = () => {
    if (saveChoice) {
      localStorage.setItem('userChoice', 'stay');
    }
    if (onStayHere) {
      onStayHere();
    }
  }

  if (!show) {
    return null;
  }

  return (
    <div id="popup">
      <div  
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            {title && <h5 className="modal-title">{title}</h5>}
            <button
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">{message}</div>
          
          {rememberMe && (
           <div className="modal-detail">
           <input
             type="checkbox"
             checked={saveChoice}
             onChange={handleSaveChoiceChange}
             id="rememberMeCheckbox"
           />
           <label htmlFor="rememberMeCheckbox"> Remember me </label>
         </div>
        )}
          {onYes && (
            <div className="modal-footer"> 
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={onYes}>
                Yes
              </button>
            </div>
          )}
           {onOk && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary" onClick={onOk}>
                Ok
              </button>
            </div>
          )}
          {onDelete && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary red" onClick={onDelete}>
                Delete
              </button>
            </div>
          )}
           {onGoToQuote && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleGoToQuote}
              >
                Go to quote
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={handleStayHere}>
                Stay here
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}