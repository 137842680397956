import BaseApi from "../Core/REST/BaseApi";
import { MfvRow, MfvSelectionTable } from "./Models/MfvSelectionTable";

class MfvService extends BaseApi {
    async getPumpMfvs(userId: string, kitId: string): Promise<MfvSelectionTable | undefined> {
        let url = `${this.baseUrl}/MultiFunctionValve/${userId}/get-mfvs/${kitId}`;
        return await this.get<MfvSelectionTable | undefined>(url);
    }
    async addMFVtoQuote(userId: string, quoteId: string, kitId: string, mfvSelected: MfvRow): Promise<MfvRow | undefined> {
        let url = `${this.baseUrl}/MultiFunctionValve/${userId}/add-to-quote/${quoteId}/${kitId}`;
        return await this.post(url, mfvSelected);
    }
}

const mfvService = new MfvService();
export default mfvService;