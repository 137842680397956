import { Quarter } from "./Quarter";

export class CommissionReport {
  territory: string;
  total: number;
  goal: number;
  currency: string;
  items: Array<CommissionReportItem>;

  constructor(
    territory?: string,
    total?: number,
    goal?: number,
    currency?: string,
    items?: Array<CommissionReportItem>
  ) {
    if (!territory) throw new ReferenceError("territory undefined");
    if (!currency) throw new ReferenceError("currency undefined");
    if (!total && total !== 0) throw new ReferenceError("total undefined");
    if (!goal) throw new ReferenceError("goal undefined");
    if (!items) throw new ReferenceError("items undefined");

    this.territory = territory;
    this.total = total;
    this.goal = goal;
    this.currency = currency;
    this.items = items;
  }
}

export class CommissionReportItem {
  total: number;
  quarter: Quarter;

  constructor(total?: number, quarter?: Quarter) {
    if (!total && total !== 0) throw new ReferenceError("total undefined");
    if (!quarter) throw new ReferenceError("quarter undefined");

    this.total = total;
    this.quarter = quarter;
  }
}
