import { useEffect, useState } from "react";
import activityService from "./ActivityService";
import activityExtensions from "./ActivityExtensions";

export default class Activity {
  id: string;
  type: ActivityType;
  objectId: string;
  objectType: string;
  employeeId: number;
  employeeName: string;
  createdDate: Date;
  description: string;

  isEditable: boolean;

  constructor(data: any) {
    if (!data.id) throw new ReferenceError("activity id undefined");
    if (!data.type) throw new ReferenceError("type undefined");
    if (!data.objectId || !data.objectType)
      throw new ReferenceError("activity object undefined");
    if (data.employeeId == null || !data.employeeName)
      throw new ReferenceError("user undefined");
    if (!data.createdDate) throw new ReferenceError("date undefined");
    if (!data.description) throw new ReferenceError("description undefined");

    this.id = data.id;
    this.type = data.type as ActivityType;
    this.objectId = data.objectId;
    this.objectType = data.objectType;
    this.employeeId = data.employeeId;
    this.employeeName = data.employeeName;
    this.createdDate = new Date(data.createdDate);
    this.description = data.description;
    this.isEditable = data.isEditable || false;
  }
}

export enum ActivityType {
  All = 0,
  History = 1,
  Comment = 2,
}

export class ActivityRequest {
  type: ActivityType;
  objectId: string;
  objectType: string;
  description: string;

  constructor(
    type: ActivityType,
    objectId: string,
    objectType: string,
    description: string
  ) {
    this.description = description;
    this.objectId = objectId;
    this.objectType = objectType;
    this.type = type;
  }
}

export enum ActivityObjectType {
  Quotes = "Quotes",
  Project = "Project",
  Quotekit = "QuoteKit",
}

export function useCommentActivities(
  employeeId?: string,
  objectId?: string | number,
  objectType?: string
) {
  const [state, setState] = useState<Array<Activity>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadData = () => {
    setLoading(true);
    if (employeeId && objectId && objectType) {
      activityService
        .getActivities(employeeId, objectId, objectType, ActivityType.Comment)
        .then((data) => {
          if (data) setState(activityExtensions.buildActivities(data));
        })
        .catch(() => {
          return new Array<Activity>();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(loadData, [employeeId, objectId, objectType]);

  return { comments: state, commentsLoading: loading, loadComments: loadData };
}
