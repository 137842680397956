import { Table } from "@tanstack/react-table";
import { DOTS, usePagination } from "../../hooks/usePagination";
import numberHelper from "../../services/Core/Helpers/number-helper";

type PaginationProps = {
  reactTable: Table<any>;
  siblingCount: number;
  showDataLength?: boolean;
  dataName?: string;
};

export default function Pagination(props: PaginationProps) {
  const { reactTable, siblingCount, showDataLength, dataName } = props;
  const paginationRange = usePagination(
    reactTable.getPageCount() - 1,
    siblingCount,
    reactTable.getState().pagination.pageIndex
  );

  return (
    <div>
      {showDataLength && (
        <div className="text-center m-1">
          <span className="measurement-unit">
            {numberHelper.toNumberFormat(
              reactTable.getFilteredRowModel().rows.length
            )}
            <span className="ms-1">{dataName}...</span>
          </span>
        </div>
      )}
      <nav>
        <ul className="pagination justify-content-center">
          <li
            key={"prev"}
            className={`page-item ${
              reactTable.getCanPreviousPage() ? "" : "disabled"
            }`}
          >
            <a
              className="page-link"
              onClick={reactTable.previousPage}
              aria-disabled={!reactTable.getCanPreviousPage()}
            >
              Prev
            </a>
          </li>

          {paginationRange?.map((item, i) => {
            return (
              <li
                key={i}
                className={`page-item ${
                  reactTable.getState().pagination.pageIndex === item
                    ? "active"
                    : ""
                }`}
              >
                {item === DOTS ? (
                  <span className="page-link">{item}</span>
                ) : (
                  <a
                    className="page-link"
                    onClick={() => setPageIndex(item as number)}
                  >
                    {(item as number) + 1}
                  </a>
                )}
              </li>
            );
          })}

          <li
            key={"next"}
            className={`page-item ${
              reactTable.getCanNextPage() ? "" : "disabled"
            }`}
          >
            <a
              className="page-link"
              onClick={reactTable.nextPage}
              aria-disabled={!reactTable.getCanNextPage()}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );

  function setPageIndex(value: number) {
    if (value >= 0 && value <= reactTable.getPageCount()) {
      reactTable.setPageIndex(value);
    }
  }
}
