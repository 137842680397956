export class QuotesSerialNumber {

    id : number;
    quoteId  : number;
    serialNumber : string;

    constructor(data : any)
    {
        this.id = data.id;
        this.quoteId = data.quoteId;
        this.serialNumber = data.serialNumber;
    }
}