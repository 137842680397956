import numberHelper from "../Core/Helpers/number-helper";
import {
  PumpTestSheetInfo,
  PumpTestSheetTag,
  PumpTestSheetValue,
} from "./Models/PumpTestSheet";
const NotThumbnail = ["PSF", "PSM", "PSMCF", "PST", "RC", "XRI", "ADRI"];

class PumpExtensions {
  isSerialNumberValid(value: string): boolean {
    if (value.length !== 6) {
      return false;
    }
    if (!numberHelper.isNumeric(value)) {
      return false;
    }
    let sn = parseFloat(value);
    if (sn < 100000 || sn > 999999) return false;
    return true;
  }

  generateNewPumpTestSheet(
    employeeId?: string,
    externalFileId?: string
  ): PumpTestSheetInfo {
    let data: any = {};

    if (externalFileId) data.quoteExternalFileId = parseFloat(externalFileId);
    if (employeeId) data.employeId = parseFloat(employeeId);

    let sheetValues = this.generatePumpTestSheetValues();
    return new PumpTestSheetInfo(data, [new PumpTestSheetTag({})], sheetValues);
  }

  generatePumpTestSheetValues(
    sheetId?: number,
    index?: number
  ): Array<PumpTestSheetValue> {
    let temp = [];
    let start = index !== undefined ? index : 0;
    for (let i = start; i < 5; ++i) {
      temp.push(new PumpTestSheetValue({ sheetId }));
    }
    return temp;
  }

  getPumpImagePath(pumpType: string) {
    let img = pumpType;
    if (img === "500 ECM  W/O com") img = "500";
    if (!NotThumbnail.find((x) => x === img)) img = `Thumbnails/${img}`;
    return `${process.env.PUBLIC_URL}/images/Pumps/${img}.png`;
  }

  getConstructionIndex(construction: string): number {
    switch (construction) {
      case "Bronze Fitted":
        return 0;
      case "All Iron":
        return 1;
      case "All Bronze":
        return 2;
      case "All S.S. 304":
        return 3;
      case "All S.S. 316":
        return 4;
      case "Ductile Iron":
        return 5;
      case "Iron Body With S.S. 304 Fitted":
        return 6;
      default:
        return -1;
    }
  }
}

const pumpExtensions = new PumpExtensions();
export default pumpExtensions;
