import Activity from "../../Activities/Activity";
import numberHelper from "../../Core/Helpers/number-helper";

export class WebQuote {
  quoteId: number = 0;
  date: Date = new Date();
  country: string = "";
  status: string = "";
  ownedBy: string = "";
  clientContact: string = "";
  activities: Array<Activity> = [];

  constructor(data: any) {
    this.quoteId = data.quoteId || 0;
    this.date = new Date(data.date || new Date());
    this.country = data.country || "";
    this.status = data.status || "";
    this.ownedBy = data.ownedBy || "";
    this.clientContact = data.clientContact || "";
    this.activities =
      data.activities.map((item: any) => new Activity(item)) || [];
  }
}

export class OpenOrder {
  orderDate: Date = new Date();
  usrOrderStatus: string = "";
  orderId: number = 0;
  poNum: string = "";
  soldTo: string = "";
  salesPerson: string = "";
  totAmnt: number = 0;
  remarks: string = "";
  currencyCode: string = "";

  // Calculated Values
  total: string;

  constructor(data: any) {
    this.orderDate = new Date(data.orderDate);
    this.usrOrderStatus = data.usrOrderStatus || "";
    this.orderId = data.orderId || 0;
    this.poNum = data.poNum || "";
    this.soldTo = data.soldTo || "";
    this.salesPerson = data.salesPerson || "";
    this.totAmnt = data.totAmnt || 0;
    this.remarks = data.remarks || "";
    this.currencyCode = data.currencyCode || "CAD";

    this.total = numberHelper.toCurrencyFormat(this.totAmnt, this.currencyCode);
  }
}
export class OrderHistoryProgress {
  po: string = "";
  quoteId: number = 0;
  client: string = "";
  invoice: string = "";
  orderKitProgress: Array<OrderKitProgress> = [];

  constructor(data: any) {
    this.po = data.po || "";
    this.quoteId = data.quoteId || 0;
    this.client = data.client || "";
    this.invoice = data.invoice || "";
  }
}

export enum OrderHistoryStatus {
  Quoting_Stage = 0,
  PO_Received = 1,
  Downloaded = 2,
  Drawing_Sent = 3,
  Drawing_Rev = 4,
  Drawing_Approved = 5,
  Production_Drawing_Approved = 6,
  Procurement = 7,
  FullPicked = 8,
  Prod100 = 9,
  Finition = 10,
  QC = 11,
  ReadyToShip = 12,
  Finished = 13,
}
export class OrderKitProgress {
  orderId: number = 0;
  kitId: number = 0;
  needsDrawing: number = 0;
  poReceivedDate: Date = new Date();
  downloadedDate: Date = new Date();
  drawingSentDate: Date = new Date();
  drawingRevDate: Date = new Date();
  drawingApprovedDate: Date = new Date();
  productionDrawingDate: Date = new Date();
  estProductionDrawingDate: Date = new Date();
  procurementDate: Date = new Date();
  estProcurementDate: Date = new Date();
  fullPickedDate: Date = new Date();
  prod100Date: Date = new Date();
  finitionDate: Date = new Date();
  qcDate: Date = new Date();
  ReadyToShipDate: Date = new Date();
  FinishedDate: Date = new Date();
  orderHistoryStatus: OrderHistoryStatus = OrderHistoryStatus.Quoting_Stage;

  constructor(data: any) {
    this.orderId = data.ordreId || 0;
    this.kitId = data.kitId || 0;
    this.needsDrawing = data.needsDrawing || 0;
    this.poReceivedDate= new Date(data.poReceivedDate);
    this. downloadedDate= new Date(data.downloadedDate);
    this.drawingSentDate = new Date(data.drawingSentDate);
    this.drawingRevDate = new Date(data.drawingRevDate);
    this.drawingApprovedDate = new Date(data.drawingApprovedDate);
    this.productionDrawingDate = new Date(data.productionDrawingDate);
    this.estProductionDrawingDate = new Date(data.estProductionDrawingDate);
    this.procurementDate = new Date(data.procurementDate);
    this.estProcurementDate = new Date(data.estProcurementDate);
    this.fullPickedDate = new Date(data.fullPickedDate);
    this.prod100Date = new Date(data.prod100Date);
    this.finitionDate = new Date(data.finitionDate);
    this.qcDate = new Date(data.qcDate);
    this.ReadyToShipDate = new Date(data.ReadyToShipDate);
    this.FinishedDate = new Date(data.FinishedDate);
    this.orderHistoryStatus= data.orderHistoryStatus || OrderHistoryStatus.Quoting_Stage;
  }
}

