import { useEffect, useState } from "react";
import { Goal } from "../../services/Goals/Goal";
import goalsService from "../../services/Goals/GoalService";
import goalExtension from "../../services/Goals/goal-extensions";
import { toast } from "react-toastify";

export function useGoal(
  employeeId: string | undefined,
  goalType: number | undefined,
  goalName: string | undefined,
  date: Date = new Date(Date.now())
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [goal, setGoal] = useState<Goal>();

  useEffect(loadGoal, [employeeId, goalType, goalName, date]);

  function loadGoal() {
    if (employeeId && goalType && goalName) {
      goalsService
        .getGoalByType(employeeId, goalType, goalName, date)
        .then((data) => {
          if (data) {
            let temp = goalExtension.toGoal(data);
            setGoal(temp);
          }
        })
        .catch(() => {
          toast.error("An error occured while fetching the data for 'Goal'");
        })
        .finally(() => setLoading(false));
    }
  }

  return { goal, loadingGoal: loading, loadGoal };
}
