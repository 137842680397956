import "./product-info-component.scss";
import { IEditableElementContainer, IElementContainer } from "../Core/core-types";
import stringHelper from "../../services/Core/Helpers/string-helper";

const generateProps = (props: IElementContainer, className?: string) => {
  return {
    id: props.id,
    className: `${className} ${props.className}`,
    title: props.title,
    children: props.children,
  };
};

export function ProductInfoContainer(props: IElementContainer) {
  return <div {...generateProps(props, "product-info")} />;
}

export function ProductInfo_Title(props: IElementContainer) {
  return <span {...generateProps(props, "product-title")} />;
}

export function ProductInfo_Subtitle(props: IElementContainer) {
  return <span {...generateProps(props, "product-subtitle")} />;
}

export function ProductInfo_Name(props: IElementContainer) {
  return <span {...generateProps(props, "info-name")} />;
}

export function ProductInfo_Value(props: IElementContainer) {
  return <span {...generateProps(props, "info-value")} />;
}
export function ProductInfo_Value_Editable(props: IEditableElementContainer) {
  return (
    <input
      type= {props.type}
      defaultValue={props.defaultValue}
      value={props.value}
      className={"info-value-editable"}
      onChange={props.onChange}
    />
  );
}
export function ProductInfo_Description(props: IElementContainer) {
  return <span {...generateProps(props, "info-description")} />;
}

type MeasurementUnitProp = "$" | "%";
type ProductCostProps = {
  productName?: string;
  value: number;
  unit: MeasurementUnitProp;
};

export function ProductCostComponent(props: ProductCostProps) {
  const { productName, value, unit } = props;
  return (
    <ProductInfoContainer>
      <ProductInfo_Name>{productName}:</ProductInfo_Name>
      <ProductInfo_Value className="info-cost">
        {unit === "$" && <MeasurementUnit className="me-1" unit={unit} />}
        {stringHelper.toFixedNumberFormat(value, 2)}
        {unit === "%" && <MeasurementUnit className="ms-1" unit={unit} />}
      </ProductInfo_Value>
    </ProductInfoContainer>
  );
}

type MeasurementUnitProps = {
  unit: string;
  className?: string;
};

export function MeasurementUnit(props: MeasurementUnitProps) {
  const { unit, className } = props;
  return <span className={`measurement-unit ${className}`}>{unit}</span>;
}


// export function ProductNetPriceComponent(props: ProductCostProps) {
//   const { productName, value, unit } = props;
//   return (
//     <ProductInfoContainer>
//       <ProductInfo_Name>{productName}:</ProductInfo_Name>
//       <ProductNetPrice_Value className="info-cost">
//         {unit === "$" && <MeasurementUnit className="me-1" unit={unit} />}
//         {stringHelper.toFixedNumberFormat(value, 2)}
//         {unit === "%" && <MeasurementUnit className="ms-1" unit={unit} />}
//       </ProductNetPrice_Value>
//     </ProductInfoContainer>
//   );
// }
