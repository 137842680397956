import "./email-config.scss";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import emailService from "../../services/Email/email_service";
import { EmailConfig, EmailRecipient } from "../../services/Email/EmailConfig";
import { toast } from "react-toastify";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import stringHelper from "../../services/Core/Helpers/string-helper";
import CheckAllowedComponent from "../../components/CheckAllowedComponent";
import { Client } from "../../services/Users/Client";

type Props = {
  user: Client;
};
export const EmailConfigTable = ({ user }: Props) => {
  const [emailConfigs, setEmailConfig] = useState<Array<EmailConfig>>([]);
  const [activeRecipients, setactiveRecipients] = useState<
    Array<EmailRecipient>
  >([]);
  const { t } = useTranslation();
  const [emailConfigId, setEmailConfigId] = useState<string>();
  const [addType, setAddType] = useState(0);
  const [addEmailAdress, setAddEmailAdress] = useState<string>("");
  const [editable, setEditable] = useState(false);
  const emailRecipientTypeArray = ["To", "CC", "BCC"];
  const handleToggleEdit = () => {
    setEditable((prevEditable) => !prevEditable);
  };
  const handleSaveChanges = async () => {
    try {
      for (var recipient of activeRecipients) {
        if (!stringHelper.RegExpEmailAdress.test(recipient.email)) {
          let errorMsg =
            t("email-config-table.invalid-email-format") + recipient.email;
          toast.error(errorMsg);
          return false;
        }
      }
      const updatedEmailConfig = emailConfigs.find(
        (x) => x.id === emailConfigId
      );
      if (updatedEmailConfig === undefined) {
        throw new Error("Id not found in emailConfigs");
      }
      updatedEmailConfig.recipients = activeRecipients;

      emailService.postEmailConfig(updatedEmailConfig).catch((ex)=>{toast.error("Error updating data");console.log(ex)});
    } catch (error) {
      console.error("Error updating data:", error);
    }
    setEditable(false);
  };
  const RemoveFromactiveRecipients = (index: number) => {
    activeRecipients.splice(index, 1);
    setactiveRecipients([...activeRecipients]);
  };
  const AddRecipientInActiveRecipients = (
    emailAdress: string,
    type: number
  ) => {
    if (stringHelper.RegExpEmailAdress.test(emailAdress)) {
      setactiveRecipients([
        ...activeRecipients,
        new EmailRecipient({ email: emailAdress, type: type }),
      ]);
      setAddEmailAdress("");
      setAddType(0);
    } else {
      let errorMsg = t("email-config-table.invalid-email-format") + emailAdress;
      toast.error(errorMsg);
    }
  };
  useEffect(() => {
    loadEmailConfigs();
  }, [user]);
  return (
    <CheckAllowedComponent
    user={user}
    objectName="email-config"
    redirect={true}
  >
      <div className="header">
        <h2>{t("email-config-table.email-configuration")}</h2>
      </div>
      <div className="m-3 row">
        <div className="col-lg-6 col-md-12">
          <h5 className="h6">Configuration :</h5>
          <div className="list-group">
            {emailConfigs?.map((emailConfig, index) => {
              return (
                <span
                  key={index}
                  className={`list-group-item list-group-item-action ${
                    emailConfig.id === emailConfigId && "active"
                  }`}
                  onClick={() => {
                    setEmailConfigId(emailConfig.id);
                    setactiveRecipients([...emailConfig.recipients]);
                    setEditable(false);
                  }}
                >
                  {emailConfig.id}
                </span>
              );
            })}
          </div>
        </div>
        {emailConfigId && (
          <div className="col-lg-6 col-md-12">
            <div className="header">
              <h5 className="h6">{t("email-config-table.email")} :</h5>
              <button
                className="btn btn-outline-primary"
                onClick={editable ? handleSaveChanges : handleToggleEdit}
              >
                {editable
                  ? t("email-config-table.save-changes")
                  : t("email-config-table.edit")}
              </button>
            </div>
            <div className="list-group">
              {activeRecipients?.map((recipient, index) => {
                if (editable) {
                  return (
                    <span
                      key={index}
                      className="list-group-form list-group-item list-group-item-action list-space"
                      id="formEmail"
                    >
                      <input
                        type="text"
                        value={recipient.email}
                        onChange={(ev) => {
                          activeRecipients[index].email = ev.target.value;
                          setactiveRecipients([...activeRecipients]);
                        }}
                      />
                      <select
                        className={`form-select`}
                        value={recipient.type}
                        onChange={(ev) => {
                          activeRecipients[index].type = parseInt(
                            ev.target.value
                          );
                          setactiveRecipients([...activeRecipients]);
                        }}
                      >
                        {emailRecipientTypeArray.map((option, index) => {
                          return (
                            <option key={index} value={index}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                      <button
                        className="btn btn-outline-primary red"
                        onClick={() => RemoveFromactiveRecipients(index)}
                      >
                        <FontAwesomeIcon icon={faX} />
                      </button>
                    </span>
                  );
                }

                return (
                  <span
                    key={index}
                    className="list-group-form list-group-item list-group-item-action list-space"
                    id="formEmail"
                  >
                    <span>{recipient.email}</span>{" "}
                    <span>
                      <b>Type: </b>
                      {emailRecipientTypeArray[recipient.type]}
                    </span>
                  </span>
                );
              })}
              {editable && (
                <span id="formEmail" className="list-group-form list-group-item list-group-item-action list-space">
                  <input
                    type="text"
                    value={addEmailAdress}
                    placeholder="someone@flofab.com"
                    onChange={(ev) => setAddEmailAdress(ev.target.value)}
                  />
                  <select
                    className={`form-select`}
                    value={addType}
                    onChange={(ev) => {
                      setAddType(parseInt(ev.target.value));
                    }}
                  >
                    {emailRecipientTypeArray.map((option, index) => {
                      return (
                        <option key={index} value={index}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-outline-primary green"
                    onClick={() =>
                      AddRecipientInActiveRecipients(addEmailAdress, addType)
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      </CheckAllowedComponent>
  );
  
  async function loadEmailConfigs() {
      try {
        await emailService.getAllEmailConfigs().then((res) => {
          if (!res) {
            console.error("Get email config response is null");
          } else if (!Array.isArray(res)) {
            console.error("Get email config response is not an array:", res);
          } else {
            setEmailConfig(res);
          }
        });
      } catch (error) {
        toast.error("An error has occured while fetching the Email Configurations")
        console.error("Error getting data:", error);
      }
  }
};
