import BaseApi from "../Core/REST/BaseApi";
import { QuotePriority } from "./Models/QuotePriority";
class QuotePriorityService extends BaseApi {
  async getAllPump(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-all-pump`;
    return await this.get<Array<QuotePriority> | undefined>(url);
  }
  async getAllPackage(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-all-package`;
    return await this.get<Array<QuotePriority> | undefined>(url);
  }
  async getAllWelding(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-all-welding`;
    return await this.get<Array<QuotePriority> | undefined>(url);
  }
  async getAllFinition(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-all-finition`;
    return await this.get<Array<QuotePriority> | undefined>(url);
  }
  async updateAllPumpPriority(priorityPump : Array<QuotePriority>)
  {
    let url = `${this.baseUrl}/QuotePriority/update-all-pump-priority`;
    priorityPump = priorityPump.map((priority)=>{return{position:priority.position,quoteExternalFileId:priority.quoteExternalFileId}});
    return await this.patch(url,priorityPump);
  }
  async updateAllPackagePriority(priorityPackage: Array<QuotePriority>)
  {
    let url = `${this.baseUrl}/QuotePriority/update-all-package-priority`;
    priorityPackage = priorityPackage.map((priority)=>{return{position:priority.position,quoteExternalFileId:priority.quoteExternalFileId}});
    return await this.patch(url,priorityPackage);
  }
  async updateAllWeldingPriority(priorityWelding : Array<QuotePriority>)
  {
    let url = `${this.baseUrl}/QuotePriority/update-all-welding-priority`;
    priorityWelding = priorityWelding.map((priority)=>{return{position:priority.position,quoteExternalFileId:priority.quoteExternalFileId}});
    return await this.patch(url,priorityWelding);
  }
  async updateAllFinitionPriority(priorityFinition: Array<QuotePriority>)
  {
    let url = `${this.baseUrl}/QuotePriority/update-all-finition-priority`;
    priorityFinition = priorityFinition.map((priority)=>{return{position:priority.position,quoteExternalFileId:priority.quoteExternalFileId}});
    return await this.patch(url,priorityFinition);
  }
  async getWeldingPossiblePriorities(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-welding-possible-priorities`;
    return await this.get<Array<QuotePriority>>(url);
  }
  async getPumpPossiblePriorities(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-pump-possible-priorities`;
    return await this.get<Array<QuotePriority>>(url);
  }
  async getPackagePossiblePriorities(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-package-possible-priorities`;
    return await this.get<Array<QuotePriority>>(url);
  }
  async getFinitionPossiblePriorities(): Promise<Array<QuotePriority> | undefined>
  {
    let url = `${this.baseUrl}/QuotePriority/get-finition-possible-priorities`;
    return await this.get<Array<QuotePriority>>(url);
  }
}

const quotePriorityService = new QuotePriorityService();
export default quotePriorityService;
