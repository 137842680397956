import { toast } from "react-toastify";
import stringHelper from "../Core/Helpers/string-helper";
import BaseApi from "../Core/REST/BaseApi";
import StatisticContent from "../Stats/StatisticContent";
import {
  CommissionReport,
  CommissionReportItem,
} from "./Models/CommissionReport";
import { Quarter } from "./Models/Quarter";
import {
  QuarterReport,
  QuarterReportSerie,
  QuarterReportSerieItem,
} from "./Models/QuarterReport";
import { start } from "repl";

class SalesService extends BaseApi {
  async getCommissionReport(
    employeeId: string,
    fiscalDate: Date
  ): Promise<Array<CommissionReport> | undefined> {
    let date = stringHelper.toDateString(fiscalDate);
    return await this.get<Array<CommissionReport> | undefined>(
      `${this.baseUrl}/Sales/${employeeId}/admin/commission-report?fiscalDate=${date}`
    ).then((data: Array<CommissionReport> | undefined) => {
      if (data) {
        //console.log(data);
        let commissions = data.map((report) => {
          let items = report.items.map((item) => {
            let quarter = new Quarter(
              item.quarter.name,
              item.quarter.startDate,
              item.quarter.endDate,
              item.quarter.quarterType
            );
            return new CommissionReportItem(item.total, quarter);
          });

          return new CommissionReport(
            report.territory,
            report.total,
            report.goal,
            report.currency,
            items
          );
        });
        return commissions;
      }
      return undefined;
    });
  }

  async getPurchasePacToolStats(
    employeeId: string,
    start: string,
    end: string,
    type: string
  ) {
    return await this.get<StatisticContent | undefined>(
      `${this.baseUrl}/Sales/${employeeId}/admin/stats/general-ledger/budget-tool-pac-stats?start=${start}&end=${end}&type=${type}`
    );
  }

  async getPurchaseBudgetPacTool(employeeId: string, date: string) {
    return await this.get<QuarterReport | undefined>(
      `${this.baseUrl}/Sales/${employeeId}/admin/stats/general-ledger/budget-tool-pac?fiscalDate=${date}`
    )
      .then((data) => {
        if (data) {
          // console.log(data);
          let series = data.series.map((serieData) => {
            let serieItems = serieData.reportSerieItems.map((serieItem) => {
              let quarter = new Quarter(
                serieItem.quarter.name,
                serieItem.quarter.startDate,
                serieItem.quarter.endDate,
                serieItem.quarter.quarterType
              );
              return new QuarterReportSerieItem(
                quarter,
                serieItem.total,
                serieItem.budget
              );
            });

            return new QuarterReportSerie(serieData.name, serieItems);
          });

          return new QuarterReport(data.title, series);
        }
        return undefined;
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "An error occured while fetching the data for 'Budget - Tool Pac'"
        );
      });
  }

}

const salesService = new SalesService();
export default salesService;
