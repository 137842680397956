import BaseApi from "../Core/REST/BaseApi";
import { Margin } from "./Margin";

class MarginQuoteService extends BaseApi {
    async getMarginQuoteData(employeeId: string): Promise<Margin | undefined> {
        let url = `${this.baseUrl}/marginQuote/${employeeId}`;
        return await this.get<Margin | undefined>(url);
    
    }
    async putMarginQuoteData(employeeId: string,  editableData :  Margin ) {
        let url = `${this.baseUrl}/marginQuote/${employeeId}/`;
        return await this.put(url,  editableData);
    }
}

const marginQuoteService = new MarginQuoteService();
export default marginQuoteService;