import "./hydrostatic-test-report.scss";
import { useState, FormEvent } from "react";
import { Dispatch, SetStateAction } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Client } from "../../services/Users/Client";
import { Page } from "../../components/Pages/page-components";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import hydrostaticTestReportService from "../../services/HydrostaticTestReport/HydrostaticTestReportService";
import { HydrostaticTestReport } from "../../services/HydrostaticTestReport/Models/HydrostaticTestReport";
import stringHelper from "../../services/Core/Helpers/string-helper";
import CreatableSelect from "react-select/creatable";
import {
  faCheck,
  faFloppyDisk,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import dateHelper from "../../services/Core/Helpers/date-helper";
import LogoComponent from "../../components/app/LogoComponent";
import userService from "../../services/Users/UserService";
type IParamTypes = {
  externalFileId: string;
  employeeId: string;
};

function HydrostaticTestReportPage() {
  const { externalFileId, employeeId } = useParams<IParamTypes>();
  const [employee, setEmployee] = useState<Client>();
  const [report, setReport] = useState<HydrostaticTestReport>();
  const { t } = useTranslation();

  useEffect(() => {
    if (externalFileId && employeeId) {
      loadReport();
    }
  }, [externalFileId]);
  useEffect(() => {
    if (employeeId) {
      loadUser(employeeId);
    }
  }, [employeeId]);

  return (
    <Page id="hydrostatic-test-report-page">
      <div className="only-print">
        <LogoComponent />
      </div>
      <div className="card mt-2">
        <div className="card-header">
          <h2 className="text-center">Hydrostatic Test Report</h2>
        </div>
        <div className="card-body">
          <div className="quoteInformationContainer">
            <label>{t("hydrostatic-test-report-page.creationDate")}: </label>
            <span>
              {report?.creationDate
                ? stringHelper.toDateString(report.creationDate)
                : stringHelper.toDateString(new Date())}
            </span>
            <label>{t("hydrostatic-test-report-page.jobNumber")}:</label>
            <span>{report?.jobNumber}</span>
            <label>{t("hydrostatic-test-report-page.customer")}:</label>
            <span>{report?.customer}</span>
            <label>{t("hydrostatic-test-report-page.project")}:</label>
            <span>{report?.project}</span>
            <label>{t("hydrostatic-test-report-page.reference")}:</label>
            <span>{report?.reference}</span>
          </div>

          <HydrostaticTestReportForm
            employee={employee}
            report={report}
            setReport={setReport}
            loadReport={loadReport}
          />
        </div>
      </div>
    </Page>
  );
  function loadReport() {
    if (externalFileId)
      hydrostaticTestReportService
        .GetHydrostaticTestReport(externalFileId)
        .then((res) => {
          setReport(res);
        })
        .catch(() => {
          toast.error(t("hydrostatic-test-report-page.get-report-error"));
        });
  }
  function loadUser(employeeId: string) {
    userService
      .getUser(employeeId)
      .then((data) => {
        if (data) {
          setEmployee(data);
        }
      })
      .catch(() => {
        toast.error(t("user-form-page.load-error"));
      });
  }
}

type HydrostaticTestReportFormProps = {
  report: HydrostaticTestReport | undefined;
  setReport: Dispatch<SetStateAction<HydrostaticTestReport | undefined>>;
  employee: Client | undefined;
  loadReport: () => void;
};
type HydrostaticTestReportFormComponentsProps = {
  report: HydrostaticTestReport | undefined;
  setReport: Dispatch<SetStateAction<HydrostaticTestReport | undefined>>;
};
function HydrostaticTestReportForm({
  report,
  setReport,
  employee,
  loadReport,
}: HydrostaticTestReportFormProps) {
  const GestionnaireRole = 2;
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const isFinish: boolean = report?.testDate != undefined;
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      event.currentTarget.checkValidity() === true &&
      report &&
      employee?.id
    ) {
      hydrostaticTestReportService
        .ValidateHydrostaticTestReport(report, employee.id)
        .then(() => {
          toast.success(t("hydrostatic-test-report-page.success-validating"));
          loadReport();
        })
        .catch(() => {
          toast.error(t(`hydrostatic-test-report-page.error-validating`));
        });
    } else setValidated(true);
  };
  function setValue(key: string, value: any) {
    setReport({ ...report, [key]: value } as HydrostaticTestReport);
  }
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div className="card mt-2">
        <div className="card-header">
          {t("hydrostatic-test-report-page.important-notes")}
        </div>
        <div className="card-body">
          <ol>
            <li>
              {t("hydrostatic-test-report-page.system-tested-using-water")}
            </li>
            <li>
              {t(
                "hydrostatic-test-report-page.all-savety-guideline-strictly-adhered"
              )}
            </li>
            <li>
              {t(
                "hydrostatic-test-report-page.test-according-to-system-capacity"
              )}
            </li>
          </ol>
          {t("hydrostatic-test-report-page.air-test")}
          <ol>
            <li>
              {t(
                "hydrostatic-test-report-page.skid-with-pump-tested-with-water"
              )}
            </li>
            <li>
              {t("hydrostatic-test-report-page.air-tested-100-psi-minimum")}
            </li>
          </ol>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-header">
          {t("hydrostatic-test-report-page.pre-test-inspection")}
        </div>
        <div className="card-body">
          <Row>
            <Col sm={6} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.is-Equipment-Installed")}
              </label>
              <InputGroup hasValidation>
                <Form.Check
                  className="text-end"
                  id="isEquipmentInstalled"
                  type="switch"
                  defaultChecked={report?.isEquipmentInstalled}
                  onChange={() => {
                    setValue(
                      "isEquipmentInstalled",
                      !report?.isEquipmentInstalled
                    );
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.is-Join-Inspected")}
              </label>
              <InputGroup hasValidation>
                <Form.Check
                  className="text-end"
                  id="isJoinInspected"
                  type="switch"
                  defaultChecked={report?.isJoinInspected}
                  onChange={() => {
                    setValue("isJoinInspected", !report?.isJoinInspected);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.city-Pressure-PSI")}
              </label>
              <InputGroup hasValidation>
                <Form.Control
                  id="cityPressurePSI"
                  type="number"
                  required
                  defaultValue={report?.cityPressurePSI}
                  onChange={(ev) => {
                    setValue("cityPressurePSI", ev.target.value);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-header">
          {t("hydrostatic-test-report-page.hydrostatic-pressure-test-data")}
        </div>
        <div className="card-body">
          <Row>
            <Col sm={6} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.required-Test-Pressure-PSI")}
              </label>
              <InputGroup hasValidation>
                <Form.Control
                  id="requiredTestPressurePSI"
                  type="number"
                  required
                  defaultValue={report?.requiredTestPressurePSI}
                  size="sm"
                  onChange={(ev) => {
                    setValue("requiredTestPressurePSI", ev.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={6} className="selectInputContainer">
              <TimeRequiredMinutesSelectInput
                report={report}
                setReport={setReport}
              />
            </Col>
            <Col sm={4} className="textInputContainer">
              <label>{t("hydrostatic-test-report-page.start-time")}</label>
              <InputGroup hasValidation>
                <Form.Control
                  id="startTime"
                  type="time"
                  required
                  value={
                    report?.startTime
                      ? stringHelper.getTimeString(report.startTime)
                      : 0
                  }
                  size="sm"
                  onChange={(ev) => {
                    var date: Date = dateHelper.convertTimeStringToDate(
                      ev.target.value
                    );
                    setValue("startTime", date);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={4} className="textInputContainer">
              <label>{t("hydrostatic-test-report-page.end-time")}</label>
              <InputGroup hasValidation>
                <Form.Control
                  id="endTime"
                  type="time"
                  required
                  value={
                    report?.endTime
                      ? stringHelper.getTimeString(report.endTime)
                      : 0
                  }
                  size="sm"
                  onChange={(ev) => {
                    var date: Date = dateHelper.convertTimeStringToDate(
                      ev.target.value
                    );
                    setValue("endTime", date);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={4} className="textInputContainer">
              {report?.endTime && report?.startTime && (
                <>
                  <label>{t("hydrostatic-test-report-page.total-time")}</label>
                  <div>
                    {dateHelper.getDifference(report.startTime, report.endTime)}
                  </div>
                </>
              )}
            </Col>
            <Col sm={4} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.start-pressure-PSI")}
              </label>
              <InputGroup hasValidation>
                <Form.Control
                  id="startPressurePSI"
                  type="number"
                  required
                  defaultValue={report?.startPressurePSI}
                  size="sm"
                  onChange={(ev) => {
                    setValue("startPressurePSI", ev.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={4} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.end-pressure-PSI")}
              </label>
              <InputGroup hasValidation>
                <Form.Control
                  id="endPressurePSI"
                  type="number"
                  required
                  defaultValue={report?.endPressurePSI}
                  size="sm"
                  onChange={(ev) => {
                    setValue("endPressurePSI", ev.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>
                {t(
                  "hydrostatic-test-report-page.system-sustain-city-water-pressure"
                )}
              </label>
              <InputGroup hasValidation>
                <Form.Check
                  className="text-end"
                  id="systemSustainCityWaterPressure"
                  type="switch"
                  defaultChecked={report?.systemSustainCityWaterPressure}
                  onChange={() => {
                    setValue(
                      "systemSustainCityWaterPressure",
                      !report?.systemSustainCityWaterPressure
                    );
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>
                {t(
                  "hydrostatic-test-report-page.confirm-join-sustained-test-pressure"
                )}
              </label>
              <InputGroup>
                <Form.Check
                  className="text-end"
                  id="confirmJoinSustainedTestPressure"
                  required
                  defaultChecked={report?.confirmJoinSustainedTestPressure}
                  onChange={() => {
                    setValue(
                      "confirmJoinSustainedTestPressure",
                      !report?.confirmJoinSustainedTestPressure
                    );
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.confirm-leak-inspected")}
              </label>
              <InputGroup>
                <Form.Check
                  className="text-end"
                  id="confirmLeakInspected"
                  required
                  defaultChecked={report?.confirmLeakInspected}
                  onChange={() => {
                    setValue(
                      "confirmLeakInspected",
                      !report?.confirmLeakInspected
                    );
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.confirm-leak-repaired")}
              </label>
              <InputGroup>
                <Form.Check
                  className="text-end"
                  required
                  id="confirmLeakRepaired"
                  defaultChecked={report?.confirmLeakRepaired}
                  onChange={() => {
                    setValue(
                      "confirmLeakRepaired",
                      !report?.confirmLeakRepaired
                    );
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>{t("hydrostatic-test-report-page.pass-test")}</label>
              <InputGroup hasValidation>
                <Form.Check
                  className="text-end"
                  id="passTest"
                  type="switch"
                  defaultChecked={report?.passTest}
                  onChange={() => {
                    setValue("passTest", !report?.passTest);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.drain-system-before-removing")}
              </label>
              <InputGroup>
                <Form.Check
                  className="text-end"
                  id="drainSystemBeforeRemoving"
                  type="switch"
                  defaultChecked={report?.drainSystemBeforeRemoving}
                  onChange={() => {
                    setValue(
                      "drainSystemBeforeRemoving",
                      !report?.drainSystemBeforeRemoving
                    );
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="textInputContainer">
              <label>
                {t("hydrostatic-test-report-page.NFPA20-requirements")}
              </label>
              <InputGroup>
                <Form.Check
                  className="text-end"
                  id="nFPA20Requirements"
                  type="switch"
                  checked={report?.nfpA20Requirements ? true : false}
                  onChange={() => {
                    setValue("nfpA20Requirements", !report?.nfpA20Requirements);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-header">
          {t("hydrostatic-test-report-page.job-issues")}
        </div>
        <div className="card-body no-print">
          <label>{t("hydrostatic-test-report-page.note")}</label>
          <InputGroup>
            <Form.Control
              id="note"
              as="textarea"
              rows={2}
              defaultValue={report?.note}
              onChange={(ev) => {
                setValue("note", ev.target.value);
              }}
            />
          </InputGroup>
        </div>
        <div className="card-body only-print">
          <label>{t("hydrostatic-test-report-page.note")}</label>
          <div>{report?.note}</div>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <Row>
            <Col sm={6} className="selectInputContainer">
              <label>{t("hydrostatic-test-report-page.tested-by")}</label>
              {(report?.testedByUserName ? report?.testedByUserName :
              "") + (employee?.nom === report?.testedByUserName
                ? ""
                : t("hydrostatic-test-report-page.save-to-become-tester", {
                    technicianName: employee?.nom,
                  }))}
            </Col>
            <Col sm={6} className="selectInputContainer">
              <label>{t("hydrostatic-test-report-page.witnessed-by")}</label>
              {report?.witnessedByUserName
                ? report?.witnessedByUserName
                : employee?.roleId && employee?.roleId <= GestionnaireRole
                ? employee.nom +
                  t("hydrostatic-test-report-page.validate-to-confirm")
                : t("hydrostatic-test-report-page.supervisor-must-validate")}
            </Col>
            <Col sm={6} className="selectInputContainer">
              <label>{t("hydrostatic-test-report-page.date-tested")}</label>
              {isFinish
                ? stringHelper.toDateString(report?.testDate)
                : employee?.roleId && employee?.roleId <= GestionnaireRole
                ? stringHelper.toDateString(new Date()) +
                  t("hydrostatic-test-report-page.validate-to-confirm")
                : t("hydrostatic-test-report-page.supervisor-must-validate")}
            </Col>
          </Row>
        </div>
      </div>
      <div className="no-print">
        <div className="d-flex text-center mt-3">
          <div className="flex-fill">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => window.print()}
            >
              <FontAwesomeIcon size="xl" className="me-2" icon={faPrint} />
              {t("common.print")}
            </button>
          </div>
          <div className="flex-fill">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                if (report && employee?.id) {
                  hydrostaticTestReportService
                    .SaveHydrostaticTestReport(report,employee?.id)
                    .then(() =>
                      toast.success(
                        t("hydrostatic-test-report-page.success-saving")
                      )
                    )
                    .catch(() =>
                      toast.error(
                        t("hydrostatic-test-report-page.error-saving")
                      )
                    );
                }
              }}
              //disabled={isFinish}
            >
              <FontAwesomeIcon size="xl" className="me-2" icon={faFloppyDisk} />
              {t("common.save")}
            </button>
          </div>
          {!report?.testDate &&
            employee?.roleId &&
            employee?.roleId <= GestionnaireRole && (
              <div className="flex-fill">
                <button className="btn btn-outline-success" type="submit">
                  <FontAwesomeIcon size="xl" className="me-2" icon={faCheck} />
                  {t("hydrostatic-test-report-page.validate")}
                </button>
              </div>
            )}
        </div>
      </div>
    </Form>
  );
}
function TimeRequiredMinutesSelectInput({
  report,
  setReport,
}: HydrostaticTestReportFormComponentsProps) {
  const { t } = useTranslation();
  if (report === undefined) return <div />;

  const options = [
    { value: 30, label: "30 minutes (header)" },
    { value: 60, label: "1 hours (Systems)" },
    { value: 120, label: "2 hours (fire pumps)" },
  ];

  if (
    report.timeRequiredMinutes &&
    !options.find((x) => x.value === report?.timeRequiredMinutes)
  )
    options.push({
      value: report.timeRequiredMinutes,
      label: report.timeRequiredMinutes + " minutes (Special)",
    });

  const handleCreate = (inputValue: string) => {
    setReport({
      ...report,
      timeRequiredMinutes: parseInt(inputValue),
    });
  };

  return (
    <>
      <Form.Label htmlFor="timeRequired">
        {t("hydrostatic-test-report-page.time-required")}
      </Form.Label>
      <CreatableSelect
        isClearable
        id="timeRequiredMinutes"
        className="react-select-container"
        classNamePrefix={
          (report.timeRequiredMinutes ? "valid" : "invalid") + " react-select"
        }
        value={options.find((x) => x.value === report.timeRequiredMinutes)}
        options={options}
        onCreateOption={handleCreate}
        onChange={(ev) => {
          setReport({
            ...report,
            timeRequiredMinutes: ev?.value,
          });
        }}
      />
    </>
  );
}
export default HydrostaticTestReportPage;
