import { Client } from "../services/Users/Client";
import { useNavigate } from "react-router";
import permissionService from "../services/Permissions/PermissionService";
import {
  Permission,
} from "../services/Permissions/Permission";
import { useEffect, useState } from "react";
type Props = {
  children: React.ReactNode;
  user: Client;
  objectName: string;
  redirect?: boolean;
  alt?: JSX.Element;
};
let permissionKeyArray: Array<Permission> | undefined;

export async function checkAllowed(user: Client, objectName: string) {
  if (permissionKeyArray === undefined) {
    permissionKeyArray = await permissionService.GetAll();
    //while (permissionKeyArray===undefined);//wait for permission to be in cache
  }
  let permissionValueArray = permissionKeyArray?.find(
    (x) => x.permissionName === objectName
  )?.permissionValues;
  if (permissionValueArray === undefined) {
    throw "Permission undefined " + objectName;
  }
  return (
    permissionValueArray.find(
      (x) => x.departmentId === user.departmentId && user.roleId && x.roleId >= user.roleId
    ) !== undefined || user.roleId === 1
  ); //1 is the admin role
}
export default function CheckAllowedComponent(props: Props) {

  const navigate = useNavigate();
  let [isAllowed, setIsAllowed] = useState(false);
  useEffect(() => {
    checkAllowed(props.user, props.objectName).then((res) => {
      setIsAllowed(res);
      if (permissionKeyArray && !res && props.redirect)
      {
        navigate("../PageNotFound");
      }
    });
  }, [permissionKeyArray]);

  return <>{isAllowed ? props.children :props.alt}</>;
}
