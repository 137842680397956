//this file should be in the service section this is just for the mockup
export class SupplierPartNumberCodeItem {
  codeItem: number = 0;
  description: string = "";
  codeValues: Array<SupplierPartNumberCodeValue> = [];
  constructor(
    codeItem: number,
    description: string,
    codeValues: Array<SupplierPartNumberCodeValue>
  ) {
    this.codeItem = codeItem;
    this.description = description;
    this.codeValues = codeValues;
  }
}
export class SupplierPartNumberCodeValue {
  id: number = 0;
  codeValue: string = "";
  desciption: string = "";
  constructor(id:number,codeValue: string, description: string) {
    this.codeValue = codeValue;
    this.desciption = description;
    this.id = id;
  }
}
var codeValueArray1 = [
  new SupplierPartNumberCodeValue(0,"TP", "AGZ-F Pump Package"),
];
var codeValueArray2 = [
  new SupplierPartNumberCodeValue(1,"S", "Single"),
  new SupplierPartNumberCodeValue(2,"D", "Dual"),
];
var codeValueArray3 = [
  new SupplierPartNumberCodeValue(3,"24", "A240"),
  new SupplierPartNumberCodeValue(4,"50", "A502"),
  new SupplierPartNumberCodeValue(5,"1K", "1000"),
];
var codeValueArray4 = [
  new SupplierPartNumberCodeValue(6,"M", "Future"),
  new SupplierPartNumberCodeValue(7,"N", "Future"),
  new SupplierPartNumberCodeValue(8,"P", "1.25x1.25x5"),
  new SupplierPartNumberCodeValue(9,"Q", "1.5x1.5x5"),
  new SupplierPartNumberCodeValue(10,"A", "1.5x1.5x6"),
  new SupplierPartNumberCodeValue(11,"B", "1.5x1.5x8"),
  new SupplierPartNumberCodeValue(12,"R", "2x2x5"),
  new SupplierPartNumberCodeValue(13,"C", "2x2x6"),
  new SupplierPartNumberCodeValue(14,"D","2x2x8"),
  new SupplierPartNumberCodeValue(15,"S","2.5x2.5x5"),
  new SupplierPartNumberCodeValue(16,"T","3x3x5"),
  new SupplierPartNumberCodeValue(17,"T","3x3x6"),
];
var codeValueArray5 = [
  new SupplierPartNumberCodeValue(18,"C", "1.5 HP"),
  new SupplierPartNumberCodeValue(19,"D", "2 HP"),
  new SupplierPartNumberCodeValue(20,"E", "3 HP"),
  new SupplierPartNumberCodeValue(21,"G", "5 HP"),
  new SupplierPartNumberCodeValue(22,"H", "7.5 HP"),
  new SupplierPartNumberCodeValue(23,"J", "10 HP"),
  new SupplierPartNumberCodeValue(24,"K", "15 HP"),
  new SupplierPartNumberCodeValue(25,"L", "20 HP"),
  new SupplierPartNumberCodeValue(26,"M", "25 HP"),
  new SupplierPartNumberCodeValue(27,"N", "30 HP"),
  new SupplierPartNumberCodeValue(28,"P", "40 HP"),
  new SupplierPartNumberCodeValue(29,"R", "50 HP"),
];
var codeValueArray6 = [
  new SupplierPartNumberCodeValue(30,"3", "3000-3300"),
  new SupplierPartNumberCodeValue(31,"7", "3600-3960"),
  new SupplierPartNumberCodeValue(32,"8", "4500-4950"),
];
var codeValueArray7 = [
  new SupplierPartNumberCodeValue(33,"V", "Variable Speed"),
  new SupplierPartNumberCodeValue(34,"S", "Fixed Speed"),
];
var codeValueArray8 = [
  new SupplierPartNumberCodeValue(35,"N", "None"),
  new SupplierPartNumberCodeValue(36,"G", "Pressure Gauges Provided"),
];
var codeValueArray9 = [
  new SupplierPartNumberCodeValue(37,"V", "With Suction and Discharge water shutoff valves"),
  new SupplierPartNumberCodeValue(38,"N", "Pressure Gauges Provided"),
];
var codeValueArray10 = [
  new SupplierPartNumberCodeValue(39,"H", "With Heat Trace"),
  new SupplierPartNumberCodeValue(40,"N", "No Heat Trace"),
];
var codeValueArray11 = [
  new SupplierPartNumberCodeValue(41,"2", "208-230V"),
  new SupplierPartNumberCodeValue(42,"4", "460V"),
  new SupplierPartNumberCodeValue(43,"6", "575V"),
];
var codeValueArray12 = [
  new SupplierPartNumberCodeValue(44,"NNN", ""),
];
export var listPartNumber = [
  new SupplierPartNumberCodeItem(1, "UNIT TYPE", codeValueArray1),
  new SupplierPartNumberCodeItem(2, "# OF PUMPS", codeValueArray2),
  new SupplierPartNumberCodeItem(3, "EVAP MODEL", codeValueArray3),
  new SupplierPartNumberCodeItem(4, "PUMP HOURS & IMPEL SIZE", codeValueArray4),
  new SupplierPartNumberCodeItem(5, "MOTOR HORSEPOWER", codeValueArray5),
  new SupplierPartNumberCodeItem(6, "MOTOR RPM", codeValueArray6),
  new SupplierPartNumberCodeItem(7, "STARTER TYPE", codeValueArray7),
  new SupplierPartNumberCodeItem(8, "GAUGES", codeValueArray8),
  new SupplierPartNumberCodeItem(9, "VALVES", codeValueArray9),
  new SupplierPartNumberCodeItem(10, "HEAT TRACE", codeValueArray10),
  new SupplierPartNumberCodeItem(11, "VOLTAGE", codeValueArray11),
  new SupplierPartNumberCodeItem(12, "FUTURE", codeValueArray12),
];
