import { Goal } from "./Goal";

class GoalsExtensions {
  toGoal(raw: Goal) {
    return new Goal(
      raw.id,
      raw.goalTypeId,
      raw.goalName,
      raw.value,
      raw.startDate,
      raw.endDate
    );
  }
}

const goalExtension = new GoalsExtensions();
export default goalExtension;
