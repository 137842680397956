import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OpenOrder } from "../../services/Orders/Models/Order";
import stringHelper from "../../services/Core/Helpers/string-helper";
import orderService from "../../services/Orders/OrderService";
import {
  ReactTableHeaderOptions,
  TableExportDataButton,
  filterFnDate,
  getTableHeaderSortProps,
} from "../../components/react-table/react-table-component";
import Pagination from "../../components/react-table/Pagination";
import numberHelper from "../../services/Core/Helpers/number-helper";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../components/Core/Loading";
import { toast } from "react-toastify";
import { ColumnVisibilityComponent } from "../../components/react-table/column-visibility-component";
import useColumnVisibility from "../../hooks/React table/useColumnVisibility";
import useColumnFilters from "../../hooks/React table/useColumnFilters";
import ResetFilterComponent from "../../components/react-table/react-table-header-options";
import {
  SelectComponent,
  SelectOption,
} from "../../components/Forms/select-component";

type IParamTypes = {
  employeeId: string;
};

export default function OpenOrdersTable() {
  const { t, i18n } = useTranslation();
  const { employeeId } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<OpenOrder>>([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const {
    columnFilters,
    editFilterFn,
    setColumnFilters,
    resetFiltersFn,
    getFilterValueFn,
  } = useColumnFilters();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    if (employeeId) {
      orderService
        .getOpenOrders(employeeId)
        .then((data) => {
          if (data) {
            setData(data.map((elem) => new OpenOrder(elem)));

            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error("An error occured while fetching the orders");
        });
    }
  }, [employeeId]);

  const columns: any = useMemo(() => {
    return [
      {
        header: "orders-page.date",
        accessorKey: "orderDate",
        cell: (props: any) => stringHelper.toDateString(props.getValue()),
        filterFn: filterFnDate,
      },
      {
        header: "orders-page.status",
        accessorKey: "usrOrderStatus",
        filterFn: "equalsString",
      },
      {
        header: "orders-page.order-id",
        accessorKey: "orderId",
      },
      {
        header: "orders-page.po-num",
        accessorKey: "poNum",
      },
      {
        header: "orders-page.sold-to",
        accessorKey: "soldTo",
      },
      {
        header: "orders-page.salesman",
        accessorKey: "salesPerson",
        filterFn: "equalsString",
      },
      {
        header: "orders-page.total",
        accessorKey: "total",
      },
      {
        header: "orders-page.remarks",
        accessorKey: "remarks",
        cell: (props: any) => {
          return <p>{props.getValue()}</p>;
        },
      },
    ];
  }, [i18n]);

  const table = useReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: { "mrt-row-expand": true },
    },
    state: {
      globalFilter,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  const iColumnVisibility = useColumnVisibility(table, columnVisibility);

  const SalesRepsOptions = useMemo(generateSalesRepOptions, [data]);

  const { totalUS, totalCAD } = useMemo(() => {
    let tempUS = 0;
    let tempCAD = 0;

    table.getFilteredRowModel().rows.map((item) => {
      let obj = item.original as OpenOrder;
      if (item.original.currencyCode === "USD") {
        tempUS += obj.totAmnt;
      } else {
        tempCAD += obj.totAmnt;
      }
    });

    return {
      totalUS: numberHelper.toCurrencyFormat(tempUS, "USD"),
      totalCAD: numberHelper.toCurrencyFormat(tempCAD, "CAD"),
    };
  }, [columnFilters, data]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div id="filters">
        <div className="row mb-2">
          <div className="col-lg-3 col-sm-12">
            <fieldset className="border rounded-3 p-1">
              <legend className="float-none w-auto px-3">Status:</legend>
              <SelectComponent
                className="form-select-sm"
                defaultValue={getFilterValueFn("usrOrderStatus")}
                onChange={(value) => {
                  editFilterFn("usrOrderStatus", value);
                }}
                options={[
                  { text: "All", value: "" },
                  { text: t("invoices.states-approved"), value: "Approved" },
                  { text: t("invoices.states-submitted"), value: "Submitted" },
                ]}
              />
            </fieldset>
          </div>

          <div className="col-lg-3 col-sm-12">
            <fieldset className="border rounded-3 p-1">
              <legend className="float-none w-auto px-3">Sales Rep:</legend>
              <SelectComponent
                className="form-select-sm"
                defaultValue={getFilterValueFn("salesPerson")}
                onChange={(value) => {
                  editFilterFn("salesPerson", value);
                }}
                options={SalesRepsOptions}
              />
            </fieldset>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-12">
            <fieldset className="border rounded-3 p-1">
              <legend className="legend float-none w-auto px-3">Date:</legend>
              <input
                type="month"
                className="form-control form-control-sm"
                defaultValue={stringHelper.toMonthYearString(
                  new Date(Date.now())
                )}
                onChange={(ev) => editFilterFn("orderDate", ev.target.value)}
              />
            </fieldset>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-12">
            <fieldset className="border rounded-3 p-1">
              <legend className="legend float-none w-auto px-3">Total:</legend>
              {totalUS} | {totalCAD}
            </fieldset>
          </div>
        </div>
      </div>

      <div id="search-bar" className="m-3">
        <div className="input-group">
          <input
            type="search"
            className="form-control form-control-sm rounded"
            placeholder={t("common.search") || ""}
            onChange={(ev) => setGlobalFilter(ev.target.value)}
          />
        </div>
      </div>

      <div id="open-orders-table">
        <table className="table">
          <thead>
            <tr className="table-options">
              <th
                colSpan={
                  table.getAllFlatColumns().filter((x) => x.getIsVisible())
                    .length
                }
              >
                <ColumnVisibilityComponent
                  columns={iColumnVisibility}
                  className="me-1"
                />

                <ResetFilterComponent
                  resetFilterFn={resetFiltersFn}
                  className="me-1"
                />

                <TableExportDataButton
                  data={getExportData()}
                  filename="open-orders"
                />
              </th>
            </tr>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      <span {...getTableHeaderSortProps(header.column)}>
                        {t(header.column.columnDef.header as string)}
                        <ReactTableHeaderOptions header={header} />
                        <br />
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i: number) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className="align-vertical-center" key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination reactTable={table} siblingCount={2} />
      </div>
    </>
  );

  function getExportData(): Array<any> {
    let temp: Array<any> = table.getFilteredRowModel().rows.map((item) => {
      var data = item.original as OpenOrder;

      return {
        Date: stringHelper.toDateString(data.orderDate),
        Status: data.usrOrderStatus,
        "No.Commande": data.orderId,
        "Bon Commande": data.poNum,
        "Vendu à": data.soldTo,
        "Sales Person": data.salesPerson,
        Total: numberHelper.toCurrencyFormat(data.totAmnt),
        Remarques: data.remarks,
      };
    });

    if (!totalCAD.endsWith("0.00")) {
      temp.push({ Total: totalCAD });
    }

    if (!totalUS.endsWith("0.00")) {
      temp.push({ Total: totalUS });
    }

    return temp;
  }

  function generateSalesRepOptions(): Array<SelectOption> {
    let temp: Array<string> = ["..."];

    data.map((item) => {
      if (!temp.includes(item.salesPerson) && item.salesPerson !== "")
        temp.push(item.salesPerson);
    });

    return temp.map((val) => {
      if (val === "...") return { text: val, value: "" };
      return { text: val, value: val };
    });
  }
}
