import { useState } from "react";
import { useParams } from "react-router-dom";
import orderService from "../../services/Orders/OrderService";

import { useTranslation } from "react-i18next";
import "./order-history-progress.scss";
import { OrderHistoryProgress } from "../../services/Orders/Models/Order";
import { toast } from "react-toastify";
import {
  ProgressBar,
  ProgressBarComponent,
} from "../../components/Charts/ProgressBarComponent";

type IParamTypes = {
  userId: string;
};

export const OrderHistoryProgressTable = () => {
  const { userId } = useParams<IParamTypes>();
  const { t } = useTranslation();

  const [ProgressBarData, setProgressBarData] =
    useState<Array<ProgressBar> | null>(null);
  const [quoteId, setQuoteId] = useState<string>();
  const [OrderHistoryHdr, setOrderHistoryHdr] =
    useState<OrderHistoryProgress | null>(null);

  const searchButtonClick = () => {
    loadHdr();
    loadProgressBar();
  };
  return (
    <div id="order-history-progress-section">
      <div className="input-group">
        <input
          type="search"
          className="input-search"
          placeholder={"Enter QuoteId"}
          onChange={(ev) => setQuoteId(ev.target.value)}
          onKeyUp={(ev) => {
            if (ev.key === "Enter") {
              searchButtonClick();
            }
          }}
        />
        <button
          id="btn-search"
          className="btn btn-outline-primary"
          onClick={searchButtonClick}
        >
          Search
        </button>
      </div>

      {OrderHistoryHdr && ProgressBarData ? (
        OrderHistoryHdr.quoteId === 0 ? (
          <div className="error-container">
            <p>Quote ID not found!</p>
          </div>
        ) : (
          <div className="container-history">
            <div className="card">
              <div className="card-header">
                <h3>{t("order-history-progress-status-page.card-header")}</h3>
              </div>
              <div className="card-body">
                <tbody>
                  <tr>
                    <th>{t("order-history-progress-status-page.quote")} :</th>
                    <td>{OrderHistoryHdr ? OrderHistoryHdr.quoteId : ""}</td>
                  </tr>
                  <tr>
                    <th>{t("order-history-progress-status-page.po")}:</th>
                    <td>{OrderHistoryHdr ? OrderHistoryHdr.po : ""}</td>
                  </tr>
                  <tr>
                    <th>{t("order-history-progress-status-page.client")} :</th>
                    <td>{OrderHistoryHdr ? OrderHistoryHdr.client : ""}</td>
                  </tr>
                  <tr>
                    <th>{t("order-history-progress-status-page.invoice")} :</th>
                    <td>{OrderHistoryHdr ? OrderHistoryHdr.invoice : ""}</td>
                  </tr>
                </tbody>
              </div>
            </div>
            {ProgressBarData &&
              ProgressBarData.length > 0 &&
              ProgressBarData.map((progressBar, index) => (
                <div className="card-footer">
                  <b>{progressBar.title}</b>
                  <ProgressBarComponent key={index} progressBar={progressBar} />
                </div>
              ))}
          </div>
        )
      ) : null}
    </div>
  );

  function loadHdr() {
    if (userId && quoteId) {
      orderService
        .getOrderHistoryProgressHdr(userId, quoteId)
        .then((res) => {
          //console.log(res);
          if (!res) {
            console.error("Get order history header response is undefined");
          } else {
            const result = new OrderHistoryProgress(res);
            setOrderHistoryHdr(result);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  function loadProgressBar() {
    if (userId && quoteId) {
      orderService
        .getOrderHistoryProgress(userId, quoteId)
        .then((res) => {
          // console.log(res);
          if (res === undefined) {
            console.error(
              "Get order history progressbar response is undefined"
            );
          } else {
            setProgressBarData(res);
          }
        })
        .catch(() => {
          toast.error("An error occured while fetching the data");
        });
    }
  }
};
