import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./page-component.scss";
import { faX , faPencil} from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { MouseEventHandler } from "react";

export type PageProps = {
  id?: string;
  className?: string;
  children?: React.ReactNode;
};

export function Page(props: PageProps) {
  const divProps = {
    id: props.id,
    className: `page ${props.className ?? ""}`,
  };

  return <div {...divProps}>{props.children}</div>;
}

export function PageHeader(props: PageProps) {
  const divProps = {
    id: props.id,
    className: `page-header ${props.className ?? ""}`,
  };

  return <div {...divProps}>{props.children}</div>;
}

export function PageInfo(props: PageProps) {
  const divProps = {
    id: props.id,
    className: `page-info ${props.className ?? ""}`,
  };

  return <div {...divProps}>{props.children}</div>;
}

const PageTitleClass = "page-title";
export function PageTitle(props: PageProps) {
  const divProps = {
    id: props.id,
    className: `${PageTitleClass} ${props.className ?? ""}`,
  };

  return <div {...divProps}>{props.children}</div>;
}

const PageDetailsClass = "page-details";
export function PageDetails(props: PageProps) {
  const divProps = {
    id: props.id,
    className: `${PageDetailsClass} ${props.className ?? ""}`,
  };

  return <div {...divProps}>{props.children}</div>;
}

const PageFooterClass = "page-footer page-separator";
export function PageFooter(props: PageProps) {
  const divProps = {
    id: props.id,
    className: `${PageFooterClass} ${props.className ?? ""}`,
  };

  return <div {...divProps}>{props.children}</div>;
}

type ClosePageBtnProps = {
  id?: string;
  className?: string;
  size?: SizeProp;
  title?: string;

  onClick?: MouseEventHandler<HTMLAnchorElement>;
};
export function ClosePageButton(props: ClosePageBtnProps) {
  return (
    <a
      id={props.id}
      className={`btn ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      <FontAwesomeIcon size={props.size} icon={faX} />
    </a>
  );
}

type EditPageBtnProps = {
  id?: string;
  className?: string;
  size?: SizeProp;
  title?: string;

  onClick?: MouseEventHandler<HTMLAnchorElement>;
};
export function EditPageButton(props: EditPageBtnProps) {
  return (
    <a
      id={props.id}
      className={`btn ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      <FontAwesomeIcon size={props.size} icon={faPencil} />
    </a>
  );
}