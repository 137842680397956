
import BaseApi from "../Core/REST/BaseApi";
import { AssemblyControl } from "./Models/AssemblyControl";
import { Client } from "../Users/Client";


class AssemblyControlService extends BaseApi {

  async GetAssemblyControl(
    externalFileId: string,
  ) : Promise<AssemblyControl | undefined>{

    let url = `${this.baseUrl}/AssemblyControl/${externalFileId}`;
    var assemblyControl : AssemblyControl|undefined = await this.get(url);
    if(assemblyControl)
    {
      if(assemblyControl.creationDate)
        assemblyControl.creationDate = new Date(assemblyControl.creationDate);
      if(assemblyControl.testDate)
        assemblyControl.testDate = new Date(assemblyControl.testDate);
    }

    return assemblyControl;
  }
  async GetAllValidWitness() : Promise<Array<Client>|undefined>
  {
    let url = `${this.baseUrl}/AssemblyControl/Get-All-Valid-Witness`;
    return await this.get(url);
  }
  async SaveAssemblyControl(assemblyControl:AssemblyControl,employeeId:string)
  {
    let url = `${this.baseUrl}/AssemblyControl/Save-Assembly-Control/${employeeId}`;
    return await this.put(url,assemblyControl);
  }
  async ValidateAssemblyControl(assemblyControl:AssemblyControl,employeeId:string)
  {
    let url = `${this.baseUrl}/AssemblyControl/Validate-Assembly-Control/${employeeId}`;
    return await this.put(url,assemblyControl);
  }
}

const assemblyControlService = new AssemblyControlService();
export default assemblyControlService;
