import BaseApi from "../Core/REST/BaseApi";
import { VfdRow, VfdSelectionTable } from "./Models/VfdSelectionTable";


class MfvService extends BaseApi {
    async getPumpVfds(userId: string, kitId: string): Promise<VfdSelectionTable | undefined> {
        let url = `${this.baseUrl}/VariableFrequencyDrive/${userId}/get-vfds/${kitId}`;
        return await this.get<VfdSelectionTable | undefined>(url);
    }
    async addVFDtoQuote(userId: string, quoteId: string, kitId: string, VfdSelected: VfdRow): Promise<VfdRow | undefined> {
        let url = `${this.baseUrl}/VariableFrequencyDrive/${userId}/add-to-quote/${quoteId}/${kitId}`;
        return await this.post(url, VfdSelected);
    }
}

const mfvService = new MfvService();
export default mfvService;