type Props = {
  id: string;
  defaultValue: any;
  type?: string;
  onchange: Function;
  readonly?: boolean;
  title: string;
};

export default function FormFloatingComponent(props: Props) {
  const { id, defaultValue, type, onchange, readonly, title } = props;

  return (
    <div className="form-floating">
      <input
        type={type ?? "text"}
        className="form-control text-center"
        id={id}
        value={defaultValue}
        onChange={(ev) => onchange(ev)}
        readOnly={readonly}
      />
      <label className="form-floating-label" htmlFor={id}>
        {title}
      </label>
    </div>
  );
}
