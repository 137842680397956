
import { FormCheck } from "react-bootstrap";
import { useState, useMemo } from "react";
import { Client } from "../../services/Users/Client";
import { useTranslation } from "react-i18next";
import Reminder from "../../services/Reminder/model/Reminder";
import stringHelper from "../../services/Core/Helpers/string-helper";
import { toast } from "react-toastify";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ReminderFormPopup } from "./reminder-menu-form-popup";

import remindersService from "../../services/Reminder/ReminderService";
type TableProps = {
  reminderArray: Array<Reminder>;
  initialColumnVisibility?: {};
  user: Client;
  globalFilter: string
};
export default function ReminderTable({
  initialColumnVisibility,
  reminderArray,
  user,
  globalFilter
}: TableProps) {
  const { t } = useTranslation();
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const [openedReminder,setOpenedReminder] = useState<Reminder>();
  const columns: any = useMemo(() => {
      return [
        {
          header: t("reminder-menu.finished"),
          accessorKey: "id",
          cell: (props: any) => (
            <FormCheck
            defaultChecked={props.row.original.finishedDate != undefined}
            onClick={(e)=>e.stopPropagation()}
            onChange={(e) => {
              remindersService
                .checkReminder(props.row.original)
                .then(()=>{
                  toast.success(t("reminder-menu.update-success"));
                })
                .catch(() => {
                  toast.error("reminder-menu.check-reminder-error");
                });
            }}
          ></FormCheck>
          ),
        },
        {
          header: t("reminder-menu.title"),
          accessorKey: "title"
        },
        {
          header: t("reminder-menu.assigned-to"),
          accessorKey: "assignedTo.nom",
        },
        {
          header: t("reminder-menu.date"),
          accessorKey: "date",
          cell: (props: any) => (
            <span>{props.getValue() && stringHelper.toDateTimeString(new Date(props.getValue()))}</span>
          ),
        },
        {
          header: t("reminder-menu.finished-date"),
          accessorKey: "finishedDate",
          cell: (props: any) => (
            <span>{stringHelper.toDateTimeString(new Date(props.getValue()))}</span>
          ),
        },
      ];
  }, []);
  const table = useReactTable({
    columns,
    data: reminderArray,
    enableColumnFilters: true,
    enableHiding: true,
    initialState: {
      columnVisibility: columnVisibility,
    },
    state: {
      globalFilter,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <section id="quote-priority-table">
      {openedReminder && <ReminderFormPopup
      user={user}
      isOpen={openedReminder !== undefined}
      closeAction={()=>setOpenedReminder(undefined)}
      reminder={openedReminder}
      ></ReminderFormPopup>}
      <table className="table table-hover">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                let headerName = header.column.columnDef.header?.toString();
                return (
                  <th key={header.id} className="columnName">
                    <span title={headerName}>{headerName}</span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className={"reminder-row-"+row.original.priority}
                onClick={() =>
                    setOpenedReminder(row.original)
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
}
