export class QuoteHistory {
  id: number;
  quoteId: number;
  clientId: number;
  date: Date;
  description: string;

  username: string;

  constructor(data: any) {
    this.id = data.id;
    this.quoteId = data.quoteId;
    this.clientId = data.clientId;
    this.date = new Date(data.date);
    this.description = data.description;
    this.username = data.username;
  }
}
