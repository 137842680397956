import "./pump-curve-page.scss";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../components/Pages/page-components";
import { FormControl, FormLabel, FormSelect } from "react-bootstrap";
import PumpSearchPump from "../../../services/Pumps/Models/PumpSearchPump";
import pumpService from "../../../services/Pumps/PumpService";
import LoadingComponent from "../../../components/Core/Loading";

type IParamTypes = {
  curvePath: string;
  quoteId: string;
};

export default function PumpCurvePage() {
  const { t } = useTranslation();
  const location = useLocation();
  const pump = location.state?.data || [];
  const { curvePath, quoteId } = useParams<IParamTypes>();
  const decodedCurvePath = decodeURIComponent(curvePath ? curvePath : "");
  const [selectedPump, setSelectedPump] = useState<PumpSearchPump | null>(null);
  const [path, setPath] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Example logic to set selectedPump, adjust as needed
    if (pump && !selectedPump) {
  
        //console.log(pump)
        setSelectedPump(pump);
        if (curvePath) {
          setPath(decodedCurvePath);
        }
      } 
        // const convertedPump: PumpSearchPump = {
        //   modelId: pump.pumpId,
        //   modelName: pump.model,
        //   seriesId: pump.seriesId,
        //   seriesName: pump.pumpType,
        //   rpm: pump.rpm,
        //   impellerDiameter: pump.diam.toString(),
        //   efficiencyPercent: "",
        //   power: pump.hp,
        //   bhp:"",
        //   nol: pump.nol.toString(),
        //   npshr: pump.suct.toString(),
        //   suct: pump.suct.toString(),
        //   disc: pump.disc.toString(),
        //   listPrice: pump.netPrice.toString(),
        //   curveLinkString: "",
        //   completeCurveLinkString: "",
        //   linkToNewPumpCurveString: "",
        //   curvePath: "",
        //   hasBindedItems: false,
        //   extraInfo: "",
        //   construction: pump.sConstruction,
        //   constructionId: pump.construction,
        //   flowRate: pump.flow,
        //   head: pump.head,
        //   minEff: pump.minimumEfficiency,
        //   flangeType: parseInt(pump.flangeType),
        //   isNol: pump.nol,
        //   glycolPercent: pump.glycol,
        //   isPremiumEfficiencyMotorNotNorthAmerican:
        //     pump.premiumEfficiencyMotorNotNorthAmerican,
        //   isPremiumEfficiency: pump.premiumEfficiency,
        //   phase: pump.phase,
        //   motorId: 0,
        //   motorType: parseInt(pump.motorType),
        //   isBarePump: pump.barePump,
        //   netPrice: pump.netPrice,
        //   quoteId: pump.quoteId,
        //   qty: pump.qty,
        //   tag: pump.tag,
        //   isMultiFunctionValve: false,
        //   isSuctionDiffuser: false,
        //   isVariableFrequencyDrive: false,
        //   isInventory: false,
        //   options: [],
        // };
        ///setSelectedPump(convertedPump);
     
    
  }, [pump]);

  useEffect(() => {
    if (selectedPump) {
      if (pump.quoteKitId === undefined && pump.id === undefined) {
           showCurve();
    }
    else{
      showQuoteCurve();
    }
  }
    
    
  }, [selectedPump]);
  useEffect(() => {}, [path]);

  const handleSelectChange = (e: any) => {
    const value = e.target.value;

    if (value === "0") {
      setSelectedPump({
        ...pump,
        impellerDiameter: -1,
      });
    } else if (value === "1") {
      setSelectedPump({
        ...pump,
        head: -1,
      });
    } else if (value === "2") {
      setSelectedPump({
        ...pump,
        flow: -1,
      });
    }
  };

  return (
    <div id="pump-curve-page">
      <PageTitle>
        <div className="row text-center">
          <div className="col-auto align-self-center">
            <h1 className="h2">{t("pump-curve-page.title")}</h1>
          </div>
        </div>
      </PageTitle>
        {selectedPump ? (
        <div className="page-load">
        <div className="form">
        <div className="form-container">
        <label htmlFor="glycol" className="form-label">
          {t("pump-curve-page.glycol")}
        </label>
        <FormControl
          id="glycol1"
          value={selectedPump?.glycolPercent ? selectedPump.glycolPercent : 0}
          size="sm"
          className="form-control active"
          type=""
          //   onChange={(ev) => {
          //     setValue("jobName", ev.target.value);
          //   }
        />
        %at
        <FormControl
          id="glycol1"
          defaultValue={140}
          size="sm"
          className="form-control active"

          //   onChange={(ev) => {
          //     setValue("jobName", ev.target.value);
          //   }
        />
        F
      </div>
      <div className="form-container">
        <FormLabel htmlFor="rpm" className="form-label">
          {t("pump-curve-page.rpm")}
        </FormLabel>
        <FormSelect
          id="rpm"
          defaultValue={pump.rpm ? pump.rpm : 1150}
          size="sm"
          className="active"
        >
          <option value={1150}>1150, 60Hz</option>
          <option value={1750}>1750, 60Hz</option>
          <option value={3450}>3450, 60Hz</option>
          <option value={970}>970, 50Hz</option>
          <option value={1450}>1450, 50Hz</option>
          <option value={2900}>2900, 50Hz</option>
          <option value={0}>Other</option>
        </FormSelect>
      </div>
      <div className="form-container">
        <FormLabel htmlFor="systemCurve">
          {t("pump-curve-page.system-curve")}
        </FormLabel>
        <FormSelect
          id="systemCurve"
          defaultValue={1}
          size="sm"
          className="form-control active"
        >
          <option value={0}>Do not show system curve</option>
          <option value={1}>Show automatically generated system curve</option>
          <option value={2}>Let me enter system curve conditions</option>
        </FormSelect>
      </div>
      <div className="form-container">
        <FormLabel htmlFor="parallelPumps" className="form-label">
          {t("pump-curve-page.parallel-pumps")}
        </FormLabel>
        <FormSelect
          id="parallelPumps"
          defaultValue={1}
          size="sm"
          className="form-control active"
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
        </FormSelect>
      </div>
      <div className="form-container">
        <FormLabel htmlFor="unit" className="form-label">
          {t("pump-curve-page.unit")}
        </FormLabel>
        <FormSelect
          id="unit"
          defaultValue={0}
          size="sm"
          className="form-control active"
          onChange={handleSelectChange}
        >
          <option value={0}>Diameter</option>
          <option value={1}>Ft</option>
          <option value={2}>GPM</option>
        </FormSelect>
      </div>
      <div className="button-containers">
        <button
          className="btn btn-primary"
          onClick={() => handleRegenarteCurve()}
        >
          {t("pump-curve-page.regenerate")}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleAdvanceSearch()}
        >
          {t("pump-curve-page.advance-search")}
        </button>
      </div>
    </div>
    <div className="pump-info">
      <div className="labels-row">
        <label htmlFor="model" className="label-row">
          {t("pump-curve-page.model")}:
        </label>
        <label htmlFor="type" className="label-row">
          {t("pump-curve-page.type")}:
        </label>
        <label htmlFor="motor" className="label-row">
          {t("pump-curve-page.motor")}:
        </label>
      </div>
      <div className="values-row">
        <label className="label-value">{selectedPump?.modelName}</label>
        <label className="label-value">{selectedPump?.seriesName}</label>
        <label className="label-value">{`${
          selectedPump ? (selectedPump.isNol ? "NOL" : "BHP") : ""
        } - 
  ${selectedPump ? (selectedPump.motorType == 1 ? "ODP" : "TEFC") : ""} - 
  ${selectedPump ? selectedPump.rpm : ""} RPM`}</label>
      </div>

      <div className="labels-row">
        <label htmlFor="gpm" className="label-row">
          {t("pump-curve-page.gpm")}:
        </label>
        <label htmlFor="head" className="label-row">
          {t("pump-curve-page.head")}:
        </label>
        <label htmlFor="diam" className="label-row">
          {t("pump-curve-page.diam")}:
        </label>
        <label htmlFor="bhp" className="label-row">
          {t("pump-curve-page.bhp")}:
        </label>
        <label htmlFor="nol" className="label-row">
          {t("pump-curve-page.nol")}:
        </label>
        <label htmlFor="npshr" className="label-row">
          {t("pump-curve-page.npshr")}:
        </label>
        <label htmlFor="eff" className="label-row">
          {t("pump-curve-page.eff")}:
        </label>
      </div>
      <div className="values-row">
        <label className="label-value">{selectedPump?.flowRate}</label>
        <label className="label-value">{selectedPump?.head}</label>
        <label className="label-value">
          {fixNumber(selectedPump?.impellerDiameter)}
        </label>
        <label className="label-value">{fixNumber(selectedPump?.bhp)}</label>
        <label className="label-value">{fixNumber(selectedPump?.nol)}</label>
        <label className="label-value">
          {fixNumber(selectedPump?.npshr) ? fixNumber(selectedPump?.npshr)   :  ""}
        </label>
        <label className="label-value">
          {fixNumber(selectedPump?.efficiencyPercent)}
        </label>
      </div>
      </div>
      </div>
      ):(
        <div className="page-load">
        <div className="form">
        <div className="form-container">
        <label htmlFor="glycol" className="form-label">
          {t("pump-curve-page.glycol")}
        </label>
        <FormControl
          id="glycol1"
          value={pump.glycol ? pump.glycol : 0}
          size="sm"
          className="form-control active"
          type=""
          //   onChange={(ev) => {
          //     setValue("jobName", ev.target.value);
          //   }
        />
        %at
        <FormControl
          id="glycol1"
          defaultValue={140}
          size="sm"
          className="form-control active"

          //   onChange={(ev) => {
          //     setValue("jobName", ev.target.value);
          //   }
        />
        F
      </div>
      <div className="form-container">
        <FormLabel htmlFor="rpm" className="form-label">
          {t("pump-curve-page.rpm")}
        </FormLabel>
        <FormSelect
          id="rpm"
          defaultValue={pump.rpm ? pump.rpm : 1150}
          size="sm"
          className="active"
        >
          <option value={1150}>1150, 60Hz</option>
          <option value={1750}>1750, 60Hz</option>
          <option value={3450}>3450, 60Hz</option>
          <option value={970}>970, 50Hz</option>
          <option value={1450}>1450, 50Hz</option>
          <option value={2900}>2900, 50Hz</option>
          <option value={0}>Other</option>
        </FormSelect>
      </div>
      <div className="form-container">
        <FormLabel htmlFor="systemCurve">
          {t("pump-curve-page.system-curve")}
        </FormLabel>
        <FormSelect
          id="systemCurve"
          defaultValue={1}
          size="sm"
          className="form-control active"
        >
          <option value={0}>Do not show system curve</option>
          <option value={1}>Show automatically generated system curve</option>
          <option value={2}>Let me enter system curve conditions</option>
        </FormSelect>
      </div>
      <div className="form-container">
        <FormLabel htmlFor="parallelPumps" className="form-label">
          {t("pump-curve-page.parallel-pumps")}
        </FormLabel>
        <FormSelect
          id="parallelPumps"
          defaultValue={1}
          size="sm"
          className="form-control active"
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
        </FormSelect>
      </div>
      <div className="form-container">
        <FormLabel htmlFor="unit" className="form-label">
          {t("pump-curve-page.unit")}
        </FormLabel>
        <FormSelect
          id="unit"
          defaultValue={0}
          size="sm"
          className="form-control active"
          onChange={handleSelectChange}
        >
          <option value={0}>Diameter</option>
          <option value={1}>Ft</option>
          <option value={2}>GPM</option>
        </FormSelect>
      </div>
      <div className="button-containers">
        <button
          className="btn btn-primary"
          onClick={() => handleRegenarteCurve()}
        >
          {t("pump-curve-page.regenerate")}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleAdvanceSearch()}
        >
          {t("pump-curve-page.advance-search")}
        </button>
      </div>
    </div>
    <div className="pump-info">
      <div className="labels-row">
        <label htmlFor="model" className="label-row">
          {t("pump-curve-page.model")}:
        </label>
        <label htmlFor="type" className="label-row">
          {t("pump-curve-page.type")}:
        </label>
        <label htmlFor="motor" className="label-row">
          {t("pump-curve-page.motor")}:
        </label>
      </div>
      <div className="values-row">
        <label className="label-value">{pump?.modelName}</label>
        <label className="label-value">{pump?.seriesName}</label>
        <label className="label-value">{`${
          pump ? (pump.isNol ? "NOL" : "BHP") : ""
        } - 
  ${pump ? (pump.motorType == 1 ? "ODP" : "TEFC") : ""} - 
  ${pump ? pump.rpm : ""} RPM`}</label>
      </div>

      <div className="labels-row">
        <label htmlFor="gpm" className="label-row">
          {t("pump-curve-page.gpm")}:
        </label>
        <label htmlFor="head" className="label-row">
          {t("pump-curve-page.head")}:
        </label>
        <label htmlFor="diam" className="label-row">
          {t("pump-curve-page.diam")}:
        </label>
        <label htmlFor="bhp" className="label-row">
          {t("pump-curve-page.bhp")}:
        </label>
        <label htmlFor="nol" className="label-row">
          {t("pump-curve-page.nol")}:
        </label>
        <label htmlFor="npshr" className="label-row">
          {t("pump-curve-page.npshr")}:
        </label>
        <label htmlFor="eff" className="label-row">
          {t("pump-curve-page.eff")}:
        </label>
      </div>
      <div className="values-row">
        <label className="label-value">{pump?.flow}</label>
        <label className="label-value">{pump?.head}</label>
        <label className="label-value">
          {fixNumber(pump?.impellerDiameter)}
        </label>
        <label className="label-value">{"fixNumber(pump?.bhp)"}</label>
        <label className="label-value">{"fixNumber(pump?.nolvalue)"}</label>
        <label className="label-value">
          {fixNumber(pump?.npshr) ? fixNumber(pump?.npshr)   :  ""}
        </label>
        <label className="label-value">
          {fixNumber(pump?.MinimumEfficiency)}
        </label>
      </div>
      </div>
      </div>
      )}
      <div className="pump-curve">
        <div className="curve-header">
          <h5>
            CURVES AND HP BASED ON WATER, SPECIFIC GRAVITY 1.0. PERFORMANCE
            GUARANTEED ONLY AT OPERATING POINT INDICATED.
          </h5>
        </div>
        <div className="curve-img">
          {path && <img src={`/images/jpg/${path}`} />}
        </div>
      </div>
      {loading && <LoadingComponent />}
    </div>
  );

  function showCurve() {
    if (selectedPump) {
      // setLoading(true);
      console.log("show curve")
      console.log(selectedPump)
      pumpService
        .generatePumpCurve(selectedPump)
        .then((res) => {
          if (res) {
            setPath(res);
            // setLoading(false);
          }
        })
        .catch((error) => {
          alert("Could not find matching pump for given conditions.");
          setLoading(false);
        });
    }
  }
  function showQuoteCurve() {
    if (pump) {
      pumpService
        .generateQuotePumpCurve(pump)
        .then((res) => {
          if (res) {
            setPath(res);
          }
        })
        .catch((error) => {
          alert("Could not find matching pump for given conditions.");
          setLoading(false);
        });
    }
  } 

  function fixNumber(num: any) {
    const value = parseFloat(num);
    return !isNaN(value) ? value.toFixed(2) : value;
  }
  function handleRegenarteCurve() {
    showCurve();
  }
  function handleAdvanceSearch() {
    window.location.href = "https://flofab.portal.intelliquip.com";
  }
}
