import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const [Language] = useState<string>(i18n.language);
  // console.log(i18n.languages)

  return (
    <div className="dropdown no-print">
      <a
        type="button"
        className="nav-link dropdown-toggle text-dark text-item p-0"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {t(`common.${i18n.language}`)}
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        <LanguageSelectorItem></LanguageSelectorItem>
      </ul>
    </div>
  );
}
export function LanguageSelectorItem() {
  const { t, i18n } = useTranslation();
  const [Language, setLanguage] = useState<string>(i18n.language);
  return (
    <>
      {i18n.languages.map((lang: string, index: number) => {
        if (lang !== Language) {
          return (
            <li key={index}>
              <a className="dropdown-item " onClick={() => updateLanguage(lang)}>
                {t(`common.${lang}`)}
              </a>
            </li>
          );
        }
      })}
    </>
  );
  function updateLanguage(newLanguage: string) {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  }
}
