import { useRef, useEffect, MutableRefObject } from "react";

type Props = {
  ref: any;
  onOutsideClick: () => void;
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(props: Props) {
  const { ref, onOutsideClick } = props;
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
