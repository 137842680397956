import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Client } from "../../services/Users/Client";
import { QuotePriority } from "../../services/QuotePriority/Models/QuotePriority";
import quotePriorityService from "../../services/QuotePriority/QuotePriorityService";
import {
  Page,
  PageDetails,
  PageHeader,
} from "../../components/Pages/page-components";
import { Button } from "react-bootstrap";
import LoadingComponent from "../../components/Core/Loading";
import BreadcrumbComponent from "../../components/Core/BreadcrumbComponent";
import CheckAllowedComponent from "../../components/CheckAllowedComponent";
import QuotePriorityTable from "./quote-priority-table";
import { toast } from "react-toastify";
type Props = {
  user: Client;
};
function QuotePriorityPage({ user }: Props) {
  const [priorityPump, setPriorityPump] = useState<Array<QuotePriority>>();
  const [priorityPackage, setPriorityPackage] =
    useState<Array<QuotePriority>>();
  const [priorityWelding, setPriorityWelding] =
    useState<Array<QuotePriority>>();
  const [priorityFinition, setPriorityFinition] =
    useState<Array<QuotePriority>>();
  const [editingPump, setEditingPump] = useState(false);
  const [editingPackage, setEditingPackage] = useState(false);
  const [editingWelding, setEditingWelding] = useState(false);
  const [editingFinition, setEditingFinition] = useState(false);

  const { t } = useTranslation();
  const pageTitle = t("quote-priority-page.quote-priority");
  const breadcrumbItems = [{ text: "Project Dashboard", link: `/Project-Dashboard` },{ text: pageTitle, active: true }];

  useEffect(() => {
    quotePriorityService
      .getAllPump()
      .then((res) => setPriorityPump(res))
      .catch(() => {
        toast.error(t("quote-priority-page.get-priority-pump-error"));
      });
    quotePriorityService
      .getAllPackage()
      .then((res) => setPriorityPackage(res))
      .catch(() => {
        toast.error(t("quote-priority-page.get-priority-package-error"));
      });
      quotePriorityService
      .getAllWelding()
      .then((res) => setPriorityWelding(res))
      .catch(() => {
        toast.error(t("quote-priority-page.get-priority-welding-error"));
      });
      quotePriorityService
      .getAllFinition()
      .then((res) => setPriorityFinition(res))
      .catch(() => {
        toast.error(t("quote-priority-page.get-priority-package-error"));
      });
  }, []);

  function submitPriorityPump() {
    if (editingPump && priorityPump) {
      if (!checkQuotePriorityDuplicate(priorityPump)) {
        quotePriorityService
          .updateAllPumpPriority(priorityPump)
          .then((res) => {
            setEditingPump(false);
          })
          .catch(() => {
            toast.error(t("quote-priority-page.submit-priority-pump-error"));
          });
      } else {
        toast.error(t("quote-priority-page.duplicate-error"));
      }
    } else {
      setEditingPump(true);
    }
  }
  function submitPriorityPackage() {
    if (editingPackage && priorityPackage) {
      if (!checkQuotePriorityDuplicate(priorityPackage)) {
        quotePriorityService
          .updateAllPackagePriority(priorityPackage)
          .then((res) => {
            setEditingPackage(false);
          })
          .catch(() => {
            toast.error(t("quote-priority-page.submit-priority-package-error"));
          });
      } else {
        toast.error(t("quote-priority-page.duplicate-error"));
      }
    } else {
      setEditingPackage(true);
    }
  }
  function submitPriorityWelding() {
    if (editingWelding && priorityWelding) {
      if (!checkQuotePriorityDuplicate(priorityWelding)) {
        quotePriorityService
          .updateAllWeldingPriority(priorityWelding)
          .then((res) => {
            setEditingWelding(false);
          })
          .catch(() => {
            toast.error(t("quote-priority-page.submit-priority-welding-error"));
          });
      } else {
        toast.error(t("quote-priority-page.duplicate-error"));
      }
    } else {
      setEditingWelding(true);
    }
  }
  function submitPriorityFinition() {
    if (editingFinition && priorityFinition) {
      if (!checkQuotePriorityDuplicate(priorityFinition)) {
        quotePriorityService
          .updateAllFinitionPriority(priorityFinition)
          .then((res) => {
            setEditingFinition(false);
          })
          .catch(() => {
            toast.error(t("quote-priority-page.submit-priority-finition-error"));
          });
      } else {
        toast.error(t("quote-priority-page.duplicate-error"));
      }
    } else {
      setEditingFinition(true);
    }
  }

  function checkQuotePriorityDuplicate(qp: QuotePriority[]) {
    var valueArr = qp.map(function (item) {
      return item.quoteExternalFileId;
    });
    return valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
  }

  return (
    <CheckAllowedComponent
      objectName="get-quote-priority"
      user={user}
      redirect={true}
    >

      <Page id="quote-priority-page">
        <PageHeader id="users-page-header" className="d-block">
          <BreadcrumbComponent items={breadcrumbItems} />
          <div className="d-flex">
            <h1 className="h3 mb-2">{pageTitle}</h1>
          </div>
        </PageHeader>
        <PageDetails>
        {priorityWelding ? (
            <>
              <div className="d-flex mb-2">
                <h4>{t("quote-priority-page.quote-priority-welding")}</h4>
                <CheckAllowedComponent
                  objectName="alter-quote-priority"
                  user={user}
                >
                  <Button
                    className="ms-2"
                    size="sm"
                    onClick={() => submitPriorityWelding()}
                  >
                    {editingWelding ? "Save" : "Edit"}
                  </Button>
                </CheckAllowedComponent>
              </div>
              <QuotePriorityTable
                editing={editingWelding}
                setQuotePriorityArray={setPriorityWelding}
                quotePriorityArray={
                  editingWelding ? priorityWelding : priorityWelding.slice(0, 5)
                }
                getQuotePossible={()=>quotePriorityService.getWeldingPossiblePriorities()}
              />
            </>
          ) : (
            <LoadingComponent />
          )}

          {priorityPump ? (
            <>
              <div className="d-flex mb-2">
                <h4>{t("quote-priority-page.quote-priority-pump")}</h4>
                <CheckAllowedComponent
                  objectName="alter-quote-priority"
                  user={user}
                >
                  <Button
                    className="ms-2"
                    size="sm"
                    onClick={() => submitPriorityPump()}
                  >
                    {editingPump ? "Save" : "Edit"}
                  </Button>
                </CheckAllowedComponent>
              </div>
              <QuotePriorityTable
                editing={editingPump}
                setQuotePriorityArray={setPriorityPump}
                quotePriorityArray={
                  editingPump ? priorityPump : priorityPump.slice(0, 5)
                }
                getQuotePossible={()=>quotePriorityService.getPumpPossiblePriorities()}
              />
            </>
          ) : (
            <LoadingComponent />
          )}

          {priorityPackage ? (
            <>
              <div className="d-flex  mb-2">
                <h4>{t("quote-priority-page.quote-priority-package")}</h4>
                <CheckAllowedComponent
                  objectName="alter-quote-priority"
                  user={user}
                >
                  <Button
                    className="ms-2"
                    size="sm"
                    onClick={() => submitPriorityPackage()}
                  >
                    {editingPackage ? "Save" : "Edit"}
                  </Button>
                </CheckAllowedComponent>
              </div>
              <QuotePriorityTable
                editing={editingPackage}
                setQuotePriorityArray={setPriorityPackage}
                quotePriorityArray={
                  editingPackage ? priorityPackage : priorityPackage.slice(0, 5)
                }
                getQuotePossible={()=>quotePriorityService.getPackagePossiblePriorities()}
              />
            </>
          ) : (
            <LoadingComponent />
          )}
          {priorityFinition ? (
            <>
              <div className="d-flex  mb-2">
                <h4>{t("quote-priority-page.quote-priority-finition")}</h4>
                <CheckAllowedComponent
                  objectName="alter-quote-priority"
                  user={user}
                >
                  <Button
                    className="ms-2"
                    size="sm"
                    onClick={() => submitPriorityFinition()}
                  >
                    {editingFinition ? "Save" : "Edit"}
                  </Button>
                </CheckAllowedComponent>
              </div>
              <QuotePriorityTable
                editing={editingFinition}
                setQuotePriorityArray={setPriorityFinition}
                quotePriorityArray={
                  editingFinition ? priorityFinition : priorityFinition.slice(0, 5)
                }
                getQuotePossible={()=>quotePriorityService.getFinitionPossiblePriorities()}
              />
            </>
          ) : (
            <LoadingComponent />
          )}
        </PageDetails>
      </Page>
    </CheckAllowedComponent>
  );
}
export default QuotePriorityPage;
