export class BoosterSystem {
  id: number;
  tag: string;
  type: number;
  nbPump: number;
  quoteId: number;
  totalFlow: number;
  bTank: boolean;
  typeNbPump: string;
  description: string;
  addToQuote: boolean;
  netPrice: number;
  cliCusPrice: number;
  listPrice: number;
  qty: number;
  imagePath: string;
  stdId: string;
  override: boolean;
  defaultItemsAreBinded: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.tag = data.tag;
    this.type = data.type;
    this.nbPump = data.nbPump;
    this.quoteId = data.quoteId;
    this.totalFlow = data.totalFlow;
    this.bTank = data.bTank;
    this.typeNbPump = data.typeNbPump;
    this.description = data.description.toString();
    this.addToQuote = data.addToQuote;
    this.netPrice = data.netPrice;
    this.cliCusPrice = data.cliCusPrice;
    this.listPrice = data.listPrice;
    this.qty = data.qty;
    this.imagePath = data.imagePath;
    this.stdId = data.stdId;
    this.override = data.override;
    this.defaultItemsAreBinded = data.defaultItemsAreBinded;
  }
}
