import BaseApi from "../Core/REST/BaseApi";
import Project,{ToDoAndLateJobs} from "./Model/Project";

class ProjectService extends BaseApi {
  async getProjects(employeeId: string): Promise<Array<Project> | undefined> {
    let url = `${this.baseUrl}/projects/${employeeId}`;
    return await this.get<Array<Project> | undefined>(url);
  }

  async postProjectKitCompleted(
    employeeId: string,
    projectId: number,
    kitId: string
  ) {
    let url = `${this.baseUrl}/projects/${employeeId}/Project-Kit/${projectId}?kitId=${kitId}`;
    return await this.post<any, boolean>(url, {});
  }

  async deleteProjectKitCompleted(employeeId: string, projectKitId: number) {
    let url = `${this.baseUrl}/projects/${employeeId}/Project-Kit/${projectKitId}`;
    return await this.delete<boolean>(url);
  }

  async patchProjectAssignedTo(
    employeeId: string,
    projectId: string,
    assignedTo: number | undefined
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/projects/${employeeId}/project/${projectId}/assigned-to/${assignedTo}`;
    return await this.patch(url, {});
  }

  async patchProjectState(
    employeeId: string,
    projectId: string,
    state: any
  ): Promise<boolean | undefined> {
    let url = `${this.baseUrl}/projects/${employeeId}/project/${projectId}/state/${state}`;
    return await this.patch(url, {});
  }

  async patchProjectNotes(
    employeeId: string,
    projectId: string,
    notes: string
  ) {
    let url = `${this.baseUrl}/projects/${employeeId}/project/${projectId}/notes`;
    return await this.patch(url, { notes });
  }
  async getNumberToDoAndLateProject(): Promise<ToDoAndLateJobs| undefined>
  {
    let url = `${this.baseUrl}/projects/get-number-to-do-late-projects`;
    return await this.get(url);
  }
}

const projectService = new ProjectService();
export default projectService;
