import { Client } from "../../services/Users/Client";
import './employee-card.scss';

type Props = {
  employee: Client | undefined;
};
export function EmployeeCard({ employee }: Props) {
  if (!employee) {
    return <></>;
  }
  return (
    <div id="employee-card" className="card">
      <div className="card-body">
        <img
          className="avatar"
          title="profil Image"
          onError={(e) => {
            e.currentTarget.src = `${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/default.jpg`;
          }}
          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/profiles/${
            employee.logoPath ? employee.logoPath : "default.jpg"
          }`}
        ></img>
        <div>
          <div className="employee-info-grid">
            <span className="name">{employee.nom}</span>
            <span className="email">{employee.email}</span>


          <span className="department-text">
            {(employee.department ?? "") + " " + (employee.role ?? "")}
          </span>
          </div>
        </div>
      </div>
    </div>
  );
}
