import { useEffect, useState } from "react";
import { GoalType } from "../../services/Goals/Goal";
import goalsService from "../../services/Goals/GoalService";

export function useGoalTypes(employeeId: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [goalTypes, setGoalTypes] = useState<Array<GoalType>>([]);

  useEffect(loadGoalTypes, [employeeId]);

  function loadGoalTypes(onSuccess?: Function) {
    if (employeeId) {
      goalsService
        .getGoalTypes(employeeId)
        .then((data) => {
          if (data) {
            // console.log("goal types : ", data);
            let goalTypesArray: Array<GoalType> = data.map((goalType) => {
              return new GoalType(goalType.id, goalType.name);
            });

            setGoalTypes(goalTypesArray);

            onSuccess && onSuccess();
          }
        })
        .finally(() => setLoading(false));
    }
  }

  return { goalTypes, loadingGoalTypes: loading, loadGoalTypes };
}
