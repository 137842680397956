import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { getSessionIdStringEmptyIfNull } from "../../Login/loginFunction";
export default class BaseApi {
  baseUrl: string = process.env.REACT_APP_API_BASE_URL || "";

  protected async get<T>(url: string): Promise<T | undefined> {
    const response = await axios
      .get<T>(url, await this.getHttpConfiguration())
      .catch((error: any) => {
        return this.handleError("GET", url, error);
      });
    return response?.data;
  }
  protected async getPdf<T>(url: string): Promise<T | undefined> {
    const response = await axios
      .get(url, {
        responseType: 'blob', 
        ...await this.getHttpConfigurationPdf(),
      })
      .catch((error: any) => {
        return this.handleError("GET", url, error);
      });
    return response?.data;
  }
  protected async post<Tin, Tout>(
    url: string,
    data: Tin
  ): Promise<Tout | undefined> {
    const response = await axios
      .post<Tout>(url, data, await this.getHttpConfiguration())
      .catch((error: any) => {
        return this.handleError("POST", url, error);
      });
    return response?.data;
  }
  protected async postFile<FormData, Tout>(
    url: string,
    data: FormData
  ): Promise<Tout | undefined> {
    const response = await axios
      .post<Tout>(url, data, await this.getHttpConfigurationFile())
      .catch((error: any) => {
        return this.handleError("POST", url, error);
      });
    return response?.data;
  }
  protected async patch<Tin, Tout>(
    url: string,
    data: Tin
  ): Promise<Tout | undefined> {
    const response = await axios
      .patch<Tout>(url, data, await this.getHttpConfiguration())
      .catch((error: any) => {
        return this.handleError("PATCH", url, error);
      });
    return response?.data;
  }

  protected async put<Tin>(url: string, data: Tin): Promise<any> {
    const response = await axios
      .put<Tin>(url, data, await this.getHttpConfiguration())
      .catch((error: any) => {
        return this.handleError("PUT", url, error);
      });
    return response;
  }

  protected async delete<Tout>(url: string): Promise<Tout | undefined> {
    const response = await axios
      .delete<Tout>(url, await this.getHttpConfiguration())
      .catch((error: any) => {
        return this.handleError("DELETE", url, error);
      });
    return response?.data;
  }

  protected async deleteAll<Tin, Tout>(url: string, data?: any): Promise<Tout | undefined> {
    const response = await axios
        .delete<Tout>(url, {
            ...await this.getHttpConfiguration(),
            data: data 
        })
        .catch((error: any) => {
            return this.handleError("DELETE", url, error);
        });
    return response?.data;
} 
  private getHttpConfiguration(token = getSessionIdStringEmptyIfNull()): any {
    return {
      headers: {
        Authorization:`Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
  }
  private getHttpConfigurationFile(token = getSessionIdStringEmptyIfNull()): any {
    return {
      headers: {
        Authorization:`Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
  }
  private getHttpConfigurationPdf(token = getSessionIdStringEmptyIfNull()): any {
    return {
      headers: {
        Authorization:`Bearer ${token}`,
        'Accept': 'application/pdf',
        "Access-Control-Allow-Origin": "*",
      },
    };
  }

  private handleError(method: string, url: string, error: AxiosError) {
    if (error.response) {
      if (error.response.status === 404) {
        return undefined;
      } else if (error.response.status === 400) {
        console.log(error.response.status);
        toast.error(
          `An error occured with the request : ${error.request.status}`
        );
      }

      console.error(
        `${method} ${url} error ${error.response.status} : '${error.response.statusText}'`
      );
    } else if (error.message) {
      console.error(`${method} ${url} error ${error.message}`);
    } else {
      console.error(`${method} ${url} unkown error`, error);
    }

    throw error;
  }
}
