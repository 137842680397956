import BaseApi from "../Core/REST/BaseApi";
import { Role } from "./Roles";
class RolesService extends BaseApi {
  async getAll(): Promise<Array<Role> | undefined>
  {
    let url = `${this.baseUrl}/Roles/getAll`;
    return await this.get<Array<Role> | undefined>(url);
  }
}

const rolesService = new RolesService();
export default rolesService;
