import BaseApi from "../Core/REST/BaseApi";
import { EmailConfig } from "./EmailConfig";
class EmailService extends BaseApi {
    async getAllEmailConfigs(): Promise<Array<EmailConfig> | undefined> {
        let url = `${this.baseUrl}/sendEmail/getEmailConfiguration`;

            return await this.get<Array<EmailConfig>| undefined>(url);

    }
    
    async postEmailConfig(newEmailConfig :EmailConfig) {
        let url = `${this.baseUrl}/sendEmail/updateEmailConfiguration`;
        //console.log("newEmailConfig", newEmailConfig);
        return await this.post(url,  newEmailConfig );
    }
}

const emailService = new EmailService();
export default emailService;