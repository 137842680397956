import BaseApi from "../Core/REST/BaseApi";
import { SdRow, SdSelectionTable } from "./Models/SdSelectionTable";

class MfvService extends BaseApi {
    async getPumpSds(userId: string, kitId: string): Promise<SdSelectionTable | undefined> {
        let url = `${this.baseUrl}/SuctionDiffuser/${userId}/get-sds/${kitId}`;
        return await this.get<SdSelectionTable | undefined>(url);
    }
    async addSDtoQuote(userId: string, quoteId: string, kitId: string, sdSelected: SdRow): Promise<SdRow | undefined> {
        let url = `${this.baseUrl}/SuctionDiffuser/${userId}/add-to-quote/${quoteId}/${kitId}`;
        return await this.post(url, sdSelected);
    }
}

const mfvService = new MfvService();
export default mfvService;