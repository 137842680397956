import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../components/Core/Loading";
import stringHelper from "../../services/Core/Helpers/string-helper";
import useCallBack from "../../hooks/useCallBack";
import { useGoal } from "../../hooks/Goals/useGoal";
import dateHelper from "../../services/Core/Helpers/date-helper";
import { GoalTypes } from "../../services/Goals/Goal";
import { useIncomeStatement } from "../../hooks/Sales/useIncomeStatement";
import { useIncomeStatementQuarter } from "../../hooks/Sales/useIncomeStatementQuarter";
import { useTranslation } from "react-i18next";
import ProductionGoalDiagram from "./production-goal-diagram";

type IParamTypes = {
  employeeId: string;
};

export default function ProductionGoalPage() {
  const { t } = useTranslation();
  const { employeeId } = useParams<IParamTypes>();
  const [dateFilter, setDateFilter] = useState<Date>(
    dateHelper.getCurrentLastDayDate()
  );
  const startDate = useMemo(
    () => dateHelper.getFirstDayDate(dateFilter),
    [dateFilter]
  );

  const { goal, loadingGoal } = useGoal(
    employeeId,
    GoalTypes.IncomeStatement,
    "monthly",
    dateFilter
  );

  const { incomeStatement, loadingIncomeStatement, loadIncomeStatement } =
    useIncomeStatement(employeeId, startDate, dateFilter);

  const { statementQuarter, loadingStatementQuarter, loadStatementQuarter } =
    useIncomeStatementQuarter(employeeId, dateFilter);

  useCallBack(refresh, 30 * 1000);



  if (loadingIncomeStatement && loadingStatementQuarter && loadingGoal) {
    return <LoadingComponent />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h1>{t("income-statement-page.title")}</h1>
      </div>
      <div className="card-body">
        <fieldset className="border rounded-3 p-1">
          <legend className="legend float-none w-auto px-3">Date:</legend>
          <input
            type="month"
            className="form-control form-control-sm"
            defaultValue={stringHelper.toMonthYearString(dateFilter)}
            onChange={(ev) => onDateChange(ev.target.valueAsDate)}
          />
        </fieldset>
        <ProductionGoalDiagram incomeStatement={incomeStatement} statementQuarter={statementQuarter} goal={goal}/>
      </div>
    </div>
  );

  function onDateChange(dt: Date | null) {
    // console.log("Date changed to :", dt);
    if (dt) {
      let temp = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
      setDateFilter(dateHelper.getLastDayDate(temp));
    }
  }

  function refresh() {
    //console.log(startDate, dateFilter);
    loadIncomeStatement();
    loadStatementQuarter();
  }


}
