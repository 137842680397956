export default class Project {
  id: number = 0;
  quoteId: number = 0;
  remarks: string = "";
  date: Date = new Date(Date.now());
  client: string = "";
  description: Array<string> = [];
  status: string = "";

  assignedTo: string = "";
  assignedToDate: Date = new Date(Date.now());
  state: ProjectState = ProjectState.New;
  finishedBy: string = "";
  finishedDate: Date = new Date(Date.now());

  projectKits: Array<ProjectKit> = [];
}

export class ProjectKit {
  id: number = 0;
  projectId: number = 0;
  kitId: string = "";

  completed: boolean = false;

  completedDate?: Date;
}
export class ToDoAndLateJobs
{
    toDoCount :number;
    lateCount: number;
    constructor(data: any) {
      this.lateCount = data.lateCount;
      this.toDoCount = data.toDoCount;
    }
}
export enum ProjectState {
  New = 0,
  Procurement = 2,
  Completed = 3,
  InProgress = 1,
}
