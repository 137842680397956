import BaseApi from "../Core/REST/BaseApi";
import Activity, { ActivityRequest } from "./Activity";

class ActivityService extends BaseApi {
  async getActivities(
    employeeId: string,
    objectId: string | number,
    objectType: string,
    activityType?: number | string
  ): Promise<Array<Activity> | undefined> {
    let type = activityType ?? "0";
    let url = `${this.baseUrl}/Activities/${employeeId}?objectId=${objectId}&objectType=${objectType}&type=${type}`;
    return await this.get<Array<Activity> | undefined>(url);
  }

  async postActivity(employeeId: string, data: ActivityRequest) {
    let url = `${this.baseUrl}/Activities/${employeeId}`;
    return await this.post(url, data);
  }

  async patchActivity(
    employeeId: string,
    activityId: string,
    description: string
  ) {
    let url = `${this.baseUrl}/Activities/${employeeId}/${activityId}`;
    return await this.patch(url, { Description: description });
  }

  async deleteActivity(employeeId: string, activityId: string) {
    let url = `${this.baseUrl}/Activities/${employeeId}/${activityId}`;
    return await this.delete(url);
  }
}

const activityService = new ActivityService();
export default activityService;
