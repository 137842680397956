export class Permission {
  id: number;
  permissionName: string;
  description?: string;
  permissionValues: Array<PermissionValue> = [];
  constructor(permissionName?: string, id?: number) {
    if (permissionName === undefined) throw new ReferenceError("id undefined");
    if (id === undefined) throw new ReferenceError("permissionName undefined");
    this.permissionName = permissionName;
    this.id = id;
  }
}
export class PermissionValue{
  permissionKeyId : number;
  departmentId: number;
  roleId: number;

  constructor(permissionKeyId?: number,departmentId?:number,roleId?:number) {
    if (permissionKeyId === undefined) throw new ReferenceError("id undefined");
    if (departmentId === undefined) throw new ReferenceError("departmentId undefined");
    if (roleId === undefined) throw new ReferenceError("roleId undefined");
    this.permissionKeyId  = permissionKeyId;
    this.departmentId = departmentId;
    this.roleId = roleId;
  }
}