import { useTranslation } from "react-i18next";
import {
  Page,
  PageDetails,
  PageHeader,
  PageTitle,
} from "../../components/Pages/page-components";
import LogoComponent from "../../components/app/LogoComponent";
import LanguageSelector from "../../components/app/LanguageSelector";
export const CreationConfirmedPage = () => {
  const { t } = useTranslation();
  return (
    <Page id="login-page">
        <div className="p-4">
          <h1>{t("creation-confirmed-page.account-request-sent")}</h1>

          <h5>{t("creation-confirmed-page.details")}</h5>
          <br />
          <a href={"https://portal.flofab.com/"}>
            <button className="btn btn-outline-primary">
              {t("not-found-component.main-page")}
            </button>
          </a>
        </div>
        <div />
    </Page>
  );
};
