import BaseApi from "../Core/REST/BaseApi";

class MotorService extends BaseApi {
  async getAvailableHorsePower(userId: string): Promise<string[] | undefined> {
    let url = `${this.baseUrl}/motors/${userId}/Horsepower`;
    return await this.get<string[] | undefined>(url);
  }

  async getRPMList(userId: string): Promise<Map<string, number> | undefined> {
    let url = `${this.baseUrl}/motors/${userId}/Revolutions-per-minute-list`;
    return await this.get<Map<string, number> | undefined>(url);
  }
}

const motorService = new MotorService();
export default motorService;
