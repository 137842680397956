import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import orderService from "../../services/Orders/OrderService";
import LoadingComponent from "../../components/Core/Loading";
import { CustOrdersAmount } from "../../services/Orders/Models/CustOrdersAmount";
import { SearchBarComponent } from "../../components/Forms/SearchBarComponent";
import stringHelper from "../../services/Core/Helpers/string-helper";
import "./orders-page.scss";
import {
  ReactTableHeaderOptions,
  TableExportDataButton,
  getTableHeaderSortProps,
} from "../../components/react-table/react-table-component";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  DatePickerComponent,
  FilterContainerComponent,
  FilterFieldset,
  useDateRangePicker,
} from "../../components/Forms/Search filters/FilterContainerComponent";
import Pagination from "../../components/react-table/Pagination";
import { MeasurementUnit } from "../../components/Pages/product-info-component";
import numberHelper from "../../services/Core/Helpers/number-helper";

type IParamTypes = {
  employeeId: string;
};

export default function CustOrdersTable() {
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dateRange = useDateRangePicker();
  const { employeeId } = useParams<IParamTypes>();
  const [custInvoicesData, setCustInvoicesData] = useState<
    Array<CustOrdersAmount>
  >([]);

  const showCostCell = (props: any) => {
    return (
      <span>
        <MeasurementUnit className="me-1" unit="$" />
        {stringHelper.toFixedNumberFormat(props.getValue())}
      </span>
    );
  };

  const columns: any = useMemo(() => {
    return [
      {
        header: t("orders-page.id"),
        accessorKey: "custId",
      },
      {
        header: t("orders-page.name"),
        accessorKey: "name",
        filterFn: "equalsString",
      },
      {
        header: t("orders-page.area"),
        accessorKey: "area",
        filterFn: "equalsString",
      },
      {
        header: t("orders-page.quotes"),
        accessorKey: "quotesTotal",
        cell: (props: any) => showCostCell(props),
      },
      {
        header: t("orders-page.submitted"),
        accessorKey: "submittedTotal",
        cell: (props: any) => showCostCell(props),
      },
      {
        header: t("orders-page.approved"),
        accessorKey: "approvedTotal",
        cell: (props: any) => showCostCell(props),
      },
      {
        header: t("orders-page.invoices"),
        accessorKey: "invoicesTotal",
        cell: (props: any) => showCostCell(props),
      },
    ];
  }, [t]);

  const reactTable = useReactTable({
    columns,
    data: custInvoicesData,
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <div id="customer-order-section">
      <FilterContainerComponent className="row">
        <div className="col-lg-4 col-md-8 col-sm-12">
          <FilterFieldset>
            <legend>{t(`common.date`)}:</legend>
            <DatePickerComponent dateRange={dateRange} />
          </FilterFieldset>
        </div>
        <div className="col-3">
          <button
            className="btn btn-outline-primary"
            onClick={() => loadCustSales()}
          >
            Search
          </button>
        </div>
      </FilterContainerComponent>

      <div id="search-bar" className="m-3">
        <div className="input-group">
          <SearchBarComponent
            value={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div>
          <div className="grid-container">
            <table className="table">
              <thead>
                <tr className="table-options">
                  <th
                    colSpan={
                      reactTable
                        .getAllFlatColumns()
                        .filter((x) => x.getIsVisible()).length
                    }
                  >
                    <div className="btn-export">
                      <TableExportDataButton
                        data={getExportData()}
                        filename={`customers-sales-report-${dateRange.startDate}-${dateRange.endDate}`}
                      />
                    </div>
                  </th>
                </tr>
                {reactTable.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <span {...getTableHeaderSortProps(header.column)}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            <ReactTableHeaderOptions header={header} />
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {reactTable.getRowModel().rows.map((row, i: number) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination reactTable={reactTable} siblingCount={2} />
          </div>
        </div>
      )}
    </div>
  );

  function loadCustSales() {
    if (!dateRange.startDate || !dateRange.endDate) {
      toast.error("Select both start and end dates!");
      return;
    } else if (dateRange.startDate > dateRange.endDate) {
      toast.error("Invalid date range. Start date should be before end date.");
      return;
    }

    if (employeeId) {
      setLoading(true);
      orderService
        .getCustOrdersAmount(employeeId, dateRange.startDate, dateRange.endDate)
        .then((data: Array<CustOrdersAmount> | undefined) => {
          if (data) {
            setCustInvoicesData(data);
          }
        })
        .catch((error) => {
          toast.error(error.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function getExportData(): Array<any> {
    let temp: Array<any> = 
      reactTable.getFilteredRowModel().rows.map((item) => {
        var data = item.original as CustOrdersAmount;
        return {
          ID: data.custId,
          Name: data.name,
          Area: data.area,
          Quotes: numberHelper.toCurrencyFormat(data.quotesTotal),
          Submitted: numberHelper.toCurrencyFormat(data.submittedTotal),
          Approved: numberHelper.toCurrencyFormat(data.approvedTotal),
          Invoices: numberHelper.toCurrencyFormat(data.invoicesTotal),
        };
      });
    return temp;
  }
}
