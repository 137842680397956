import { useEffect, useRef } from "react";

function useCallBack(callback: Function, delay = 1000, deps = []) {
  const savedCallback = useRef<any>(); // to save the current "fresh" callback

  // keep callback ref up to date
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // create the interval
  useEffect(() => {
    // function to call the callback
    function runCallback() {
      if (savedCallback?.current) {
        savedCallback.current();
      }
    }

    if (typeof delay === "number") {
      // run the interval
      let interval = setInterval(runCallback, delay);
      // clean up on unmount or dependency change
      return () => clearInterval(interval);
    }
  }, [delay, ...deps]);
}

export default useCallBack;
