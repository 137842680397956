import "./Loading.scss";

export default function LoadingComponent() {
  return (
    <div className="loading-image">
      <img
        className="beat fade-in-image text-center"
        src={window.location.origin + "/flofab_logo.svg"}
      />
    </div>
  );
}
